import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import FileSaver from 'file-saver';
import {
  ArchiveProfileAssessment,
  GetAssessmentCsv,
  GetFilteredProfileAssessments,
  GetFilteredProfileCustomersAssessments,
  GetProfileAssessmentReports,
  SetAssessmentsOrganization,
  RestoreProfileAssessment,
} from 'api/endpoints';
import { getCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY, TOKEN_AUTH_KEY } from 'utils/auth';
import { composeRequest } from 'utils/index';
import { getAssessmentRequest } from './assessment';

export const getAssessmentsRequest = async (args, { getState }) => {
  const { assessments, assessmentsFilters, app } = getState();
  const payload = {
    count: assessments.pagination.pageSize,
    page: assessments.pagination.current,
    descending: assessments.sorter.descending,
    column: assessments.sorter.column || '',
    search: assessments.search,
    ...args,
  };

  assessmentsFilters.filters.forEach((filter) => {
    if (filter.id !== undefined) {
      if (app.isMyVendorsTabSelected) {
        payload[filter.type === 'vendor' ? 'customer' : filter.type] = JSON.stringify(filter.id);
      } else {
        payload[`get-filtered-assessments-${filter.type}`] = JSON.stringify(filter.id);
      }
    }
  });

  const url = app.isMyVendorsTabSelected
    ? GetFilteredProfileCustomersAssessments.url
    : GetFilteredProfileAssessments.url;

  return axios.post(url, payload, { withCredentials: true });
};

export const getAssessments = createAsyncThunk('getAssessments', getAssessmentsRequest);

export const getAssessmentsPerCount = async (args) => {
  const { count, page } = args;
  const payload = {
    count: count || 10,
    page: page || 1,
    descending: false,
    column: '',
    search: '',
  };
  const url = GetFilteredProfileAssessments.url;
  return axios.post(url, payload, { withCredentials: true });
};

export const fetchZipReports = async (assessmentsIds) => {
  try {
    const assessments = assessmentsIds.join(',');
    const params = {
      'get-assessment-reports-assessment-tokens': assessments,
      'get-assessment-reports-assessment-base64': false,
      org_id: getCookie(ORG_ID_AUTH_KEY),
      token: getCookie(TOKEN_AUTH_KEY),
    };

    const url = composeRequest({ endpoint: GetProfileAssessmentReports.url });
    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url.toString());
    const blob = await response.blob();
    const fileName = `Findings Report (${moment().format('DD-MM-YYYY HH:mm')}).zip`;

    const file = await new File([blob], fileName, { type: blob.type });

    FileSaver.saveAs(file);
    return true;
  } catch (e) {
    return e;
  }
};

const exportToCsvRequest = async (assessmentId) => {
  try {
    const data = new FormData();
    data.append('assessment-token', assessmentId);
    data.append('org_id', getCookie(ORG_ID_AUTH_KEY));
    data.append('token', getCookie(TOKEN_AUTH_KEY));

    const url = composeRequest({ endpoint: GetAssessmentCsv.url });

    const response = await fetch(url, {
      method: 'POST',
      body: data,
    });
    const blob = await response.blob();

    const { _assessment } = await getAssessmentRequest({ id: assessmentId });

    const fileName = `${_assessment.customerName}_${
      _assessment.title ? _assessment.title : 'Untitled'
    }_(${moment().format('DD-MM-YYYY HH:mm')}).csv`;

    const file = await new File([blob], fileName, { type: blob.type });
    FileSaver.saveAs(file);
    return true;
  } catch (e) {
    return e;
  }
};

export const exportToCsv = createAsyncThunk('exportToCsv', exportToCsvRequest);

const archiveRequest = async (ids) => {
  const payload = {
    'archive-assessment-assessment-tokens': ids.join(','),
  };
  return axios.post(ArchiveProfileAssessment.url, payload, { withCredentials: true });
};

export const archive = createAsyncThunk('archive', archiveRequest);

const restoreRequest = async (ids) => {
  const payload = {
    'restore-assessment-assessment-tokens': ids.join(','),
  };
  return axios.post(RestoreProfileAssessment.url, payload, { withCredentials: true });
};

export const restore = createAsyncThunk('restore', restoreRequest);

const assignAssessmentsToOrganizationRequest = async (organizationsToAssessments) => {
  const payload = {
    organizationsToAssessments: JSON.stringify(organizationsToAssessments),
  };
  return axios.post(SetAssessmentsOrganization.url, payload, { withCredentials: true });
};

export const assignAssessmentsToOrganization = createAsyncThunk(
  'assignAssessmentsToOrganization',
  assignAssessmentsToOrganizationRequest,
);
