import React, { lazy, Suspense, useMemo, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import PageHeader from 'components/PageHeader';
import AssessmentsFilters from 'components/AssessmentsFilters';
import AddFilterButton from 'components/AddFilterButton';

// UI COMPONENTS
import Tabs from 'ui/Tabs';
import UiButton from 'ui/Button';
import Icon from 'ui/Icon';

// UTILS
import { MixpanelAssignAssessmentSource } from 'constants/mixpanel';
import { translate } from 'utils/index';
import ROUTES from 'constants/routes';
import { useAppNavigate, useIsMyVendorsTabSelected, useSelectedPath } from 'hooks/navigation';
import { useTheme } from 'hooks/theme';
import { archive, getAssessments, exportToCsv, fetchZipReports, restore } from 'api/assessments';
import { downloadReportDisableState } from 'utils/assessment';
import { useRole } from 'hooks/useRole';

// REDUX
import { setFilter } from 'components/AssessmentsFilters/reducers';
import { selectIsFilteredArchived } from 'components/AssessmentsFilters/selectors';
import { messages } from './messages';
import AssessmentsList from './list';
import AssessmentsTemplates from './templates';
import Search from './search';
import FilterArchive from './filterArchive';

// STYLE
import './index.scss';

const tabs = {
  assigned: 'assigned',
  templates: 'templates',
};

const ShowCaseEditComponent = lazy(() => import('pages/Showcases/showcaseEditor'));

const Assessments = () => {
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const selectedPath = useSelectedPath();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { assessments } = useSelector((state) => state.assessments);
  const { colorTheme } = useTheme();
  const isFilteredArchived = useSelector(selectIsFilteredArchived);
  const { isRestrictedBoSoViewerRole, isBoSoRole } = useRole();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [createShowcase, setCreateShowcase] = useState(false);

  const handleCreateShowcase = () => {
    setCreateShowcase((state) => !state);
  };

  const isTemplatesPath =
    selectedPath === ROUTES.VENDOR_ASSESSMENT_TEMPLATES ||
    selectedPath === ROUTES.CUSTOMER_ASSESSMENT_TEMPLATES;

  const assignAssessment = () =>
    appNavigate(
      isMyVendorsTabSelected ? ROUTES.VENDOR_ASSIGN_ASSESSMENT : ROUTES.CUSTOMER_ASSIGN_ASSESSMENT,
      {},
      {
        state: {
          source: isMyVendorsTabSelected
            ? MixpanelAssignAssessmentSource.VendorAssessments
            : MixpanelAssignAssessmentSource.CustomerAssessments,
        },
      },
    );

  const onGenerateZipReport = async (assessmentId) => {
    if (selectedRowKeys.length === 0 && !assessmentId) return;
    setIsLoading(true);
    const assessmentIds = assessmentId ? [assessmentId] : selectedRowKeys;
    await fetchZipReports(assessmentIds);
    setIsLoading(false);
  };

  const onExport = async (assessmentId) => {
    if (selectedRowKeys.length === 0 && !assessmentId) return;

    setIsLoading(true);

    const assessmentIds = assessmentId ? [assessmentId] : selectedRowKeys;

    await Promise.all(
      assessmentIds.map(async (selectedItem) => {
        await dispatch(exportToCsv(selectedItem)).unwrap();
      }),
    );

    setIsLoading(false);
  };

  const onArchive = async (assessmentId) => {
    if (selectedRowKeys.length === 0 && !assessmentId) return;

    const assessmentIds = assessmentId ? [assessmentId] : selectedRowKeys;

    setIsLoading(true);
    await dispatch(archive(assessmentIds)).unwrap();
    await dispatch(getAssessments()).unwrap();
    setSelectedRowKeys([]);
    setIsLoading(false);
  };

  const onRestore = async (assessmentId) => {
    if (selectedRowKeys.length === 0 && !assessmentId) return;

    const assessmentIds = assessmentId ? [assessmentId] : selectedRowKeys;

    setIsLoading(true);
    await dispatch(restore(assessmentIds)).unwrap();
    await dispatch(getAssessments()).unwrap();
    setSelectedRowKeys([]);
    setIsLoading(false);
  };

  const setAssessmentsFilter = (type) => {
    dispatch(setFilter({ type }));
  };

  const disableOfReportDownload = useMemo(() => {
    return downloadReportDisableState(assessments, selectedRowKeys);
  }, [selectedRowKeys[selectedRowKeys.length - 1]]);

  return (
    <div className="assessments-page">
      <PageHeader>{translate(messages.assessments)}:</PageHeader>
      <Tabs
        defaultActiveKey={isTemplatesPath ? tabs.templates : tabs.assigned}
        onChange={(activeKey) => {
          if (activeKey === tabs.assigned) {
            appNavigate(
              isMyVendorsTabSelected ? ROUTES.VENDOR_ASSESSMENTS : ROUTES.CUSTOMER_ASSESSMENTS,
            );
          }
          if (activeKey === tabs.templates) {
            appNavigate(
              isMyVendorsTabSelected
                ? ROUTES.VENDOR_ASSESSMENT_TEMPLATES
                : ROUTES.CUSTOMER_ASSESSMENT_TEMPLATES,
            );
          }
        }}
        rounded
      >
        <Tabs.Pane tab={translate(messages.assigned)} key="assigned" data-test="assigned-tab">
          <div className="assessments-page__wrapper">
            <div className="assessments-page-actions">
              <Search />
              <AddFilterButton setFilter={setAssessmentsFilter} filtersType="assessment" />
              <div className="assessments-page__actions-right">
                <FilterArchive />
                {!isMyVendorsTabSelected && (
                  <Tooltip title={translate(messages.createShowcase)}>
                    <Button
                      className="assessments-page__action"
                      disabled={selectedRowKeys.length === 0 || isRestrictedBoSoViewerRole}
                      type="link"
                      onClick={handleCreateShowcase}
                    >
                      <Icon icon="create-showcase" />
                    </Button>
                  </Tooltip>
                )}

                <Tooltip title={translate(messages.downloadReport)}>
                  <Button
                    className="assessments-page__action"
                    type="link"
                    onClick={() => onGenerateZipReport()}
                    disabled={disableOfReportDownload}
                  >
                    <Icon icon="download" />
                  </Button>
                </Tooltip>
                <Tooltip title={translate(messages.exportCsv)}>
                  <Button
                    className="assessments-page__action"
                    type="link"
                    onClick={() => onExport()}
                    disabled={selectedRowKeys.length === 0}
                  >
                    <Icon icon="xlsx" />
                  </Button>
                </Tooltip>
                {isFilteredArchived ? (
                  <Tooltip title={translate(messages.restoreFromArchive)}>
                    <Button
                      className="assessments-page__action"
                      type="link"
                      onClick={() => onRestore()}
                      disabled={selectedRowKeys.length === 0 || isRestrictedBoSoViewerRole}
                    >
                      <Icon icon="restore" />
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title={translate(messages.sendToArchive)}>
                    <Button
                      className="assessments-page__action"
                      type="link"
                      onClick={() => onArchive(null)}
                      disabled={selectedRowKeys.length === 0 || isRestrictedBoSoViewerRole}
                    >
                      <Icon icon="archive" />
                    </Button>
                  </Tooltip>
                )}

                <UiButton
                  data-test="assessments-page-button-add-assessment"
                  className="assessments-page__assign-button"
                  disabled={isRestrictedBoSoViewerRole}
                  beforeIcon="plus"
                  color={colorTheme}
                  size="sm"
                  onClick={assignAssessment}
                >
                  {translate(messages.assignAssessment)}
                </UiButton>
              </div>
            </div>
            <AssessmentsFilters hideArchive />
            <AssessmentsList
              isLoading={isLoading}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              onArchive={onArchive}
              onExport={onExport}
              onGenerateZipReport={onGenerateZipReport}
              onRestore={onRestore}
            />
          </div>
        </Tabs.Pane>
        <Tabs.Pane
          disabled={isBoSoRole}
          tab={translate(messages.templates)}
          key="templates"
          data-test="templates-tab"
        >
          <AssessmentsTemplates />
        </Tabs.Pane>
      </Tabs>
      {!isMyVendorsTabSelected && createShowcase && (
        <Suspense fallback={<div>Loading...</div>}>
          <ShowCaseEditComponent
            isOpen={createShowcase}
            onClose={handleCreateShowcase}
            newShowCase
            selectedRowKeys={selectedRowKeys}
          />
        </Suspense>
      )}
    </div>
  );
};

export default Assessments;
