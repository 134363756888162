import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import { translate } from 'utils/index';
import { selectAggregatedOrganizations } from 'pages/Organization/selectors';
import { messages } from 'pages/Vendors/VendorsList/messages';
import Filter from './filter';

const { Option } = Select;

const FilterParentOrganization = ({ fetchVendors, filter }) => {
  const aggregatedOrganizations = useSelector(selectAggregatedOrganizations);

  return (
    <Filter
      filter={filter}
      fetchVendors={fetchVendors}
      innerLabel={translate(messages.filterOrganization)}
    >
      {aggregatedOrganizations?.map(({ id, label }) => (
        <Option key={id} id={id} value={label}>
          {label}
        </Option>
      ))}
    </Filter>
  );
};

FilterParentOrganization.propTypes = {
  fetchVendors: PropTypes.func,
  filter: PropTypes.object,
};

export default FilterParentOrganization;
