import { messages } from './messages';

export const availableFilters = [
  {
    type: 'company-filter',
    name: messages.filterCompany,
  },
  {
    type: 'assessment-filter',
    name: messages.filterAssessment,
  },
  {
    type: 'vendor-risk-filter',
    name: messages.filterCustomerRisk,
  },
  {
    type: 'parent-organization-filter',
    name: messages.filterParentOrganization,
  },
];
