import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import SecondaryButton from 'ui/SecondaryButton';
import { selectFinding } from 'pages/Assessment/finalized/findings/reducers';
import { getOffset } from 'utils/html';
import { translate } from 'utils/index';
import ROUTES from 'constants/routes';
import { selectAssessment } from './selectors';
import { countFindings } from './lib';
import AddFinding from './addFinding';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import './findings.scss';

const Findings = ({ control, 'data-test': dataTest, showcaseAssessmentPreview = false }) => {
  const [addFindingModal, setAddFindingModal] = useState();
  const appNavigate = useAppNavigate();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { isRestrictedBoSoViewerRole } = useRole();
  const { assessor, id } = useSelector(selectAssessment);
  const dispatch = useDispatch();

  const { findings, answer } = control;

  const goToFinding = () => {
    if (showcaseAssessmentPreview) {
      setAddFindingModal(true);
      return;
    }
    appNavigate(
      isMyVendorsTabSelected
        ? ROUTES.VENDOR_ASSESSMENT_FINDINGS
        : ROUTES.CUSTOMER_ASSESSMENT_FINDINGS,
      { assessmentId: id },
      { replace: true },
    );

    const firstEnabledFindingId = findings.find((finding) => finding.enabled)?.id;
    dispatch(selectFinding(firstEnabledFindingId));

    setTimeout(() => {
      const findingsElement = document.getElementById('assessment-finalized-findings');
      const findingsElementOffset = getOffset(findingsElement);

      window.scroll({
        top: findingsElementOffset.top - 132,
        left: 0,
        behavior: 'smooth',
      });
    });
  };

  const findingsLength = countFindings(findings, answer);

  const renderAddFindingButton = () => {
    return (
      (assessor || showcaseAssessmentPreview) && (
        <SecondaryButton
          data-test={dataTest || 'add-finding-button'}
          className="assessment-findings__add-finding-button"
          disabled={isRestrictedBoSoViewerRole}
          onClick={() => setAddFindingModal(true)}
          icon="add2"
          tooltip={translate(messages.addFinding)}
          link
        />
      )
    );
  };

  return (
    <div>
      {findingsLength ? (
        <SecondaryButton
          data-test={dataTest || 'add-finding-button'}
          className="assessment-findings__button"
          counter={findingsLength}
          onClick={goToFinding}
          icon="findings"
          tooltip={translate(messages.findingsText)}
          link
        />
      ) : (
        renderAddFindingButton()
      )}
      <AddFinding
        control={control}
        visible={addFindingModal}
        toggleModal={setAddFindingModal}
        finding={showcaseAssessmentPreview ? findings[0] : null}
      />
    </div>
  );
};

Findings.propTypes = {
  control: PropTypes.object,
  showcaseAssessmentPreview: PropTypes.bool,
  'data-test': PropTypes.string,
};

export default Findings;
