import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import fileDownload from 'js-file-download';

// COMPONENTS:
import FileUploadItem from 'components/UploadFiles/fileUploadItem';
import CardWithLogo from 'components/CardWithLogo';
import Popup from 'ui/Popup';
import ExcelView from 'components/FileBrowser/excelView';
import Icon from 'ui/Icon';
import Button from 'ui/Button';
import DocView from 'components/FileBrowser/docView';

// HOOKS:
import { useTheme } from 'hooks/theme';

// UTILS:
import { downloadFile } from 'api/myFiles';
import { getFileExtension } from 'utils/files';
import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';
import { getTemplateReport } from 'api/editor';
import { truncateFilename } from 'pages/MyFiles/lib';

// STYLES:
import './index.scss';

const excelFileTypes = ['xlsx', 'xls', 'csv'];

const FileBrowser = ({ files, edit, browse, disabled, setFileToRemove, isTemplate }) => {
  const { templateId } = useParams();

  const initState = {
    open: false,
    activeIndex: 0,
    url: '',
  };

  const [fileBrowse, setFileBrowse] = useState(initState);
  const { colorTheme } = useTheme();

  const getFileSrc = useCallback(
    async (fileIndex) => {
      const { path } = await downloadFile({
        fileId: files[fileIndex].id || files[fileIndex].fileId,
      });
      return path;
    },
    [files],
  );

  const handlePath = async (index) => {
    let path = '';
    if (isTemplate) {
      const title = files[fileBrowse.activeIndex]?.title || '';
      const type = getFileExtension(title);
      const response = await getTemplateReport({
        assessmentId: templateId,
      });
      path = `data:${type};base64,${response}`;
    } else {
      path = await getFileSrc(index);
    }
    return path;
  };

  const openFileBrowser = async (index) => {
    const path = await handlePath(index || fileBrowse.activeIndex);

    setFileBrowse((state) => ({
      open: true,
      activeIndex: index || state.activeIndex,
      url: path,
    }));
  };

  const closeFileBrowser = () => {
    setFileBrowse(initState);
  };

  const onClickNext = async () => {
    const index = fileBrowse.activeIndex + 1 > files.length - 1 ? 0 : fileBrowse.activeIndex + 1;
    const path = await handlePath(index);

    setFileBrowse((state) => ({
      ...state,
      activeIndex: index,
      url: path,
    }));
  };

  const onClickPrevious = async () => {
    const index = fileBrowse.activeIndex - 1 < 0 ? files.length - 1 : fileBrowse.activeIndex - 1;
    const path = await handlePath(index);

    setFileBrowse((state) => ({
      ...state,
      activeIndex: index,
      url: path,
    }));
  };

  const handleDownload = async (currentFile) => {
    if (!currentFile) return;
    let path = currentFile;
    if (!isTemplate) {
      path =
        (
          await downloadFile({
            fileId: currentFile.id,
          })
        )?.path || {};
      if (!path) return;
    }

    fetch(path, { credentials: 'same-origin' })
      .then((res) => res.blob())
      .then((data) => {
        const dataFile = new File([data], 'file', { type: data.type });
        fileDownload(dataFile, files[fileBrowse.activeIndex].title);
      });
  };

  const rightFooterButtons = (
    <div className="file-browser__footer-right-buttons">
      <Button
        data-test="file-browser-button-footer-download"
        outline
        name={translate(generalMessages.download)}
        onClick={() => handleDownload(isTemplate ? fileBrowse.url : files[fileBrowse.activeIndex])}
        color={colorTheme}
        size="sm"
      />
      <Button
        data-test="file-browser-button-footer-next"
        name={translate(generalMessages.next)}
        onClick={onClickNext}
        color={colorTheme}
        disabled={files.length === 1}
        size="sm"
      />
    </div>
  );

  const footer = useMemo(
    () => (
      <CardWithLogo.Footer
        leftButtonOnClick={onClickPrevious}
        leftButtonAdditionalProps={{ disabled: files.length === 1 }}
        leftButtonText={translate(generalMessages.previous)}
        rightComponent={rightFooterButtons}
      />
    ),
    [fileBrowse],
  );

  const popup = useMemo(() => {
    const title = files[fileBrowse.activeIndex]?.title || '';
    const path = files[fileBrowse.activeIndex]?.path || '';
    const type = getFileExtension(path);
    const isExcel = excelFileTypes.indexOf(type) > -1;
    const isDoc = type === 'docx' || type === 'doc';

    return (
      <Popup
        open={fileBrowse.open}
        width={1000}
        withIconOnTitle="upload2"
        footer={footer}
        onCancel={closeFileBrowser}
        title={title}
        className="file-browser__popup"
      >
        <>
          <Icon icon="upload2" className="ui-popup__title-icon file-browser__popup-icon" />
          <div className="file-browser__file">
            {isExcel && <ExcelView fileUrl={fileBrowse.url} />}
            {isDoc && <DocView handleDownload={() => handleDownload(fileBrowse.url)} />}
            {!isExcel && !isDoc && (
              <iframe
                title={files[fileBrowse.activeIndex]?.title || ''}
                src={fileBrowse.url}
                className="file-browser__iframe"
              />
            )}
          </div>
        </>
      </Popup>
    );
  }, [fileBrowse.url]);

  return (
    <>
      <div className="file-browser">
        {files?.map((file, index) => {
          const type = getFileExtension(file?.path);
          return (
            <FileUploadItem
              key={file?.id || file?.fileId}
              id={file?.id || file?.fileId}
              name={truncateFilename(file)}
              type={type}
              handleEdit={edit ? () => edit(file) : undefined}
              handleRemove={!disabled ? () => setFileToRemove(file) : undefined}
              openFileBrowser={browse ? () => openFileBrowser(index, type) : undefined}
            />
          );
        })}
      </div>
      {popup}
    </>
  );
};

FileBrowser.propTypes = {
  files: PropTypes.array,
  edit: PropTypes.func,
  browse: PropTypes.bool,
  disabled: PropTypes.bool,
  setFileToRemove: PropTypes.func,
  isTemplate: PropTypes.bool,
  assessmentIdProp: PropTypes.string,
  assessmentId: PropTypes.string,
};

export default FileBrowser;
