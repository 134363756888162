import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Tooltip } from 'antd';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

import { moveControlBetweenCategories, saveTemplate } from 'api/editor';
import { translate } from 'utils/index';
import Input from 'ui/Input';
import Icon from 'ui/Icon';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import { selectSubject } from 'pages/Editor/selectors';
import TriggersModal from 'pages/Editor/subjects/triggersModal';
import { getAllCategoryTriggers } from 'utils/triggers';
import { messages } from '../messages';
import './listItem.scss';

const ListItem = ({
  activeEdit,
  id,
  index,
  selectedSubjectId,
  title,
  weight,
  subjectOnClick,
  onDoubleClickTitle,
  onChangeInputTitle,
  onBlurInputTitle,
  onPressEnter,
  reorderSubjects,
  saveReorderedSubjects,
  onReorderChange,
  disabled = false,
}) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { controls } = useSelector(selectSubject);
  const [openedModal, setOpenModal] = useState(false);
  const [triggersControls, setTriggersControls] = useState([]);
  const [loading, setLoading] = useState();
  const [subjectToRemove, setSubjectToRemove] = useState();
  const [{ handlerId, isOver, canDrop }, dropRef] = useDrop({
    accept: ['question', 'category'],
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.getItemType() === 'question',
      canDrop: monitor.canDrop() && monitor.getItemType() === 'question' && !disabled,
      handlerId: monitor.getHandlerId(),
    }),
    drop: async (item, monitor) => {
      if (disabled) return;
      if (monitor.getItemType() === 'question') {
        return moveControl(item);
      }

      saveReorderedSubjects();
    },
    hover(item, monitor) {
      if (!ref.current || monitor.getItemType() === 'question') {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      reorderSubjects(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, dragRef, dragPreviewRef] = useDrag({
    type: 'category',
    item: { id, title, weight, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    onReorderChange(isDragging);
  }, [isDragging]);

  dragPreviewRef(dropRef(ref));

  const moveControl = async (item) => {
    if (selectedSubjectId === id) {
      return;
    }

    const allTriggers = getAllCategoryTriggers(controls, item.triggers);
    const triggersControls = controls.filter((control) => allTriggers.includes(control.id));

    if (triggersControls.length) {
      setOpenModal(true);
      setTriggersControls(triggersControls);
    }

    await dispatch(moveControlBetweenCategories({ controlId: item.id, categoryId: id }));
  };

  const moveTriggers = async (triggers) => {
    setLoading(true);
    await Promise.all(
      triggers.map(async (trigger) => {
        await dispatch(moveControlBetweenCategories({ controlId: trigger, categoryId: id }));
      }),
    );
    setOpenModal(false);
    setLoading(false);
  };

  const onRemove = () => {
    const payload = {
      categories: [
        {
          id,
        },
      ],
    };

    dispatch(saveTemplate(payload));
  };

  const subjectClassName = classNames('editor-subject-list-subject', {
    'editor-subject-list-subject--selected': selectedSubjectId === id,
    'editor-subject-list-subject--active': activeEdit.id === id,
    'editor-subject-list-subject--drop-over': isOver && selectedSubjectId !== id && !disabled,
    'editor-subject-list-subject--drop-over--disabled':
      isOver && selectedSubjectId !== id && disabled,
    'editor-subject-list-subject--drop-active': canDrop && selectedSubjectId !== id,
    'editor-subject-list-subject--is-dragging': isDragging && !disabled,
  });

  const editorSubjectClasses = classNames('editor-subject-list-subject__name', {
    'editor-subject-list-subject__name--active-edit': activeEdit.id !== '',
  });

  return (
    <>
      <div
        ref={ref}
        className={subjectClassName}
        onClick={() => subjectOnClick(id)}
        onDoubleClick={() => !disabled && onDoubleClickTitle(id, title)}
        data-test={`subject-list-item-${index}`}
      >
        <div
          ref={dragRef}
          className="editor-subject-list-subject__drag-icon-wrapper"
          data-test={`editor-subject-list-drag-icon-${index}`}
        >
          <Icon
            ref={dragRef}
            data-handler-id={handlerId}
            icon="drag"
            className="editor-subject-list-subject__drag-icon"
          />
        </div>
        <span className={editorSubjectClasses}>
          {activeEdit.id === id ? (
            <Input
              inline
              value={activeEdit.value}
              inputClassName="editor-subject-list-subject__title-input"
              data-test={`subject-list-input-0-${index}`}
              onChange={onChangeInputTitle}
              onBlur={onBlurInputTitle}
              onKeyUp={onPressEnter}
              autoFocus
              disabled={disabled}
            />
          ) : (
            <Tooltip
              key={`tooltip-${id}`}
              placement="bottom"
              color="white"
              title={translate(messages.subjectTitleTooltip)}
              overlayInnerStyle={{ color: 'black' }}
              mouseEnterDelay={2}
            >
              <div>
                {`${index + 1}. `}
                {title || translate(messages.categoryPlaceholder)}
              </div>
            </Tooltip>
          )}
        </span>

        {!!weight && (
          <span className="editor-subject-list-subject__weight">{(weight * 100).toFixed(2)}%</span>
        )}

        <div className="editor-subject-list-subject__remove-wrapper">
          <Button
            data-test={`editor-subject-list-subject-button-remove-subject-${id}`}
            className="editor-subject-list-subject__remove-subject"
            type="link"
            onClick={() => setSubjectToRemove(id)}
            disabled={disabled}
          >
            <Icon icon="remove" />
          </Button>
        </div>
      </div>
      <TriggersModal
        controls={triggersControls}
        loading={loading}
        onCancel={() => setOpenModal(false)}
        onOk={moveTriggers}
        subjectId={selectedSubjectId}
        open={openedModal}
      />
      <RemoveConfirmationModal
        confirmLoading={loading}
        onOk={onRemove}
        onCancel={() => setSubjectToRemove(null)}
        text={translate(messages.removeSubjectText)}
        open={!!subjectToRemove}
      />
    </>
  );
};

ListItem.propTypes = {
  activeEdit: PropTypes.object,
  id: PropTypes.string,
  index: PropTypes.number,
  selectedSubjectId: PropTypes.string,
  title: PropTypes.string,
  weight: PropTypes.number,
  subjectOnClick: PropTypes.func,
  onDoubleClickTitle: PropTypes.func,
  onChangeInputTitle: PropTypes.func,
  onBlurInputTitle: PropTypes.func,
  onPressEnter: PropTypes.func,
  reorderSubjects: PropTypes.func,
  saveReorderedSubjects: PropTypes.func,
  onReorderChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ListItem;
