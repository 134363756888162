import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import { newUserSchema } from 'pages/Organization/schema';
import { translate } from 'utils/index';
import { mixpanelTrackTeammateAdded } from 'utils/mixpanel';
import { messages } from 'pages/Organization/messages';
import { addOrganizationProfile } from 'api/organizations';
import Icon from 'ui/Icon';
import Button from 'ui/Button';
import Input from 'ui/Input';
import Popup from 'ui/Popup';
import Select from 'ui/Select';

import { Roles } from 'constants/roles';
import 'pages/Organization/settings/index.scss';
import { notification } from 'antd';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

const NewUserButton = ({ orgId, orgIndex, assessmentId, ...otherProps }) => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const initValues = { email: '', role: undefined };
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const setPopupOnClick = () => {
    setShowPopup(!showPopup);
    setValues(initValues, false);
    setErrors({});
  };

  useEffect(() => {
    if (assessmentId) {
      setPopupOnClick();
    }
  }, []);

  const options = [
    { label: translate(messages.Admin), value: Roles.Admin, key: 'AdminRoleSelect' },
    { label: translate(messages.BO), value: Roles.BO, key: 'BORoleSelect' },
    { label: translate(messages.SO), value: Roles.SO, key: 'SORoleSelect' },
    { label: translate(messages.Viewer), value: Roles.Viewer, key: 'ViewerRoleSelect' },
  ];

  const {
    errors,
    values: { email, role },
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    setErrors,
  } = useFormik({
    validateOnChange: false,

    initialValues: {
      email: '',
      role: undefined,
    },

    validationSchema: newUserSchema(
      translate(messages.orgEditUserEmailErrorFormat),
      translate(messages.orgEditUserEmailRequired),
      translate(messages.orgEditUserRoleRequired),
    ),

    onSubmit: async () => {
      const payload = { email, role, orgId, orgIndex };
      if (assessmentId) payload.assessmentId = assessmentId;
      const {
        payload: { profiles },
      } = await dispatch(addOrganizationProfile(payload));
      setShowPopup(!showPopup);
      setValues(initValues);

      const source = isMyVendorsTabSelected
        ? 'upstream user management'
        : 'downstream user management';
      mixpanelTrackTeammateAdded({ ...payload, options, source });

      if (profiles) {
        if (assessmentId) {
          return notification.success({
            message: translate(messages.orgEditNewUserAssessmentSuccess),
          });
        }
        return notification.success({ message: translate(messages.orgEditNewUserSuccess) });
      }
      if (assessmentId) {
        return notification.success({
          message: translate(messages.orgEditNewUserAssessmentError),
        });
      }
      return notification.error({ message: translate(messages.orgEditNewUserError) });
    },
  });

  return (
    <>
      <Button
        size="sm"
        name={translate(messages.orgEditAddUser)}
        beforeComponent={<Icon className="organizations-settings-page__button-icon" icon="plus" />}
        color="pink"
        width={120}
        onClick={setPopupOnClick}
        data-test="organization-edit-button-add-user"
        {...otherProps}
      />
      <Popup.WithForm
        className="organizations-settings-page__new-org-popup"
        open={showPopup}
        onClickCancel={setPopupOnClick}
        withIconOnTitle="plus"
        title={translate(messages.orgEditNewUserTitle)}
        width={400}
        onClickOk={handleSubmit}
      >
        <Input
          block
          name="email"
          type="email"
          value={email}
          placeholder={translate(messages.orgEditNewUserEmailPlaceholder)}
          onChange={handleChange}
          error={errors.email}
          data-test="organization-edit-new-user-input-email"
        />
        <Select
          placeholder={translate(messages.orgEditNewUserRolePlaceholder)}
          options={options}
          size="large"
          color="gray"
          name="role"
          value={role}
          onSelect={(value) => setFieldValue('role', value, false)}
          error={errors.role}
          className="organizations-settings-page__new-org-popup-select"
          showSearch={false}
        />
      </Popup.WithForm>
    </>
  );
};

NewUserButton.propTypes = {
  orgId: PropTypes.string,
  orgIndex: PropTypes.string,
  assessmentId: PropTypes.string,
};
export default NewUserButton;
