import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Select } from 'antd';
import classNames from 'classnames';

import Icon from 'ui/Icon';
import { translate } from 'utils/index';

import { setFilter, removeFilter } from '../../reducers';
import { messages } from '../messages';
import './filter.scss';

const Filter = ({ children, filter, fetchCustomers, ...props }) => {
  const dispatch = useDispatch();

  const onSelect = () => {
    fetchCustomers();
  };

  const onChange = (value, { id }) => {
    dispatch(setFilter({ type: filter.type, value, id }));
  };

  const onRemove = () => {
    dispatch(removeFilter({ type: filter.type }));
    fetchCustomers();
  };

  return (
    <div
      className={classNames(
        'customers-filters-filter ',
        `customers-filters-filter--${filter.type}`,
      )}
    >
      <Select
        showSearch={true}
        value={filter.value}
        onChange={onChange}
        onSelect={onSelect}
        placeholder={translate(messages.filterPlaceholder)}
        style={{ width: '100%' }}
        {...props}
      >
        {children}
      </Select>

      <Button type="link" className="customers-filters-filter__close-button" onClick={onRemove}>
        <Icon className="customers-filters-filter__close-button-ico" icon="close" />
      </Button>
    </div>
  );
};

Filter.propTypes = {
  children: PropTypes.node,
  fetchCustomers: PropTypes.func,
  filter: PropTypes.object,
};

export default Filter;
