import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ComponentFilterArchive from 'components/AssessmentsFilters/filterArchive';
import { messages as assessmentsFiltersMessages } from 'components/AssessmentsFilters/messages';
import { translate } from 'utils/index';
import { setPagination } from 'pages/Vendors/reducers';
import { getProfileVendors } from 'api/vendor';

import { selectFilters } from '../../selectors';
import { setFilter } from '../../reducers';
import './filterArchive.scss';

const FilterArchive = () => {
  const filters = useSelector(selectFilters);
  const dispatch = useDispatch();

  const fetchAssessments = () => {
    dispatch(setPagination({ current: 1 }));
    dispatch(getProfileVendors());
  };

  const onChange = (value, id, type) => {
    dispatch(setFilter({ type, value, id: Boolean(id) }));
  };

  const defaultFilter = {
    type: 'archive',
    id: false,
    value: translate(assessmentsFiltersMessages.active),
  };

  return (
    <div className="vendors-page-filter-archive">
      <ComponentFilterArchive
        filter={filters.find(({ type }) => type === 'archive') || defaultFilter}
        fetchData={fetchAssessments}
        onChange={onChange}
        isBooleanValue
      />
    </div>
  );
};

export default FilterArchive;
