import { translate } from 'utils/index';
import { contactInformationSchema } from './Tabs/ContactInformationTab/schema';
import { messages } from './messages';

export const validPayload = async (payload) => {
  const schema = contactInformationSchema(
    translate(messages.requiredCompanyName),
    translate(messages.requiredCompanyContact),
    translate(messages.companyEmailErrorFormat),
    translate(messages.requiredCompanyEmail),
    translate(messages.inputNameError),
    translate(messages.companyDomainErrorFormat),
  );
  let err = {};
  try {
    await schema.validate(payload, { abortEarly: false });
  } catch (e) {
    err = e.inner.reduce(
      (acc, errorItem) => ({
        ...acc,
        [errorItem.path]: errorItem.errors[0],
      }),
      {},
    );
  }
  return err;
};
