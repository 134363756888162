import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

// COMPONENTS
import Filter from 'ui/Filter';

// REDUX
import {
  selectFindingsActiveFilters,
  selectFindingsActiveFiltersValues,
} from 'pages/Findings/selectors';
import { removeFilter, setFilter } from 'pages/Findings/reducers';
import { getProfileFindings } from 'api/finding';
import { messages } from 'components/AddFilterButton/messages';

// UTILS
import { translate } from 'utils/index';
import { selectAggregatedOrganizations } from 'pages/Organization/selectors';

const { Option } = Select;

const ActiveFilters = () => {
  const filters = useSelector(selectFindingsActiveFilters);
  const filtersValues = useSelector(selectFindingsActiveFiltersValues);
  const aggregatedOrganizations = useSelector(selectAggregatedOrganizations);
  const dispatch = useDispatch();

  const handleOnChange = (value, id, type) => dispatch(setFilter({ type, value, id }));
  const handleRemove = (type) => {
    dispatch(removeFilter({ type }));
    dispatch(getProfileFindings({}));
  };

  const fetchData = () => {
    dispatch(getProfileFindings({}));
  };

  const filterOptions = (filterData) => {
    return filterData.map(({ id, profileCorporateName, name, label }) => {
      return (
        <Option key={id} value={profileCorporateName || name || translate(label)} id={id}>
          {profileCorporateName || name || translate(label)}
        </Option>
      );
    });
  };

  const filtersDisplay = useMemo(() => {
    return filters.map((filter) => {
      let title = filter.type;
      if (filter.type === 'assessmentName') title = 'assessment';
      if (filter.type === 'findingStatus') title = 'status';

      const options =
        filter.type === 'parent-organization'
          ? aggregatedOrganizations
          : filtersValues[filter.type];

      return (
        <Filter
          key={`findings-filter-${filter.type}`}
          filter={filter}
          onRemove={() => handleRemove(filter.type)}
          onChange={handleOnChange}
          fetchData={fetchData}
          innerLabel={translate(messages[`${title}Filter`])}
        >
          {filterOptions(options || [])}
        </Filter>
      );
    });
  }, [filters, filtersValues]);

  return <div className="findings-page__active-filters">{filtersDisplay}</div>;
};

export default ActiveFilters;
