import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'antd';

import Icon from 'ui/Icon';
import Select from 'ui/Select';
import EditableContent from 'ui/EditableContent';
import Radio from 'ui/Radio';
import Checkbox from 'ui/Checkbox';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import { translate } from 'utils/index';
import { filterArrayValue, parseArrayValue } from 'utils/assessment';
import { ControlType } from 'constants/controlType';
import { messages } from 'pages/Editor/messages';
import { getDefaultAnswer } from 'utils/controls';
import { Sorter } from 'ui/Table/sorter';

import { getWeightDataSource } from './lib';
import './editor.scss';

const Editor = ({ answerType, control, controlType, setAnswerType, onChange, value }) => {
  const [optionToRemove, setOptionToRemove] = useState();
  const [id, setId] = useState();

  useEffect(() => {
    if (control?.id) {
      return setId(control.id);
    }
    setId(uuidv4());
  }, [control]);

  useEffect(() => {
    if (controlType === ControlType.Checkbox && control?.answer) {
      setValue(filterArrayValue(control.answer));
    }
  }, [control]);

  const addOption = () => {
    const option = {
      key: answerType.options.length
        ? answerType.options[answerType.options.length - 1].key + 1
        : 1,
      value: '',
      weight: 0,
    };
    setAnswerType((state) => ({ ...state, options: [...state.options, option] }));
  };

  const setValue = (key, value) => {
    setAnswerType((state) => ({
      ...state,
      options: state.options.map((option) => (option.key === key ? { ...option, value } : option)),
    }));
  };

  const setWeight = (key, weight) => {
    setAnswerType((state) => ({
      ...state,
      options: state.options.map((option) => (option.key === key ? { ...option, weight } : option)),
    }));
  };

  const removeOption = () => {
    const options = answerType.options.filter((option) => option.key !== optionToRemove);

    setAnswerType((state) => ({ ...state, options }));

    if (optionToRemove === value || (Array.isArray(value) && value.includes(optionToRemove))) {
      const parsedValue = Array.isArray(value)
        ? removeOptionFromValue(value)
        : getDefaultAnswer(answerType.type);
      onChange(parsedValue);
    }

    setOptionToRemove(null);
  };

  const removeOptionFromValue = (value) => {
    const newValue = [...value];
    newValue.sort(Sorter.DEFAULT);
    newValue.splice(-1);

    return parseArrayValue(newValue);
  };

  const onRadioChange = (event) => {
    const parsedValue = parseInt(event.target.value, 10);
    onChange(parsedValue);
  };

  return (
    <div className="editor-answer-creator-editor">
      <div className="editor-answer-creator-editor__header">
        <div className="editor-answer-creator-editor__header-empty-space" />
        <div className="editor-answer-creator-editor__header-weight">
          {translate(messages.weight)}
        </div>
      </div>
      <div className="editor-answer-creator-editor__options">
        {answerType.options.map((option, index) => {
          const onCheckboxChange = () => {
            const newValue = value ? [...value] : [];
            if (newValue.includes(option.key)) {
              const parsedValue = parseArrayValue(newValue.filter((key) => key !== option.key));
              onChange(parsedValue);
            } else {
              const parsedValue = parseArrayValue([...newValue, option.key]);
              onChange(parsedValue);
            }
          };

          return (
            <div key={option.key} className="editor-answer-creator-editor__option">
              {![ControlType.RadioGroup, ControlType.Checkbox].includes(controlType) && (
                <div className="editor-answer-creator-editor__option-key">{index + 1}. </div>
              )}
              {controlType === ControlType.RadioGroup && (
                <div className="editor-answer-creator-editor__option-key">
                  <Radio
                    key={option.key}
                    onChange={onRadioChange}
                    value={option?.key}
                    name={`radio-${id}`}
                    checked={option?.key.toString() === value?.toString()}
                  />
                </div>
              )}
              {controlType === ControlType.Checkbox && (
                <div className="editor-answer-creator-editor__option-key">
                  <Checkbox
                    key={option.key}
                    onChange={onCheckboxChange}
                    value={option.key}
                    name={`checkbox-${id}-${option.key}`}
                    checked={(value || [])?.indexOf(option.key) > -1}
                    mode="checkbox"
                  />
                </div>
              )}
              <EditableContent
                className="editor-answer-creator-editor__option-value"
                onChange={(value) => setValue(option.key, value)}
                placeholder={translate(messages.typeOption)}
                value={option.value}
              />
              <Select
                data-test={`editor-answer-creator-editor-select-weight`}
                className="editor-answer-creator-editor__option-weight"
                color="gray"
                options={getWeightDataSource(option.weight)}
                onChange={(value) => setWeight(option.key, value)}
                value={option.weight}
              />
              <Button
                data-test={`editor-answer-creator-editor-remove-option-${index}`}
                className="editor-answer-creator-editor__remove-button"
                type="link"
                onClick={() => setOptionToRemove(option.key)}
              >
                <Icon icon="close" />
              </Button>
            </div>
          );
        })}
      </div>
      <div
        className="editor-answer-creator-editor__add-option"
        onClick={addOption}
        data-test={`editor-answer-creator-editor-add-option`}
      >
        {![ControlType.RadioGroup, ControlType.Checkbox].includes(controlType) && (
          <span className="editor-answer-creator-editor__option-key">
            {answerType.options.length + 1}.
          </span>
        )}
        {controlType === ControlType.RadioGroup && (
          <div className="editor-answer-creator-editor__option-key">
            <Radio onChange={() => {}} value="" name="radio-add-option" checked={false} />
          </div>
        )}
        {controlType === ControlType.Checkbox && (
          <div className="editor-answer-creator-editor__option-key">
            <Checkbox
              checked={false}
              onChange={() => {}}
              value=""
              name="checkbox-add-option"
              mode="checkbox"
            />
          </div>
        )}
        <span className="editor-answer-creator-editor__add-option-text">
          {translate(messages.addOption)}
        </span>
      </div>
      <RemoveConfirmationModal
        onOk={removeOption}
        onCancel={() => setOptionToRemove(null)}
        text={translate(messages.removeOptionText)}
        open={!!optionToRemove}
      />
    </div>
  );
};

Editor.propTypes = {
  answerType: PropTypes.object,
  control: PropTypes.object,
  controlType: PropTypes.oneOf([ControlType.Checkbox, ControlType.RadioGroup]),
  onChange: PropTypes.func,
  setAnswerType: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
};

export default Editor;
