import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Slider } from 'antd';
import PropTypes from 'prop-types';

// COMPONENTS
import Button from 'ui/Button';
import Popup from 'ui/Popup';

// HOOKS
import { useTheme } from 'hooks/theme';

// UTILS
import { translate } from 'utils/index';
import { generalMessages as messages } from 'constants/messages';

// STYLE
import './index.scss';

function ImageEditor({ image, visibility, setVisibility, setFunction }) {
  const editor = useRef(null);
  const [fileUrl, setFileUrl] = useState(null);
  const { colorTheme } = useTheme();
  const [scale, setScale] = useState(0.55);

  useEffect(() => {
    if (image) {
      setFileUrl(URL.createObjectURL(image));
    }
  }, [image]);

  const onSave = async () => {
    if (editor) {
      const scaledImage = editor.current.getImageScaledToCanvas().toDataURL();
      const blob = await (await fetch(scaledImage)).blob();
      const file = new File([blob], 'logo.jpg', { type: 'image/jpeg', lastModified: new Date() });
      setFunction(file);
      setVisibility();
    }
  };

  const onChangeScale = (value) => {
    setScale(value / 100);
  };

  const onIncrease = () => {
    onChangeScale(scale * 100 + 1);
  };

  const onDecrease = () => {
    onChangeScale(scale * 100 - 1);
  };

  const footer = (
    <div className="image-editor__footer">
      <Button
        outline
        color="black"
        name="Cancel"
        onClick={setVisibility}
        data-test="modal-button-cancel"
      />
      <Button
        color={colorTheme}
        name={translate(messages.save)}
        onClick={onSave}
        data-test="modal-button-accept"
      />
    </div>
  );

  return (
    <Popup visible={visibility} onCancel={setVisibility} width={450} footer={footer}>
      <div className="image-editor">
        <AvatarEditor ref={editor} width={346} height={144} image={fileUrl} scale={scale} />
        <div className="image-editor__slider">
          <Button
            link
            color="black"
            name="-"
            className="image-editor__scale-button"
            onClick={onDecrease}
            data-test="decrease-size"
          />
          <Slider onChange={onChangeScale} value={scale * 100} max={100} min={10} />
          <Button
            link
            color="black"
            name="+"
            className="image-editor__scale-button"
            onClick={onIncrease}
            data-test="increase-size"
          />
        </div>
      </div>
    </Popup>
  );
}

ImageEditor.propTypes = {
  image: PropTypes.any.isRequired,
  visibility: PropTypes.bool.isRequired,
  setVisibility: PropTypes.func.isRequired,
  setFunction: PropTypes.func.isRequired,
};

export default ImageEditor;
