import axios from 'axios';
import { TrustPartners, Webconsts } from 'api/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthenticationToken } from 'utils/auth';

export const getWebConstantsRequest = () => axios.get(Webconsts.url, { withCredentials: true });

export const getWebConstants = createAsyncThunk('Webconsts', getWebConstantsRequest);

export const getTrustedPartnersRequest = async (org_id) => {
  const token = getAuthenticationToken();
  const payload = {
    org_id,
    token,
  };
  const { trustRelations } = await axios.post(TrustPartners.url, payload, { withCredentials: true });

  return trustRelations;
};

export const getTrustedPartners = createAsyncThunk('getTrustedPartners', getTrustedPartnersRequest);
