import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import SecondaryButton from 'ui/SecondaryButton';
import Divider from 'ui/Divider';
import { calculateControlKey } from 'pages/Assessment/lib';
import { stripHtml } from 'utils/html';
import { saveTemplate } from 'api/editor';
import { getAnswerType } from 'utils/controls';
import CloudMonitoringOptions from 'pages/Editor/questions/cloudMonitoringOptions';
import { selectAnswerTypes, selectSubjectKey } from 'pages/Editor/selectors';
import Answer from './answer';
import VisibleSwitcher from 'pages/Editor/questions/visibleSwitcher';
import { selectApps } from 'pages/Profile/selectors';
import './question.scss';

const Question = ({
  categoryId,
  control,
  index,
  setFindings,
  setTriggers,
  setOpenVisibleSwitcher,
  disabled = false,
  withOtherTrigger,
  addQuestion,
}) => {
  const subjectKey = useSelector(selectSubjectKey);
  const dispatch = useDispatch();
  const apps = useSelector(selectApps);

  const answerTypes = useSelector(selectAnswerTypes);
  const answerType = getAnswerType(control.answer_type_idx, answerTypes);

  const onSave = (field, value) => {
    const payload = {
      categories: [
        {
          id: categoryId,
          controls: [{ ...control, [field]: value }],
        },
      ],
    };
    dispatch(saveTemplate(payload));
  };

  const triggersLength = useMemo(
    () => Object.entries(control?.triggers || {}).flatMap(([, triggers]) => triggers).length,
    [control],
  );

  const findingsLength = useMemo(
    () => control.findings && control.findings.flatMap(({ triggers }) => triggers).length,
    [control],
  );

  return (
    <div className={`editor-question ${disabled ? 'disabled-question' : ''}`}>
      <div className="editor-question__main">
        <div className="editor-question__key">
          {calculateControlKey(subjectKey, control.counter)}
        </div>
        <div className="editor-question__wrapper">
          {control.apps?.length ? (
            <div className="editor-question__apps-icons">
              {control.apps?.map((app) => {
                const currentApp = apps?.find(({ id }) => id === app) || {};
                return (
                  <img
                    className="editor-question__apps-icons-icon"
                    key={app.id}
                    src={currentApp?.icon}
                    alt={currentApp?.name}
                  />
                );
              })}
            </div>
          ) : null}
          <div className="editor-question__title">
            <pre>{stripHtml(control.title)}</pre>
          </div>
          <div
            className="editor-question__question-preview"
            data-test={`editor-question-question-preview-${index}`}
          >
            {withOtherTrigger ? (
              <CloudMonitoringOptions
                control={control}
                options={answerType.options}
                onSave={(value) => onSave('answer', value)}
                addQuestion={addQuestion}
              />
            ) : (
              <Answer
                control={control}
                onSave={(value) => onSave('answer', value)}
                disabled={disabled}
              />
            )}
          </div>
        </div>
      </div>
      {(control.findings?.length > 0 || triggersLength > 0 || !control.enabled) && (
        <div className="editor-question__action-bar">
          {control.findings.length > 0 && (
            <SecondaryButton
              disabled={disabled}
              className="editor-question__findings-button"
              onClick={setFindings}
              link
              icon="findings"
              counter={findingsLength}
              uppercase
            />
          )}
          {control.findings?.length > 0 && triggersLength > 0 && <Divider vertical size="xxs" />}
          {triggersLength > 0 && (
            <SecondaryButton
              className="editor-question__triggers-button"
              data-test={`editor-question-triggers-button-${index}`}
              onClick={setTriggers}
              link
              icon="trigger"
              counter={triggersLength}
              disabled={disabled}
            />
          )}
          {!control.enabled && (
            <VisibleSwitcher
              onSave={(value) => onSave('enabled', value)}
              defaultValue={control.enabled}
              data-test={`question-visible-switcher-${index}`}
              open={false}
              onClick={() => setOpenVisibleSwitcher(true)}
              disabled={disabled}
            />
          )}
        </div>
      )}
    </div>
  );
};

Question.propTypes = {
  categoryId: PropTypes.string,
  control: PropTypes.object,
  index: PropTypes.number,
  setFindings: PropTypes.func,
  setTriggers: PropTypes.func,
  setOpenVisibleSwitcher: PropTypes.func,
  disabled: PropTypes.bool,
  withOtherTrigger: PropTypes.bool,
  addQuestion: PropTypes.func,
};

export default Question;
