import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'antd';

import { translate } from 'utils/index';
import { getStatusFromRecommendations } from 'utils/findings';
import Table from 'ui/Table';
import Risk from 'pages/Recent/findingsColumns/risk';
import Status from 'pages/Recent/findingsColumns/status';
import { selectAssessment } from 'pages/Assessment/selectors';
import { sanitizer } from 'utils/sanitizer';
import { Apps } from 'constants/apps';
import { selectFinding } from './reducers';
import {
  selectAllFindings,
  seletCategoriesWithFindings,
  selectFinding as selectorsSelectFinding,
} from './selectors';
import { getRiskData } from './lib';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import SbomIcon from 'assets/svgs/sbom-icon.svg';
import { getSbomFindingSeverityData } from 'utils/sbomFindingSeverity';
import Collapse from 'ui/Collapse';
import AddFindingModal from 'pages/Assessment/addFinding';
import Icon from 'ui/Icon';
import Select from 'ui/Select';
import { TableSource } from 'ui/Table/lib';
import { mixpanelTrackFindingViewed } from 'utils/mixpanel';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { selectCurrentOrganization } from 'pages/Profile/selectors';

import './index.scss';

const Findings = () => {
  const findings = useSelector(selectAllFindings);
  const [addFindingModal, setAddFindingModal] = useState();
  const selectedFinding = useSelector(selectorsSelectFinding);
  const selectedCategoriesWithFindings = useSelector(seletCategoriesWithFindings);
  const { assessor, risk, apps, customerId } = useSelector(selectAssessment);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { isRestrictedBoSoViewerRole } = useRole();
  const dispatch = useDispatch();
  const { id: organizationId } = useSelector(selectCurrentOrganization);

  const [searchParams] = useSearchParams();
  const findingId = searchParams.get('findingId');

  const [allFindings, setAllFindings] = useState([]);
  const [sbomFindings, setSbomFindings] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [activePanel, setActivePanel] = useState('all-findings');

  useEffect(() => {
    if (findingId) {
      dispatch(selectFinding(findingId));
    }
  }, [findingId]);

  useEffect(() => {
    setAllFindings(
      findings
        .filter((finding) => {
          if (finding.app === Apps.sbom) {
            return false;
          }
          if (selectedCategories.length) {
            return selectedCategories.includes(finding.categoryId);
          }
          return true;
        })
        ?.map((finding) => ({
          ...finding,
          ...getRiskData(finding.impact, finding.probability, risk),
          status: getStatusFromRecommendations(finding),
        })),
    );
    if (apps?.includes(Apps.sbom)) {
      setSbomFindings(
        findings
          .filter((finding) => finding.app === Apps.sbom)
          ?.map((finding) => ({
            ...finding,
            ...getSbomFindingSeverityData(finding),
            status: getStatusFromRecommendations(finding),
          })),
      );
    }

    if (findings[0] && !selectedFinding.id) {
      dispatch(selectFinding(findings[0].id));
    }
  }, [findings, selectedCategories]);

  useEffect(() => {
    setActivePanel(
      apps?.includes(Apps.sbom) && sbomFindings?.length > allFindings?.length
        ? 'sbom-findings'
        : 'all-findings',
    );
  }, [allFindings, sbomFindings, apps]);

  const onRow = (finding) => ({
    onClick: () => {
      dispatch(selectFinding(finding.id));
      mixpanelTrackFindingViewed({
        relation: customerId || organizationId,
        source: 'assessment',
        streamDirection: isMyVendorsTabSelected ? 'downstream' : 'upstream',
      });
    },
  });

  const columns = [
    {
      title: translate(messages.description),
      dataIndex: 'description',
      render: (description) => {
        const shortDescription =
          (description || '').length > 100 ? `${description.slice(0, 100)}...` : description;

        return description ? (
          <span dangerouslySetInnerHTML={{ __html: sanitizer(shortDescription) }} />
        ) : (
          translate(messages.noDescription)
        );
      },
    },
    {
      title: translate(messages.risk),
      dataIndex: 'risk',
      render: (_, finding) => {
        return <Risk risk={finding.riskValue} />;
      },
      sorter: (a, b) => a.riskIndex - b.riskIndex,
    },
    {
      title: translate(messages.status),
      dataIndex: 'status',
      width: 140,
      render: (_, finding) => {
        return <Status status={finding.status} finding={finding} />;
      },
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: translate(messages.recommendations),
      dataIndex: 'recommendations',
      align: 'center',
      render: (recommendations) => {
        return recommendations.length ? (
          <div className="assessment-finalized-findings__recommendations">
            {recommendations.length > 9 ? '9+' : recommendations.length}
          </div>
        ) : (
          ''
        );
      },
    },
  ];

  const sbomColumns = [
    {
      title: translate(messages.description),
      dataIndex: 'component',
      render: (component) =>
        component ? (
          <span dangerouslySetInnerHTML={{ __html: sanitizer(component) }} />
        ) : (
          translate(messages.noDComponent)
        ),
    },
    {
      title: translate(messages.version),
      dataIndex: 'version',
      render: (version) =>
        version ? (
          <span dangerouslySetInnerHTML={{ __html: sanitizer(version) }} />
        ) : (
          translate(messages.notSpecified)
        ),
    },
    {
      title: translate(messages.severity),
      dataIndex: 'severity',
      render: (_, finding) => {
        return <Risk risk={finding.severityValue} />;
      },
      sorter: (a, b) => a.severityIndex - b.severityIndex,
      defaultSortOrder: 'descend',
    },
    {
      title: translate(messages.status),
      dataIndex: 'status',
      width: 140,
      render: (_, finding) => {
        return <Status status={finding.status} finding={finding} />;
      },
      sorter: (a, b) => a.status - b.status,
    },
  ];

  const rowClassName = (record, rowIndex) => {
    return record?.id === selectedFinding?.id ? 'selected' : '';
  };

  const sbomRowClassName = (record, rowIndex) => {
    return record?.id === selectedFinding?.id ? 'selected' : '';
  };

  const collapsePanels = () => {
    const panels = [
      {
        id: 'all-findings',
        header: (
          <>
            <div className="assessment-finalized-findings__header-title">
              <span className="assessment-finalized-findings__header-title-text">
                {translate(messages.findings)}
              </span>
              {` (${allFindings?.length})`}
            </div>
            {assessor && (
              <Button
                type="link"
                className="assessment-finalized-add-finding__button"
                disabled={isRestrictedBoSoViewerRole}
                onClick={(e) => {
                  e.stopPropagation();
                  setAddFindingModal(true);
                }}
              >
                <Icon icon="plus" />
                {translate(messages.addFinding)}
              </Button>
            )}
          </>
        ),
        children: (
          <>
            <Select
              mode="multiple"
              innerLabel={translate(messages.categorySelectLabel)}
              showArrow={true}
              options={selectedCategoriesWithFindings}
              value={selectedCategories}
              allowClear={true}
              onChange={(value) => {
                setSelectedCategories(value);
              }}
            />
            <Table
              className="assessment-finalized-findings__findings-table"
              columns={columns}
              dataSource={allFindings}
              onRow={onRow}
              pagination={false}
              rowClassName={rowClassName}
              emptyStateSource={TableSource.findingsList}
            />
          </>
        ),
      },
    ];
    if (apps?.includes(Apps.sbom)) {
      panels.push({
        id: 'sbom-findings',
        header: (
          <div className="assessment-finalized-findings__header-title">
            <img src={SbomIcon} alt="sbom icon" />
            <span className="assessment-finalized-findings__header-title-text">
              {translate(messages.sbomFindings)}
            </span>
            {` (${sbomFindings?.length})`}
          </div>
        ),
        children: (
          <Table
            className={'sbom-findings__table'}
            dataSource={sbomFindings}
            onRow={onRow}
            pagination={false}
            rowClassName={sbomRowClassName}
            columns={sbomColumns}
            emptyStateSource={TableSource.findingsList}
          />
        ),
      });
    }

    return panels;
  };

  return (
    <div className="assessment-finalized-findings">
      <Collapse
        activeKey={activePanel}
        accordion={true}
        onChange={(key) => setActivePanel(key)}
        defaultActiveKey={'all-findings'}
        panels={collapsePanels()}
        ghost={true}
        collapsible={apps?.includes(Apps.sbom) ? true : 'disabled'}
      />
      <AddFindingModal visible={addFindingModal} toggleModal={setAddFindingModal} />
    </div>
  );
};

export default Findings;
