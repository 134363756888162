import React, { useCallback, useState } from 'react';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';
import { useAppNavigate } from 'hooks/navigation';
import ROUTES from 'constants/routes';
import { getProfileOrganizations, removeProfileOrganization } from 'api/organizations';
import { API_STATUS } from 'constants/api';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import LogoAndTitle from 'pages/Organization/edit/heading/logoAndTitle';
import { selectCurrentOrganization } from 'pages/Profile/selectors';
import { logout } from 'utils/logout';

const OrganizationEditHeading = ({ label, orgId, logoPath, getProfiles }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const appNavigate = useAppNavigate();
  const dispatch = useDispatch();
  const setPopupOnClick = () => setShowPopup((state) => !state);
  const { id: currentOrgId } = useSelector(selectCurrentOrganization);

  const removeOrganization = useCallback(async () => {
    setLoading(true);
    const { status } = await removeProfileOrganization(orgId);
    setLoading(false);

    if (status === API_STATUS.FAILED)
      return notification.error({ message: translate(messages.orgSettingsRemoveOrg) });
    if (status === API_STATUS.SUCCESS) {
      if (currentOrgId === orgId) {
        await logout();
        return;
      } else appNavigate(ROUTES.VENDOR_RECENT, { organization: 0 });
      await dispatch(getProfileOrganizations());

      return notification.success({ message: translate(messages.orgSettingsRemoveOrgSuccess) });
    }
    return notification.error({ message: translate(messages.orgSettingsRemoveOrgError) });
  }, [orgId]);

  return (
    <div className="organization-edit-page__header-wrap">
      <LogoAndTitle logoPath={logoPath} label={label} getProfiles={getProfiles} />
      <Button
        data-test="organization-edit-page-button-remove-org"
        link
        color="black"
        beforeIcon="remove"
        onClick={setPopupOnClick}
        className="organization-edit-page__remove-org-button"
      >
        {translate(messages.orgEditRemoveOrganization)}
      </Button>
      <RemoveConfirmationModal
        headerText={translate(messages.orgSettingsRemoveHeaderText, {
          orgName: label,
        })}
        confirmLoading={loading}
        onOk={removeOrganization}
        onCancel={setPopupOnClick}
        text={`${translate(messages.orgSettingsRemoveMessage)}?`}
        open={!!showPopup}
      />
    </div>
  );
};

OrganizationEditHeading.propTypes = {
  label: PropTypes.string,
  orgId: PropTypes.string,
  logoPath: PropTypes.string,
  getProfiles: PropTypes.func,
};

export default OrganizationEditHeading;
