import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import DatePicker from 'ui/DatePicker';
import Icon from 'ui/Icon';
import './metadataItem.scss';

const MetadataItem = ({ onRemove, onChange, itemKey, text, date, metadataValue, placeholder }) => {
  const ref = useRef();
  const translatedText = translate(text);
  return (
    <div className="metadata-item">
      <strong className="metadata-item__title">{translatedText}</strong>
      {date ? (
        <DatePicker
          value={metadataValue}
          placeholder={translatedText}
          onChange={(value) => onChange(value, itemKey, true)}
          className="metadata-item__datepicker"
        />
      ) : (
        <form className="metadata-item__value" autoComplete="off">
          <input
            placeholder={placeholder || translatedText}
            ref={ref}
            type="text"
            className="metadata-item__value-input"
            value={metadataValue}
            onChange={({ target: { value } }) => onChange(value, itemKey)}
          />
          <Icon
            onClick={() => onRemove(itemKey, ref)}
            icon="remove"
            className="metadata-item__value-remove"
          />
        </form>
      )}
    </div>
  );
};

MetadataItem.propTypes = {
  date: PropTypes.bool.isRequired,
  itemKey: PropTypes.string.isRequired,
  metadataValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  text: PropTypes.object.isRequired,
};

export default MetadataItem;
