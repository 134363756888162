import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import Icon from 'ui/Icon';
import Input from 'ui/Input';
import LogoEmptyState from 'pages/Organization/edit/heading/logoEmptyState';
import OrganizationLogo from 'ui/OrganizationLogo';
import PageHeader from 'components/PageHeader';
import UploadLogoButtons from 'pages/Organization/edit/heading/uploadLogoButtons';
import ImageEditor from 'components/ImageEditor';
import useLogoUpload from 'pages/Organization/edit/heading/useLogoUpload';

// UTILS AND API
import { getProfileOrganizations, setOrganizationName } from 'api/organizations';
import { API_STATUS } from 'constants/api';
import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';
import { getProfile } from 'api/profile';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

// ASSETS
import close from 'assets/svgs/close-light-weight.svg';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';

function LogoAndTitle({ logoPath, label, getProfiles }) {
  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();

  const [currentLogoPath, setCurrentLogoPath] = useState('');
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const [showPopup, setShowPopup] = useState({
    removePopup: false,
    editorPopup: false,
  });
  const setPopupState = (key) => {
    setShowPopup((state) => ({ ...state, [key]: !state[key] }));
  };
  const { organizationId } = useParams();
  const inputRef = useRef();
  const fileRef = useRef(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orgIndex = searchParams.get('orgIndex');
  const dispatch = useDispatch();
  const [editTitle, setEditTitle] = useState(false);
  const [title, setTitle] = useState('');

  const { setLogo, onRemove } = useLogoUpload({
    setCurrentLogoPath,
    organizationId,
    orgIndex,
    setPopupState,
    abortController,
    startUploading,
    finishUploding,
  });

  useEffect(() => {
    setCurrentLogoPath(logoPath);
  }, [logoPath]);

  const onClickBack = () => {
    return navigate(-1);
  };

  const openFileBrowse = () => {
    inputRef.current?.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    fileRef.current = fileUploaded;
    setPopupState('editorPopup');
  };

  const onEditTitle = (e) => {
    setTitle(e.target.value);
  };

  const setEditTitleOnClick = () => setEditTitle((state) => !state);

  useEffect(() => {
    setTitle(label);
  }, [label]);

  const onCloseEditTitle = () => {
    setEditTitleOnClick();
    setTitle(label);
  };

  const saveTitle = useCallback(async () => {
    const status = await setOrganizationName({ orgId: organizationId, title });
    setEditTitleOnClick();

    if (status === API_STATUS.FAILED) {
      setTitle(label);
      return notification.error({ message: translate(messages.orgSettingsEditTitleError) });
    }

    notification.success({ message: translate(messages.orgSettingsEditTitleSuccess) });

    await dispatch(getProfileOrganizations());
    await getProfiles();
    await dispatch(getProfile({ upstream: !isMyVendorsTabSelected })).unwrap();

    return;
  }, [title, label, organizationId, getProfiles]);

  return (
    <>
      <div className="organization-edit-page__logo-part">
        <Icon icon="left-arrow" className="organization-edit-page__arrow" onClick={onClickBack} />
        <div className="organization-edit-page__header-elements">
          {!currentLogoPath && <LogoEmptyState handleClick={openFileBrowse} />}
          {currentLogoPath && <OrganizationLogo size="md" path={currentLogoPath} />}
          <div className="organization-edit-page__header-title">
            {!editTitle && (
              <div className="organization-edit-page__header-title-wrap">
                <PageHeader noMargin> {title || label} </PageHeader>
                <Icon
                  icon={'edit'}
                  className="organization-edit-page__edit-icon"
                  onClick={setEditTitleOnClick}
                />
              </div>
            )}
            {editTitle && (
              <div className="organization-edit-page__header-title-wrap">
                <Input
                  data-test={'organization-edit-page-input-title'}
                  value={title}
                  className="organization-edit-page__input-title"
                  onChange={onEditTitle}
                />
                <Icon
                  icon={'check'}
                  className="organization-edit-page__active-edit-icon"
                  onClick={saveTitle}
                />
                <img
                  className="organization-edit-page__active-edit-icon"
                  src={close}
                  alt="close"
                  onClick={onCloseEditTitle}
                />
              </div>
            )}
            <UploadLogoButtons
              handleClickUpload={openFileBrowse}
              handleRemoveLogo={onRemove}
              handleClickRemove={() => setPopupState('removePopup')}
              showRemovePopup={showPopup.removePopup}
            />
          </div>
        </div>
      </div>
      <ImageEditor
        image={fileRef.current}
        setFunction={setLogo}
        setVisibility={() => setPopupState('editorPopup')}
        visibility={showPopup.editorPopup}
      />
      <input hidden type="file" ref={inputRef} onChange={handleChange} />
      {renderConfirmationModal()}
    </>
  );
}

LogoAndTitle.propTypes = {
  logoPath: PropTypes.string,
  label: PropTypes.string,
  getProfiles: PropTypes.func,
};

export default LogoAndTitle;
