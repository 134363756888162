import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigationType } from 'react-router';
import classNames from 'classnames';
import moment from 'moment';

import { translate } from 'utils/index';
import Table from 'ui/Table';
import Icon from 'ui/Icon';

import { useAppNavigate, useGetPath, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { getAssessments } from 'api/assessments';
import ROUTES from 'constants/routes';
import { DATE_FORMAT } from 'constants/date';
import ScoreBenchmark from 'components/AssessmentScoreBenchmark';
import { clearFilters } from 'components/AssessmentsFilters/reducers';
import { TableSource } from 'ui/Table/lib';
import { selectIsViewAggregated } from 'pages/Organization/selectors';

import FindingsColumn from './columns/findingsColumn';
import Status from './columns/assessmentStatusColumn';
import Actions from './columns/actions';
import { setPagination, setSearch, setSorter } from './reducers';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import { shouldAppNavigateToContinuousMonitoring } from 'utils/appsUtils';
import './list.scss';

const AssessmentsList = ({
  isLoading,
  onArchive,
  onExport,
  onGenerateZipReport,
  onRestore,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const dispatch = useDispatch();
  const { assessments, loading, pagination, sorter } = useSelector((state) => state.assessments);
  const { isRestrictedBoSoViewerRole } = useRole();
  const isViewAggregated = useSelector(selectIsViewAggregated);
  const getPath = useGetPath();
  const appNavigate = useAppNavigate();
  const navigationType = useNavigationType();

  useEffect(() => {
    const rows = document.querySelectorAll('[data-row-key]');
    [].forEach.call(rows, (row, index) => {
      const attrValue = `assessment-key-${index + 1}-${pagination.current}`;
      row.removeAttribute('data-test');
      row.setAttribute('data-test', attrValue);
    });
  }, [pagination]);

  const onTableChange = (newPagination, filters, sorter) => {
    dispatch(setPagination(newPagination));
    const filterParams = {
      column: sorter.field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? filterParams : {};

    dispatch(setSorter(params));
    dispatch(getAssessments()).unwrap();
  };

  useEffect(() => {
    const getAssessmentsCall = async () => {
      if (navigationType !== 'POP') {
        dispatch(clearFilters());
        dispatch(setPagination({ current: 1 }));
        dispatch(setSearch());
        const sorter = {
          column: 'timestampCreated',
          descending: true,
        };
        dispatch(setSorter(sorter));
      }
      await dispatch(getAssessments()).unwrap();
    };
    getAssessmentsCall();
  }, []);

  const sortOrder = sorter.descending ? 'descend' : 'ascend';

  const columns = [
    {
      title: translate(messages.date),
      dataIndex: 'timestampCreated',
      width: 120,
      render: (timestampCreated) => moment(timestampCreated).format(DATE_FORMAT),
      sorter: true,
      sortOrder: sorter.column === 'timestampCreated' ? sortOrder : null,
    },
    {
      title: isMyVendorsTabSelected ? translate(messages.vendor) : translate(messages.customer),
      dataIndex: 'profileCustomerCorporateName',
      render: (name, assessment) => {
        const vendorName = isMyVendorsTabSelected ? name : name || translate(messages.self);

        if (!isMyVendorsTabSelected || isRestrictedBoSoViewerRole || !name) {
          return vendorName;
        }

        return (
          isMyVendorsTabSelected && (
            <Link
              className="assessments-page-list__table-link"
              to={getPath(ROUTES.VENDOR_EDIT, {
                vendorId: assessment?.['organizationCustomer_id'] || '',
              })}
            >
              {vendorName}
            </Link>
          )
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'profileCustomerCorporateName' ? sortOrder : null,
    },
    {
      title: translate(messages.type),
      dataIndex: 'assessmentName',
      render: (assessmentName, assessment) => {
        const { showcaseName, app } = assessment;
        return (
          <>
            {showcaseName ? (
              <p className="showcase-name-indicator">{translate(messages.showcaseIndicator)}</p>
            ) : null}
            <Link
              className="assessments-page-list__table-link"
              to={
                app && shouldAppNavigateToContinuousMonitoring(app)
                  ? getPath(
                      isMyVendorsTabSelected
                        ? ROUTES.VENDOR_CONTINUOUS_MONITORING
                        : ROUTES.CUSTOMER_CONTINUOUS_MONITORING,
                      { assessmentId: assessment.id, appId: assessment.app },
                    )
                  : getPath(
                      isMyVendorsTabSelected
                        ? ROUTES.VENDOR_ASSESSMENT
                        : ROUTES.CUSTOMER_ASSESSMENT,
                      { assessmentId: assessment.id },
                    )
              }
              target="_blank"
            >
              <Icon icon="external-link" />
              {showcaseName || assessmentName}
            </Link>
          </>
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'assessmentName' ? sortOrder : null,
    },
    {
      title: translate(messages.due),
      dataIndex: 'due',
      width: 120,
      render: (due, { finalized, showcaseName }) => {
        const isExpired = moment().isAfter(moment(due)) && !finalized;
        const className = classNames('assessments-page-list__due-date', {
          'assessments-page-list__due-date--expired': isExpired,
        });
        return showcaseName ? (
          <span className="empty-score">{translate(messages.emptyStateValue)}</span>
        ) : (
          <div className={className}>
            <Icon icon="date" className="recent-page-assessments__due-date-ico" />
            {due ? moment(due).format(DATE_FORMAT) : 'N/A'}
          </div>
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'due' ? sortOrder : null,
    },
    {
      title: translate(messages.score),
      dataIndex: 'assessmentScore',
      align: 'center',
      width: 190,
      render: (score, assessment) => {
        return assessment.showcaseName ? (
          <span className="empty-score">{translate(messages.emptyStateValue)}</span>
        ) : (
          <ScoreBenchmark score={score} benchmark={assessment.assessmentBenchmark} />
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'assessmentScore' ? sortOrder : null,
    },
    {
      title: translate(messages.status),
      dataIndex: 'status',
      width: 140,
      render: (status, assessment) =>
        assessment.app ? (
          'N/A'
        ) : (
          <Status assessment={assessment} totalScore={assessment.total_score} />
        ),
      sorter: true,
      sortOrder: sorter.column === 'status' ? sortOrder : null,
    },
    {
      title: translate(messages.openFindings),
      dataIndex: 'assessmentAdviceNumerator',
      render: (findings, assessment) => (
        <FindingsColumn assessment={assessment} totalScore={assessment.total_score} />
      ),
      sorter: true,
      sortOrder: sorter.column === 'assessmentAdviceNumerator' ? sortOrder : null,
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_, assessment) => (
        <Actions
          assessmentId={assessment.id}
          assessmentStatus={assessment.status}
          className="assessments-page-list__actions"
          onArchive={onArchive}
          onExport={onExport}
          onGenerateZipReport={onGenerateZipReport}
          onRestore={onRestore}
        />
      ),
      width: 130,
    },
  ];

  if (isViewAggregated) {
    const column = {
      title: translate(messages.organization),
      dataIndex: 'parentOrganizationCorporateName',
      sorter: true,
      sortOrder: sorter.column === 'parentOrganizationCorporateName' ? sortOrder : null,
    };
    columns.splice(1, 0, column);
  }

  const dataSource = (assessments || []).map((assessment) => ({
    ...assessment,
    key: assessment.id,
  }));

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const onRow = (assessment) => ({
    onClick: (event) => {
      if (event.target.classList.contains('recent-page-assessments__table-link')) {
        return;
      }

      if (assessment.showcaseName) {
        const url = isMyVendorsTabSelected
          ? ROUTES.VENDOR_SHOWCASE_PREVIEW
          : ROUTES.CUSTOMER_SHOWCASE_PREVIEW;
        return appNavigate(url, { showcaseId: assessment.customerAssessmentGroup });
      }
      if (assessment.app && shouldAppNavigateToContinuousMonitoring(assessment.app)) {
        return appNavigate(
          isMyVendorsTabSelected
            ? ROUTES.VENDOR_CONTINUOUS_MONITORING
            : ROUTES.CUSTOMER_CONTINUOUS_MONITORING,
          { assessmentId: assessment.id, appId: assessment.app },
        );
      }
      const url = isMyVendorsTabSelected ? ROUTES.VENDOR_ASSESSMENT : ROUTES.CUSTOMER_ASSESSMENT;
      return appNavigate(url, { assessmentId: assessment.id });
    },
  });

  return (
    <div className="assessments-page-list">
      <Table
        className="assessments-page-list__table"
        columns={columns}
        dataSource={dataSource}
        loading={loading || isLoading}
        pagination={pagination}
        rowSelection={rowSelection}
        onChange={onTableChange}
        onRow={onRow}
        sortable
        emptyStateSource={TableSource.assessmentsAssigned}
        scroll={{ x: 1160 }}
      />
    </div>
  );
};

AssessmentsList.propTypes = {
  isLoading: PropTypes.bool,
  onArchive: PropTypes.func,
  onExport: PropTypes.func,
  onGenerateZipReport: PropTypes.func,
  onRestore: PropTypes.func,
  selectedRowKeys: PropTypes.array,
  setSelectedRowKeys: PropTypes.func,
};

export default AssessmentsList;
