export const messages = {
  orgSettingsTitle: {
    key: 'Organization.orgSettingsTitle',
    defaultMessage: 'Organizations settings',
  },
  orgSettingsDefaultOrgLabel: {
    key: 'Organization.orgSettingsDefaultOrgLabel',
    defaultMessage: 'Default organization',
  },
  orgSettingsNewOrganization: {
    key: 'Organization.orgSettingsNewOrganization',
    defaultMessage: 'organization',
  },
  orgSettingsNewOrgTitle: {
    key: 'Organization.orgSettingsNewOrgTitle',
    defaultMessage: 'Create new organization',
  },
  orgSettingsNewOrgPlaceHolder: {
    key: 'Organization.orgSettingsNewOrgPlaceHolder',
    defaultMessage: 'Enter Organization name',
  },
  orgSettingsNewOrgInputError: {
    key: 'Organization.orgSettingsNewOrgInputError',
    defaultMessage: 'Please enter organization name',
  },
  orgSettingsNewOrgError: {
    key: 'Organization.orgSettingsNewOrgError',
    defaultMessage: 'New organization was not created, an error occurred',
  },
  orgSettingsNewOrgSuccess: {
    key: 'Organization.orgSettingsNewOrgSuccess',
    defaultMessage: 'New organization was created successfully',
  },
  cancel: {
    key: 'Organization.cancel',
    defaultMessage: 'CANCEL',
  },
  sendRequest: {
    key: 'Organization.sendRequest',
    defaultMessage: 'SEND REQUEST',
  },
  emailPlaceholder: {
    key: 'Organization.emailPlaceholder',
    defaultMessage: 'Enter contact email',
  },
  addTrustedOrganization: {
    key: 'Organization.addTrustedOrganization',
    defaultMessage: 'Add Trusted Organization',
  },
  orgSettingsTableDate: {
    key: 'Organization.orgSettingsTableDate',
    defaultMessage: 'Date',
  },
  orgSettingsTableName: {
    key: 'Organization.orgSettingsTableName',
    defaultMessage: 'Name',
  },
  orgSettingsTableOwner: {
    key: 'Organization.orgSettingsTableOwner',
    defaultMessage: 'Owner',
  },
  orgSettingsTableYourRole: {
    key: 'Organization.orgSettingsTableYourRole',
    defaultMessage: 'Your Role',
  },
  orgSettingsTableAggregatedView: {
    key: 'Organization.orgSettingsTableAggregatedView',
    defaultMessage: 'Aggregated View',
  },
  orgSettingsRemoveOrg: {
    key: 'Organization.orgSettingsRemoveOrg',
    defaultMessage: 'Yes, Remove',
  },
  orgSettingsRemoveOrgError: {
    key: 'Organization.orgSettingsRemoveOrgError',
    defaultMessage: 'Organization can not be removed',
  },
  orgSettingsRemoveOrgSuccess: {
    key: 'Organization.orgSettingsRemoveOrgSuccess',
    defaultMessage: 'Organization was removed successfully',
  },
  orgSettingsRemoveUserTitle: {
    key: 'Organization.orgSettingsRemoveUserTitle',
    defaultMessage: 'Remove {{userName}}?',
  },
  orgSettingsRemoveUserDescription: {
    key: 'Organization.orgSettingsRemoveUserDescription',
    defaultMessage: '{{userName}} will no longer have access to this organization in Findings.',
  },
  orgSettingsRemoveMessage: {
    key: 'Organization.orgSettingsRemoveMessage',
    defaultMessage: 'All organization information will be lost',
  },
  orgSettingsRemoveHeaderText: {
    key: 'Organization.orgSettingsRemoveHeaderText',
    defaultMessage: 'Remove {{orgName}}',
  },
  orgSettingsRemoveFail: {
    key: 'Organization.orgSettingsRemoveFail',
    defaultMessage: 'Sorry, you can not remove the only organization in your list',
  },
  orgSettingsRemoveRedirect: {
    key: 'Organization.orgSettingsRemoveRedirect',
    defaultMessage: 'You are redirected to the new default organization',
  },
  orgEditLinkUploadLogo: {
    key: 'Organization.orgEditLinkUploadLogo',
    defaultMessage: 'Upload new logo',
  },
  orgEditLinkRemoveLogo: {
    key: 'Organization.orgEditLinkRemoveLogo',
    defaultMessage: 'Remove Logo',
  },
  orgEditCompanyLogo: {
    key: 'Organization.orgEditCompanyLogo',
    defaultMessage: 'Company Logo',
  },
  orgEditRemoveOrganization: {
    key: 'Organization.orgEditRemoveOrganization',
    defaultMessage: 'Remove organization',
  },
  orgEditLinkUploadLogoSuccess: {
    key: 'Organization.orgEditLinkUploadLogoSuccess',
    defaultMessage: 'Logo updated successfully',
  },
  orgEditLinkUploadLogoError: {
    key: 'Organization.orgEditLinkUploadLogoError',
    defaultMessage: 'Unable to update logo',
  },
  orgEditLinkRemoveLogoSuccess: {
    key: 'Organization.orgEditLinkRemoveLogoSuccess',
    defaultMessage: 'Logo removed successfully',
  },
  orgEditLinkRemoveLogoError: {
    key: 'Organization.orgEditLinkRemoveLogoError',
    defaultMessage: 'Unable to remove logo',
  },
  orgEditLinkRemoveLogoMessage: {
    key: 'Organization.orgEditLinkRemoveLogoMessage',
    defaultMessage: 'Are you sure you want to remove the logo?',
  },
  orgEditLinkRemoveLogoConfirm: {
    key: 'Organization.orgEditLinkRemoveLogoConfirm',
    defaultMessage: 'Yes, Remove',
  },
  orgSettingsEditTitleError: {
    key: 'Organization.orgSettingsEditTitleError',
    defaultMessage: 'Unable to update organization title',
  },
  orgSettingsEditTitleSuccess: {
    key: 'Organization.orgSettingsEditTitleSuccess',
    defaultMessage: 'Organization title updated successfully',
  },
  orgEditTabUserManagement: {
    key: 'Organization.orgEditTabUserManagement',
    defaultMessage: 'User Management',
  },
  orgEditTabNotification: {
    key: 'Organization.orgEditTabNotification',
    defaultMessage: 'Notification',
  },
  orgEditTabSecurity: {
    key: 'Organization.orgEditTabSecurity',
    defaultMessage: 'Security',
  },
  orgEditTabSecurity2FaPolicy: {
    key: 'Organization.orgEditTabSecurity2FaPolicy',
    defaultMessage: '2FA Policy',
  },
  orgEditTabSecurityEnforce2Fa: {
    key: 'Organization.orgEditTabSecurityEnforce2Fa',
    defaultMessage: "Enforce 2FA for all organization's users",
  },
  orgEditTabSecurityRequireStrengthenAuth: {
    key: 'Organization.orgEditTabSecurityRequireStrengthenAuth',
    defaultMessage: 'Require vendors to strengthen authentication',
  },
  orgEditTabSecurityRequireStrengthenAuthTooltip: {
    key: 'Organization.orgEditTabSecurityRequireStrengthenAuthTooltip',
    defaultMessage:
      "Require vendor's users who don't use 2FA to strengthen their authentication before accessing information shared by your organization (assessments and findings).",
  },
  orgEditTabOkta: {
    key: 'Organization.orgEditTabOkta',
    defaultMessage: 'OKTA',
  },
  orgEditTabSOBO: {
    key: 'Organization.orgEditTabSOBO',
    defaultMessage: 'So/Bo pages',
  },
  orgEditTabApps: {
    key: 'Organization.orgEditTabApps',
    defaultMessage: 'Apps',
  },
  orgEditTabTrustedOrganization: {
    key: 'Organization.orgEditTabTrustedOrganization',
    defaultMessage: 'Trusted organization',
  },
  orgEditAddUser: {
    key: 'Organization.orgEditAddUser',
    defaultMessage: 'Add User',
  },
  orgEditTabColTitleDate: {
    key: 'Organization.orgEditTabColTitleDate',
    defaultMessage: 'Date',
  },
  orgEditTabColTitleName: {
    key: 'Organization.orgEditTabColTitleName',
    defaultMessage: 'Name',
  },
  orgEditTabColTitleEmail: {
    key: 'Organization.orgEditTabColTitleEmail',
    defaultMessage: 'Email',
  },
  orgEditTabColTitleRole: {
    key: 'Organization.orgEditTabColTitleRole',
    defaultMessage: 'Role',
  },
  orgEditNewUserTitle: {
    key: 'Organization.orgEditNewUserTitle',
    defaultMessage: 'Add new user',
  },
  orgEditNewUserSuccess: {
    key: 'Organization.orgEditNewUserSuccess',
    defaultMessage: 'User has been added successfully',
  },
  orgEditNewUserError: {
    key: 'Organization.orgEditNewUserError',
    defaultMessage: 'User creation failed',
  },
  orgEditNewUserAssessmentSuccess: {
    key: 'Organization.orgEditNewUserAssessmentSuccess',
    defaultMessage: 'Assessment has been sent successfully',
  },
  orgEditNewUserAssessmentError: {
    key: 'Organization.orgEditNewUserAssessmentError',
    defaultMessage: 'Could not send assessment to user',
  },
  orgEditNewUserEmailPlaceholder: {
    key: 'Organization.orgEditNewUserEmailPlaceholder',
    defaultMessage: 'Enter user email address',
  },
  orgEditNewUserRolePlaceholder: {
    key: 'Organization.orgEditNewUserRolePlaceholder',
    defaultMessage: 'Role',
  },
  orgEditUserRoleUpdate: {
    key: 'Organization.orgEditUserRoleUpdate',
    defaultMessage: 'Update user role',
  },
  orgEditUserEmailErrorFormat: {
    key: 'Organization.orgEditUserEmailErrorFormat',
    defaultMessage: 'Email format is invalid',
  },
  orgEditUserEmailRequired: {
    key: 'Organization.orgEditUserEmailRequired',
    defaultMessage: 'Email is required',
  },
  orgEditUserRoleRequired: {
    key: 'Organization.orgEditUserRoleRequired',
    defaultMessage: 'Role is required',
  },
  orgEditUserRoleUpdateSuccess: {
    key: 'Organization.orgEditUserRoleUpdateSuccess',
    defaultMessage: 'Role updated successfully',
  },
  orgEditUserRoleUpdateError: {
    key: 'Organization.orgEditUserRoleUpdateError',
    defaultMessage: 'Unable to update the role of this user',
  },
  orgEditNotificationsLabel: {
    key: 'Organization.orgEditNotificationsLabel',
    defaultMessage: 'Label',
  },
  orgEditNotificationsPlaceholderLabel: {
    key: 'Organization.orgEditNotificationsPlaceholderLabel',
    defaultMessage: 'Choose Label',
  },
  orgEditNotificationsSubject: {
    key: 'Organization.orgEditNotificationsSubject',
    defaultMessage: 'Subject',
  },
  orgEditNotificationsCheckbox: {
    key: 'Organization.orgEditNotificationsCheckbox',
    defaultMessage: 'Send me a test message',
  },
  orgEditNotificationsDisableNotification: {
    key: 'Organization.orgEditNotificationsDisableNotification',
    defaultMessage: 'Disable notification',
  },
  orgEditNotificationsRestoreDefault: {
    key: 'Organization.orgEditNotificationsRestoreDefault',
    defaultMessage: 'Restore default',
  },
  orgEditNotificationsSaved: {
    key: 'Organization.orgEditNotificationsSaved',
    defaultMessage: 'Template is saved',
  },
  orgEditNotificationsSavedAndSent: {
    key: 'Organization.orgEditNotificationsSavedAndSent',
    defaultMessage: 'Template is saved and sent',
  },
  orgEditNotificationsSaveFail: {
    key: 'Organization.orgEditNotificationsSaveFail',
    defaultMessage: 'Unable to make a save',
  },
  orgEditNotificationsResetDefaultText: {
    key: 'Organization.orgEditNotificationsResetDefaultText',
    defaultMessage: 'Are you sure you wish to reset to default message?',
  },
  orgEditNotificationsResetDefaultSuccess: {
    key: 'Organization.orgEditNotificationsResetDefaultSuccess',
    defaultMessage: 'Message has been reset',
  },
  orgEditNotificationsResetDefaultFail: {
    key: 'Organization.orgEditNotificationsResetDefaultFail',
    defaultMessage: 'Failed to reset message',
  },
  orgEditNotificationsSelectError: {
    key: 'Organization.orgEditNotificationsSelectError',
    defaultMessage: 'Please select a label',
  },
  orgEditOktaTitle: {
    key: 'Organization.orgEditOktaTitle',
    defaultMessage: 'OKTA integration setup',
  },
  orgEditOktaDescription1: {
    key: 'Organization.orgEditOktaDescription1',
    defaultMessage: 'Okta integration setup allow you to manage your own users externally',
  },
  orgEditOktaDescription2: {
    key: 'Organization.orgEditOktaDescription2',
    defaultMessage: 'Restricted access can be given to Business Owner, Sales Owner or Admin\n',
  },
  orgEditOktaDescription3: {
    key: 'Organization.orgEditOktaDescription3',
    defaultMessage: 'An in-depth step by step instructions available here',
  },
  orgEditOktaGeneral: {
    key: 'Organization.orgEditOktaGeneral',
    defaultMessage: 'General',
  },
  orgEditOktaGroups: {
    key: 'Organization.orgEditOktaGroups',
    defaultMessage: 'Groups',
  },
  orgEditOktaApplicationClientID: {
    key: 'Organization.orgEditOktaApplicationClientID',
    defaultMessage: 'Application client ID',
  },
  orgEditOktaApplicationClientIDPlaceholder: {
    key: 'Organization.orgEditOktaApplicationClientIDPlaceholder',
    defaultMessage: 'Enter application client ID',
  },
  orgEditOktaOrganizationUrl: {
    key: 'Organization.orgEditOktaOrganizationUrl',
    defaultMessage: 'Organization Url',
  },
  orgEditOktaOrganizationUrlPlaceholder: {
    key: 'Organization.orgEditOktaOrganizationUrlPlaceholder',
    defaultMessage: 'Enter organization url',
  },
  orgEditOktaAdmin: {
    key: 'Organization.orgEditOktaAdmin',
    defaultMessage: 'Admin',
  },
  orgEditOktaAdminPlaceholder: {
    key: 'Organization.orgEditOktaAdminPlaceholder',
    defaultMessage: 'Enter admin',
  },
  orgEditOktaSalesOwner: {
    key: 'Organization.orgEditOktaSalesOwner',
    defaultMessage: 'Sales Owner',
  },
  orgEditOktaSalesOwnerPlaceholder: {
    key: 'Organization.orgEditOktaSalesOwnerPlaceholder',
    defaultMessage: 'Enter sales owner',
  },
  orgEditOktaBusinessOwner: {
    key: 'Organization.orgEditOktaBusinessOwner',
    defaultMessage: 'Business Owner',
  },
  orgEditOktaBusinessOwnerPlaceholder: {
    key: 'Organization.orgEditOktaBusinessOwnerPlaceholder',
    defaultMessage: 'Enter business owner',
  },
  orgEditOktaUpdateSuccess: {
    key: 'Organization.orgEditOktaUpdateSuccess',
    defaultMessage: 'Okta information is updated',
  },
  orgEditOktaUpdateFail: {
    key: 'Organization.orgEditOktaUpdateFail',
    defaultMessage: 'Okta information failed to update',
  },
  orgEditSoBoPagesSO: {
    key: 'Organization.orgEditSoBoPagesSO',
    defaultMessage: 'SO',
  },
  orgEditSoBoPagesBO: {
    key: 'Organization.orgEditSoBoPagesBO',
    defaultMessage: 'BO',
  },
  orgEditSoBoPagesChoose: {
    key: 'Organization.orgEditSoBoPagesChoose',
    defaultMessage: 'Page',
  },
  orgEditSoBoPagesTitle: {
    key: 'Organization.orgEditSoBoPagesTitle',
    defaultMessage: 'Title',
  },
  orgEditSoBoPagesEditButton: {
    key: 'Organization.orgEditSoBoPagesEditButton',
    defaultMessage: 'Edit Assessment',
  },
  orgEditSoBoPagesSaveSuccess: {
    key: 'Organization.orgEditSoBoPagesSaveSuccess',
    defaultMessage: 'Page saved successfully',
  },
  orgEditSoBoPagesSaveError: {
    key: 'Organization.orgEditSoBoPagesSaveError',
    defaultMessage: 'Unable to save changes',
  },
  orgEditTransferOwnership: {
    key: 'Organization.orgEditTransferOwnership',
    defaultMessage: 'Transfer ownership?',
  },
  orgEditTransferOwnershipDescription: {
    key: 'Organization.orgEditTransferDescription',
    defaultMessage: 'Are you sure you want to make {{userName}} the owner of the organization?',
  },
  orgEditTransferOwnershipDescription2: {
    key: 'Organization.orgEditTransferDescription2',
    defaultMessage: "You can't undo this action.",
  },
  orgEditDowngradeRole: {
    key: 'Organization.orgEditDowngradeRole',
    defaultMessage: 'Downgrade {{userName}} permission?',
  },
  orgEditDowngradeRoleDescription: {
    key: 'Organization.orgEditDowngradeRoleDescription',
    defaultMessage: 'Downgrading will change their permission to "{{newRole}}"',
  },
  orgEditTransferOwnershipButton: {
    key: 'Organization.orgEditTransferOwnershipButton',
    defaultMessage: 'TRANSFER OWNERSHIP',
  },
  orgEditDowngradeButton: {
    key: 'Organization.orgEditDowngradeButton',
    defaultMessage: 'DOWNGRADE',
  },
  Admin: {
    key: 'Organization.Admin',
    defaultMessage: 'Admin',
  },
  BO: {
    key: 'Organization.BO',
    defaultMessage: 'BO',
  },
  SO: {
    key: 'Organization.SO',
    defaultMessage: 'SO',
  },
  Owner: {
    key: 'Organization.Owner',
    defaultMessage: 'Owner',
  },
  Reporter: {
    key: 'Organization.Reporter',
    defaultMessage: 'Reporter',
  },
  Viewer: {
    key: 'Organization.Viewer',
    defaultMessage: 'Viewer',
  },
  orgEditAppsDefaultApps: {
    key: 'Organization.orgEditAppsDefaultApps',
    defaultMessage: 'DEFAULT APPS',
  },
  orgEditAppsAdditionalApps: {
    key: 'Organization.orgEditAppsAdditionalApps',
    defaultMessage: 'ADDITIONAL APPS',
  },
  orgEditAppsStatus: {
    key: 'Organization.orgEditAppsStatus',
    defaultMessage: 'Status',
  },
  orgEditAppsCredits: {
    key: 'Organization.orgEditAppsCredits',
    defaultMessage: 'Credits',
  },
  orgEditAppsConfiguration: {
    key: 'Organization.orgEditAppsConfiguration',
    defaultMessage: 'Configuration',
  },
  orgEditAppsNoExtraConfiguration: {
    key: 'Organization.orgEditAppsNoExtraConfiguration',
    defaultMessage: 'No extra configuration needed',
  },
  orgEditAppsConfigurationRequired: {
    key: 'Organization.orgEditAppsConfigurationRequired',
    defaultMessage: 'Configuration required',
  },
  orgEditAppsUploadSbomTitle: {
    key: 'Organization.orgEditAppsUploadSbomTitle',
    defaultMessage: 'Upload whitelisted versions',
  },
  orgEditAppsUploadSbomSuccess: {
    key: 'Organization.orgEditAppsUploadSbomSuccess',
    defaultMessage: 'SBOM file upload successfully',
  },
  orgEditAppsUploadSbomFail: {
    key: 'Organization.orgEditAppsUploadSbomFail',
    defaultMessage: 'SBOM file upload failed',
  },
  orgEditAppsRemoveSbomSuccess: {
    key: 'Organization.orgEditAppsRemoveSbomSuccess',
    defaultMessage: 'SBOM file removed successfully',
  },
  orgEditAppsRemoveSbomFail: {
    key: 'Organization.orgEditAppsRemoveSbomFail',
    defaultMessage: 'SBOM file remove failed',
  },
  orgEditAppsUsername: {
    key: 'Organization.orgEditAppsUsername',
    defaultMessage: 'Username',
  },
  orgEditAppsPassword: {
    key: 'Organization.orgEditAppsPassword',
    defaultMessage: 'Password',
  },
  orgEditAppsKey: {
    key: 'Organization.orgEditAppsKey',
    defaultMessage: 'Key',
  },
  orgEditTrustedOrganizationsOrganization: {
    key: 'Organization.orgEditTrustedOrganizationsOrganization',
    defaultMessage: 'ORGANIZATION',
  },
  orgEditTrustedOrganizationsEmail: {
    key: 'Organization.orgEditTrustedOrganizationsEmail',
    defaultMessage: 'CONTACT EMAIL',
  },
  orgEditTrustedOrganizationsDate: {
    key: 'Organization.orgEditTrustedOrganizationsDate',
    defaultMessage: 'REQUEST DATE',
  },
  orgEditTrustedOrganizationsStatus: {
    key: 'Organization.orgEditTrustedOrganizationsStatus',
    defaultMessage: 'REQUEST STATUS',
  },
  orgEditTrustedOrganizationsApprove: {
    key: 'Organization.orgEditTrustedOrganizationsApprove',
    defaultMessage: 'Approve',
  },
  orgEditTrustedOrganizationsReject: {
    key: 'Organization.orgEditTrustedOrganizationsReject',
    defaultMessage: 'Reject',
  },
  orgEditTrustedOrganizationsRemove: {
    key: 'Organization.orgEditTrustedOrganizationsRemove',
    defaultMessage: 'Remove organization',
  },
  orgEditTrustedOrganizationsResend: {
    key: 'Organization.orgEditTrustedOrganizationsResend',
    defaultMessage: 'Resend trust invite',
  },
  orgEditTrustedOrganizationsLearnMore: {
    key: 'Organization.orgEditTrustedOrganizationsLearnMore',
    defaultMessage: 'LEARN MORE',
  },
  orgEditTrustedOrganizationsStatusRejected: {
    key: 'Organization.orgEditTrustedOrganizationsStatusRejected',
    defaultMessage: 'REJECTED',
  },
  orgEditTrustedOrganizationsStatusPending: {
    key: 'Organization.orgEditTrustedOrganizationsStatusPending',
    defaultMessage: 'PENDING',
  },
  orgEditTrustedOrganizationsStatusTrusted: {
    key: 'Organization.orgEditTrustedOrganizationsStatusTrusted',
    defaultMessage: 'TRUSTED',
  },
  orgEditTrustedOrganizationsStatusAwaitingApproval: {
    key: 'Organization.orgEditTrustedOrganizationsStatusAwaitingApproval',
    defaultMessage: 'AWAITING APPROVAL',
  },
  orgEditTrustedOrganizationsInfo: {
    key: 'Organization.orgEditTrustedOrganizationsInfo',
    defaultMessage:
      'Trusted Organizations allow businesses to get extra indicators about their 3rd party vendors, without disclosing any sensitive (or even company-specific) information.',
  },
};

export default {
  prefix: 'Organization',
  messages,
};
