import axios from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DownloadFile,
  GetOrganizationFileGallery,
  SetFileName,
  UploadOrganizationFileGallery,
  DeleteFile,
} from './endpoints';
import { FILES_COUNT_PER_OFFSET, FIRST_OFFSET } from 'pages/MyFiles/constants';

const getMyFilesRequest = async ({ type, expiration, search, offset = FIRST_OFFSET }) => {
  let payload = {
    count: FILES_COUNT_PER_OFFSET,
    offset,
  };

  if (type) {
    payload.type = type;
  }
  if (search) {
    payload.search = search;
  }
  if (expiration) {
    payload.expirationStatus = expiration;
  }

  return axios.post(GetOrganizationFileGallery.url, payload, { withCredentials: true });
};

export const getMyFiles = createAsyncThunk('getMyFiles', getMyFilesRequest);

const uploadMyFilesRequest = async ({ file, abortController }) => {
  return axios.post(
    UploadOrganizationFileGallery.url,
    { file },
    { signal: abortController?.signal, withCredentials: true },
  );
};

export const uploadMyFiles = createAsyncThunk('uploadMyFiles', uploadMyFilesRequest);

const setFileNameRequest = async ({ fileId, title }) => {
  return axios.post(SetFileName.url, { fileId, title }, { withCredentials: true });
};

export const setFileName = createAsyncThunk('setFileName', setFileNameRequest);

export const downloadFile = async ({ fileId }) => {
  return axios.post(DownloadFile.url, { fileId }, { withCredentials: true });
};

const deleteFileRequest = async ({ fileId }) => {
  return axios.post(DeleteFile.url, { fileId }, { withCredentials: true });
};

export const deleteFile = createAsyncThunk('deleteFile', deleteFileRequest);
