import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import debounce from 'lodash/debounce';

import Input from 'ui/Input';
import Select from 'ui/Select';
import Icon from 'ui/Icon';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import { saveTemplate } from 'api/editor';
import { translate } from 'utils/index';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { getBenchmarkDataSource, getWeightDataSource } from 'pages/Editor/lib';
import { selectSaveLoading } from 'pages/Editor/selectors';
import { messages } from 'pages/Editor/messages';
import './subject.scss';

const Subject = ({ category, activeSubject, id, setActiveSubject, disabled = false }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [values, setValues] = useState(category);
  const [selectorOpened, setSelectorOpened] = useState();
  const [subjectToRemove, setSubjectToRemove] = useState();
  const loading = useSelector(selectSaveLoading);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  useEffect(() => {
    setValues(category);
  }, [category]);

  useEffect(() => {
    if (activeSubject === id && !selectorOpened) {
      ref.current?.focus();
    }
  }, [activeSubject]);

  const onSaveTitleDebounced = useCallback(
    debounce((newValue) => {
      const payload = {
        categories: [
          {
            id: category.id,
            title: newValue,
          },
        ],
      };
      dispatch(saveTemplate(payload));
    }, 1000),
    [category],
  );

  const onTitleChange = ({ target: { value } }) => {
    setValues((state) => ({ ...state, title: value }));
    onSaveTitleDebounced(value);
  };

  const onChange = async (field, value) => {
    setValues((state) => ({ ...state, [field]: value }));

    const payload = {
      categories: [
        {
          id: category.id,
          [field]: value,
        },
      ],
    };

    await dispatch(saveTemplate(payload));
    setSelectorOpened(false);
  };

  const onRemove = () => {
    const payload = {
      categories: [
        {
          id: category.id,
        },
      ],
    };

    dispatch(saveTemplate(payload));
  };

  const visibleControls = category.controls.filter(({ enabled }) => enabled);

  return (
    <div
      className={`editor-subject ${!isMyVendorsTabSelected && 'editor-subject--customer-view'} ${
        activeSubject === id && 'editor-subject--focus'
      }`}
      onClick={() => setActiveSubject(id)}
      onBlur={() => setActiveSubject('')}
    >
      <div className="editor-subject__cell editor-subject__cell--name">
        <Input
          data-test="editor-subject-input-subject-title"
          className="editor-subject__name-input"
          onChange={onTitleChange}
          value={values.isPlaceholder ? translate(values.title) : values.title}
          loading={loading && values.title !== category.title}
          inline
          inputRef={ref}
          placeholder={translate(messages.subjectTitlePlaceholder)}
          disabled={disabled}
        />
      </div>
      {isMyVendorsTabSelected && (
        <>
          <div className="editor-subject__cell editor-subject__cell--weight">
            <Select
              className="editor-subject__weight-selector"
              data-test={`editor-subject-select-weight-${category.id}`}
              placeholder={translate(messages.noWeight)}
              options={getWeightDataSource(values.weight)}
              onChange={(value) => onChange('weight', value)}
              onClick={() => setSelectorOpened(true)}
              value={values.weight || undefined}
              inline
              loading={loading && values.weight !== category.weight}
              disabled={disabled}
            />
          </div>
          <div className="editor-subject__cell editor-subject__cell--benchmark">
            <Select
              className="editor-subject__benchmark-selector"
              data-test={`editor-subject-select-benchmark-${category.id}`}
              placeholder={translate(messages.noBenchmark)}
              options={getBenchmarkDataSource(values.benchmark)}
              onChange={(value) => onChange('benchmark', value)}
              onClick={() => setSelectorOpened(true)}
              value={values.benchmark || undefined}
              loading={loading && values.benchmark !== category.benchmark}
              inline
              disabled={disabled}
            />
          </div>
        </>
      )}

      <div className="editor-subject__cell editor-subject__cell--questions">
        {category.controls.length || '-'}
      </div>
      {isMyVendorsTabSelected && (
        <div className="editor-subject__cell editor-subject__cell--visible">
          {visibleControls.length || '-'}
        </div>
      )}
      <div className="editor-subject__cell editor-subject__cell--actions">
        <Button
          data-test={`editor-subject-button-remove-subject-${category.id}`}
          className="editor-subject__remove-subject"
          type="link"
          disabled={disabled}
          onClick={() => setSubjectToRemove(category)}
        >
          <Icon icon="remove" />
        </Button>
      </div>

      <RemoveConfirmationModal
        headerText={translate(messages.removeSubjectHeaderText)}
        confirmLoading={loading}
        onOk={onRemove}
        onCancel={() => setSubjectToRemove(null)}
        text={translate(messages.removeSubjectText)}
        open={!!subjectToRemove}
      />
    </div>
  );
};

Subject.propTypes = {
  category: PropTypes.object,
  activeSubject: PropTypes.string,
  id: PropTypes.string,
  setActiveSubject: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Subject;
