import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';

import Icon from 'ui/Icon';
import SecondaryButton from 'ui/SecondaryButton';
import { translate } from 'utils/index';
import { calculateControlKey } from 'pages/Assessment/lib';
import { generalMessages } from 'constants/messages';
import AddFinding from 'pages/Assessment/addFinding';
import Risk from 'pages/Recent/findingsColumns/risk';
import Status from 'pages/Recent/findingsColumns/status';
import { getRiskValue } from 'pages/Assessment/finalized/findings/lib';
import FindingStatus from 'constants/findingStatus';
import { stripHtml } from 'utils/html';
import { saveTemplate } from 'api/editor';

import { selectAnswerTypes, selectSubjectKey, selectTemplate } from 'pages/Editor/selectors';
import { messages } from 'pages/Editor/messages';
import FindingsList from './findingsList';
import DuplicateFindingButton from './duplicateFindingButton';
import './index.scss';

const Findings = ({ control, index, onCancel }) => {
  const dispatch = useDispatch();
  const subjectKey = useSelector(selectSubjectKey);
  const templateAnswerTypes = useSelector(selectAnswerTypes);
  const { risk } = useSelector(selectTemplate);
  const [findings, setFindings] = useState(control.findings);
  const [addFindingModal, setAddFindingModal] = useState();
  const [findingToEdit, setFindingToEdit] = useState();
  const [duplicateFinding, setDuplicateFinding] = useState();

  useEffect(() => {
    setFindings(control.findings);
  }, [control]);

  const answerType = templateAnswerTypes[control.answer_type_idx];

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'finding',
      item: duplicateFinding,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [duplicateFinding],
  );

  const onDrop = (item, key) => {
    setFindings((state) =>
      state.map((finding) =>
        finding.id === item.id ? { ...finding, triggers: [...finding.triggers, key] } : finding,
      ),
    );
    const finding = findings.find(({ id }) => id === item.id);
    const triggers = [...finding.triggers, key];
    setDuplicateFinding(null);
    onControlSave(finding.id, [...new Set(triggers)]);
  };

  const onControlSave = (findingId, triggers) => {
    const payload = {
      categories: [
        {
          id: control.categoryId,
          controls: [
            {
              id: control.id,
              findings: [
                {
                  id: findingId,
                  triggers,
                },
              ],
            },
          ],
        },
      ],
    };
    dispatch(saveTemplate(payload));
  };

  return (
    <div className="editor-findings">
      <div className="editor-findings__header">
        <Icon icon="findings" />
        {translate(messages.findings)} (
        {control.findings.flatMap(({ triggers }) => triggers).length})
      </div>
      <div className="editor-findings__main">
        <div className="editor-findings__key">{calculateControlKey(subjectKey, index)}</div>
        <div className="editor-findings__wrapper">
          <div className="editor-findings__title">{stripHtml(control.title)}</div>
          <div className="editor-findings__answer-type">
            {answerType.options.map((option, answerTypeIndex) => {
              const findingsForKey = findings.filter((finding) =>
                finding.triggers.includes(option.key),
              );

              return (
                <div key={option.key} className="editor-findings__answer-type-option">
                  <span className="editor-findings__answer-type-option-key">
                    {answerTypeIndex + 1}.
                  </span>
                  <div className="editor-findings__answer-type-option-content">
                    <div className="editor-findings__answer-type-option-value">{option.value}</div>
                    <FindingsList
                      onDrop={
                        findingsForKey.some(
                          ({ id }) =>
                            duplicateFinding &&
                            id === duplicateFinding.id &&
                            duplicateFinding.key === option.key,
                        )
                          ? undefined
                          : (item) => onDrop(item, option.key)
                      }
                    >
                      {findingsForKey.map((finding) => {
                        const riskValue = getRiskValue(finding.impact, finding.probability, risk);
                        const isDraggable =
                          duplicateFinding &&
                          finding.id === duplicateFinding.id &&
                          duplicateFinding.key === option.key;
                        return (
                          <div key={finding.id} className="editor-findings__finding">
                            <div
                              className={classNames('editor-findings__finding-wrapper', {
                                'editor-findings__finding-wrapper--draggable': isDraggable,
                              })}
                              ref={isDraggable ? dragRef : undefined}
                              style={{ opacity: isDraggable ? opacity : 1 }}
                            >
                              <div className="editor-findings__findings-description">
                                {finding.description}
                              </div>
                              <Risk risk={riskValue} />
                              <Status status={FindingStatus.open} />
                              <Tooltip
                                title={`${finding.recommendations.length} ${translate(
                                  messages.recommendations,
                                )}`}
                              >
                                <div className="editor-findings__recommendations">
                                  {finding.recommendations.length > 9
                                    ? '9+'
                                    : finding.recommendations.length}
                                </div>
                              </Tooltip>
                            </div>
                            <div className="editor-findings__actions">
                              <Tooltip title={translate(messages.edit)}>
                                <Button
                                  className="editor-findings__action"
                                  type="link"
                                  onClick={() => {
                                    setFindingToEdit(finding);
                                    setAddFindingModal(option.key);
                                  }}
                                >
                                  <Icon icon="edit" />
                                </Button>
                              </Tooltip>

                              <Tooltip title={translate(messages.duplicateFinding)}>
                                <Button
                                  className="editor-findings__action"
                                  type="link"
                                  onClick={() =>
                                    setDuplicateFinding({ key: option.key, id: finding.id })
                                  }
                                >
                                  <Icon icon="plus2" />
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        );
                      })}
                    </FindingsList>
                    <SecondaryButton
                      className="editor-findings__add-finding-button"
                      link
                      onClick={() => setAddFindingModal(option.key)}
                      uppercase
                    >
                      {translate(messages.addFinding)}
                    </SecondaryButton>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="editor-findings__action-bar">
        <SecondaryButton
          className="editor-findings__action-bar-ok-button"
          onClick={onCancel}
          size="medium"
          type="submit"
          outline
          uppercase
        >
          {translate(generalMessages.done)}
        </SecondaryButton>
      </div>
      <AddFinding
        isEditor
        finding={findingToEdit}
        control={control}
        visible={addFindingModal}
        toggleModal={(value) => {
          setAddFindingModal(value);
          setFindingToEdit(null);
        }}
      />
      {duplicateFinding && <DuplicateFindingButton onClose={() => setDuplicateFinding(null)} />}
    </div>
  );
};

Findings.propTypes = {
  control: PropTypes.object,
  index: PropTypes.number,
  onCancel: PropTypes.func,
};

export default Findings;
