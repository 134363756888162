import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from 'ui/Button';
import { translate } from 'utils/index';
import { useTheme } from 'hooks/theme';
import { getAssessment, markAsReviewAssessment } from 'api/assessment';
import { AssessmentStatus } from 'constants/assessmentStatus';
import { selectAssessment } from 'pages/Assessment/selectors';
import { messages } from 'pages/Assessment/messages';
import { useRole } from 'hooks/useRole';

import './markAsReview.scss';

const MarkAsReview = () => {
  const dispatch = useDispatch();
  const { assessmentId } = useParams();
  const [loading, setLoading] = useState();
  const { status, assessor } = useSelector(selectAssessment);
  const { isRestrictedBoSoViewerRole } = useRole();
  const { colorTheme } = useTheme();

  const onMarkAsReview = async () => {
    setLoading(true);
    await dispatch(markAsReviewAssessment()).unwrap();
    await dispatch(getAssessment({ id: assessmentId })).unwrap();
    setLoading(false);
  };

  return (
    status === AssessmentStatus.reviewed &&
    assessor === true && (
      <div className="assessment-finalized-mark-as-review">
        <Button
          className="assessment-finalized-mark-as-review__finalize-button"
          disabled={isRestrictedBoSoViewerRole}
          size="sm"
          color={colorTheme}
          onClick={onMarkAsReview}
          loading={loading}
          data-test="assessment-finalized-mark-as-review-button-mark-as-review"
        >
          {translate(messages.markAsReview)}
        </Button>
      </div>
    )
  );
};

export default MarkAsReview;
