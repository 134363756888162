import { getFileExtension } from 'utils/files';

export const truncateFilename = (file, maxLength = 25) => {
  const type = getFileExtension(file.path);

  const fileName = file?.title?.split('.')[0] || '';

  if (fileName?.length <= maxLength) return `${fileName}.${type}`;

  const truncatedFileName = `${fileName.substring(0, maxLength - 3)}...`;

  return `${truncatedFileName}.${type}`;
};
