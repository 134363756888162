export const LANGUAGES = [
  {
    label: 'English',
    value: 0,
    code: 'en',
  },
  {
    label: 'עברית',
    value: 1,
    code: 'he',
  },
  {
    label: 'Bulgarian',
    value: 2,
    code: 'bg',
  },
  {
    label: 'Findings',
    value: 3,
    code: 'fi',
  },
  {
    label: 'Polish',
    value: 4,
    code: 'pl',
  },
  {
    label: 'German',
    value: 5,
    code: 'de',
  },
];
