import React from 'react';
import { useSelector } from 'react-redux';

// COMPONENTS
import Risk from 'pages/Recent/findingsColumns/risk';
import Status from 'pages/Recent/findingsColumns/status';
import Tag from 'ui/Tag';
import SbomIcon from 'assets/svgs/sbom-icon.svg';

// REDUX
import { selectFindingsPage } from 'pages/Findings/selectors';
import { selectIsViewAggregated } from 'pages/Organization/selectors';

// UTILS
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { translate } from 'utils/index';
import { messages } from 'pages/Findings/messages';
import { FindingsRisk } from 'constants/findingsRisk';
import { Apps } from 'constants/apps';
import { getAppIconById, shouldAppNavigateToContinuousMonitoring } from 'utils/appsUtils';

export function useFindingsTable() {
  const { findings, sorter } = useSelector(selectFindingsPage);
  const isViewAggregated = useSelector(selectIsViewAggregated);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const sortOrder = sorter.descending ? 'descend' : 'ascend';

  const columns = [
    {
      title: isMyVendorsTabSelected ? translate(messages.vendor) : translate(messages.customer),
      key: 'findings-page-table-organization-name',
      dataIndex: 'vendor',
      width: 200,
      render: (vendor) => vendor,
      sorter: true,
      sortOrder: sorter.column === 'vendor' ? sortOrder : null,
    },
    {
      title: translate(messages.assessment),
      key: 'findings-page-table-assessment-name',
      dataIndex: 'assessmentName',
      width: 100,
      render: (_, finding) => {
        return (
          <div className="findings-table-assessment-container">
            {finding.app && shouldAppNavigateToContinuousMonitoring(finding.app) ? (
              <Tag
                icon={<img src={getAppIconById(finding.app)} alt="" />}
                name={finding.assessmentName}
                color="blue"
              />
            ) : (
              <Tag name={finding.assessmentName} color="blue" />
            )}
          </div>
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'assessment_name' ? sortOrder : null,
    },
    {
      title: translate(messages.subject),
      key: 'findings-page-table-subject',
      dataIndex: 'subject',
      render: (_, finding) => {
        return (
          <span className="finding-subject">
            {finding.app === Apps.sbom ? <img src={SbomIcon} alt="sbom icon" /> : null}
            {finding.subject}
          </span>
        );
      },
      width: 60,
      sorter: true,
      sortOrder: sorter.column === 'subject' ? sortOrder : null,
    },
    {
      title: translate(messages.description),
      key: 'findings-page-table-description',
      dataIndex: 'description',
      width: 100,
      render: (description) => {
        const text =
          description?.length > 130 ? `${description.substring(0, 130)}...` : description;
        return description ? (
          <div className="findings-page__list-description-column">{text}</div>
        ) : (
          translate(messages.noDescription)
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'description' ? sortOrder : null,
    },
    {
      title: translate(messages.risk),
      key: 'findings-page-table-risk',
      dataIndex: 'risk',
      width: 100,
      render: (risk) => {
        return <Risk risk={FindingsRisk.getRiskValue(risk).toString()} />;
      },
      sorter: true,
      sortOrder: sorter.column === 'risk' ? sortOrder : null,
    },
    {
      title: translate(messages.status),
      key: 'findings-page-table-status',
      dataIndex: 'status',
      width: 100,
      render: (status, finding) => {
        return <Status status={status} finding={finding} />;
      },
      sorter: true,
      sortOrder: sorter.column === 'status' ? sortOrder : null,
    },
  ];

  if (isViewAggregated) {
    const column = {
      title: translate(messages.organization),
      dataIndex: 'parentOrganizationCorporateName',
      sorter: true,
      sortOrder: sorter.column === 'parentOrganizationCorporateName' ? sortOrder : null,
      width: 200,
    };
    columns.splice(0, 0, column);
  }

  const dataSource = findings.map((finding) => ({
    id: finding.id,
    key: finding.id,
    findingId: finding.id,
    parentOrganizationCorporateName: finding.parentOrganizationCorporateName || '',
    subject: finding.subject || '',
    description: finding.description || '',
    vendor: finding.vendor || '',
    assessmentName: finding?.assessment_name || '',
    assessmentId: finding?.assessment_id || '',
    risk: finding.risk,
    status: finding.status,
    app: finding.app,
    customerAssessmentGroup: finding?.customerAssessmentGroup || '',
  }));

  return { columns, dataSource };
}
