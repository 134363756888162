import ROUTES from 'constants/routes';

// API
import { clearCookie } from 'utils/apiHelpers';
import { logoutRequest } from 'api/authentication';

// UTILS
import { isProduction, isProductionDomain } from 'utils/index';
import { ADMIN_TOKEN, ORG_ID_AUTH_KEY, TOKEN_AUTH_KEY, WS_TOKEN_AUTH_KEY } from 'utils/auth';

export const LOGOUT_TIMEOUT = 1000;

export const logout = async () => {
  if (isProduction()) await logoutRequest();
  clearCookie(TOKEN_AUTH_KEY);
  clearCookie(ORG_ID_AUTH_KEY);
  clearCookie(WS_TOKEN_AUTH_KEY);
  clearCookie(ADMIN_TOKEN);
  if (isProductionDomain()) {
    window?.SessionStack?.identify(false);
  }
  window.location.href = ROUTES.LOGOUT;
};
