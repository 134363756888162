export const messages = {
  main: {
    key: 'Editor.main',
    defaultMessage: '1. Main',
  },
  questionnaire: {
    key: 'Editor.questionnaire',
    defaultMessage: '2. Questionnaire',
  },
  assessmentEditor: {
    key: 'Editor.assessmentEditor',
    defaultMessage: 'Assessment editor',
  },
  assessment: {
    key: 'Editor.assessment',
    defaultMessage: 'assessment',
  },
  template: {
    key: 'Editor.template',
    defaultMessage: 'template',
  },
  assessmentTitle: {
    key: 'Editor.assessmentTitle',
    defaultMessage: 'Assessment Title',
  },
  assessmentDescription: {
    key: 'Editor.assessmentDescription',
    defaultMessage: '+ Add description',
  },
  subjectList: {
    key: 'Editor.subjectList',
    defaultMessage: 'Subject list',
  },
  subjectTitlePlaceholder: {
    key: 'Editor.subjectTitlePlaceholder',
    defaultMessage: 'Type category name',
  },
  name: {
    key: 'Editor.name',
    defaultMessage: 'NAME',
  },
  weight: {
    key: 'Editor.weight',
    defaultMessage: 'WEIGHT',
  },
  benchmark: {
    key: 'Editor.benchmark',
    defaultMessage: 'BENCHMARK',
  },
  questions: {
    key: 'Editor.questions',
    defaultMessage: 'NO. QUESTIONS',
  },
  visible: {
    key: 'Editor.visible',
    defaultMessage: 'NO. VISIBLE',
  },
  noWeight: {
    key: 'Editor.noWeight',
    defaultMessage: 'No weight',
  },
  noBenchmark: {
    key: 'Editor.noBenchmark',
    defaultMessage: 'No benchmark',
  },
  removeSubjectText: {
    key: 'Editor.removeSubjectText',
    defaultMessage: 'All category information will be lost',
  },
  removeSubjectHeaderText: {
    key: 'Editor.removeSubjectHeaderText',
    defaultMessage: 'Delete category',
  },
  addSubject: {
    key: 'Editor.addSubject',
    defaultMessage: 'ADD SUBJECT',
  },
  subjects: {
    key: 'Editor.subjects',
    defaultMessage: 'Subjects',
  },
  subjectTitleTooltip: {
    key: 'Editor.subjectTitleTooltip',
    defaultMessage: 'to edit name double click',
  },
  moveFollowUpControls: {
    key: 'Editor.moveFollowUpControls',
    defaultMessage: 'Move follow-up controls?',
  },
  moveControls: {
    key: 'Editor.moveControls',
    defaultMessage: 'MOVE CONTROLS',
  },
  selectAll: {
    key: 'Editor.selectAll',
    defaultMessage: 'SELECT ALL',
  },
  addTopDescription: {
    key: 'Editor.addTopDescription',
    defaultMessage: '+ Add top description',
  },
  addBottomDescription: {
    key: 'Editor.addBottomDescription',
    defaultMessage: '+ Add bottom description',
  },
  enterQuestion: {
    key: 'Editor.enterQuestion',
    defaultMessage: 'Enter question',
  },
  createNewAnswer: {
    key: 'Editor.createNewAnswer',
    defaultMessage: 'CREATE NEW ANSWER',
  },
  or: {
    key: 'Editor.or',
    defaultMessage: 'or',
  },
  openAnswerBank: {
    key: 'Editor.openAnswerBank',
    defaultMessage: 'OPEN ANSWER BANK',
  },
  required: {
    key: 'Editor.required',
    defaultMessage: 'Required',
  },
  optional: {
    key: 'Editor.optional',
    defaultMessage: 'Optional',
  },
  visibleText: {
    key: 'Editor.visibleText',
    defaultMessage: 'Visible',
  },
  hidden: {
    key: 'Editor.hidden',
    defaultMessage: 'Hidden',
  },
  addQuestion: {
    key: 'Editor.addQuestion',
    defaultMessage: 'ADD QUESTION',
  },
  addAppTrigger: {
    key: 'Editor.addAppTrigger',
    defaultMessage: 'ADD APP TRIGGER',
  },
  select: {
    key: 'Editor.select',
    defaultMessage: 'SELECT',
  },
  cancel: {
    key: 'Editor.cancel',
    defaultMessage: 'CANCEL',
  },
  findPreviousAnswer: {
    key: 'Editor.findPreviousAnswer',
    defaultMessage: 'Find previous answer:',
  },
  linkedQuestionsView: {
    key: 'Editor.linkedQuestionsView',
    defaultMessage: 'VIEW ALL LINKED QUESTIONS',
  },
  linkedQuestionsTitle: {
    key: 'Editor.linkedQuestionsTitle',
    defaultMessage: 'This answer is linked to following questions:',
  },
  linkedQuestionQuestionName: {
    key: 'Editor.linkedQuestionQuestionName',
    defaultMessage: 'QUESTION NAME',
  },
  results: {
    key: 'Editor.results',
    defaultMessage: 'Results',
  },
  radioButton: {
    key: 'Editor.radioButton',
    defaultMessage: 'RADIO BUTTON',
  },
  checkbox: {
    key: 'Editor.checkbox',
    defaultMessage: 'CHECKBOX',
  },
  dropDown: {
    key: 'Editor.dropDown',
    defaultMessage: 'DROP DOWN',
  },
  multiSelect: {
    key: 'Editor.multiSelect',
    defaultMessage: 'MULTI SELECT',
  },
  answerTypeAll: {
    key: 'Editor.answerTypeAll',
    defaultMessage: 'All',
  },
  answerTypeInput: {
    key: 'Editor.answerTypeInput',
    defaultMessage: 'Single line text',
  },
  answerTypeTextArea: {
    key: 'Editor.answerTypeTextArea',
    defaultMessage: 'Multi line text',
  },
  answerTypeRadioButton: {
    key: 'Editor.answerTypeRadioButton',
    defaultMessage: 'Radio button',
  },
  answerTypeCheckbox: {
    key: 'Editor.answerTypeCheckbox',
    defaultMessage: 'Checkbox',
  },
  answerTypeDropDown: {
    key: 'Editor.answerTypeDropDown',
    defaultMessage: 'Drop down',
  },
  answerTypeMultiSelect: {
    key: 'Editor.answerTypeMultiSelect',
    defaultMessage: 'Multi select',
  },
  answerTypeFileUpload: {
    key: 'Editor.answerTypeFileUpload',
    defaultMessage: 'File upload',
  },
  answerTypeDatePicker: {
    key: 'Editor.answerTypeDatePicker',
    defaultMessage: 'Date picker',
  },
  chooseAnswerType: {
    key: 'Editor.chooseAnswerType',
    defaultMessage: 'Choose answer type',
  },
  emptyAnswerTypeWarning: {
    key: 'Editor.emptyAnswerTypeWarning',
    defaultMessage: 'Please select an answer type',
  },
  done: {
    key: 'Editor.done',
    defaultMessage: 'DONE',
  },
  answerCreatorCreateNewAnswer: {
    key: 'Editor.answerCreatorCreateNewAnswer',
    defaultMessage: 'Create new answer',
  },
  typeOption: {
    key: 'Editor.typeOption',
    defaultMessage: 'Type option',
  },
  addOption: {
    key: 'Editor.addOption',
    defaultMessage: 'Add option',
  },
  removeOptionText: {
    key: 'Editor.removeOptionText',
    defaultMessage: 'Are you sure you want to permanently remove this option?',
  },
  enterCategoryTitle: {
    key: 'Editor.enterCategoryTitle',
    defaultMessage: 'Enter category title',
  },
  addSubjectDescription: {
    key: 'Editor.addSubjectDescription',
    defaultMessage: '+ Add subject description',
  },
  editAnswers: {
    key: 'Editor.editAnswers',
    defaultMessage: 'EDIT ANSWERS',
  },
  editAnswersText: {
    key: 'Editor.editAnswersText',
    defaultMessage: 'Edit answers',
  },
  saveForAll: {
    key: 'Editor.saveForAll',
    defaultMessage: 'Save for all',
  },
  saveOnlyForThisQuestion: {
    key: 'Editor.saveOnlyForThisQuestion',
    defaultMessage: 'Save only for this question',
  },
  saveChanges: {
    key: 'Editor.saveChanges',
    defaultMessage: 'Save changes',
  },
  settingsTitle: {
    key: 'Editor.settingsTitle',
    defaultMessage: 'SETTINGS',
  },
  settingsAssessmentBenchmark: {
    key: 'Editor.settingsAssessmentBenchmark',
    defaultMessage: 'Assessment Benchmark',
  },
  settingsAssessmentBenchmarkPlaceholder: {
    key: 'Editor.settingsAssessmentBenchmarkPlaceholder',
    defaultMessage: 'Enter number (between 1-100)',
  },
  settingsDueDate: {
    key: 'Editor.settingsDueDate',
    defaultMessage: 'Due date',
  },
  settingsCustomDaysInputPlaceholder: {
    key: 'Editor.settingsCustomDaysInputPlaceholder',
    defaultMessage: 'Enter number of days',
  },
  settingsDueDateSelectPlaceholder: {
    key: 'Editor.settingsDueDateSelectPlaceholder',
    defaultMessage: 'Select due date',
  },
  settingsCustomDaysInputLabel: {
    key: 'Editor.settingsCustomDaysInputLabel',
    defaultMessage: 'Days to submit',
  },
  settingsTextDirection: {
    key: 'Editor.settingsTextDirection',
    defaultMessage: 'Text Direction',
  },
  settingsTextDirectionRTL: {
    key: 'Editor.settingsTextDirectionRTL',
    defaultMessage: 'RTL',
  },
  settingsTextDirectionLTR: {
    key: 'Editor.settingsTextDirectionLTR',
    defaultMessage: 'LTR',
  },
  settingsReport: {
    key: 'Editor.settingsReport',
    defaultMessage: 'Report',
  },
  settingsUploadReport: {
    key: 'Editor.settingsUploadReport',
    defaultMessage: 'Upload Report',
  },
  settingsUploadFileError: {
    key: 'Editor.settingsUploadFileError',
    defaultMessage: 'Failed to upload file',
  },
  settingUploadFiles: {
    key: 'Editor.settingUploadFiles',
    defaultMessage: 'Upload Files:',
  },
  settingUploadFilesTypes: {
    key: 'Editor.settingUploadFilesTypes',
    defaultMessage: 'Support: Word, Excel, PDF, JPG, PNG.\n',
  },
  settingUploadFilesButton: {
    key: 'Editor.settingUploadFilesButton',
    defaultMessage: 'Upload',
  },
  settingsInvalidFileType: {
    key: 'Editor.settingsInvalidFileType',
    defaultMessage: 'Invalid file type',
  },
  settingsInvalidFileDimensions: {
    key: 'Editor.settingsInvalidFileDimensions',
    defaultMessage: 'Invalid file dimensions',
  },
  settingsInvalidFileSize1: {
    key: 'Editor.settingsInvalidFileSize1',
    defaultMessage: 'The file',
  },
  settingsInvalidFileSize2: {
    key: 'Editor.settingsInvalidFileSize2',
    defaultMessage: 'is too large',
  },
  findings: {
    key: 'Editor.findings',
    defaultMessage: 'Findings',
  },
  addFinding: {
    key: 'Editor.addFinding',
    defaultMessage: '+ Add finding',
  },
  recommendations: {
    key: 'Editor.recommendations',
    defaultMessage: 'recommendations',
  },
  edit: {
    key: 'Editor.edit',
    defaultMessage: 'Edit',
  },
  duplicateFinding: {
    key: 'Editor.duplicateFinding',
    defaultMessage: 'Duplicate finding',
  },
  removeQuestion: {
    key: 'Editor.removeQuestion',
    defaultMessage: 'All question information will be lost',
  },
  removeQuestionHeader: {
    key: 'Editor.removeQuestionHeader',
    defaultMessage: 'Delete question?',
  },
  oneWeek: {
    key: 'Editor.oneWeek',
    defaultMessage: '1 Week',
  },
  twoWeeks: {
    key: 'Editor.twoWeeks',
    defaultMessage: '2 Weeks',
  },
  oneMonth: {
    key: 'Editor.oneMonth',
    defaultMessage: '1 Month',
  },
  customDueDate: {
    key: 'Editor.customDueDate',
    defaultMessage: 'Custom...',
  },
  previewButton: {
    key: 'Editor.previewButton',
    defaultMessage: 'PREVIEW',
  },
  assignButton: {
    key: 'Editor.assignButton',
    defaultMessage: 'ASSIGN',
  },
  editTemplate: {
    key: 'Editor.editTemplate',
    defaultMessage: 'edit template',
  },
  createNewFollowUpQuestion: {
    key: 'Editor.createNewFollowUpQuestion',
    defaultMessage: 'Create new Follow up question',
  },
  addNewQuestion: {
    key: 'Editor.addNewQuestion',
    defaultMessage: 'Add new question',
  },
  addAppTriggerTitle: {
    key: 'Editor.addAppTriggerTitle',
    defaultMessage: 'Add app trigger',
  },
  findQuestions: {
    key: 'Editor.findQuestions',
    defaultMessage: 'Find questions:',
  },
  searchQuestionOrPick: {
    key: 'Editor.searchQuestionOrPick',
    defaultMessage: 'Search question or pick from selected category',
  },
  followUpQuestions: {
    key: 'Editor.followUpQuestions',
    defaultMessage: 'Follow up questions',
  },
  if: {
    key: 'Editor.if',
    defaultMessage: 'If',
  },
  goToQuestion: {
    key: 'Editor.goToQuestion',
    defaultMessage: 'Go to question',
  },
  returnToFollowUpQuestions: {
    key: 'Editor.returnToFollowUpQuestions',
    defaultMessage: 'Return to follow up question',
  },
  dragFindingToDuplicate: {
    key: 'Editor.dragFindingToDuplicate',
    defaultMessage: 'Drag finding to duplicate',
  },
  noSubjectPlaceholder: {
    key: 'Editor.noSubjectPlaceholder',
    defaultMessage: 'Add a subject and his title to begin',
  },
  pleaseSelectApp: {
    key: 'Editor.pleaseSelectApp',
    defaultMessage: 'Please select app, you can select more than one',
  },
  appTriggerSelectTooltip: {
    key: 'Editor.appTriggerSelectTooltip',
    defaultMessage: 'Select app',
  },
  appTriggerDisabledTooltipAlreadySelected: {
    key: 'Editor.appTriggerDisabledTooltipAlreadySelected',
    defaultMessage: 'App already selected',
  },
  appTriggerDisabledTooltipAppNotActivated: {
    key: 'Editor.appTriggerDisabledTooltipAppNotActivated',
    defaultMessage: 'App must be enabled in app settings first',
  },
  chooseOneQuestionForEachParameter: {
    key: 'Editor.chooseOneQuestionForEachParameter',
    defaultMessage: 'Choose one question for each parameter',
  },
  continueButton: {
    key: 'Editor.continueButton',
    defaultMessage: 'Continue',
  },
  categoryPlaceholder: {
    key: 'Editor.categoryPlaceholder',
    defaultMessage: 'Category',
  },
  removeAppText: {
    key: 'Editor.removeAppText',
    defaultMessage: 'This app will no longer be part of this template',
  },
  removeAppHeaderText: {
    key: 'Editor.removeAppHeaderText',
    defaultMessage: 'Delete app?',
  },
  appsQuestionsCollapseHeaderTextShow: {
    key: 'Editor.appsQuestionsCollapseHeaderTextShow',
    defaultMessage: 'SHOW FOLLOW-UP CONTROLS',
  },
  appsQuestionsCollapseHeaderTextHide: {
    key: 'Editor.appsQuestionsCollapseHeaderTextHide',
    defaultMessage: 'HIDE FOLLOW-UP CONTROLS',
  },
  removeFollowUpQuestionPopup: {
    key: 'Editor.removeFollowUpQuestionPopup',
    defaultMessage: 'Are you sure you want to remove follow up questions for this answer?',
  },
  removeFollowUpQuestionPopupHeader: {
    key: 'Editor.removeFollowUpQuestionPopupHeader',
    defaultMessage: 'Delete question?',
  },
  answerBankDescription: {
    key: 'Editor.answerBankDescription',
    defaultMessage:
      'Please enter search term or search by answer type. You can edit the answer and their weight after selection.',
  },
  moveFollowUpControlsDescription: {
    key: 'Editor.moveFollowUpControlsDescription',
    defaultMessage:
      'Which of the following follow-up controls do you want to move with the original control to the new category?',
  },
  mainText: {
    key: 'Editor.mainText',
    defaultMessage:
      'Please name your assessment and add subjects before continuing to add questions. You can always add more subjects at a later stage or edit this info.',
  },
};

export default {
  prefix: 'Editor',
  messages,
};
