import { useCallback, useMemo, useEffect, useState } from 'react';
import CardWithLogo from 'components/CardWithLogo';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';

import { downloadFile } from 'api/myFiles';
import { translate } from 'utils/index';
import { getFileExtension } from 'utils/files';
import { useTheme } from 'hooks/theme';
import Popup from 'ui/Popup';
import Button from 'ui/Button';
import ExcelView from 'components/FileBrowser/excelView';
import DocView from 'components/FileBrowser/docView';
import { generalMessages } from 'constants/messages';
import { messages } from './messages';
import noPreviewImage from 'assets/svgs/no-preview.svg';
import { ReactComponent as PreviewIcon } from 'assets/svgs/preview-icon.svg';

const excelFileTypes = ['xlsx', 'xls', 'csv'];

const FilePreview = ({
  fileData,
  open,
  onClose,
  onClickPrev,
  onClickNext,
  hasNext = true,
  hasPrev = true,
}) => {
  const [fileUrl, setFileUrl] = useState('');
  const { colorTheme } = useTheme();

  const type = getFileExtension(fileData?.path || '');
  const isExcel = excelFileTypes.indexOf(type) > -1;
  const isDoc = type === 'docx' || type === 'doc';

  useEffect(() => {
    if (fileData) {
      getFileSrc(fileData.id).then((path) => {
        setFileUrl(path);
      });
    }
  }, [fileData]);

  const handleDownload = () => {
    if (fileData && fileUrl) {
      fetch(fileUrl, { credentials: 'same-origin' })
        .then((res) => res.blob())
        .then((data) => {
          const dataFile = new File([data], 'file', { type: data.type });
          fileDownload(dataFile, fileData.title);
        });
    }
  };

  const rightFooterButtons = (
    <div className="file-browser__footer-right-buttons">
      <Button
        data-test="file-browser-button-footer-download"
        outline
        name={translate(generalMessages.download)}
        onClick={handleDownload}
        color={colorTheme}
        size="sm"
        disabled={!fileUrl}
      />
      <Button
        data-test="file-browser-button-footer-next"
        name={translate(generalMessages.next)}
        onClick={onClickNext}
        color={colorTheme}
        disabled={!hasNext}
        size="sm"
      />
    </div>
  );

  const footer = useMemo(
    () => (
      <CardWithLogo.Footer
        leftButtonOnClick={onClickPrev}
        leftButtonText={translate(generalMessages.previous)}
        leftButtonAdditionalProps={{ disabled: !hasPrev }}
        rightComponent={rightFooterButtons}
      />
    ),
    [fileUrl],
  );

  const getFileSrc = useCallback(async () => {
    const payloadFileId = fileData.id;

    const { path } = await downloadFile({
      fileId: payloadFileId,
    });
    return path;
  }, [fileData?.id]);

  return (
    <Popup
      open={open}
      footer={footer}
      onCancel={onClose}
      width={900}
      title={
        <>
          <PreviewIcon />
          {fileData?.title || translate(messages.filePreviewDefaultTitle)}
        </>
      }
      className="file-browser__popup"
    >
      <div className="file-browser__file">
        {isExcel && <ExcelView fileUrl={fileUrl} />}
        {isDoc && <DocView handleDownload={() => handleDownload()} />}
        {!isExcel && !isDoc && fileUrl && (
          <iframe title={fileData?.title || ''} src={fileUrl} className="file-browser__iframe" />
        )}
        {!fileUrl && (
          <div className="file-browser__file__no-preview">
            <img src={noPreviewImage} alt="no-preview" />
            <p>{translate(messages.noPreview)}</p>
          </div>
        )}
      </div>
    </Popup>
  );
};

FilePreview.propTypes = {
  fileData: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  hasNext: PropTypes.bool,
  hasPrev: PropTypes.bool,
  onClickNext: PropTypes.func,
  onClickPrev: PropTypes.func,
};

export default FilePreview;
