import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { saveTemplate } from 'api/editor';
import { selectTemplate } from 'pages/Editor/selectors';
import Radio from 'ui/Radio';
import Checkbox from 'ui/Checkbox';
import { filterArrayValue, parseArrayValue } from 'utils/assessment';
import { ControlType } from 'constants/controlType';

import TriggerMenu from './triggerMenu';
import TriggersModal from './triggersModal';
import './answerTypeOptions.scss';

const AnswerTypeOptions = ({ addQuestion, control, controlType, options, onSave }) => {
  const { upstream } = useSelector(selectTemplate);
  const [showTriggersModal, setShowTriggersModal] = useState();
  const [activeAnswer, setActiveAnswer] = useState();
  const [value, setValue] = useState(control.answer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (controlType === ControlType.Checkbox) {
      setValue(filterArrayValue(control.answer));
    }
  }, [control]);

  const filteredOptions = (options || []).filter(
    (option) => option.value !== '' || option.value !== 0,
  );

  const createNewTrigger = (key) => {
    setShowTriggersModal(true);
    setActiveAnswer(key);
  };

  const onControlSave = (key, triggers) => {
    const payload = {
      categories: [
        {
          id: control.categoryId,
          controls: [
            {
              ...control,
              triggers: {
                ...control.triggers,
                [key]: control.triggers[key] ? [...control.triggers[key], ...triggers] : triggers,
              },
            },
          ],
        },
      ],
    };
    dispatch(saveTemplate(payload));
  };

  const onRadioChange = (event) => {
    const parsedValue = value == event.target.value ? '' : parseInt(event.target.value, 10);
    setValue(parsedValue);
    onSave(parsedValue);
  };

  return (
    <div className="editor-answer-type-options">
      {filteredOptions.map((option, index) => {
        const weightClassName = classNames('editor-answer-type-options__weight', {
          'editor-answer-type-options__weight--red': option.weight < 33,
          'editor-answer-type-options__weight--orange': option.weight >= 33 && option.weight < 66,
          'editor-answer-type-options__weight--green': option.weight >= 66,
        });

        const onCheckboxChange = () => {
          const newValue = value ? [...value] : [];
          if (newValue.includes(option.key)) {
            const parsedValue = parseArrayValue(newValue.filter((key) => key !== option.key));
            setValue(parsedValue);
            onSave(parsedValue);
          } else {
            const parsedValue = parseArrayValue([...newValue, option.key]);
            setValue(parsedValue);
            onSave(parsedValue);
          }
        };

        return (
          <div key={option.key} className="editor-answer-type-options__option">
            {![ControlType.RadioGroup, ControlType.Checkbox].includes(controlType) && (
              <>
                <div className="editor-answer-type-options__key">{index + 1}. </div>
                <div className="editor-answer-type-options__value">{option.value}</div>
              </>
            )}
            {controlType === ControlType.RadioGroup && (
              <div className="editor-answer-type-options__value">
                <Radio
                  key={option.key}
                  onClick={onRadioChange}
                  value={option?.key}
                  name={`radio-${control.id}`}
                  checked={option?.key.toString() === value?.toString()}
                >
                  {option.value}
                </Radio>
              </div>
            )}
            {controlType === ControlType.Checkbox && (
              <div className="editor-answer-type-options__value">
                <Checkbox
                  key={option.key}
                  onChange={onCheckboxChange}
                  value={option.key}
                  name={`checkbox-${control.id}-${option.key}`}
                  checked={(value || []).indexOf(option.key) > -1}
                  mode="checkbox"
                >
                  {option.value}
                </Checkbox>
              </div>
            )}
            <div className={weightClassName}>{option.weight}%</div>
            {!upstream && (
              <TriggerMenu
                active={(control.triggers[option.key] || []).length > 0}
                createNewTrigger={() => createNewTrigger(option.key)}
                addQuestion={() => addQuestion(option.key)}
              />
            )}
          </div>
        );
      })}
      <TriggersModal
        controlId={control.id}
        onSelectTriggers={onControlSave}
        selectedAnswer={activeAnswer}
        selectedTriggers={control.triggers[activeAnswer] || []}
        toggleModal={setShowTriggersModal}
        open={showTriggersModal}
      />
    </div>
  );
};

AnswerTypeOptions.propTypes = {
  addQuestion: PropTypes.func,
  control: PropTypes.object,
  controlType: PropTypes.oneOf([ControlType.Checkbox, ControlType.RadioGroup]),
  options: PropTypes.array,
  onSave: PropTypes.func,
};

export default AnswerTypeOptions;
