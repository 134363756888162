import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import Button from 'ui/Button';
import SecondaryButton from 'ui/SecondaryButton';
import Icon from 'ui/Icon';
import Input from 'ui/Input';
import { translate } from 'utils/index';
import { getAnswerTypes } from 'api/editor';

import { selectEditableAnswerTypes } from 'pages/Editor/selectors';
import { messages } from 'pages/Editor/messages';
import List from './list';
import AnswerTypeMenu from './answerTypeMenu';
import { filterByType, getGroupCounts } from './lib';
import './index.scss';

const AnswerBank = ({ open, toggleModal, selectAnswerType }) => {
  const dispatch = useDispatch();
  const answerTypes = useSelector(selectEditableAnswerTypes);
  const [selectedAnswerType, setSelectedAnswerType] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getAnswerTypes());
  }, []);

  useEffect(() => {
    setSelectedAnswerType(null);
  }, [selectedType]);

  useEffect(() => {
    if (!open) {
      setSelectedAnswerType(null);
      setSelectedType(null);
      setSearch('');
    }
  }, [open]);

  const filteredAnswerTypes = answerTypes.filter((answerType) => {
    if (search === '') {
      return answerType;
    }

    return (answerType.options || []).some((option) => {
      return option.value.toLowerCase().includes(search.toLowerCase());
    });
  });

  const groupCounts = getGroupCounts(filteredAnswerTypes);

  const onCancel = () => {
    toggleModal(false);
  };

  const onOk = () => {
    if (!selectedAnswerType) return;
    selectAnswerType(selectedAnswerType);
    toggleModal(false);
  };

  const footer = [
    <Button
      key="footer_index_1"
      data-test="editor-answer-bank-button-cancel"
      className="editor-answer-bank__footer-cancel-button"
      size="sm"
      color="gray"
      onClick={onCancel}
      link
    >
      {translate(messages.cancel)}
    </Button>,
    <SecondaryButton
      key="footer_index_2"
      disabled={!selectedAnswerType}
      className="editor-answer-bank__footer-ok-button"
      onClick={onOk}
      size="medium"
      type="submit"
      outline
    >
      {translate(messages.select)}
    </SecondaryButton>,
  ];

  const onSearch = ({ target: { value } }) => {
    setSearch(value);
  };

  return (
    <Modal
      className="editor-answer-bank__modal"
      closeIcon={<Icon icon="close" />}
      open={open}
      onCancel={onCancel}
      width={900}
      footer={footer}
    >
      <div className="editor-answer-bank__header">
        <h3 className="editor-answer-bank__header-title">
          {translate(messages.findPreviousAnswer)}
        </h3>
        <div className="editor-answer-bank__header-description">
          {translate(messages.answerBankDescription)}
        </div>
        <Input.Search className="editor-answer-bank__search" onChange={onSearch} value={search} />
        <AnswerTypeMenu
          groupCounts={groupCounts}
          selected={selectedType}
          setSelectedType={setSelectedType}
        />
      </div>

      <div className="editor-answer-bank__list-wrapper">
        <div className="editor-answer-bank__list-header">{translate(messages.results)}:</div>
        <List
          onOk={onOk}
          groupCounts={!selectedType ? groupCounts : undefined}
          rows={
            selectedType ? filterByType(filteredAnswerTypes, selectedType) : filteredAnswerTypes
          }
          search={search}
          select={setSelectedAnswerType}
          selectedType={selectedType}
          selected={selectedAnswerType}
        />
      </div>
    </Modal>
  );
};

AnswerBank.propTypes = {
  selectAnswerType: PropTypes.func,
  toggleModal: PropTypes.func,
  open: PropTypes.bool,
};

export default AnswerBank;
