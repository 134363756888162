/* eslint-disable */

export const createStonlyConfig = () => {
  const id = process.env.REACT_APP_STONLY_ID;
  if (id) {
    window.STONLY_WID = id;
    !(function (s, t, o, n, l, y, w, g) {
      s.StonlyWidget ||
        (((w = s.StonlyWidget =
          function () {
            w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
          }).scriptPath = n),
        (w.queue = []),
        ((y = t.createElement(o)).async = !0),
        (g = new XMLHttpRequest()).open('GET', `${n}version?v=${Date.now()}`, !0),
        (g.onreadystatechange = function () {
          g.readyState === 4 &&
            ((y.src = `${n}stonly-widget.js?v=${g.status === 200 ? g.responseText : Date.now()}`),
            (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l));
        }),
        g.send());
    })(window, document, 'script', 'https://stonly.com/js/widget/v2/');
  }
};
