import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ControlComponent from 'components/ControlComponent';
import { ControlType } from 'constants/controlType';
import { translate } from 'utils/index';
import NewVendorFooter from 'components/NewVendorFooter';
import Form from 'ui/Form';
import { ReactComponent as ArrowIconRight } from 'assets/svgs/arrowRight.svg';
import { generalMessages } from 'constants/messages';
import { useCustomerManageContext } from 'hooks/useCustomerManageContext';

import { validPayload } from 'pages/Customers/CustomerManage/lib';
import { messages } from './messages';
import './index.scss';

const ContactInformationTab = ({ handleTabChange, prevAction }) => {
  const {
    currentCustomer,
    customerContactData,
    isContactFormDirty,
    contactFormErrors,
    onChangeContactFrom,
    groups,
    updateCustomer,
  } = useCustomerManageContext();
  const [currentErrors, setCurrentErrors] = useState(contactFormErrors);
  const [touched, setTouched] = useState({});
  const { isLoading } = useSelector((state) => state.customers);

  useEffect(() => {
    setCurrentErrors(contactFormErrors);
  }, [contactFormErrors]);

  const onSubmit = (event) => {
    event.preventDefault();
    validAsync(customerContactData);
    setTouched((state) => ({ ...state, corporateName: true, companyContact: true, email: true }));

    currentCustomer ? updateCustomer() : handleTabChange();
  };

  const validAsync = useCallback(async (payload) => {
    const currentError = await validPayload(payload);
    setCurrentErrors(currentError);
  }, []);

  useEffect(() => {
    if (isContactFormDirty.current) {
      validAsync(customerContactData);
    }
  }, [customerContactData]);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <ControlComponent
          type={ControlType.Input}
          debounceTime={250}
          data={{
            label: `${translate(messages.companyName)}*`,
            payload: {
              value: customerContactData.corporateName,
              name: 'corporateName',
              error: touched.corporateName && currentErrors?.corporateName,
              onChange: (value) => {
                onChangeContactFrom(value, 'corporateName');
              },
              onBlur: () => {
                setTouched((state) => ({ ...state, corporateName: true }));
              },
              placeholder: translate(messages.enterCompanyName),
            },
          }}
        />
        <ControlComponent
          type={ControlType.Input}
          debounceTime={250}
          data={{
            label: `${translate(messages.contactName)}*`,
            payload: {
              value: customerContactData.companyContact,
              name: 'companyContact',
              error: touched.companyContact && currentErrors?.companyContact,
              onChange: (value) => {
                onChangeContactFrom(value, 'companyContact');
              },
              onBlur: () => {
                setTouched((state) => ({ ...state, companyContact: true }));
              },
              placeholder: translate(messages.enterContactName),
            },
          }}
        />
        <ControlComponent
          type={ControlType.Input}
          debounceTime={250}
          data={{
            label: `${translate(messages.contactEmail)}*`,
            payload: {
              value: customerContactData.email,
              name: 'email',
              error: touched.email && currentErrors?.email,
              onChange: (value) => {
                onChangeContactFrom(value, 'email');
              },
              onBlur: () => {
                setTouched((state) => ({ ...state, email: true }));
              },
              type: 'email',
              placeholder: translate(messages.enterContactEmail),
            },
          }}
        />
        <ControlComponent
          type={ControlType.Input}
          debounceTime={250}
          data={{
            label: translate(messages.contactPhone),
            payload: {
              value: customerContactData.phone,
              name: 'phone',
              onChange: (value) => {
                onChangeContactFrom(value, 'phone');
              },
              placeholder: translate(messages.enterContactPhoneNumber),
            },
          }}
        />
        <ControlComponent
          type={ControlType.MultiSelect}
          isSimple
          data={{
            label: translate(messages.groups),
            payload: {
              value: customerContactData['groups'] || undefined,
              options: groups.map(({ id, label }) => ({ label, value: id })),
              name: 'groups',
              onChange: (value) => {
                onChangeContactFrom(value, 'groups');
              },
              placeholder: translate(messages.selectGroups),
            },
          }}
        />
        <NewVendorFooter
          nextAfterComponent={!currentCustomer && <ArrowIconRight width={13} height={14} />}
          nextAction={onSubmit}
          prevAction={prevAction}
          nextLabel={
            currentCustomer ? translate(messages.saveAndClose) : translate(generalMessages.next)
          }
          prevLabel={translate(generalMessages.cancel)}
          dataTestNext="customer-contact-information-tab-button-footer-next"
          dataTestPrev="customer-contact-information-tab-button-footer-back"
          isNextLoading={isLoading}
        />
      </Form>
    </>
  );
};

ContactInformationTab.propTypes = {
  handleTabChange: PropTypes.func,
  prevAction: PropTypes.func,
};

export default ContactInformationTab;
