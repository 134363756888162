import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import SecondaryButton from 'ui/SecondaryButton';
import { translate } from 'utils/index';
import { messages } from './messages';

import './chatButton.scss';

const ChatButton = ({ toggleChat, threadId, 'data-test': dataTest }) => {
  const untreadThreadsCount = useSelector((state) => state.chat.untreadThreadsCount);

  const pusherAppKey = process.env.REACT_APP_PUSHER_APP_KEY;
  const missingAppKeyMessage = !pusherAppKey ? translate(messages.missingPusherAppKey) : '';

  const threadUnreadCount = untreadThreadsCount[threadId];
  return threadUnreadCount > 0 ? (
    <SecondaryButton
      data-test={dataTest}
      key="button-with-messages"
      className={classNames(
        'assessment-chat-button',
        'assessment-chat-button--with-unread-messages',
        {
          'assessment-chat-button--many-unread-messages': threadUnreadCount > 9,
          'assessment-chat-button--very-many-unread-messages': threadUnreadCount > 99,
        },
      )}
      counter={threadUnreadCount > 99 ? '+99' : threadUnreadCount}
      title={missingAppKeyMessage}
      disabled={!pusherAppKey}
      onClick={toggleChat}
      icon="messages"
      tooltip={translate(messages.chat)}
      link
    />
  ) : (
    <SecondaryButton
      data-test={dataTest}
      key="button"
      title={missingAppKeyMessage}
      disabled={!pusherAppKey}
      className="assessment-chat-button"
      onClick={toggleChat}
      icon="message"
      tooltip={translate(messages.chat)}
      link
    />
  );
};

ChatButton.propTypes = {
  toggleChat: PropTypes.func,
  threadId: PropTypes.string,
  'data-test': PropTypes.string,
};

export default ChatButton;
