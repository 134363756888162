import axios from 'axios';
import {
  CloneAssessmentTemplate,
  GetProfileAssessmentsTemplates,
  GetProfileCustomersAssessmentsTemplates,
  RemoveProfileAssessmentsTemplate,
} from 'api/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAssessmentsTemplatesRequest = async (args, { getState }) => {
  const { app } = getState();
  const url = app.isMyVendorsTabSelected
    ? GetProfileAssessmentsTemplates.url
    : GetProfileCustomersAssessmentsTemplates.url;
  return axios.post(url, {}, { withCredentials: true });
};

export const getAssessmentsTemplates = createAsyncThunk(
  'getAssessmentsTemplates',
  getAssessmentsTemplatesRequest,
);

const cloneTemplateRequest = async (payload) => {
  return axios.post(CloneAssessmentTemplate.url, payload, { withCredentials: true });
};

export const cloneTemplate = createAsyncThunk('cloneTemplate', cloneTemplateRequest);

const removeTemplateRequest = async (payload) => {
  return axios.post(RemoveProfileAssessmentsTemplate.url, payload, { withCredentials: true });
};

export const removeTemplate = createAsyncThunk('removeTemplate', removeTemplateRequest);

export const getAssessmentsShowcaseTemplates = async () => {
  return axios.post(GetProfileAssessmentsTemplates.url, {}, { withCredentials: true });
};

export const getAssessmentsShowcase = createAsyncThunk(
  'getAssessmentsShowcase',
  getAssessmentsShowcaseTemplates,
);
