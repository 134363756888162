import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import Button from 'ui/Button';
import Loader from 'ui/Loader';
import SecondaryButton from 'ui/SecondaryButton';
import { ControlType } from 'constants/controlType';
import { calculateControlKey } from 'pages/Assessment/lib';
import { translate } from 'utils/index';
import { saveAnswerType } from 'api/editor';
import { getDefaultAnswer, isEditableAnswerType } from 'utils/controls';

import { selectAllAnswerTypes, selectSubject, selectSubjectKey } from 'pages/Editor/selectors';
import { messages } from 'pages/Editor/messages';
import AnswerTypeSelector from './answerTypeSelector';
import Answer from './answer';
import './index.scss';

const AnswerCreator = ({ className, onAddQuestion, onCancel, title }) => {
  const subjectKey = useSelector(selectSubjectKey);
  const subject = useSelector(selectSubject);
  const answerTypes = useSelector(selectAllAnswerTypes);
  const dispatch = useDispatch();
  const [selectedType, selectType] = useState();
  const [values, setValues] = useState({ answer: '', answer_type: undefined });
  const [answerType, setAnswerType] = useState({
    options: [{ key: 1, weight: 0, value: '' }],
    rtl: false,
    type: selectedType,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAnswerType((state) => ({ ...state, type: selectedType }));
    setValues((state) => ({ ...state, answer: getDefaultAnswer(selectedType) }));

    const isStaticType = [ControlType.Input, ControlType.TextArea, ControlType.FileUpload].includes(
      selectedType,
    );
    // eslint-disable-next-line no-shadow
    const answerType = answerTypes.find(({ type }) => type === selectedType);

    if (isStaticType) {
      setValues((state) => ({ ...state, answer_type: answerType?.id || undefined }));
    }
  }, [selectedType]);

  const onAddAnswerType = async () => {
    const payload = {
      ...answerType,
      id: uuidv4(),
    };

    const result = await dispatch(saveAnswerType(payload)).unwrap();
    return result.answer_type.id;
  };

  const onSave = async () => {
    setLoading(true);
    if (isEditableAnswerType(selectedType)) {
      const id = await onAddAnswerType();
      await onAddQuestion({ ...values, answer_type: id });
    } else {
      await onAddQuestion(values);
    }

    setLoading(false);
  };

  return (
    <div className={classNames('editor-answer-creator', className)}>
      <div className="editor-answer-creator__header">
        <Icon icon="add3" />
        {translate(messages.answerCreatorCreateNewAnswer)}
      </div>
      <div className="editor-answer-creator__main">
        <div className="editor-answer-creator__key">
          {calculateControlKey(subjectKey, subject.controls.length)}
        </div>
        <div className="editor-answer-creator__wrapper">
          <div className="editor-answer-creator__title">
            <pre>{title}</pre>
          </div>
          <div className="editor-answer-creator__answer">
            <AnswerTypeSelector onChange={selectType} value={selectedType} />
            <Answer
              answerType={answerType}
              onValueChange={(value) => {
                setValues((state) => ({ ...state, answer: value }));
              }}
              setAnswerType={setAnswerType}
              type={selectedType}
              value={values.answer}
            />
          </div>
        </div>
      </div>
      <div className="editor-answer-creator__action-bar">
        <Button
          data-test="editor-answer-creator-button-save"
          className="editor-answer-creator__footer-cancel-button"
          size="sm"
          color="gray"
          onClick={onCancel}
          link
        >
          {translate(messages.cancel)}
        </Button>
        <Tooltip title={translate(messages.emptyAnswerTypeWarning)}>
          <div>
            <SecondaryButton
              className="editor-answer-creator__footer-ok-button"
              onClick={onSave}
              size="medium"
              type="submit"
              disabled={selectedType === undefined}
              outline
            >
              {translate(messages.done)}
            </SecondaryButton>
          </div>
        </Tooltip>
      </div>
      {loading && <Loader />}
    </div>
  );
};

AnswerCreator.propTypes = {
  className: PropTypes.string,
  onAddQuestion: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
};

export default AnswerCreator;
