import axios from 'axios';
import {
  FreemiumDetails,
  RRFreemiumProgress,
  PurchaseRRTokens,
  RRFull,
  RRPart,
} from 'api/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';

const startRRFullScanRequest = async ({ vendorId, companyDomain }) => {
  const payload = {
    relation_id: vendorId,
    companyDomain,
  };
  return axios.post(RRFull.url, payload, { withCredentials: true });
};

export const startRRFullScan = createAsyncThunk('startRRFullScan', startRRFullScanRequest);

const startRRPartScanRequest = async ({ vendorId }) => {
  const payload = {
    relation_id: vendorId,
  };
  return axios.post(RRPart.url, payload, { withCredentials: true });
};

export const startRRPartScan = createAsyncThunk('startRRPartScan', startRRPartScanRequest);

const getRRFreemiumDetailsRequest = async ({ vendorId, appId }) => {
  const payload = {
    relation_id: vendorId,
    service_id: appId,
  };
  return axios.post(FreemiumDetails.url, payload, { withCredentials: true });
};

export const getFreemiumDetails = createAsyncThunk(
  'getFreemiumDetails',
  getRRFreemiumDetailsRequest,
);

const purchaseRRTokensRequest = async (payload) => {
  return axios.post(PurchaseRRTokens.url, payload, { withCredentials: true });
};

export const purchaseRRTokens = createAsyncThunk('purchaseRRTokens', purchaseRRTokensRequest);

const RRFreemiumProgressRequest = async ({ vendorId }) => {
  const payload = {
    relation_id: vendorId,
  };
  return axios.post(RRFreemiumProgress.url, payload, { withCredentials: true });
};

export const getRRFreemiumProgress = createAsyncThunk(
  'getRRFreemiumProgress',
  RRFreemiumProgressRequest,
);
