import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import OrganizationLogo from 'ui/OrganizationLogo';
import Icon from 'ui/Icon';
import ROUTES, { ROUTES_TAB } from 'constants/routes';
import { MixpanelLogoutTypes } from 'constants/mixpanel';
import { logout } from 'utils/logout';
import { translate } from 'utils/index';
import { mixpanelTrackLogout, setMixpanelSuperProperties } from 'utils/mixpanel';
import { useAppNavigate } from 'hooks/navigation';
import { getProfileOrganizations } from 'api/organizations';
import { selectProfile } from 'pages/Profile/selectors';
import { selectIsMyVendorsTabSelected } from 'pages/App/selectors';
import { selectOrganizationsExtended } from 'pages/Organization/selectors';

import { messages } from './messages';
import { getCookie, setCookie } from 'utils/apiHelpers';
import classNames from 'classnames';
import { ORG_ID_AUTH_KEY } from 'utils/auth';
import { clearStore } from 'pages/App/reducers';
import { Roles } from 'constants/roles';

import './profileMenu.scss';

const ProfileMenu = () => {
  const {
    user: {
      current_organization: { id: currentOrgId },
      fullname = '',
      email = '',
    },
  } = useSelector(selectProfile);
  const { organizations } = useSelector(selectOrganizationsExtended) || [];
  const isMyVendorsTabSelected = useSelector(selectIsMyVendorsTabSelected);
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const { user } = useSelector(selectProfile);
  const [visible, setVisible] = useState();
  const { organization: organizationIndex } = useParams();

  useEffect(() => {
    if (organizations.length === 0) {
      dispatch(getProfileOrganizations());
    }
  }, []);

  const onLogout = () => {
    mixpanelTrackLogout(false, MixpanelLogoutTypes.UserAction);
    logout();
  };

  const handleVisibleChange = (value) => {
    setVisible(value);
  };

  const organizationItems = useMemo(() => {
    const organizationsToRender = [...organizations] || [];
    const currentOrgId = getCookie(ORG_ID_AUTH_KEY);

    return organizationsToRender.map((organization) => {
      const isCurrentOrgActive = organization.id === currentOrgId;

      const className = classNames('base-layout-organizations-submenu__menu-item', {
        'base-layout-organizations-submenu__menu-item--selected': isCurrentOrgActive,
      });
      const { corporateLogo } =
        organizationsToRender.find(({ id }) => id === organization.id) || {};
      const organizationExtendedIndex = organizationsToRender.findIndex(({ id }) => {
        return id === organization.id;
      });

      const switchOrganization = () => {
        if (isCurrentOrgActive) {
          return null;
        }

        dispatch(clearStore());
        setCookie(ORG_ID_AUTH_KEY, organization.id);

        appNavigate(isMyVendorsTabSelected ? ROUTES.VENDOR_RECENT : ROUTES.CUSTOMER_RECENT, {
          organization: organizationExtendedIndex || 0,
        });
        setMixpanelSuperProperties(user, organization.label);
        return null;
      };

      const onEditOrganization = (event) => {
        event.stopPropagation();

        appNavigate(`${ROUTES.ORGANIZATION_EDIT}/*`, {
          baseOrganization: currentOrgId,
          type: isMyVendorsTabSelected ? ROUTES_TAB.VENDOR : ROUTES_TAB.CUSTOMER,
          organizationId: organization.id,
          '*': `?orgIndex=${organizationExtendedIndex}`,
        });

        setVisible(false);
      };

      return {
        key: organization.id,
        className,
        onClick: switchOrganization,
        icon: (
          <OrganizationLogo defaultLogo={!corporateLogo} circle path={corporateLogo} size="xs" />
        ),
        'data-test': `profile-menu-item-organization-${organization.label}`,
        label: (
          <div className="base-layout-organizations-submenu__menu-label-wrapper">
            <span className="base-layout-organizations-submenu__menu-label">
              {organization.label}
            </span>
            {[Roles.Admin, Roles.Owner].includes(organization.role) && (
              <Button
                className="base-layout-organizations-submenu__menu-edit-button"
                type="link"
                onClick={onEditOrganization}
              >
                <Icon icon="edit" />
              </Button>
            )}
          </div>
        ),
      };
    });
  }, [organizationIndex, organizations]);

  const renderMenuItems = useCallback(
    (isMyVendorsTab, currentOrg) => {
      const items = [
        {
          label: (
            <div className="base-layout-profile-menu__user">
              <div className="base-layout-profile-menu__user-badge">{fullname[0] || email[0]}</div>
              <div className="base-layout-profile-menu__user-info">
                <div className="base-layout-profile-menu__user-name">{fullname}</div>
                <div className="base-layout-profile-menu__user-email">{email}</div>
              </div>
            </div>
          ),
          type: 'group',
          key: 'profile-menu-user-info',
          'data-test': 'profile-menu-user-info',
        },
        {
          type: 'divider',
        },
        {
          type: 'group',
          className: 'base-layout-organizations-submenu__menu',
          label: `${translate(messages.organizations)}:`,
          children: organizationItems,
        },
        {
          type: 'divider',
        },
        {
          'data-test': 'profile-menu-item-profile-settings',
          key: ROUTES.PROFILE_SETTINGS,
          className: 'base-layout-profile-menu__menu-item',
          onClick: () => {
            appNavigate(ROUTES.PROFILE_SETTINGS, {
              type: isMyVendorsTab ? ROUTES_TAB.VENDOR : ROUTES_TAB.CUSTOMER,
            });
            handleVisibleChange(false);
          },
          label: translate(messages.profileSettings),
        },
        {
          'data-test': 'profile-menu-item-organizations-settings',
          key: ROUTES.ORGANIZATIONS_SETTINGS,
          className: 'base-layout-profile-menu__menu-item',
          onClick: () => {
            appNavigate(ROUTES.ORGANIZATIONS_SETTINGS, {
              type: isMyVendorsTab ? ROUTES_TAB.VENDOR : ROUTES_TAB.CUSTOMER,
            });
            handleVisibleChange(false);
          },
          label: translate(messages.organizationsSettings),
        },
        isMyVendorsTab && {
          'data-test': 'profile-menu-item-business-owner-page',
          key: ROUTES.BUSINESS_OWNER_PAGE,
          className: 'base-layout-profile-menu__menu-item',
          onClick: () => {
            appNavigate(ROUTES.BUSINESS_OWNER_PAGE, { organizationId: currentOrg }, null, true);
            handleVisibleChange(false);
          },
          label: translate(messages.businessOwnerPage),
        },
        !isMyVendorsTab && {
          'data-test': 'profile-menu-item-sales-owner-page',
          key: ROUTES.SALES_OWNER_PAGE,
          className: 'base-layout-profile-menu__menu-item',
          onClick: () => {
            appNavigate(ROUTES.SALES_OWNER_PAGE, { organizationId: currentOrg }, null, true);
            handleVisibleChange(false);
          },
          label: translate(messages.salesOwnerPage),
        },
        {
          label: (
            <a target="_blank" href="https://findings.co/privacy_policy" rel="noreferrer">
              {translate(messages.privacyPolicy)}
            </a>
          ),
          'data-test': 'profile-menu-item-privacy-policy',
          key: messages.privacyPolicy.key,
          className: 'base-layout-profile-menu__menu-item',
        },
        {
          'data-test': 'profile-menu-item-terms-of-use',
          key: messages.termOfUse.key,
          className: 'base-layout-profile-menu__menu-item',
          label: (
            <a target="_blank" href="https://findings.co/eula" rel="noreferrer">
              {translate(messages.termOfUse)}
            </a>
          ),
        },
        {
          type: 'divider',
        },
        {
          'data-test': 'profile-menu-item-logout',
          key: messages.logout.key,
          className: 'base-layout-profile-menu__menu-item',
          onClick: onLogout,
          icon: <Icon icon="logout" className="base-layout-profile-menu__menu-item-ico" />,
          label: translate(messages.logout),
        },
      ];

      return items;
    },
    [organizationItems],
  );

  const dropdown = useMemo(
    () => (
      <Dropdown
        menu={{
          items: renderMenuItems(isMyVendorsTabSelected, currentOrgId),
          className: 'base-layout-profile-menu__menu',
        }}
        trigger="click"
        onOpenChange={handleVisibleChange}
        open={visible}
      >
        <Button
          data-test="profile-menu-button-open-profile-menu"
          type="link"
          className="base-layout-profile-menu__button"
        >
          <OrganizationLogo
            defaultLogo={!user.corporateLogo}
            circle
            path={user.corporateLogo}
            size="sm"
          />
        </Button>
      </Dropdown>
    ),
    [isMyVendorsTabSelected, visible, currentOrgId],
  );

  return <div className="base-layout-profile-menu">{dropdown}</div>;
};

export default ProfileMenu;
