import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Tooltip } from 'antd';
import { useSelector } from 'react-redux';

import { translate } from 'utils/index';
import { messages } from 'pages/Assessments/messages';
import Icon from 'ui/Icon';
import { AssessmentStatus } from 'constants/assessmentStatus';
import { selectIsFilteredArchived } from 'components/AssessmentsFilters/selectors';
import { useRole } from 'hooks/useRole';
import './actions.scss';

const Actions = ({
  assessmentId,
  assessmentStatus,
  className,
  onArchive,
  onExport,
  onGenerateZipReport,
  onRestore,
}) => {
  const isFilteredArchived = useSelector(selectIsFilteredArchived);
  const { isRestrictedBoSoViewerRole } = useRole();

  return (
    <div className={classNames('*assessments-page-list-actions', className)}>
      <Tooltip title={translate(messages.downloadReport)}>
        <Button
          className="assessments-page-list-actions__action"
          type="link"
          disabled={assessmentStatus < AssessmentStatus.reviewed}
          onClick={(event) => {
            event.stopPropagation();
            onGenerateZipReport(assessmentId);
          }}
        >
          <Icon icon="download" />
        </Button>
      </Tooltip>
      <Tooltip title={translate(messages.exportCsv)}>
        <Button
          className="assessments-page-list-actions__action"
          type="link"
          onClick={(event) => {
            event.stopPropagation();
            onExport(assessmentId);
          }}
        >
          <Icon icon="xlsx" />
        </Button>
      </Tooltip>
      {isFilteredArchived ? (
        <Tooltip title={translate(messages.restoreFromArchive)}>
          <Button
            className="assessments-page-list-actions__action"
            disabled={isRestrictedBoSoViewerRole}
            type="link"
            onClick={(event) => {
              event.stopPropagation();
              onRestore(assessmentId);
            }}
          >
            <Icon icon="restore" />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title={translate(messages.sendToArchive)}>
          <Button
            className="assessments-page-list-actions__action"
            disabled={isRestrictedBoSoViewerRole}
            type="link"
            onClick={(event) => {
              event.stopPropagation();
              onArchive(assessmentId);
            }}
          >
            <Icon icon="archive" />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

Actions.propTypes = {
  assessmentId: PropTypes.string,
  assessmentStatus: PropTypes.number,
  className: PropTypes.string,
  onArchive: PropTypes.func,
  onExport: PropTypes.func,
  onGenerateZipReport: PropTypes.func,
  onRestore: PropTypes.func,
};

export default Actions;
