import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';

import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';
import Icon from 'ui/Icon';

import './actionIcons.scss';

const ActionIcons = ({ editDisabled, editOnclick, removeDisabled, removeOnClick }) => {
  return (
    <div className="organizations-table__actions">
      {!editDisabled && (
        <Tooltip title={translate(generalMessages.edit)}>
          <Button className="organizations-table__action-icons" type="link" onClick={editOnclick}>
            <Icon icon="edit" title="edit" />
          </Button>
        </Tooltip>
      )}

      {!removeDisabled && (
        <Tooltip title={translate(generalMessages.remove)}>
          <Button className="organizations-table__action-icons" type="link" onClick={removeOnClick}>
            <Icon icon="remove" title="remove" />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

ActionIcons.propTypes = {
  editDisabled: PropTypes.bool,
  editOnclick: PropTypes.func,
  removeDisabled: PropTypes.bool,
  removeOnClick: PropTypes.func,
};

ActionIcons.defaultProps = {
  editDisabled: false,
  removeDisabled: false,
};

export default ActionIcons;
