import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import SecondaryButton from 'ui/SecondaryButton';
import AwsIcon from 'assets/svgs/aws-icon-2.svg';
import { ReactComponent as AzureIcon } from 'assets/svgs/azure-icon-white.svg';
import { translate } from 'utils/index';
import {
  selectCloudMonitoringResultCategory,
  selectIsCloudMonitoringResults,
  selectIsCloudMonitoringTriggered,
} from 'pages/Assessment/selectors';
import { selectCategory } from 'pages/Assessment/reducers';
import Icon from 'ui/Icon';
import Button from 'ui/Button';

import { messages } from './messages';
import './activateCloudMonitoring.scss';

const ActivateCloudMonitoring = ({
  activate,
  activationLoading,
  cancel,
  cancelLoading,
  isAzure,
}) => {
  const dispatch = useDispatch();
  const isCloudMonitoringTriggered = useSelector(selectIsCloudMonitoringTriggered);
  const isCloudMonitoringResults = useSelector(selectIsCloudMonitoringResults);
  const resultCategory = useSelector(selectCloudMonitoringResultCategory);

  const goToResultsCategory = () => {
    dispatch(selectCategory(resultCategory.id));
  };

  const icon = isAzure ? (
    <AzureIcon className="assessment-controls-activate-cloud-monitoring__button-icon" />
  ) : (
    <img
      src={AwsIcon}
      className="assessment-controls-activate-cloud-monitoring__button-icon"
      alt={'assessment-controls-activate-cloud-monitoring__button-icon'}
    />
  );

  if (isCloudMonitoringResults) {
    return (
      <div className="assessment-controls-activate-cloud-monitoring">
        <Icon className="assessment-controls-activate-cloud-monitoring__cloud-icon" icon="cloud" />
        <Button color="blue" onClick={goToResultsCategory} size="sm" link>
          {translate(messages.viewResults)}
        </Button>
      </div>
    );
  }

  if (isCloudMonitoringTriggered) {
    return (
      <div className="assessment-controls-activate-cloud-monitoring">
        <Icon className="assessment-controls-activate-cloud-monitoring__cloud-icon" icon="ok4" />
        <span>
          <span className="assessment-controls-activate-cloud-monitoring__title">
            {translate(messages.emailRequestSentToCloudAdmin)}
          </span>{' '}
          {translate(messages.weWillNotifyYouViaEmail)}
        </span>

        <div>
          <Button
            className="assessment-controls-activate-cloud-monitoring__cancel-request-button"
            color="black"
            onClick={cancel}
            loading={cancelLoading}
            size="sm"
            squared
            outline
          >
            {translate(messages.cancelRequest)}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="assessment-controls-activate-cloud-monitoring">
      <SecondaryButton
        className="assessment-controls-activate-cloud-monitoring__button"
        icon={icon}
        size="small"
        type="submit"
        uppercase
        onClick={activate}
        loading={activationLoading}
      >
        {translate(messages.activateApp)}
      </SecondaryButton>
      <span>{translate(messages.activateAppDescription)}</span>
    </div>
  );
};

ActivateCloudMonitoring.propTypes = {
  activate: PropTypes.func,
  activationLoading: PropTypes.bool,
  cancel: PropTypes.func,
  cancelLoading: PropTypes.bool,
  isAzure: PropTypes.bool,
};

export default ActivateCloudMonitoring;
