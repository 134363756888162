import axios from 'axios';
import { SetAssessment, UnlinkFile, SetNotes, LinkFiles } from 'api/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FileObjectType } from 'constants/FileObjectType';
import { extractFileIdsFromFiles } from 'utils/files';

const saveRecommendationRequest = async ({ control, findingId, recommendation }, { getState }) => {
  const { assessment } = getState();

  const data = {
    _assessment: {
      categories: [
        {
          id: control.categoryId,
          controls: [
            {
              id: control.id,
              findings: [
                {
                  id: findingId,
                  recommendations: [recommendation],
                },
              ],
            },
          ],
        },
      ],
    },
  };

  const payload = {
    'set-assessment-assessment-token': assessment.assessment.id,
    'set-assessment-_assessment-json': JSON.stringify(data),
  };
  return axios.post(SetAssessment.url, payload, { withCredentials: true });
};

export const saveRecommendation = createAsyncThunk('saveRecommendation', saveRecommendationRequest);

const saveNoteRequest = async ({ note, assessmentId }, { getState }) => {
  const { assessment } = getState();

  const payload = {
    notes: JSON.stringify([note]),
    customerAssessmentID: assessmentId || assessment.assessment.id,
  };
  return axios.post(SetNotes.url, payload, { withCredentials: true });
};

export const saveNote = createAsyncThunk('saveNote', saveNoteRequest);

const uploadEvidencesRequest = async ({ recommendationId, files, abortController }) => {
  const payload = {
    'object-id': recommendationId,
    'object-type': FileObjectType.recommendation,
    'file-ids': extractFileIdsFromFiles(files),
  };

  return axios.post(LinkFiles.url, payload, { signal: abortController?.signal, withCredentials: true });
};

export const uploadEvidences = createAsyncThunk('uploadEvidences', uploadEvidencesRequest);

const removeEvidenceRequest = async ({ fileId, objectId }, { getState }) => {
  const { assessment } = getState();

  const payload = {
    'remove-assessment-file-_assessment-token': assessment.assessment.id,
    'remove-assessment-file-file-id': fileId,
    'assessment-type': 0,
    objectId,
  };

  return axios.post(UnlinkFile.url, payload, { withCredentials: true });
};

export const removeEvidence = createAsyncThunk('removeEvidence', removeEvidenceRequest);
