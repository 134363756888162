import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import Popup from 'ui/Popup';
import UploadFileStage from 'pages/Assessments/importWizardMenuModal/uploadFileStage';

// CONSTANTS
import { STAGE } from 'pages/Assessments/importWizardMenuModal/lib';
import { fillDocumentAssessment, getExcelSuggestedQuestions } from 'api/importWizard';
import { ASSESSMENT_FILE_UPLOAD } from 'constants/assessmentFileUpload';

// REDUX
import { resetParams } from 'components/ImportWizard/excel/reducers';
import { selectExcelImportWizard } from 'components/ImportWizard/selectors';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { selectProfile } from 'pages/Profile/selectors';

// UTILS
import { checkIfFindingsUser } from 'utils/isFindingsUser';

// STYLE
import './index.scss';

const ALLOWED_ORG = ['6530615d-61f5-493c-896b-90f7b929ae7f'];

const importWizard = (type, currentOrganizationId, isFindingUser) =>
  lazy(() => {
    if ((currentOrganizationId && ALLOWED_ORG.includes(currentOrganizationId)) || isFindingUser) {
      const path = type === ASSESSMENT_FILE_UPLOAD.CSV.type ? 'excel' : type.toLowerCase();
      return import(`components/ImportWizard/${path}/index`);
    } else return import('components/ImportWizard/word/index');
  });

const ImportWizardMenuModal = ({ popupState, onClose }) => {
  const dispatch = useDispatch();
  const { selectedType } = useSelector(selectExcelImportWizard);
  const { user } = useSelector(selectProfile);
  const isVendorSelected = useIsMyVendorsTabSelected();

  const [wizardModalData, setWizardModalData] = useState({
    stage: STAGE.UPLOAD_FILE,
    uploadedFileObject: undefined,
    wizard: undefined,
  });

  const setWizard = (wizard) => {
    setWizardModalData((state) => ({ ...state, wizard }));
  };

  const setStage = (stageValue) => {
    setWizardModalData((state) => ({ ...state, stage: stageValue }));
  };

  const setUploadedFile = (file) => {
    setWizardModalData((state) => ({ ...state, uploadedFileObject: file }));
  };

  const handleClose = () => {
    dispatch(resetParams());
    setUploadedFile(undefined);
    setStage(STAGE.UPLOAD_FILE);
    onClose();
  };

  useEffect(() => {
    const getWizard = async () => {
      const isFindingUser =
        checkIfFindingsUser(user?.email) ||
        user?.email?.split('@')?.[1] === process.env.TESTING_DOMAIN;
      const Wizard = await importWizard(
        selectedType,
        user?.current_organization?.id,
        isFindingUser,
      );
      return (
        <Wizard handleClose={handleClose} rawFile={wizardModalData.uploadedFileObject || {}} />
      );
    };
    if (wizardModalData.stage === STAGE.WIZARD) {
      if (selectedType !== ASSESSMENT_FILE_UPLOAD.WORD.type)
        dispatch(getExcelSuggestedQuestions({ file: wizardModalData.uploadedFileObject }));
      if (selectedType === ASSESSMENT_FILE_UPLOAD.WORD.type) {
        fillDocumentAssessment({
          file: wizardModalData.uploadedFileObject,
          upstream: !isVendorSelected,
        }).then();
      }
      getWizard().then((wizardComponent) => setWizard(wizardComponent));
    }
  }, [wizardModalData.stage]);

  return (
    <div>
      <Popup
        className="import-wizard-menu-modal"
        open={popupState}
        onCancel={handleClose}
        footer={<div />}
        width={776}
      >
        {wizardModalData.stage === STAGE.UPLOAD_FILE && (
          <UploadFileStage
            setStage={setStage}
            closeMenuModal={onClose}
            uploadedFile={wizardModalData.uploadedFileObject}
            setUploadedFile={setUploadedFile}
          />
        )}
      </Popup>
      <Suspense fallback={<div>...</div>}>{wizardModalData.wizard}</Suspense>
    </div>
  );
};

ImportWizardMenuModal.propTypes = {
  popupState: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ImportWizardMenuModal;
