import { Status } from 'constants/status';
import { AssessmentStatus } from 'constants/assessmentStatus';
import { ControlType } from 'constants/controlType';

export function parseArrayValue(values) {
  if (values.length === 0) {
    return 0;
  }
  return values.map((value) => Number(value));
}

export function filterArrayValue(value) {
  return (Array.isArray(value) ? value : [value]).filter((key) => key);
}

export function isAssessmentPending(assessment) {
  const { total_score: totalScore, finalized, status, upstream, assessor, assesse } = assessment;
  return (
    Status.Completed === totalScore.progress &&
    finalized &&
    status === AssessmentStatus.reviewed &&
    !upstream &&
    (assessor || assesse)
  );
}

export const downloadReportDisableState = (assessments, selectedRowKeys) => {
  if (selectedRowKeys.length === 0) return true;
  for (let i = 0; i < selectedRowKeys.length; i++) {
    const assessment = assessments.find((assessment) => assessment.id === selectedRowKeys[i]);
    const status = assessment?.status
      ? assessment.status
      : assessment?.assessmentStatus
      ? assessment.assessmentStatus
      : null;

    if (status < AssessmentStatus.reviewed) {
      return true;
    }
  }
  return false;
};

export function compareDataSource(a, b) {
  const [, valueA] = a.relative_position || [0, a.position];
  const [, valueB] = b.relative_position || [0, b.position];

  let comparison = 0;

  if (valueA > valueB) {
    comparison = 1;
  } else if (valueA < valueB) {
    comparison = -1;
  }

  return comparison;
}

export const setAssessmentControlsOrder = (assessment = {}) => {
  return {
    ...assessment,
    categories: assessment?.categories?.map((category) => ({
      ...category,
      controls: [...category.controls].sort(compareDataSource),
    })),
  };
};

export const parseFileLinksFromAssessment = (assessment = {}) => {
  const fileLinks = {};
  assessment?.categories?.forEach((category) => {
    category.controls.forEach((control) => {
      if (control.control_answer_type === ControlType.FileUpload) {
        control?.answer?.forEach((file) => {
          if (!fileLinks[control.id]) {
            fileLinks[control.id] = [file?.id];
          } else {
            fileLinks[control.id].push(file.id);
          }
        });
      }
    });
  });
  return fileLinks;
};
