import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';

import Popup from 'ui/Popup';
import Form from 'ui/Form';
import Input from 'ui/Input';
import Select from 'ui/Select';
import Loader from 'ui/Loader';
import Icon from 'ui/Icon';
import CardWithLogo from 'components/CardWithLogo';
import TextArea from 'ui/TextArea';
import Button from 'ui/Button';
import { translate } from 'utils/index';
import { getCustomersAssessmentsFilterData } from 'api/filtersData';
import { generalMessages } from 'constants/messages';
import { API_STATUS } from 'constants/api';
import { addShowcase, editShowcase, getShowcaseTemplates } from 'api/showcase';
import { mixpanelTrackShowcaseCreated } from 'utils/mixpanel';

import { messages } from '../messages';
import './index.scss';

const initialValues = {
  title: '',
  description: '',
  selectedAssessments: [],
};

const ShowCaseEditor = ({ newShowCase, showcase, isOpen, onClose, selectedRowKeys }) => {
  const [assessments, setAssessments] = useState([]);
  const dispatch = useDispatch();
  const {
    pagination: { total: totalAssessments },
  } = useSelector((state) => state.assessments);
  const [loading, setLoading] = useState();
  const [isFetchingAssessments, setIsFetchingAssessments] = useState();

  const getAssignedAssessments = useCallback(async () => {
    const { assessments: assessmentsObject } = await dispatch(
      getCustomersAssessmentsFilterData(),
    ).unwrap();

    const assessments = Object.entries(assessmentsObject).map(([value, label]) => ({
      label,
      value,
    }));
    setAssessments(assessments);
  }, [totalAssessments]);

  useEffect(async () => {
    setIsFetchingAssessments(true);
    await getAssignedAssessments().then();
    setIsFetchingAssessments(false);
  }, [totalAssessments]);

  const {
    errors,
    values,
    touched,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: yup.object({
      title: yup.string().required(translate(messages.requiredTitle)),
      description: yup.string().required(translate(messages.requiredDescription)),
      selectedAssessments: yup
        .array()
        .of(yup.string())
        .min(1, translate(messages.requiredAssessment)),
    }),
    onSubmit: async () => {
      setLoading(true);
      const { title, description, selectedAssessments } = values;
      const payload = {
        title,
        description,
        selectedAssessments,
      };

      if (showcase) {
        payload.showcaseID = showcase.id;
      }

      const response = showcase
        ? await dispatch(editShowcase(payload)).unwrap()
        : await dispatch(addShowcase(payload)).unwrap();

      await dispatch(getShowcaseTemplates());
      if (response.status === API_STATUS.SUCCESS) {
        if (!showcase) {
          mixpanelTrackShowcaseCreated({
            name: title,
            assessments: selectedAssessments,
            description,
          });
        }

        resetForm();
        onClose();
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    const selectedAssessments = assessments
      .filter((option) => {
        const isSelectedRowKey = () => selectedRowKeys?.includes(option.value);
        const isShowcaseAssessment = () =>
          showcase?.assessments?.some(([id]) => id === option.value);

        return isShowcaseAssessment() || isSelectedRowKey();
      })
      .map(({ value }) => value);

    if (showcase) {
      return setValues({
        ...showcase,
        selectedAssessments: showcase.assessments.map(([value]) => value),
      });
    }

    const defaultValues = selectedRowKeys?.length
      ? {
          ...values,
          selectedAssessments,
        }
      : initialValues;

    setValues(defaultValues);
  }, [assessments, showcase]);

  const onCancel = () => {
    resetForm();
    onClose();
  };

  const title = newShowCase ? (
    <span>
      <Icon className="showcase-editor__title-icon" icon="plus" />{' '}
      {translate(messages.createNewModalTitle)}
    </span>
  ) : (
    <span>
      <Icon className="showcase-editor__title-icon" icon="edit" />{' '}
      {translate(messages.editShowcase)}
    </span>
  );

  return (
    <Popup.WithForm
      className="showcase-editor"
      open={isOpen}
      onCancel={onCancel}
      title={title}
      width={779}
      footer={null}
    >
      <Form.Item>
        <Input
          data-test="showcase-editor-input-title"
          error={touched.title && errors.title}
          label={String(translate(messages.showCaseTitle))}
          name="title"
          onChange={handleChange}
          placeholder={translate(messages.showcaseTitlePlaceholder)}
          value={values.title}
        />
      </Form.Item>
      <Form.Item>
        <TextArea
          error={touched.description && errors.description}
          data-test="showcase-editor-input-description"
          label={String(translate(messages.showCaseDescription))}
          name="description"
          onChange={handleChange}
          placeholder={translate(messages.showcaseDescriptionPlaceholder)}
          value={values.description}
        />
      </Form.Item>
      <Form.Item className="showcase-editor__multi-select">
        <Form.Label text={translate(messages.selectAssessments)} />
        <span className="showcase-editor__multi-select-label-text">
          {translate(messages.moreThanOne)}
        </span>
        <Select
          className="showcase-editor__multi-select-selector"
          data-test="showcase-editor-select-assessments"
          mode="multiple"
          placeholder={translate(messages.showcaseAssessmentsPlaceholder)}
          options={assessments}
          color="gray"
          size="large"
          value={values.selectedAssessments}
          onChange={(value) => setFieldValue('selectedAssessments', value)}
          error={touched.selectedAssessments && errors.selectedAssessments}
        />
      </Form.Item>
      <CardWithLogo.Footer
        leftComponent={
          <Button
            onClick={onCancel}
            size="sm"
            color="white"
            name={translate(generalMessages.cancel)}
          />
        }
        dynamicColor
        rightButtonText={translate(showcase ? generalMessages.save : messages.create)}
        noRightIcon
        rightButtonOnClick={handleSubmit}
        rightButtonAdditionalProps={{ loading }}
      />
      {isFetchingAssessments && <Loader />}
    </Popup.WithForm>
  );
};

ShowCaseEditor.propTypes = {
  newShowCase: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showcase: PropTypes.object,
  selectedRowKeys: PropTypes.array,
};

export default ShowCaseEditor;
