import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import { createSorterByString } from 'utils/strings';

import { useAppNavigate } from 'hooks/navigation';
import ROUTES from 'constants/routes';
import { DATE_FORMAT } from 'constants/date';

// Components
import Table from 'ui/Table';
import Button from 'ui/Button';
import Input from 'ui/Input';

// Utils
import { getAssignedShowcases } from 'api/showcase';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';

import sentShowcaseEs from 'assets/svgs/sent-showcases-es.svg';
import '../index.scss';

const ShowcasesSentList = ({ handleCreateShowcase }) => {
  const [searchValue, setSearchValue] = useState('');
  const appNavigate = useAppNavigate();
  const dispatch = useDispatch();
  const { sentShowcases, isLoadingSent } = useSelector((state) => state.showcases);
  const { isRestrictedBoSoViewerRole } = useRole();
  const [dataSource, setDataSource] = useState();

  const emptyState = useMemo(() => {
    return (
      <div className="showcases-list__empty-state">
        <img src={sentShowcaseEs} alt="sent-showcase" width="130" />
        <div className="showcases-list__empty-state-text">{translate(messages.emptyState)}</div>
        <Button
          link
          onClick={() => handleCreateShowcase()}
          className="showcases-list__empty-state-button"
        >
          + {translate(messages.createShowcase)}
        </Button>
      </div>
    );
  }, [sentShowcases]);

  useEffect(() => {
    dispatch(getAssignedShowcases());
  }, []);

  useEffect(() => {
    setDataSource(sentShowcases);
  }, [sentShowcases]);

  const columns = [
    {
      title: translate(messages.columnSendDate),
      dataIndex: 'sendDate',
      render: (sendDate) => <div>{moment(sendDate).format(DATE_FORMAT)}</div>,
      sorter: (a, b) => new Date(a.sendDate).getTime() - new Date(b.sendDate).getTime(),
    },
    {
      title: translate(messages.columnTitle),
      dataIndex: 'title',
      sorter: createSorterByString('title'),
    },
    {
      title: translate(messages.columnDescription),
      dataIndex: 'description',
      render: (value) => <span>{value}</span>,
      sorter: createSorterByString('description'),
      ellipsis: { showTitle: false },
    },
    {
      title: translate(messages.columnSendTo),
      dataIndex: 'sendTo',
      sorter: createSorterByString('sendTo'),
      render: (value) => <span>{value}</span>,
    },
    {
      title: translate(messages.columnSharedUntil),
      dataIndex: 'sharedUntil',
      render: (value) => <div>{moment(value).format(DATE_FORMAT)}</div>,
      sorter: (a, b) => new Date(a.sharedUntil).getTime() - new Date(b.sharedUntil).getTime(),
    },
  ];

  const onRedirectToAssignAssessment = () => {
    appNavigate(ROUTES.CUSTOMER_SHOWCASES_ASSIGN_SHOWCASE);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    const lowerCaseValue = value.toLowerCase();

    setDataSource(
      sentShowcases.filter((data) => {
        const description = data.description.toLowerCase();
        const title = data.title.toLowerCase();
        const sendTo = data.sendTo.toLowerCase();

        return (
          description.includes(lowerCaseValue) ||
          sendTo.includes(lowerCaseValue) ||
          title.includes(lowerCaseValue)
        );
      }),
    );
  };

  const onRow = (data) => ({
    onClick: async () => {
      appNavigate(ROUTES.CUSTOMER_SHOWCASE_PREVIEW, {
        showcaseId: data.id,
      });
    },
  });

  return (
    <div className="showcases-list">
      <div className="showcases-list-actions">
        <Input.Search onChange={handleSearch} size="small" value={searchValue} />
        <Button
          size="sm"
          beforeIcon="share"
          color="green"
          disabled={isRestrictedBoSoViewerRole}
          data-test="showcases-sent-list-send-showcase"
          className="showcases-list__button"
          onClick={onRedirectToAssignAssessment}
        >
          {translate(messages.shareButton)}
        </Button>
      </div>

      <Table
        locale={{ emptyText: emptyState }}
        className="showcases-list__table"
        columns={columns}
        dataSource={dataSource}
        loading={isLoadingSent}
        pagination={false}
        onRow={onRow}
      />
    </div>
  );
};

ShowcasesSentList.propTypes = {
  handleCreateShowcase: PropTypes.func.isRequired,
};

export default ShowcasesSentList;
