import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigationType } from 'react-router';
import moment from 'moment/moment';
import classNames from 'classnames';

import { useAppNavigate } from 'hooks/navigation';
import { translate } from 'utils/index';
import Table from 'ui/Table';
import Button from 'ui/Button';
import ROUTES from 'constants/routes';
import { DATE_FORMAT } from 'constants/date';
import { MixpanelAssignAssessmentSource } from 'constants/mixpanel';
import PageHeader from 'components/PageHeader';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import Assessments from 'pages/Recent/vendorsColumns/assessments';
import VendorRiskPopover from 'pages/Recent/vendorsColumns/vendorRiskPopover';
import VendorRisk from 'pages/Recent/vendorsColumns/vendorRisk';
import Status from 'pages/Vendors/VendorsList/status';
import Actions from 'pages/Recent/vendorsColumns/actions';
import { removeCustomer } from 'api/customer';
import { getProfileCustomers } from 'api/customers';
import { TableSource } from 'ui/Table/lib';
import { selectIsViewAggregated } from 'pages/Organization/selectors';

import Search from './search';
import AddFilter from './addFilter';
import CustomersFilters from './filters';
import { clearFilters, setPagination, setSearch, setSorter } from '../reducers';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';

import './index.scss';

const CustomersList = () => {
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const { isViewerRole } = useRole();
  const navigationType = useNavigationType();
  const [loading, setLoading] = useState(false);
  const [customerToRemove, setCustomerToRemove] = useState();
  const isViewAggregated = useSelector(selectIsViewAggregated);

  const { customers, isCustomersFetching, pagination, sorter } = useSelector(
    (state) => state.customers,
  );

  useEffect(() => {
    if (navigationType !== 'POP') {
      dispatch(clearFilters());
      dispatch(setPagination({ current: 1 }));
      dispatch(setSearch());
      const sorter = {
        column: 'timestampCreated',
        descending: true,
      };
      dispatch(setSorter(sorter));
    }
    dispatch(getProfileCustomers()).unwrap();
  }, []);

  const onTableChange = (newPagination, filters, sorter) => {
    dispatch(setPagination(newPagination));
    const filterParams = {
      column: sorter.field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? filterParams : {};

    dispatch(setSorter(params));
    dispatch(getProfileCustomers()).unwrap();
  };

  const sortOrder = sorter.descending ? 'descend' : 'ascend';

  const columns = [
    {
      title: translate(messages.date),
      dataIndex: 'timestampCreated',
      width: 130,
      render: (timestampCreated) => (
        <div style={{ textAlign: 'center' }}>{moment(timestampCreated).format(DATE_FORMAT)}</div>
      ),
      sorter: true,
      sortOrder: sorter.column === 'timestampCreated' ? sortOrder : null,
    },
    {
      title: translate(messages.company),
      dataIndex: 'profileCorporateName',
      render: (name, customer) => {
        return (
          <div>
            {customer.onboarding && (
              <span
                className="customers-list__onboarding-not-completed"
                title={translate(messages.onboardingNotCompleted)}
              />
            )}
            <span>{name}</span>
          </div>
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'profileCorporateName' ? sortOrder : null,
    },
    {
      title: translate(messages.name),
      dataIndex: 'profileFullname',
      sorter: true,
      sortOrder: sorter.column === 'profileFullname' ? sortOrder : null,
    },
    {
      title: translate(messages.email),
      dataIndex: 'profileEmail',
      render: (value) => <span>{value}</span>,
      sorter: true,
      sortOrder: sorter.column === 'profileEmail' ? sortOrder : null,
      ellipsis: { showTitle: false },
    },
    {
      title: translate(messages.phone),
      dataIndex: 'profilePhone',
      render: (phone) =>
        !phone || phone === 'undefined' ? translate(messages.notSpecified) : phone,
      sorter: true,
      sortOrder: sorter.column === 'profilePhone' ? sortOrder : null,
      width: 140,
    },
    {
      title: translate(messages.activeAssessments),
      dataIndex: 'assessments',
      render: (assessments) => <Assessments assessments={assessments || []} />,
      sorter: false,
    },
    {
      title: translate(messages.customerRisk),
      dataIndex: 'vendorRisk',
      render: (customerRisk, customer) => (
        <div>
          <VendorRiskPopover vendor={customer}>
            <div>
              <VendorRisk value={customerRisk} />
            </div>
          </VendorRiskPopover>
        </div>
      ),
      sorter: true,
      sortOrder: sorter.column === 'vendorRisk' ? sortOrder : null,
      width: 140,
    },
    {
      title: translate(messages.status),
      dataIndex: 'profileStatus',
      render: (_, customer) => <Status vendor={customer} />,
      sorter: false,
      width: 140,
    },
    {
      dataIndex: 'actions',
      render: (_, customer) => (
        <Actions
          isCustomersList
          source={MixpanelAssignAssessmentSource.Customers}
          vendor={customer}
          setVendorToRemove={setCustomerToRemove}
        />
      ),
      width: 150,
    },
  ];

  if (isViewAggregated) {
    const column = {
      title: translate(messages.organization),
      dataIndex: 'parentOrganizationCorporateName',
      sorter: true,
      sortOrder: sorter.column === 'parentOrganizationCorporateName' ? sortOrder : null,
    };
    columns.splice(1, 0, column);
  }

  const onRow = (customer) => ({
    onClick: (event) => {
      event.stopPropagation();
      const classes = event.target.getAttribute('class') || '';
      if (classes.includes('icon') || classes.includes('ant-popover') || isViewerRole) {
        return;
      }
      appNavigate(ROUTES.CUSTOMER_EDIT_CUSTOMER, { customerId: customer.id });
    },
  });

  const dataSource = (customers || []).map((customer) => ({
    ...customer,
    key: customer.id,
  }));

  const onRemove = async (event) => {
    event.stopPropagation();
    setLoading(true);
    await dispatch(removeCustomer({ id: customerToRemove.id })).unwrap();
    await dispatch(getProfileCustomers()).unwrap();
    setCustomerToRemove(null);
    setLoading(false);
  };

  return (
    <div className="customers-list">
      <PageHeader>{translate(messages.customerHeader)}</PageHeader>
      <div className="customers-list__wrapper">
        <div className="customers-list__actions">
          <Search />
          <AddFilter />
          <div className="customers-list__actions-right">
            <Button
              data-test="customer-list-button-add-customer"
              className="customers-list__button"
              disabled={isViewerRole}
              beforeIcon="plus"
              color="green"
              size="sm"
              onClick={() => appNavigate(ROUTES.CUSTOMER_NEW_CUSTOMER)}
            >
              {translate(messages.customer)}
            </Button>
          </div>
        </div>
        <CustomersFilters />
        <Table
          className={classNames('customers-list__table', {
            'customers-list__table--disable-row-click': isViewerRole,
          })}
          columns={columns}
          dataSource={dataSource}
          loading={isCustomersFetching}
          pagination={pagination}
          onChange={onTableChange}
          onRow={onRow}
          emptyStateSource={TableSource.customersList}
          scroll={{ x: 1160 }}
        />
      </div>
      <RemoveConfirmationModal
        confirmLoading={loading}
        onOk={onRemove}
        onCancel={() => setCustomerToRemove(null)}
        text={translate(messages.removeCustomerText)}
        open={!!customerToRemove}
      />
    </div>
  );
};

export default CustomersList;
