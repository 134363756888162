import { v4 as uuidv4 } from 'uuid';
import { ControlType } from 'constants/controlType';

export function calculateControlKey(categoryKey, index) {
  const key = index < 9 ? `0${index + 1}` : `${index + 1}`;
  return `${categoryKey}.${key}`;
}

export function getAnswerTypeType(answerTypeIdx, answerTypes = []) {
  const answerType = answerTypes[answerTypeIdx];
  return answerType ? answerType.type : undefined;
}

export function triggerControls(control, controls) {
  const triggers = control?.triggers ? Object.entries(control?.triggers) : [];
  if (triggers?.length === 0) {
    return [];
  }
  const updatedControls = controls.reduce((acc, controlToUpdate) => {
    const isInTriggers = triggers.some(([, ids]) => ids.includes(controlToUpdate.id));
    const isTriggered = triggers.some(
      ([key, ids]) =>
        control.enabled &&
        isAnswerTriggered(key, control.answer) &&
        ids.includes(controlToUpdate.id),
    );

    if (isInTriggers && isTriggered !== controlToUpdate.enabled) {
      const newControl = {
        ...controlToUpdate,
        enabled: !controlToUpdate.enabled,
      };
      if (!controlToUpdate.enabled === false) {
        newControl.answer = '';
      }
      acc.push(newControl);
    }

    return acc;
  }, []);

  const nextUpdatedControls = updatedControls.flatMap((updatedControl) => {
    return triggerControls(updatedControl, controls);
  });

  return updatedControls.concat(nextUpdatedControls);
}

const isAnswerTriggered = (key, answer) => {
  return (
    key.toString() === answer.toString() || (Array.isArray(answer) && answer.includes(Number(key)))
  );
};

export function getCategoryControlsWithTriggered(controls, triggeredControls) {
  return controls.map((control) => {
    return (
      triggeredControls.find((triggeredControl) => triggeredControl.id === control.id) || control
    );
  });
}

export const getDefaultRecommendation = () => {
  return {
    description: '',
    comment: '',
    due: '',
    force_evidence: false,
    id: uuidv4(),
  };
};

export const countAssessmentFindings = (categories = []) => {
  return categories.reduce((acc, category) => acc + countCategoryFindings(category), 0);
};

export const countCategoryFindings = (category = {}) => {
  const enabledControls = (category.controls || []).filter((control) => control.enabled);

  return enabledControls.reduce(
    (acc, { findings, answer }) => acc + countFindings(findings, answer),
    0,
  );
};

export const countFindings = (findings, answer) => {
  return (findings || []).filter(
    (finding) => finding.enabled || isFindingTriggeredByTrigger(finding.triggers, answer),
  ).length;
};

export const isFindingTriggeredByTrigger = (triggers, answer) => {
  return (triggers || []).some((trigger) =>
    Array.isArray(answer)
      ? answer.includes(trigger.toString()) || answer.includes(trigger)
      : answer === trigger,
  );
};

export const hasSuggestionNearControl = (type) => {
  return [
    ControlType.Input,
    ControlType.TextArea,
    ControlType.DropDown,
    ControlType.MultiSelect,
  ].includes(type);
};

export const hasSuggestionNearTitle = (type) => {
  return [ControlType.Checkbox, ControlType.RadioGroup].includes(type);
};

function traverseAndFlatten(currentNode, target, flattenedKey, isValueArray) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in currentNode) {
    // eslint-disable-next-line no-prototype-builtins
    if (currentNode.hasOwnProperty(key)) {
      let newKey;
      if (flattenedKey === undefined) {
        newKey = key;
      } else if (isValueArray) {
        newKey = `${flattenedKey}[${key}]`;
      } else {
        newKey = `${flattenedKey}.${key}`;
      }

      const value = currentNode[key];
      if (Array.isArray(value)) {
        traverseAndFlatten(value, target, newKey, true);
      } else if (typeof value === 'object') {
        traverseAndFlatten(value, target, newKey);
      } else {
        target[newKey] = value;
      }
    }
  }
}
export const flatErrors = (errors) => {
  const flattenedObject = {};
  traverseAndFlatten(errors, flattenedObject);
  return flattenedObject;
};
