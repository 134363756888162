import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { translate } from 'utils/index';
import EvidenceStatus from 'constants/evidenceStatus';
import { messages } from 'pages/Recent/messages';
import './status.scss';

const Status = ({ status }) => {
  const getTextByStatusValue = () => {
    switch (status) {
      case EvidenceStatus.Pending:
        return messages.pending;
      case EvidenceStatus.Unapproved:
        return messages.unapproved;
      case EvidenceStatus.Verified:
        return messages.verified;
      default:
        return '';
    }
  };
  const text = getTextByStatusValue(status);
  const className = classNames(
    'recent-page-evidences-status__label',
    `recent-page-evidences-status__label--${status}`,
  );

  return (
    <div className="recent-page-evidences-status">
      <span className={className}>{translate(text)}</span>
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.number,
};

export default Status;
