import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';

import { useTheme } from 'hooks/theme';

import { finalizeAssessment, getAssessment } from 'api/assessment';

import Modal from 'ui/Modal';
import Button from 'ui/Button';

import { ReactComponent as InfoIcon } from 'assets/svgs/info.svg';

import { generalMessages } from 'constants/messages';

import { selectAssessment } from 'pages/Assessment/selectors';

import { messages } from './messages';

import './forceSubmitModal.scss';

const ForceSubmitModal = ({ open, onCancel }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { colorTheme } = useTheme();
  const { id: assessmentId } = useSelector(selectAssessment);

  const handleOnOk = async () => {
    setIsSubmitting(true);
    const payload = {
      forceSubmitted: true,
    };
    await dispatch(finalizeAssessment(payload)).unwrap();
    setIsSubmitting(false);
    dispatch(
      getAssessment({
        id: assessmentId,
      }),
    );
    onCancel();
  };

  return (
    <Modal open={open} onCancel={onCancel} footer={null} width={651}>
      <div className="force-submit-modal">
        <div className="force-submit-modal__header">
          <InfoIcon /> {translate(messages.forceSubmitHeader)}
        </div>
        <div className="force-submit-modal__description">
          {translate(messages.forceSubmitDescription)}
        </div>
        <div className="force-submit-modal__footer">
          <Button
            color={colorTheme}
            outline
            size="sm"
            data-test="force-submit-cancel-button"
            onClick={onCancel}
          >
            {translate(messages.cancel)}
          </Button>
          <Button
            loading={isSubmitting}
            color={colorTheme}
            size="sm"
            data-test="force-submit-cancel-button"
            onClick={handleOnOk}
          >
            {translate(generalMessages.submit)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ForceSubmitModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default ForceSubmitModal;
