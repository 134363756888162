import { isProductionDomain } from 'utils/index';
import { createStonlyConfig } from 'configs/createStonlyConfig';
import { createHotjarConfig } from 'configs/createHotjarConfig';
import { initializeMixpanel } from 'utils/mixpanel';

export const setThirdPartyConfigs = async () => {
  if (process.env.REACT_APP_MIXPANEL_KEY) await initializeMixpanel(); // initialize Mixpanel
  if (!isProductionDomain()) return;
  await createHotjarConfig(); // initialize Hotjar
  if (process.env.REACT_APP_STONLY_ID) await createStonlyConfig(); // initialize Stonly
};
