import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getFreemiumDetails,
  purchaseRRTokens,
  startRRFullScan,
  startRRPartScan,
  getRRFreemiumProgress,
} from 'api/riskRecon';

const initialState = {
  'rating': { 'findings': '', 'rating': [0, ''] },
  'companyDomain': '',
  'credits': 0,
  'lastPurchasedDomains': '',
  'categories': [{ 'domain': '', 'rating': [0, '-'], 'criticalFindings': '' }],
  'lastPurchased': '2024-03-28T09:21:15.658973',
  'detailedReport': {}, // file object
  'criticalFindings': '',
  'loadingFree': false,
  'loadingDomain': false,
  'loadingFullReport': false,
  'bundlePopupVisible': false,
  'creditsPurchaseVisible': false,
  'domainCriticalFindingsTotal': 0,
  'pendingDomain': null,
  'pendingFullReport': null,
};

const reducers = createSlice({
  name: 'riskRecon',
  initialState,
  reducers: {
    resetRiskRecon: () => initialState,
    setParam: (state, { payload: { key, value } }) => {
      state[key] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRRFreemiumProgress.fulfilled, (state, { payload }) => {
        state.pendingDomain = payload?.pendingDomain;
        state.pendingFullReport = payload?.pendingFullReport;
      })
      .addCase(getFreemiumDetails.fulfilled, (state, { payload }) => {
        state.rating = payload?.rating;
        state.companyDomain = payload?.companyDomain;
        state.credits = payload?.credits;
        state.lastPurchasedDomains = payload?.lastPurchasedDomains;
        state.categories = payload?.categories;
        state.lastPurchased = payload?.lastPurchased;
        state.detailedReport = payload?.detailedReport;
        state.criticalFindings = payload?.criticalFindings;
        state.domainCriticalFindingsTotal = payload?.domainCriticalFindingsTotal;
        state.loadingFree = false;
        state.loadingDomain = payload?.pendingDomain;
        state.loadingFullReport = payload?.pendingFullReport;
      })
      .addCase(startRRPartScan.pending, (state) => {
        state.loadingFree = true;
        state.loadingDomain = true;
      })
      .addCase(purchaseRRTokens.fulfilled, (state, { payload }) => {
        state.credits = payload?.tokens;
      })
      .addMatcher(isAnyOf(getFreemiumDetails.pending, startRRFullScan.pending), (state) => {
        state.loadingFree = true;
        state.loadingDomain = true;
        state.loadingFullReport = true;
      });
  },
});

export const { resetRiskRecon, setParam } = reducers.actions;
export default reducers;
