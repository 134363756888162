import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// HOOKS
import { useEmptyState } from 'ui/Table/useEmptyState';

// STYLES
import './emptyState.scss';

function EmptyState({ source }) {
  const { icon, text } = useEmptyState(source);

  const iconClassNames = classNames('table-empty-state__icon', {
    'table-empty-state__icon--recent': source?.includes('Recent'),
    'table-empty-state__icon--default': !source?.includes('Recent'),
  });

  const textClassNames = classNames('table-empty-state__text-wrap', {
    'table-empty-state__text-wrap--recent': source?.includes('Recent'),
  });
  return (
    <div className="table-empty-state" data-test={`empty-table-${source}`}>
      <div className={iconClassNames}>
        <img src={icon} alt={source} />
      </div>
      <div className={textClassNames}>{text}</div>
    </div>
  );
}

EmptyState.propTypes = {
  source: PropTypes.string.isRequired,
};

export default EmptyState;
