import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAssessments } from 'api/assessments';
import { setFilter } from 'components/AssessmentsFilters/reducers';
import ComponentFilterArchive from 'components/AssessmentsFilters/filterArchive';
import { selectFilters } from 'components/AssessmentsFilters/selectors';
import { messages as assessmentsFiltersMessages } from 'components/AssessmentsFilters/messages';
import { translate } from 'utils/index';

import './filterArchive.scss';

const FilterArchive = () => {
  const filters = useSelector(selectFilters);
  const dispatch = useDispatch();

  const fetchAssessments = () => {
    dispatch(getAssessments());
  };

  const onChange = (value, id, type) => {
    dispatch(setFilter({ type, value, id }));
  };

  const defaultFilter = {
    type: 'archive',
    id: 0,
    value: translate(assessmentsFiltersMessages.active),
  };

  return (
    <div className="assessments-page-filter-archive">
      <ComponentFilterArchive
        filter={filters.find(({ type }) => type === 'archive') || defaultFilter}
        fetchData={fetchAssessments}
        onChange={onChange}
      />
    </div>
  );
};

export default FilterArchive;
