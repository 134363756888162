import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

import { selectGroups, selectGroupsLoading } from 'components/AssessmentsFilters/selectors';
import { translate } from 'utils/index';
import { getCustomersGroups } from 'api/customersGroup';

import Filter from './filter';
import { messages } from '../messages';
import './index.scss';

const { Option } = Select;

const FilterGroup = ({ fetchVendors, filter }) => {
  const loading = useSelector(selectGroupsLoading);
  const groups = useSelector(selectGroups);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomersGroups());
  }, []);

  return (
    <Filter
      filter={filter}
      mode="multiple"
      fetchVendors={fetchVendors}
      loading={loading}
      innerLabel={translate(messages.filterGroup)}
    >
      {groups?.map(({ label, id }) => (
        <Option key={id} value={label} id={id}>
          {label}
        </Option>
      ))}
    </Filter>
  );
};

FilterGroup.propTypes = {
  fetchVendors: PropTypes.func,
  filter: PropTypes.object,
};

export default FilterGroup;
