import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// COMPONENTS
import Button from 'ui/Button';

// CONSTANTS
import { TableSource } from 'ui/Table/lib';
import ROUTES from 'constants/routes';

// HOOKS
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'ui/Table/messages';

// ASSETS
import emptyAssignAssessmentCustomer from 'assets/svgs/assign-assessment-empty-state-3.svg';
import emptyAssignAssessmentVendor from 'assets/svgs/assign-assessment-empty-state.svg';
import emptyAssessmentTemplatesCustomers from 'assets/svgs/assign-assessment-empty-state-2.svg';
import emptyAssessmentTemplatesVendors from 'assets/svgs/assign-assessment-empty-state-1.svg';
import customersEmptyState from 'assets/svgs/customers-empty-state.svg';
import vendorsEmptyState from 'assets/svgs/vendors-empty-state.svg';
import findingsEmptyStateCustomers from 'assets/svgs/findings-empty-state-1.svg';
import findingsEmptyStateVendors from 'assets/svgs/findings-empty-state.svg';
import evidenceEmptyStateVendors from 'assets/svgs/evidence-empty-state.svg';
import evidenceEmptyStateCustomers from 'assets/svgs/evidence-empty-state-2.svg';
import messagesEmptyStateVendors from 'assets/svgs/messages-empty-state-vendors.svg';
import messagesEmptyStateCustomers from 'assets/svgs/messages-empty-state-customers.svg';

export function useEmptyState(source) {
  const isVendorSelected = useIsMyVendorsTabSelected();
  const appNavigate = useAppNavigate();

  const [data, setData] = useState({
    icon: '',
    text: <div />,
  });

  const handleAddButton = useCallback(() => {
    switch (source) {
      case TableSource.customersList:
        appNavigate(ROUTES.CUSTOMER_NEW_CUSTOMER);
        break;
      case TableSource.vendorsList:
        appNavigate(ROUTES.VENDOR_NEW_VENDOR);
        break;
      case TableSource.assessmentsAssigned:
        appNavigate(
          isVendorSelected ? ROUTES.VENDOR_ASSIGN_ASSESSMENT : ROUTES.CUSTOMER_ASSIGN_ASSESSMENT,
        );
        break;
      case TableSource.assessmentsTemplates: {
        const newId = uuidv4();
        appNavigate(
          isVendorSelected
            ? ROUTES.VENDOR_ASSESSMENT_TEMPLATE
            : ROUTES.CUSTOMER_ASSESSMENT_TEMPLATE,
          { templateId: newId },
        );
        break;
      }
      default:
        break;
    }
  }, [source]);

  const emptyState = useCallback(() => {
    switch (source) {
      // Assessments
      case TableSource.assessmentsRecent:
        setData(() => ({
          icon: isVendorSelected ? emptyAssignAssessmentVendor : emptyAssignAssessmentCustomer,
          text: <div> {translate(messages.assessmentsRecent)} </div>,
        }));
        break;
      case TableSource.assessmentsAssigned:
        setData(() => ({
          icon: isVendorSelected ? emptyAssignAssessmentVendor : emptyAssignAssessmentCustomer,
          text: (
            <div className="table-empty-state__text-with-button">
              {' '}
              {translate(messages.assessmentsAssigned)}{' '}
              <Button
                link
                className="table-empty-state__button-add"
                data-test="table-empty-state-assessments-list-button-assign-new"
                onClick={handleAddButton}
              >
                {translate(messages.assessmentAssignNew)}
              </Button>
            </div>
          ),
        }));
        break;
      case TableSource.assessmentsTemplates:
        setData(() => ({
          icon: isVendorSelected
            ? emptyAssessmentTemplatesVendors
            : emptyAssessmentTemplatesCustomers,
          text: (
            <div className="table-empty-state__text-with-button">
              {' '}
              {translate(messages.assessmentsTemplates)}
              <Button
                link
                className="table-empty-state__button-add"
                data-test="table-empty-state-assessments-templates-button-template-new"
                onClick={handleAddButton}
              >
                {translate(messages.assessmentTemplateCreate)}
              </Button>
            </div>
          ),
        }));
        break;

      // Vendors
      case TableSource.vendorsRecent:
        setData(() => ({
          icon: vendorsEmptyState,
          text: <div> {translate(messages.vendorsRecent)} </div>,
        }));
        break;
      case TableSource.vendorsList:
        setData(() => ({
          icon: vendorsEmptyState,
          text: (
            <div className="table-empty-state__text-with-button">
              {translate(messages.vendorsList)}{' '}
              <Button
                link
                className="table-empty-state__button-add"
                data-test="table-empty-state-vendors-list-button-add-vendor"
                onClick={handleAddButton}
              >
                {translate(messages.vendorListCreate)}
              </Button>
            </div>
          ),
        }));
        break;

      // Customers
      case TableSource.customersRecent:
        setData(() => ({
          icon: customersEmptyState,
          text: <div> {translate(messages.customersRecent)} </div>,
        }));
        break;
      case TableSource.customersList:
        setData(() => ({
          icon: customersEmptyState,
          text: (
            <div className="table-empty-state__text-with-button">
              {translate(messages.customersList)}{' '}
              <Button
                link
                className="table-empty-state__button-add"
                data-test="table-empty-state-customers-list-button-add-customer"
                onClick={handleAddButton}
              >
                {translate(messages.customerListCreate)}
              </Button>
            </div>
          ),
        }));
        break;

      // Findings
      case TableSource.findingsRecent:
        setData(() => ({
          icon: isVendorSelected ? findingsEmptyStateVendors : findingsEmptyStateCustomers,
          text: <div> {translate(messages.findingsRecent)} </div>,
        }));
        break;
      case TableSource.findingsList:
        setData(() => ({
          icon: isVendorSelected ? findingsEmptyStateVendors : findingsEmptyStateCustomers,
          text: <div> {translate(messages.findingsList)} </div>,
        }));
        break;

      // Evidence
      case TableSource.evidenceRecent:
        setData(() => ({
          icon: isVendorSelected ? evidenceEmptyStateVendors : evidenceEmptyStateCustomers,
          text: <div> {translate(messages.evidenceRecent)} </div>,
        }));
        break;
      case TableSource.evidenceList:
        break;

      // Messages
      case TableSource.messages:
        setData(() => ({
          icon: isVendorSelected ? messagesEmptyStateVendors : messagesEmptyStateCustomers,
          text: <div> {translate(messages.messages)} </div>,
        }));
        break;

      default:
        break;
    }
  }, [source]);

  useEffect(() => {
    emptyState();
  }, [source]);

  return data;
}
