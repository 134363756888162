import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import classNames from 'classnames';

import { translate } from 'utils/index';
import { getAssessmentMode } from 'utils/getAssessmentMode';
import { AssessmentMode } from 'constants/assessmentMode';
import { messages } from 'pages/Assessments/messages';

import './assessmentStatusColumn.scss';

const AssessmentStatusColumn = ({
  assessmentMode,
  totalScore,
  scoreBenchmarkStatus,
  assessment: {
    autoSubmitted,
    assessmentProgress,
    force_finalized: forceFinalized,
    finalized,
    status,
    upstream,
    assessor,
    assesse,
    assessmentStatus,
    submittingUser,
    showcaseName,
  } = {},
}) => {
  const progress = assessmentProgress || totalScore?.progress;
  const calculatedAssessmentMode = getAssessmentMode({
    progress,
    finalized,
    status: status || assessmentStatus,
    upstream,
    assessor,
    assesse,
    showcaseName,
  });
  const mode = !isNil(assessmentMode) ? assessmentMode : calculatedAssessmentMode;
  const { status: scoreStatus } = totalScore || {};
  const { message, statusClass } = getFormattedValue(
    mode,
    status,
    scoreBenchmarkStatus || scoreStatus,
  );

  const className = classNames('assessment-status-column', {
    [`assessment-status-column--${statusClass}`]: statusClass,
    'assessment-status-column--auto-submitted': autoSubmitted,
    'assessment-status-column--force-submitted': forceFinalized,
  });

  return (
    <div className={className}>
      <span
        className={`assessment-status-column__label assessment-status-column__label--${statusClass}`}
      >
        {translate(message)}
      </span>
      {message === messages.statusInProgress && (
        <span className="assessment-status-column__progress-percentage">{`${assessmentProgress}%`}</span>
      )}
      {!showcaseName && autoSubmitted && (
        <div className="assessment-status-column__auto-submitted">
          {translate(messages.pastDueDate)}
        </div>
      )}
      {!showcaseName && forceFinalized && !autoSubmitted && (
        <div className="assessment-status-column__force-submitted">
          <span className="assessment-status-column__force-submitted-label">
            {translate(messages.by)}
          </span>
          : {submittingUser || 'User'}
        </div>
      )}
    </div>
  );
};

const getFormattedValue = (assessmentMode, status, scoreStatus) => {
  switch (assessmentMode) {
    case AssessmentMode.StartAssessment:
      return { message: messages.statusNotStarted, statusClass: 'gray' };
    case AssessmentMode.ResumeAssessment:
      return { message: messages.statusInProgress, statusClass: 'yellow' };
    case AssessmentMode.ReviewAndFinalizeLocked:
      return { message: messages.statusCompleted, statusClass: 'green' };
    case AssessmentMode.ReviewAndFinalizeWizard:
    case AssessmentMode.ReviewAndFinalizeWizardEditable:
    case AssessmentMode.ReviewAndFinalizeDisabled:
      return { message: messages.statusSubmitted, statusClass: 'blue' };
    case AssessmentMode.MarkAsReviewed:
      return { message: messages.statusSubmitted, statusClass: 'blue' };
    case AssessmentMode.MarkAsReviewedDisabled:
    case AssessmentMode.ReviewAssessment:
      return composeReviewedAssessmentStatus(scoreStatus);
    case AssessmentMode.ShowCase:
      return { message: messages.statusShowCase, statusClass: 'turquoise' };
    default:
      return messages.statusNotStarted;
  }
};

const composeReviewedAssessmentStatus = (status) => {
  if (status === 1) {
    return { message: messages.fail, statusClass: 'red' };
  }
  if (status === 2) {
    return { message: messages.pass, statusClass: 'green' };
  }

  return { message: messages.undefined, statusClass: 'gray' };
};

AssessmentStatusColumn.propTypes = {
  assessmentMode: PropTypes.number,
  assessment: PropTypes.object,
  totalScore: PropTypes.object,
  scoreBenchmarkStatus: PropTypes.number,
};

export default AssessmentStatusColumn;
