import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// COMPONENTS
import ShowcasesSentList from 'pages/Showcases/showcasesSentList';
import ShowcasesTemplatesList from 'pages/Showcases/showcasesTemplatesList';
import PageHeader from 'components/PageHeader';
import ShowCaseEditor from 'pages/Showcases/showcaseEditor';
import Tabs from 'ui/Tabs';

// UTILS
import { translate } from 'utils/index';
import { messages } from './messages';
import { selectProfile } from 'pages/Profile/selectors';

import './index.scss';

const Showcases = () => {
  const [createShowcase, setCreateShowcase] = useState(false);
  const [showcase, setShowcase] = useState();

  const { user } = useSelector(selectProfile);

  const handleCreateShowcase = (showcase) => {
    setCreateShowcase((state) => !state);
    setShowcase(showcase ? showcase : undefined);
  };

  return (
    <>
      <div className="showcases">
        <PageHeader className="showcases__header">
          {translate(messages.showcasesHeader)}:
        </PageHeader>
        <div className="showcases__subtitle">{translate(messages.showcaseSubTitle)}</div>
        <Tabs rounded>
          <Tabs.Pane tab={translate(messages.sharedTabTitle)} key="shared">
            <div className="showcases__wrapper">
              <ShowcasesSentList handleCreateShowcase={handleCreateShowcase} />
            </div>
          </Tabs.Pane>
          <Tabs.Pane tab={translate(messages.templatesTabTitle)} key="templates">
            <div className="showcases__wrapper">
              <ShowcasesTemplatesList handleCreateShowcase={handleCreateShowcase} />
            </div>
          </Tabs.Pane>
        </Tabs>
      </div>
      <ShowCaseEditor
        isOpen={createShowcase}
        onClose={() => setCreateShowcase(false)}
        newShowCase={!showcase}
        showcase={showcase}
        corporateLogo={user?.corporateLogo || ''}
      />
    </>
  );
};

export default Showcases;
