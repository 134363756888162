import React, { useEffect, useMemo, useState } from 'react';
import { translate } from 'utils/index';
import NewVendorFooter from 'components/NewVendorFooter';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ArrowIconLeft } from 'assets/svgs/arrowLeft.svg';
import { ReactComponent as ShowCaseIcon } from 'assets/svgs/showcase.svg';
import { useCustomerManageContext } from 'hooks/useCustomerManageContext';
import Form from 'ui/Form';
import { generalMessages } from 'constants/messages';

import { messages } from './messages';
import DatePicker from 'ui/DatePicker';
import CheckboxGroup from 'ui/CheckboxGroup';
import { getShowcaseTemplates } from 'api/showcase';
import { mixpanelTrackShowcaseShared } from 'utils/mixpanel';
import './index.scss';

const ShareShowcaseTab = ({ handleBackTab }) => {
  const { isLoading } = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  const { currentCustomer, updateCustomer, onChangeAssets } = useCustomerManageContext();

  const [checkedAssessmentRequired, setCheckedAssessmentRequired] = useState([]);
  const [checkedAssessmentPeriod] = useState(null);
  const { templatesShowcases } = useSelector((state) => state.showcases);

  useEffect(() => {
    if (!templatesShowcases.length) {
      dispatch(getShowcaseTemplates());
    }
  }, []);

  useEffect(() => {
    onChangeAssets({
      checkedAssessmentRequired,
      checkedAssessmentPeriod,
    });
  }, [checkedAssessmentRequired, checkedAssessmentPeriod]);

  const onChange = (value) => {
    setCheckedAssessmentRequired(value);
  };

  const showcaseTypesMapped = useMemo(
    () =>
      templatesShowcases.map((type) => ({
        label: type.title,
        key: type.id,
        value: type.id,
      })),
    [templatesShowcases],
  );

  const onSubmit = (event) => {
    event.preventDefault();

    if (checkedAssessmentRequired?.length) {
      checkedAssessmentRequired.forEach((sentShowcase) => {
        const templatesShowcase = templatesShowcases.find(
          (currentTempShoecase) => currentTempShoecase.id === sentShowcase,
        );
        const mixpanelData = {
          relation: currentCustomer?.id || '',
          name: templatesShowcase?.title || '',
          assessmentsCount: templatesShowcase.assessments?.length || 0,
          description: templatesShowcase?.description || '',
          source: 'customer card',
        };
        mixpanelTrackShowcaseShared(mixpanelData);
      });
    }

    updateCustomer();
  };

  return (
    <Form className="share-showcase-tab" onSubmit={onSubmit}>
      <div className="share-showcase-tab__info">
        <div className="share-showcase-tab__info-title">{translate(messages.selectShowcase)}</div>
        <div className="share-showcase-tab__info-desc">
          {translate(messages.selectShowcaseDescription)}
        </div>
      </div>
      {!showcaseTypesMapped.length && (
        <div className="share-showcase-tab__empty">
          <div className="share-showcase-tab__empty-icon">
            <ShowCaseIcon />
            {translate(messages.showcaseEmptyInfo)}
          </div>
        </div>
      )}

      {!!showcaseTypesMapped.length && (
        <CheckboxGroup
          data-test="assign-showcase-items"
          options={showcaseTypesMapped}
          name="assessmentType"
          mode="label"
          value={checkedAssessmentRequired}
          onChange={onChange}
        />
      )}
      <div className="share-showcase-tab__date-picker">
        <label className="share-showcase-tab__date-picker-label">
          {translate(messages.sharedUntil)}
        </label>
        <DatePicker
          allowClear={false}
          color="gray"
          className="share-showcase-tab__date-picker-input"
          // onChange={(value) => setFieldValue('sharedUntil', value)}
          size="large"
          // value={values.sharedUntil}
        />
      </div>

      <NewVendorFooter
        prevBeforeComponent={<ArrowIconLeft width={13} height={14} />}
        nextAction={onSubmit}
        prevAction={handleBackTab}
        nextLabel={
          currentCustomer ? translate(messages.saveAndClose) : translate(messages.createNewCustomer)
        }
        prevLabel={translate(generalMessages.back)}
        isNextLoading={isLoading}
      />
    </Form>
  );
};

ShareShowcaseTab.propTypes = {
  handleBackTab: PropTypes.func,
};

export default ShareShowcaseTab;
