export const messages = {
  recent: {
    key: 'BaseLayout.recent',
    defaultMessage: 'Recent',
  },
  overview: {
    key: 'BaseLayout.overview',
    defaultMessage: 'Overview',
  },
  vendors: {
    key: 'BaseLayout.vendors',
    defaultMessage: 'Vendors',
  },
  customers: {
    key: 'BaseLayout.customers',
    defaultMessage: 'Customers',
  },
  assessments: {
    key: 'BaseLayout.assessments',
    defaultMessage: 'Assessments',
  },
  showcases: {
    key: 'BaseLayout.showcases',
    defaultMessage: 'Showcases',
  },
  findings: {
    key: 'BaseLayout.findings',
    defaultMessage: 'Findings',
  },
  myVendors: {
    key: 'BaseLayout.myVendors',
    defaultMessage: 'My vendors',
  },
  myCustomers: {
    key: 'BaseLayout.myCustomers',
    defaultMessage: 'My customers',
  },
  createVendor: {
    key: 'BaseLayout.createVendor',
    defaultMessage: 'Create vendor',
  },
  createCustomer: {
    key: 'BaseLayout.createCustomer',
    defaultMessage: 'Create customer',
  },
  uploadVendorList: {
    key: 'BaseLayout.uploadVendorList',
    defaultMessage: 'Upload vendor list',
  },
  uploadCustomersList: {
    key: 'BaseLayout.uploadCustomersList',
    defaultMessage: 'Upload customers list',
  },
  assignAssessment: {
    key: 'BaseLayout.assignAssessment',
    defaultMessage: 'Assign assessment',
  },
  createNewTemplate: {
    key: 'BaseLayout.createNewTemplate',
    defaultMessage: 'Create new template',
  },
  profileSettings: {
    key: 'BaseLayout.profileSettings',
    defaultMessage: 'Profile settings',
  },
  organizationsSettings: {
    key: 'BaseLayout.organizationsSettings',
    defaultMessage: 'Organizations settings',
  },
  businessOwnerPage: {
    key: 'BaseLayout.businessOwnerPage',
    defaultMessage: 'Business owner page',
  },
  salesOwnerPage: {
    key: 'BaseLayout.salesOwnerPage',
    defaultMessage: 'Sales owner page',
  },
  privacyPolicy: {
    key: 'BaseLayout.privacyPolicy',
    defaultMessage: 'Privacy policy',
  },
  termOfUse: {
    key: 'BaseLayout.termOfUse',
    defaultMessage: 'Term of use',
  },
  logout: {
    key: 'BaseLayout.logout',
    defaultMessage: 'Logout',
  },
  organizations: {
    key: 'BaseLayout.organizations',
    defaultMessage: 'ORGANIZATIONS',
  },
  trustOrganizationModalHeader: {
    key: 'BaseLayout.trustOrganizationModalHeader',
    defaultMessage: 'Associate the “trust request” to the appropriate organization',
  },
  trustOrganizationColumnReqFrom: {
    key: 'BaseLayout.trustOrganizationColumnReqFrom',
    defaultMessage: 'REQUEST FROM',
  },
  trustOrganizationColumnContactEmail: {
    key: 'BaseLayout.trustOrganizationColumnContactEmail',
    defaultMessage: 'CONTACT EMAIL',
  },
  trustOrganizationColumnAssignOrg: {
    key: 'BaseLayout.trustOrganizationColumnAssignOrg',
    defaultMessage: 'ASSIGN ORGANIZATION',
  },
  trustOrganizationColumnRejectReq: {
    key: 'BaseLayout.trustOrganizationColumnRejectReq',
    defaultMessage: 'REJECT REQUEST',
  },
  trustOrganizationCancel: {
    key: 'BaseLayout.trustOrganizationCancel',
    defaultMessage: 'Cancel',
  },
  trustOrganizationApprove: {
    key: 'BaseLayout.trustOrganizationApprove',
    defaultMessage: 'Approve',
  },
  trustOrganizationReject: {
    key: 'BaseLayout.trustOrganizationReject',
    defaultMessage: 'Reject',
  },
  trustOrganizationSelectOrganizations: {
    key: 'BaseLayout.trustOrganizationSelectOrganizations',
    defaultMessage: 'Select Organizations',
  },
  Admin: {
    key: 'BaseLayout.Admin',
    defaultMessage: 'Admin',
  },
  BO: {
    key: 'BaseLayout.BO',
    defaultMessage: 'BO',
  },
  SO: {
    key: 'BaseLayout.SO',
    defaultMessage: 'SO',
  },
  Owner: {
    key: 'BaseLayout.Owner',
    defaultMessage: 'Owner',
  },
  Reporter: {
    key: 'BaseLayout.Reporter',
    defaultMessage: 'Reporter',
  },
  Viewer: {
    key: 'BaseLayout.Viewer',
    defaultMessage: 'Viewer',
  },
  inviteTeamMemberUserTitle: {
    key: 'BaseLayout.inviteTeamMemberUserTitle',
    defaultMessage: 'Add new user',
  },
  toOrganization: {
    key: 'BaseLayout.toOrganization',
    defaultMessage: 'to organization',
  },
  inviteTeamMemberEmailPlaceholder: {
    key: 'BaseLayout.inviteTeamMemberEmailPlaceholder',
    defaultMessage: 'Enter user email address',
  },
  inviteTeamMemberRolePlaceholder: {
    key: 'BaseLayout.inviteTeamMemberRolePlaceholder',
    defaultMessage: 'Role',
  },
  inviteTeamMemberRoleUpdate: {
    key: 'BaseLayout.inviteTeamMemberRoleUpdate',
    defaultMessage: 'Update user role',
  },
  inviteTeamMemberEmailErrorFormat: {
    key: 'BaseLayout.inviteTeamMemberEmailErrorFormat',
    defaultMessage: 'Email format is invalid',
  },
  inviteTeamMemberEmailRequired: {
    key: 'BaseLayout.inviteTeamMemberEmailRequired',
    defaultMessage: 'Email is required',
  },
  inviteTeamMemberRoleRequired: {
    key: 'BaseLayout.inviteTeamMemberRoleRequired',
    defaultMessage: 'Role is required',
  },
  inviteTeamMemberButton: {
    key: 'BaseLayout.inviteTeamMemberButton',
    defaultMessage: 'Invite team member',
  },
  inviteTeamMemberSuccess: {
    key: 'BaseLayout.inviteTeamMemberSuccess',
    defaultMessage: 'Team member has been invited successfully',
  },
  inviteTeamMemberError: {
    key: 'BaseLayout.inviteTeamMemberError',
    defaultMessage: 'Team member invitation failed',
  },
  myFiles: {
    key: 'BaseLayout.myFiles',
    defaultMessage: 'My files',
  },
};

export default {
  prefix: 'BaseLayout',
  messages,
};
