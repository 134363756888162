import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'ui/Button';
import { ReactComponent as FileUploadIcon } from 'assets/svgs/upload-icon.svg';
import MyFiles from 'components/MyFiles';

import './index.scss';

const FILE_UPLOAD_ICON_SIZE = 25;

const FileUpload = ({
  button,
  className,
  disabled,
  openMyFiles,
  text,
  handleUpload,
  accept,
  icon,
  size,
  buttonColor,
  ...otherProps
}) => {
  const ref = useRef();
  const [isMyFilesOpen, setIsMyFilesOpen] = useState(false);

  const handleClick = () => {
    if (openMyFiles) {
      return setIsMyFilesOpen(true);
    }
    ref.current.click();
  };

  const handleChange = (event, files) => {
    const fileUploaded = files || event.target.files[0];
    handleUpload(fileUploaded);

    if (isMyFilesOpen) {
      setIsMyFilesOpen(false);
    }
  };

  return (
    <div className={classNames('ui-file-upload', className)}>
      {button ? (
        React.cloneElement(button, {
          'data-test': 'ui-file-upload-button-browse',
          disabled,
          onClick: handleClick,
          ...otherProps,
        })
      ) : (
        <Button
          data-test="ui-file-upload-button-browse"
          size={size}
          outline
          beforeComponent={
            icon || <FileUploadIcon width={FILE_UPLOAD_ICON_SIZE} height={FILE_UPLOAD_ICON_SIZE} />
          }
          onClick={handleClick}
          disabled={disabled}
          {...otherProps}
          color="blue"
        >
          {text}
        </Button>
      )}
      <input hidden accept={accept} type="file" ref={ref} onChange={handleChange} />
      {isMyFilesOpen && (
        <MyFiles
          visible={isMyFilesOpen}
          onCancel={() => setIsMyFilesOpen(false)}
          onOk={handleChange}
          multi={true}
        />
      )}
    </div>
  );
};

FileUpload.propTypes = {
  button: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  openMyFiles: PropTypes.bool,
  text: PropTypes.string,
  handleUpload: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,
  icon: PropTypes.node,
  size: PropTypes.string,
  buttonColor: PropTypes.string,
};

FileUpload.defaultProps = {
  size: 'xs',
  buttonColor: 'pink',
};
export default FileUpload;
