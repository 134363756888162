import * as yup from 'yup';
import { AssessmentPerformedBy } from 'constants/assessmentPerformedBy';

export const assignAssessmentFullSchema = (requiredTemplate, requiredCustomer) =>
  yup.object({
    templates: yup.array().of(yup.string()).min(1, requiredTemplate),
    customers: yup.array().when('performedBy', {
      is: AssessmentPerformedBy.vendor,
      then: yup.array().of(yup.string()).min(1, requiredCustomer),
    }),
  });
