import React from 'react';
import PropTypes from 'prop-types';

import Popup from 'ui/Popup/Popup';
import Button from 'ui/Button';
import classNames from 'classnames';
import Icon from 'ui/Icon';
import { generalMessages } from 'constants/messages';
import { translate } from 'utils/index';
import Form from 'ui/Form';

const PopupWithForm = ({
  onClickCancel,
  onClickOk,
  withIconOnTitle,
  okButtonText,
  cancelButtonText,
  children,
  iconColor,
  ...otherProps
}) => {
  const popupClassNames = classNames({
    'ui-popup--withIconOnTitle': withIconOnTitle,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    onClickOk();
  };

  const footerButtons = [
    <Button
      key="footer-button_popup_with_form_1"
      link
      className="assessment-add-finding__footer-cancel-button"
      data-test="popup-with-form-button-footer-cancel"
      size="sm"
      color="white"
      onClick={onClickCancel}
      fluid
      name={cancelButtonText || translate(generalMessages.cancel)}
    />,
    <Button
      key="footer-button_popup_with_form_2"
      data-test="popup-with-form-button-footer-save"
      size="sm"
      width={100}
      color="pink"
      type="submit"
      onClick={onSubmit}
      name={okButtonText || translate(generalMessages.save)}
    />,
  ];
  return (
    <Popup
      className={popupClassNames}
      maskClosable
      onCancel={onClickCancel}
      footer={footerButtons}
      withIconOnTitle={withIconOnTitle}
      {...otherProps}
    >
      <div>
        {withIconOnTitle && (
          <Icon icon={withIconOnTitle} className="ui-popup__title-icon" color={iconColor || ''} />
        )}
        <Form onSubmit={onSubmit}>{children}</Form>
      </div>
    </Popup>
  );
};

PopupWithForm.propTypes = {
  onClickCancel: PropTypes.func,
  onClickOk: PropTypes.func,
  withIconOnTitle: PropTypes.string,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  children: PropTypes.any,
  iconColor: PropTypes.string,
};

export default PopupWithForm;
