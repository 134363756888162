import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import { createSorterByString } from 'utils/strings';

import Table from 'ui/Table';
import Button from 'ui/Button';
import Input from 'ui/Input';

import Assessments from 'pages/Recent/vendorsColumns/assessments';
import { DATE_FORMAT } from 'constants/date';
import { getShowcaseTemplates } from 'api/showcase';
import { messages } from './messages';
import { messages as messagesFromList } from 'pages/Showcases/showcasesSentList/messages';
import templatesShowcasesEs from 'assets/svgs/templates-showcases-es.svg';
import { useRole } from 'hooks/useRole';

import '../index.scss';

const ShowcasesTemplatesList = ({ handleCreateShowcase }) => {
  const [searchValue, setSearchValue] = useState('');
  const { templatesShowcases, isLoadingTemplates } = useSelector((state) => state.showcases);
  const { isRestrictedBoSoViewerRole } = useRole();
  const [dataSource, setDataSource] = useState(templatesShowcases);
  const dispatch = useDispatch();

  const emptyState = useMemo(() => {
    return (
      <div className="showcases-list__empty-state">
        <img src={templatesShowcasesEs} alt="sent-showcase" width="130" />
        <div className="showcases-list__empty-state-text">
          {translate(messagesFromList.emptyState)}
        </div>
        <Button
          link
          onClick={() => handleCreateShowcase()}
          className="showcases-list__empty-state-button"
        >
          + {translate(messagesFromList.createShowcase)}
        </Button>
      </div>
    );
  }, [templatesShowcases]);

  useEffect(() => {
    setDataSource(templatesShowcases);
  }, [templatesShowcases]);

  useEffect(() => {
    dispatch(getShowcaseTemplates());
  }, []);

  const columns = [
    {
      title: translate(messages.columnLastUpdated),
      dataIndex: 'lastUpdated',
      render: (value) => <div>{moment(value).format(DATE_FORMAT)}</div>,
      sorter: (a, b) => new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime(),
    },
    {
      title: translate(messages.columnTitle),
      dataIndex: 'title',
      sorter: createSorterByString('title'),
    },
    {
      title: translate(messages.columnDescription),
      dataIndex: 'description',
      render: (value) => <span>{value}</span>,
      sorter: createSorterByString('description'),
      ellipsis: { showTitle: false },
    },
    {
      title: translate(messages.columnAssessmentIncluded),
      dataIndex: 'assessments',
      render: (assessments) => <Assessments assessments={assessments} />,
    },
  ];

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    const lowerCaseValue = value.toLowerCase();

    setDataSource(
      templatesShowcases.filter((data) => {
        const description = data.description.toLowerCase();
        const title = data.title.toLowerCase();

        return description.includes(lowerCaseValue) || title.includes(lowerCaseValue);
      }),
    );
  };

  const onRow = (data) => ({
    onClick: () => {
      handleCreateShowcase(data);
    },
  });

  return (
    <div className="showcases-list">
      <div className="showcases-list-actions">
        <Input.Search onChange={handleSearch} size="small" value={searchValue} />
        <Button
          size="sm"
          beforeIcon="plus"
          color="green"
          disabled={isRestrictedBoSoViewerRole}
          data-test="showcases-sent-list-send-showcase"
          className="showcases-list__button"
          onClick={() => handleCreateShowcase()}
        >
          {translate(messages.createShowcase)}
        </Button>
      </div>

      <Table
        className="showcases-list__table"
        columns={columns}
        dataSource={dataSource}
        loading={isLoadingTemplates}
        pagination={false}
        onRow={onRow}
        locale={{
          emptyText: emptyState,
        }}
      />
    </div>
  );
};

ShowcasesTemplatesList.propTypes = {
  handleCreateShowcase: PropTypes.func.isRequired,
};

export default ShowcasesTemplatesList;
