import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import { messages as assessmentsFiltersMessages } from 'components/AssessmentsFilters/messages';
import './filterArchive.scss';

const FilterArchive = ({ fetchData, filter, onChange, isBooleanValue }) => {
  const onSelect = ({ target }) => {
    const value = !target.value
      ? translate(assessmentsFiltersMessages.active)
      : translate(assessmentsFiltersMessages.archived);
    onChange(value, target.value, 'archive');
    fetchData();
  };

  return (
    <Radio.Group
      className="assessments-filters-filter-archive"
      value={filter.id}
      onChange={onSelect}
    >
      <Radio.Button value={isBooleanValue ? false : 0}>
        {translate(assessmentsFiltersMessages.active)}
      </Radio.Button>
      <Radio.Button value={isBooleanValue ? true : 1}>
        {translate(assessmentsFiltersMessages.archived)}
      </Radio.Button>
    </Radio.Group>
  );
};

FilterArchive.propTypes = {
  fetchData: PropTypes.func,
  filter: PropTypes.object,
  onChange: PropTypes.func,
  isBooleanValue: PropTypes.bool,
};

export default FilterArchive;
