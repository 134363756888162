import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import Form from 'ui/Form';
import DatePicker from 'ui/DatePicker';
import UploadReport from 'pages/Editor/settings/uploadReport';
import Files from 'pages/Editor/settings/files';
import Divider from 'ui/Divider';
import Input from 'ui/Input';
import RadioGroup from 'ui/RadioGroup';
import { messages } from 'pages/Editor/messages';
import { translate } from 'utils/index';
import { selectTemplate } from 'pages/Editor/selectors';
import { saveTemplate } from 'api/editor';
import { BACKEND_INPUT_DATE_FORMAT } from 'constants/date';
import { useRole } from 'hooks/useRole';
import Select from 'ui/Select';

import './index.scss';

const Settings = ({ isAssessmentEdit, themeColor }) => {
  const { templateId } = useParams();
  const template = useSelector(selectTemplate);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [searchParams] = useSearchParams();
  const { isRestrictedBoSoViewerRole } = useRole();

  const [settings, setSettings] = useState({
    totalScore: { benchmark: null },
    rtl: null,
  });
  const [showCustomDaysToSubmit, setShowCustomDaysToSubmit] = useState(false);

  useEffect(() => {
    setSettings({
      ...settings,
      totalScore: { ...template.total_score },
      rtl: template.rtl,
      due: template.due,
      due_delta: template.due_delta || undefined,
    });
  }, [template]);

  const getDueDeltaValue = useCallback(() => {
    if (showCustomDaysToSubmit) {
      return daysToSubmitOptions[daysToSubmitOptions.length - 1].value;
    }
    if (settings?.due_delta) {
      let selectedOption = daysToSubmitOptions.find(
        (option) => option.value === settings.due_delta,
      );

      if (!selectedOption) {
        selectedOption = daysToSubmitOptions[daysToSubmitOptions.length - 1];
        setShowCustomDaysToSubmit(true);
      }

      return selectedOption.value;
    } else return undefined;
  }, [settings?.due_delta, showCustomDaysToSubmit]);

  const textDirectionsOptions = [
    {
      key: 'ltr',
      label: translate(messages.settingsTextDirectionLTR),
      value: '0',
      'data-test': 'settings-text-direction',
    },
    {
      key: 'rtl',
      label: translate(messages.settingsTextDirectionRTL),
      value: '1',
      'data-test': 'settings-text-direction',
    },
  ];

  const onChangeTextDirection = async ({ target: { value } }) => {
    setSettings((state) => {
      return { ...state, rtl: !state.rtl };
    });
    await dispatch(saveTemplate({ rtl: value !== '0' }));
  };

  const setBenchmark = (value) => {
    setSettings((state) => {
      return { ...state, totalScore: { ...state.totalScore, benchmark: parseInt(value, 10) } };
    });
  };

  const onChangeBenchmark = ({ target: { value } }) => {
    let newValue = value;
    if (!newValue) {
      newValue = 0;
    }
    setBenchmark(newValue);
  };

  const onKeyPressBenchmark = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const onClickBenchmark = () => {
    inputRef?.current?.focus();
  };

  const onBlurBenchmark = () => {
    return settings.totalScore.benchmark !== undefined
      ? dispatch(saveTemplate({ total_score: settings.totalScore }))
      : {};
  };

  const benchmarkValue = useMemo(() => {
    if (settings.totalScore) {
      const { benchmark } = settings.totalScore;
      if (benchmark > 100) setBenchmark(100);
      if (benchmark && benchmark !== 0) return benchmark.toString();
    }
    return '';
  }, [settings.totalScore]);

  const onChangeDueDate = async (due) => {
    setSettings((state) => {
      return { ...state, due };
    });
    await dispatch(saveTemplate({ due: due.format(BACKEND_INPUT_DATE_FORMAT) }));
  };

  const daysToSubmitOptions = useMemo(
    () => [
      { label: translate(messages.oneWeek), value: 7 },
      { label: translate(messages.twoWeeks), value: 14 },
      { label: translate(messages.oneMonth), value: 30 },
      { label: translate(messages.customDueDate), value: 'custom' },
    ],
    [],
  );

  return (
    <div className="editor-settings">
      <div className="editor-settings__title">
        <Icon icon="settings" className="editor-settings__title-icon" />
        <span>{translate(messages.settingsTitle)}</span>
      </div>
      <div className="editor-settings__body">
        <Divider hidden size="sm" />
        <Form.Item>
          <Input
            label={translate(messages.settingsAssessmentBenchmark)}
            inputRef={inputRef}
            data-test="settings-benchmark-input"
            placeholder={translate(messages.settingsAssessmentBenchmarkPlaceholder)}
            value={benchmarkValue}
            onChange={onChangeBenchmark}
            onKeyPress={onKeyPressBenchmark}
            onClick={onClickBenchmark}
            onBlur={onBlurBenchmark}
            disabled={isRestrictedBoSoViewerRole}
          />
        </Form.Item>

        {isAssessmentEdit ? null : (
          <>
            <Form.Item>
              <Select
                data-test="due-date-select"
                label={translate(messages.settingsDueDate)}
                placeholder={translate(messages.settingsDueDateSelectPlaceholder)}
                options={daysToSubmitOptions}
                value={getDueDeltaValue()}
                color="gray"
                onChange={(value) => {
                  if (value === 'custom') {
                    setShowCustomDaysToSubmit(true);
                  } else {
                    setShowCustomDaysToSubmit(false);
                    setSettings((prev) => {
                      return { ...prev, due_delta: value };
                    });
                    dispatch(saveTemplate({ due_delta: value }));
                  }
                }}
              />
            </Form.Item>
            {showCustomDaysToSubmit ? (
              <Form.Item>
                <Input
                  className="custom-days-input"
                  color="gray"
                  placeholder={translate(messages.settingsCustomDaysInputPlaceholder)}
                  label={translate(messages.settingsCustomDaysInputLabel)}
                  data-test="custom-days-to-submit-input"
                  type="number"
                  value={settings?.due_delta ? settings?.due_delta.toString() : ''}
                  onBlur={() => {
                    dispatch(saveTemplate({ due_delta: settings.due_delta }));
                  }}
                  onChange={(event) => {
                    setSettings((prev) => {
                      return { ...prev, due_delta: event.target.value };
                    });
                  }}
                />
              </Form.Item>
            ) : null}
          </>
        )}

        {isAssessmentEdit && (
          <Form.Item>
            <Form.Label text={translate(messages.settingsDueDate)} />
            <DatePicker
              allowClear={false}
              color="gray"
              className="editor-settings__due-date-picker"
              onChange={onChangeDueDate}
              size="large"
              value={settings.due}
              disabled={isRestrictedBoSoViewerRole}
            />
          </Form.Item>
        )}
        <Form.Item>
          <Form.Label text={translate(messages.settingsTextDirection)} />
          <Divider hidden size="xs" />
          <RadioGroup
            options={textDirectionsOptions}
            name="setTextDirection"
            value={settings.rtl ? '1' : '0'}
            onChange={onChangeTextDirection}
            disabled={isRestrictedBoSoViewerRole}
          />
        </Form.Item>
        {!searchParams.get('assessment') && (
          <UploadReport
            isTemplate
            templateId={templateId}
            reportFromTemplate={{
              name: template.report || '',
              id: template.id || '',
            }}
            themeColor={themeColor}
            disabled={isRestrictedBoSoViewerRole}
          />
        )}

        <Divider size="lg" className="editor-settings__divider" />
        <Files
          templateId={templateId}
          filesFromTemplate={template.files}
          themeColor={themeColor}
          disabled={isRestrictedBoSoViewerRole}
        />
      </div>
    </div>
  );
};

Settings.propTypes = {
  isAssessmentEdit: PropTypes.bool,
  themeColor: PropTypes.string,
};

export default Settings;
