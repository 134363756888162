import { messages } from './messages';
import VendorRisk from 'constants/vendorRisk';

export const availableFilters = [
  {
    type: 'company-filter',
    name: messages.filterCompany,
  },
  {
    type: 'assessment-filter',
    name: messages.filterAssessment,
  },
  {
    type: 'vendor-risk-filter',
    name: messages.filterVendorRisk,
  },
  {
    type: 'profile-relation-group-filter',
    name: messages.filterGroup,
  },
  {
    type: 'vendor-status-filter',
    name: messages.filterVendorStatus,
  },
  {
    type: 'parent-organization-filter',
    name: messages.filterParentOrganization,
  },
];

export const vendorRisk = [
  {
    name: messages.low,
    value: VendorRisk.Low,
  },
  {
    name: messages.medium,
    value: VendorRisk.Medium,
  },
  {
    name: messages.high,
    value: VendorRisk.High,
  },
];
