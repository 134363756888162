import React from 'react';
import axios from 'axios';

import Form from 'ui/Form';
import ControlComponent from 'components/ControlComponent';
import { ControlType } from 'constants/controlType';
import { isProduction } from 'utils/index';

const ApiUrlSwitch = () => {
  if (isProduction()) return null;

  const apiUrls =
    process.env.REACT_APP_API_URLS?.split(',')?.map((url) => ({ label: url, value: url })) || [];

  const onChangeApiUrl = (url) => {
    axios.defaults.baseURL = `${url}/api`;
  };

  return (
    <Form.Item>
      <ControlComponent
        type={ControlType.DropDown}
        isSimple
        data={{
          payload: {
            name: 'api-url',
            options: apiUrls,
            onChange: onChangeApiUrl,
            placeholder: 'Choose API_URL',
          },
        }}
      />
    </Form.Item>
  );
};

export default ApiUrlSwitch;
