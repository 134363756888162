import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

// ENDPOINTS:
import { GetOrganizationAssessmentsLog } from 'api/endpoints';

const getOrganizationAssessmentsLogRequest = async ({ offset, count }) => {
  const payload = {
    offset,
    count,
  };
  return axios.post(GetOrganizationAssessmentsLog.url, payload, { withCredentials: true });
};

export const getOrganizationAssessmentsLog = createAsyncThunk(
  'getOrganizationAssessmentsLog',
  getOrganizationAssessmentsLogRequest,
);
