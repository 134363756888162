import React from 'react';
import { useDispatch } from 'react-redux';

// COMPONENTS
import Search from 'pages/Findings/search';
import AddFilterButton from 'components/AddFilterButton';
import ActiveFilters from 'pages/Findings/activeFilters';

// REDUX
import { setFilter } from 'pages/Findings/reducers';
import { getAllOrganizations, getProfileSubjectsFindings } from 'api/finding';
import { getFindingsPageAssessmentFilter } from 'api/filtersData';

const ActionsBar = () => {
  const dispatch = useDispatch();

  const setFindingsFilter = (type) => {
    dispatch(setFilter({ type }));
    if (type === 'vendor' || type === 'customer') dispatch(getAllOrganizations());
    if (type === 'assessmentName') dispatch(getFindingsPageAssessmentFilter());
    if (type === 'subject') dispatch(getProfileSubjectsFindings());
  };

  return (
    <div className="findings-page__action-bar">
      <div className="findings-page__action-bar-top">
        <Search />
        <AddFilterButton filtersType="findings" setFilter={setFindingsFilter} />
      </div>
      <div className="findings-page__action-bar-bottom">
        <ActiveFilters />
      </div>
    </div>
  );
};

export default ActionsBar;
