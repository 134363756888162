import React, { useMemo, useState } from 'react';
import { Popover } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import Button from 'ui/Button';
import Icon from 'ui/Icon';
import useLinkedQuestions from 'pages/Editor/questions/useLinkedQuestions';
import Divider from 'ui/Divider';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Editor/messages';
import { selectSubject } from 'pages/Editor/reducers';

// STYLES
import './linkedQuestions.scss';

const LinkedQuestions = ({
  activeControlAnswerType,
  setActiveQuestion,
  defaultControl: currentControl,
}) => {
  const linkedQuestions = useLinkedQuestions(activeControlAnswerType.id);
  const [showLinkedQuestions, setShowLinkedQuestions] = useState(false);
  const dispatch = useDispatch();

  const onClickLinkedQuestions = () => {
    setShowLinkedQuestions((state) => !state);
  };

  const onClickLinkedQuestion = (subjectId, controlId) => {
    dispatch(selectSubject(subjectId));
    setActiveQuestion(controlId);
  };

  const linkedQuestionsTitle = () => {
    return (
      <div className="linked-questions__popover-title">
        <div className="linked-questions__popover-title-text">
          <Icon icon="linked-question-mark" className="linked-questions__icon" />
          <div>{translate(messages.linkedQuestionsTitle)}</div>
        </div>
        <Button
          data-test="linked-questions-button-close"
          link
          color="gray"
          onClick={onClickLinkedQuestions}
          afterIcon="close"
          className="linked-questions__icon-close"
        />
      </div>
    );
  };

  const linkedQuestionsContent = useMemo(() => {
    const filteredLinkedQuestions = linkedQuestions.filter(
      (linkedQuestion) => linkedQuestion.id !== currentControl.id,
    );
    return (
      <div className="linked-questions__popover-content">
        <div className="linked-questions__popover-content-title">
          {translate(messages.linkedQuestionQuestionName)}
        </div>
        <div>
          {filteredLinkedQuestions.map((linkedQuestion) => {
            return (
              <div
                key={linkedQuestion.id}
                className="linked-questions__popover-content-linked-question"
                onClick={() => onClickLinkedQuestion(linkedQuestion.categoryId, linkedQuestion.id)}
              >
                <div className="linked-questions__popover-content-list-title">
                  <span>{linkedQuestion.indexKey}</span> {linkedQuestion.title}
                </div>
                <div>
                  <Icon icon="go-to-file" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [linkedQuestions]);

  return (
    <Popover
      color="white"
      className="linked-questions__popover"
      open={showLinkedQuestions}
      title={linkedQuestionsTitle}
      content={linkedQuestionsContent}
    >
      {linkedQuestions.length > 1 && (
        <div>
          <Divider vertical size="xxs" />
          <Button
            className="linked-questions__button"
            data-test="linked-questions-button-open"
            link
            color="blue"
            onClick={onClickLinkedQuestions}
            style={{ marginLeft: '15px' }}
          >
            <Icon icon="linked-question-mark" className="linked-questions__icon" />{' '}
            {translate(messages.linkedQuestionsView)}
          </Button>
        </div>
      )}
    </Popover>
  );
};

LinkedQuestions.propTypes = {
  activeControlAnswerType: PropTypes.object,
  setActiveQuestion: PropTypes.func,
  defaultControl: PropTypes.object,
};

export default LinkedQuestions;
