import React, { useEffect, useMemo } from 'react';
import { Button, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'ui/Icon';
import { translate } from 'utils/index';
import ROUTES, { ROUTES_TAB } from 'constants/routes';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { clearStore, setIsMyVendorsTab } from 'pages/App/reducers';
import { selectIsMyVendorsTabSelected } from 'pages/App/selectors';
import { selectProfile } from 'pages/Profile/selectors';
import myVendorsIcon from 'assets/images/vendor.svg';
import myCustomersIcon from 'assets/images/customer.svg';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import './myVendorsMyCustomersSwitcher.scss';

const MyVendorsMyCustomersSwitcher = () => {
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const isMyVendorsTab = useSelector(selectIsMyVendorsTabSelected);
  const {
    user: {
      current_organization: { label: organizationLabel },
    },
  } = useSelector(selectProfile);
  const { isBoSoRole } = useRole();
  const appNavigate = useAppNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMyVendorsTabSelected !== isMyVendorsTab) {
      dispatch(setIsMyVendorsTab(isMyVendorsTabSelected));
    }
  }, [isMyVendorsTabSelected, isMyVendorsTab]);

  const handleMenuClick = (event) => {
    if (event.key === ROUTES_TAB.VENDOR && !isMyVendorsTab) {
      dispatch(clearStore());
      return appNavigate(ROUTES.VENDOR_RECENT);
    }

    if (event.key === ROUTES_TAB.CUSTOMER && isMyVendorsTab) {
      dispatch(clearStore());
      return appNavigate(ROUTES.CUSTOMER_RECENT);
    }

    return null;
  };

  const menuItems = useMemo(
    () => [
      {
        key: ROUTES_TAB.VENDOR,
        icon: <img src={myVendorsIcon} alt={translate(messages.myVendors)} />,
        label: translate(messages.myVendors),
        'data-test': 'navigate-to-my-vendors',
      },
      {
        key: ROUTES_TAB.CUSTOMER,
        icon: <img src={myCustomersIcon} alt={translate(messages.myCustomers)} />,
        label: translate(messages.myCustomers),
        'data-test': 'navigate-to-my-customers',
      },
    ],
    [],
  );

  const dropdown = useMemo(
    () => (
      <Dropdown
        menu={{ items: menuItems, onClick: handleMenuClick }}
        trigger="click"
        overlayClassName="base-layout-my-vendors-my-customers-switcher__dropdown-menu"
        disabled={isBoSoRole}
      >
        {isMyVendorsTab ? (
          <Button type="link" className="base-layout-my-vendors-my-customers-switcher__button">
            <img
              className="base-layout-my-vendors-my-customers-switcher__button-ico"
              src={myVendorsIcon}
              alt={translate(messages.myVendors)}
            />
            <span className="base-layout-my-vendors-my-customers-switcher__button-text">
              {translate(messages.myVendors)}
              {organizationLabel && (
                <span className="base-layout-my-vendors-my-customers-switcher__organization-name">
                  {' '}
                  ({organizationLabel}){' '}
                </span>
              )}
            </span>
            <Icon
              className="base-layout-my-vendors-my-customers-switcher__button-arrow"
              icon="arrow-down"
            />
          </Button>
        ) : (
          <Button type="link" className="base-layout-my-vendors-my-customers-switcher__button">
            <img
              className="base-layout-my-vendors-my-customers-switcher__button-ico"
              src={myCustomersIcon}
              alt={translate(messages.myCustomers)}
            />
            <span className="base-layout-my-vendors-my-customers-switcher__button-text">
              {translate(messages.myCustomers)}
              {organizationLabel && (
                <span className="base-layout-my-vendors-my-customers-switcher__organization-name">
                  {' '}
                  ({organizationLabel}){' '}
                </span>
              )}
            </span>
            <Icon
              className="base-layout-my-vendors-my-customers-switcher__button-arrow"
              icon="arrow-down"
            />
          </Button>
        )}
      </Dropdown>
    ),
    [isMyVendorsTab],
  );

  return <div className="base-layout-my-vendors-my-customers-switcher">{dropdown}</div>;
};

export default MyVendorsMyCustomersSwitcher;
