import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import ControlComponent from 'components/ControlComponent';
import EvidenceMetadataEditor from 'components/EvidenceMetadataEditor';

// REDUX
import { selectAnswerTypes, selectCategory, selectIsAssessmentDisabled } from './selectors';
import { removeControlFile, uploadControlFiles } from 'api/assessment';

// UTILS
import { getAnswerType } from 'utils/controls';
import { messages } from 'pages/Assessment/messages';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';
import { translate } from 'utils/index';
import { API_STATUS } from 'constants/api';
import { selectCurrentOrganization } from 'pages/Profile/selectors';

// CONSTANTS
import { ControlType } from 'constants/controlType';

const ControlAnswerEdit = ({
  categoryId,
  control = {},
  className,
  disabled,
  forceEdit,
  onAnswerChange,
  fromFindings,
  customAnswerTypes,
  customPayload,
}) => {
  const [fileLoading, setFileLoading] = useState();
  const [selectedControlFile, selectControlFile] = useState();
  const answerTypes = useSelector(selectAnswerTypes);
  const category = useSelector(selectCategory);
  const dispatch = useDispatch();
  const { showCasePreview, customerId } = useSelector((state) => state.assessment);
  const { id: organizationId } = useSelector(selectCurrentOrganization);
  const isAssessmentDisabled = useSelector(selectIsAssessmentDisabled) || showCasePreview;
  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();

  const {
    id,
    answer_type_idx: answerTypeIdx,
    answer,
    app_trigger: appTrigger,
    readonly,
    suggest,
  } = control;
  const answerType = getAnswerType(answerTypeIdx, answerTypes || customAnswerTypes);

  const handleUpload = async (files) => {
    const payload = {
      files,
      controlId: id,
      categoryId: categoryId || category.id,
      abortController,
    };

    setFileLoading(true);
    startUploading();
    const linkFilesResp = await dispatch(uploadControlFiles(payload)).unwrap();
    finishUploding();
    if (linkFilesResp?.status === API_STATUS.SUCCESS) {
      const value = answer ? [...answer, ...files] : files;
      onAnswerChange(value, id);
    }
    setFileLoading(false);
  };

  const handleRemove = async (fileId) => {
    setFileLoading(true);
    await dispatch(removeControlFile({ fileId, controlId: id })).unwrap();
    setFileLoading(false);
  };

  const data = {
    payload: {
      options: (answerType.options || []).map((option) => ({
        key: option.key,
        value: option.key,
        label: option.value,
      })),
      name: `control-${id}`,
      [answerType.type === ControlType.RadioGroup ? 'onClick' : 'onChange']: (value) =>
        onAnswerChange(value, id),
      value: answer,
      disabled: forceEdit ? false : disabled || readonly || isAssessmentDisabled,
      suggest,
      controlId: id,
      categoryId,
      appTrigger,
    },
  };

  if (answerType.type === ControlType.DropDown || answerType.type === ControlType.MultiSelect) {
    data.payload.getPopupContainer = () => {
      const controlWrapper = () => document.querySelector('.assessment-controls-control__wrapper');
      const findingWrapper = () =>
        document.querySelector('.assessment-finalized-finding__control-wrapper');

      return fromFindings ? findingWrapper() : controlWrapper();
    };
  }

  if (answerType.type === ControlType.FileUpload) {
    data.payload.handleUpload = handleUpload;
    data.payload.handleRemove = handleRemove;
    data.payload.uploadedFiles = answer;
    data.payload.loading = fileLoading;
    data.payload.edit = selectControlFile;
    data.payload.browse = true;
  }

  if (answerType.type === ControlType.Input || answerType.type === ControlType.TextArea) {
    data.payload.placeholder = translate(messages.questionInputPlaceholder);

    if (appTrigger) {
      data.payload.onChange = () => {};
      data.payload.onBlur = (event) => {
        onAnswerChange(event.target.value, id);
      };
    }
  }

  return (
    <>
      <ControlComponent
        type={answerType.type}
        className={className}
        data={{ payload: { ...data.payload, ...customPayload } }}
      />
      {selectedControlFile && (
        <EvidenceMetadataEditor
          file={selectedControlFile}
          onHide={() => selectControlFile(null)}
          open={!!selectedControlFile}
          relation={customerId || organizationId}
        />
      )}
      {renderConfirmationModal()}
    </>
  );
};

ControlAnswerEdit.propTypes = {
  categoryId: PropTypes.string,
  control: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  forceEdit: PropTypes.bool,
  onAnswerChange: PropTypes.func,
  fromFindings: PropTypes.bool,
  customAnswerTypes: PropTypes.object,
  customPayload: PropTypes.object,
};

export default ControlAnswerEdit;
