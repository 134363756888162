import { useSelector } from 'react-redux';

// HOOKS
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

// REDUX
import { selectActiveFilters } from 'components/Messenger/selectors';
import { selectFilters } from 'components/AssessmentsFilters/selectors';
import { selectFindingsActiveFilters } from 'pages/Findings/selectors';

// CONSTANTS
import {
  assessmentAvailableFilters,
  findingsAvailableFilters,
  messagesAvailableFilters,
  myFilesAvailableFilters,
} from 'components/AddFilterButton/lib';
import { selectIsViewAggregated } from 'pages/Organization/selectors';

export function useAvailableFilters(filtersType) {
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const messagesFilters = useSelector(selectActiveFilters);
  const assessmentFilters = useSelector(selectFilters);
  const findingsFilters = useSelector(selectFindingsActiveFilters);
  const isViewAggregated = useSelector(selectIsViewAggregated);
  const myFilesFilters = useSelector((state) => {
    return state.myFiles.filters;
  });

  const filterFunction = (availableFilters, reduxState) => {
    return availableFilters(isMyVendorsTabSelected, isViewAggregated).filter(
      (availableFilter) =>
        !reduxState.some((selectedFilter) => selectedFilter.type === availableFilter.type),
    );
  };

  switch (filtersType) {
    case 'assessment':
      return filterFunction(assessmentAvailableFilters, assessmentFilters);
    case 'messages':
      return filterFunction(messagesAvailableFilters, messagesFilters);
    case 'findings':
      return filterFunction(findingsAvailableFilters, findingsFilters);
    case 'myFiles':
      return filterFunction(myFilesAvailableFilters, myFilesFilters);
    default:
      return [];
  }
}
