import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';

import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { translate } from 'utils/index';
import ROUTES from 'constants/routes';
import { MixpanelAssignAssessmentSource } from 'constants/mixpanel';
import { getProfileVendors, setVendorEnableStatus } from 'api/vendor';
import { getProfileCustomers } from 'api/customers';
import { setCustomerStatus } from 'api/customer';
import { getRecentVendors } from 'api/recent';
import Icon from 'ui/Icon';
import { messages } from 'pages/Recent/messages';
import { useRole } from 'hooks/useRole';

import './actions.scss';

const Actions = ({ vendor, isVendorsList, isCustomersList, source, setVendorToRemove }) => {
  const appNavigate = useAppNavigate();
  const { isViewerRole, isRestrictedBoSoViewerRole } = useRole();

  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const onEdit = (event) => {
    event.stopPropagation();
    appNavigate(isMyVendorsTabSelected ? ROUTES.VENDOR_EDIT : ROUTES.CUSTOMER_EDIT_CUSTOMER, {
      [isMyVendorsTabSelected ? 'vendorId' : 'customerId']: vendor.id,
    });
  };

  const onAssign = (event) => {
    event.stopPropagation();
    appNavigate(
      isMyVendorsTabSelected ? ROUTES.VENDOR_ASSIGN_ASSESSMENT : ROUTES.CUSTOMER_ASSIGN_ASSESSMENT,
      {},
      { state: { customerId: vendor.id, source } },
    );
  };

  const onSetVendorStatus = async (event) => {
    event.stopPropagation();
    isMyVendorsTabSelected
      ? await dispatch(setVendorEnableStatus({ id: vendor.id, isActive: !vendor.enabled })).unwrap()
      : await dispatch(setCustomerStatus({ id: vendor.id, isActive: !vendor.enabled })).unwrap();

    if (isVendorsList && isMyVendorsTabSelected) {
      await dispatch(getProfileVendors()).unwrap();
    } else if (isCustomersList) {
      await dispatch(getProfileCustomers()).unwrap();
    } else {
      await dispatch(getRecentVendors()).unwrap();
    }
  };

  return (
    <div className="recent-page-vendors-actions">
      <Tooltip title={translate(messages.edit)}>
        <Button
          className="recent-page-vendors-actions__action"
          disabled={isViewerRole}
          type="link"
          onClick={onEdit}
        >
          <Icon icon="edit" />
        </Button>
      </Tooltip>

      <Tooltip title={translate(messages.assignAssessment)}>
        <Button
          className="recent-page-vendors-actions__action"
          disabled={isRestrictedBoSoViewerRole}
          type="link"
          onClick={onAssign}
        >
          <Icon icon="add" />
        </Button>
      </Tooltip>

      {vendor.enabled ? (
        <Tooltip title={translate(messages.deactivate)}>
          <Button
            className="recent-page-vendors-actions__action"
            disabled={isRestrictedBoSoViewerRole}
            type="link"
            onClick={onSetVendorStatus}
          >
            <Icon icon="disabled" />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title={translate(messages.activate)}>
          <Button
            className="recent-page-vendors-actions__action"
            disabled={isRestrictedBoSoViewerRole}
            type="link"
            onClick={onSetVendorStatus}
          >
            <Icon icon="check" className="recent-page-vendors-actions__activate-icon" />
          </Button>
        </Tooltip>
      )}

      <Tooltip title={translate(messages.remove)}>
        <Button
          className="recent-page-vendors-actions__action"
          disabled={isRestrictedBoSoViewerRole}
          type="link"
          onClick={() => setVendorToRemove(vendor)}
        >
          <Icon icon="remove" />
        </Button>
      </Tooltip>
    </div>
  );
};

Actions.propTypes = {
  vendor: PropTypes.object,
  isVendorsList: PropTypes.bool,
  isCustomersList: PropTypes.bool,
  source: PropTypes.oneOf(Object.keys(MixpanelAssignAssessmentSource)),
  setVendorToRemove: PropTypes.func,
};

export default Actions;
