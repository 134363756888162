import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'ui/Icon';
import Modal from 'ui/Modal';
import { translate } from 'utils/index';
import Trash from 'assets/svgs/trash.svg';
import { saveRecommendation } from 'api/recommendation';

import { selectFinding } from 'pages/Assessment/finalized/findings/selectors';
import { messages } from './messages';
import './removeRecommendation.scss';

const RemoveRecommendation = ({ disabled, id }) => {
  const dispatch = useDispatch();
  const [popupOpened, setPopupOpened] = useState(false);
  const { control, id: findingId } = useSelector(selectFinding);

  const onRemove = () => {
    const payload = {
      control,
      findingId,
      recommendation: {
        id,
      },
    };

    setPopupOpened(false);
    dispatch(saveRecommendation(payload));
  };

  return (
    <div>
      <Tooltip title={translate(messages.removeRecommendation)}>
        <Button
          className="assessment-finalized-remove-recommendation"
          disabled={disabled}
          type="link"
          onClick={() => setPopupOpened(true)}
        >
          <Icon icon="remove" />
        </Button>
      </Tooltip>
      <Modal
        className="assessment-finalized-remove-recommendation__confirm-modal"
        open={popupOpened}
        onOk={onRemove}
        onCancel={() => setPopupOpened(false)}
        width={460}
      >
        <img
          className="assessment-finalized-remove-recommendation__confirm-modal-trash"
          src={Trash}
          alt="Trash"
        />
        <br />
        {translate(messages.removeRecommendationPopup)}
      </Modal>
    </div>
  );
};

RemoveRecommendation.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default RemoveRecommendation;
