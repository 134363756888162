import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import { translate } from 'utils/index';
import { selectOrganizations } from 'pages/Profile/selectors';
import { messages } from 'pages/Customers/CustomerList/messages';
import Filter from './filter';

const { Option } = Select;

const FilterParentOrganization = ({ fetchCustomers, filter }) => {
  const organizations = useSelector(selectOrganizations);

  return (
    <Filter
      filter={filter}
      fetchCustomers={fetchCustomers}
      innerLabel={translate(messages.filterParentOrganization)}
    >
      {organizations.map(({ id, label }) => (
        <Option key={id} id={id} value={label}>
          {label}
        </Option>
      ))}
    </Filter>
  );
};

FilterParentOrganization.propTypes = {
  fetchCustomers: PropTypes.func,
  filter: PropTypes.object,
};

export default FilterParentOrganization;
