import { useParams } from 'react-router-dom';
import { useLocation, useNavigate, generatePath, resolvePath, matchPath } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import ROUTES, { OLD_ROUTES, ROUTES_TAB } from 'constants/routes';
import { DEFAULT_ORGANIZATION_INDEX } from 'utils/organizations';

import { selectUploadInProgress } from 'components/FileUploadIndicator/selectors';
import { setNavigateTo } from 'components/FileUploadIndicator/reducers';

export function useAppNavigate() {
  const getPath = useGetPath();
  const navigate = useNavigate();
  const uploadInProgress = useSelector(selectUploadInProgress);
  const dispatch = useDispatch();

  function pathCreator(routePath, passedParams, searchParams) {
    let path = getPath(routePath, passedParams);
    if (searchParams && Object.keys(searchParams)?.length) {
      path = `${path}?${new URLSearchParams(searchParams).toString()}`;
    }
    return path;
  }

  if (uploadInProgress) {
    return (routePath, passedParams, args, newTab = false, searchParams) => {
      const path = pathCreator(routePath, passedParams, searchParams);
      dispatch(setNavigateTo({ path, args, newTab }));
    };
  } else {
    return (routePath, passedParams, args, newTab = false, searchParams) => {
      const path = pathCreator(routePath, passedParams, searchParams);
      return newTab ? window.open(path, '_blank') : navigate(path, args);
    };
  }
}

export function useGetPath() {
  const routerParams = useParams();

  return (routePath, passedParams) => {
    const organizationIndex =
      passedParams?.organization || routerParams.organization || DEFAULT_ORGANIZATION_INDEX;

    const params = passedParams
      ? { organization: organizationIndex, ...passedParams }
      : { organization: organizationIndex };
    return generatePath(routePath, params);
  };
}

export function useSelectedPath() {
  const location = useLocation();
  const selectedRoute = Object.entries({ ...ROUTES, ...OLD_ROUTES }).find(([, path]) => {
    const resolvedPath = resolvePath(path);
    const match = matchPath({ path: resolvedPath.pathname, end: true }, location.pathname);

    return !!match;
  });

  if (!selectedRoute) {
    return null;
  }

  const [, selectedPath] = selectedRoute;

  return selectedPath;
}

export function usePathname() {
  const location = useLocation();
  return location.pathname;
}

export function useIsMyVendorsTabSelected() {
  const pathName = usePathname();
  if (!pathName) {
    return null;
  }
  return pathName.includes(ROUTES_TAB.VENDOR);
}

export function useSelectedMenuItems() {
  const selectedPath = useSelectedPath();
  const selectedMenuItems = [selectedPath];

  if (selectedPath.includes(ROUTES.VENDOR_VENDORS)) {
    selectedMenuItems.push(ROUTES.VENDOR_VENDORS);
  }

  if (selectedPath.includes(ROUTES.CUSTOMER_CUSTOMERS)) {
    selectedMenuItems.push(ROUTES.CUSTOMER_CUSTOMERS);
  }

  if (selectedPath.includes(ROUTES.VENDOR_ASSESSMENTS)) {
    selectedMenuItems.push(ROUTES.VENDOR_ASSESSMENTS);
  }

  if (selectedPath.includes(ROUTES.CUSTOMER_ASSESSMENTS)) {
    selectedMenuItems.push(ROUTES.CUSTOMER_ASSESSMENTS);
  }
  if (selectedPath.includes(ROUTES.CUSTOMER_SHOWCASES)) {
    selectedMenuItems.push(ROUTES.CUSTOMER_SHOWCASES);
  }

  return selectedMenuItems;
}
