import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'ui/Icon';
import Spin from 'ui/Spin';
import './index.scss';

const SecondaryButton = ({
  children,
  className,
  counter,
  color,
  dashed,
  disabled,
  fullWidth,
  icon,
  link,
  loading,
  onClick,
  outline,
  size,
  type,
  tooltip,
  uppercase,
  iconComponent,
  iconComponentWidth,
  ...otherProps
}) => {
  const buttonRef = useRef(null);
  useEffect(() => {
    if (!tooltip) {
      return false;
    }

    const tooltipElement = buttonRef.current.querySelector('.ui-secondary-button__tooltip');
    const width = iconComponentWidth
      ? iconComponentWidth + 10 + tooltipElement.clientWidth
      : buttonRef.current.clientWidth;
    const iconWidth = iconComponentWidth || width - tooltipElement.clientWidth - 4;

    const handleMousover = () => {
      buttonRef.current.style.width = `${width}px`;
    };
    const handleMouseleave = () => {
      buttonRef.current.style.width = `${iconWidth}px`;
    };

    setTimeout(() => {
      buttonRef.current.style.width = `${iconWidth}px`;

      buttonRef.current.addEventListener('mouseover', handleMousover);
      buttonRef.current.addEventListener('mouseleave', handleMouseleave);
    });

    return () => {
      if (!buttonRef || !buttonRef.current) {
        return false;
      }
      buttonRef.current.removeEventListener('mouseover', handleMousover);
      buttonRef.current.removeEventListener('mouseleave', handleMouseleave);
    };
  }, [tooltip]);

  const buttonClassName = classNames('ui-secondary-button', className, {
    'ui-secondary-button--full-width': fullWidth,
    'ui-secondary-button--dashed': dashed,
    'ui-secondary-button--link': link,
    'ui-secondary-button--outline': outline,
    'ui-secondary-button--uppercase': uppercase,
    'ui-secondary-button--tooltip': tooltip,
    [`ui-secondary-button--color-${color}`]: color,
    [`ui-secondary-button--size-${size}`]: size,
  });

  const renderIcon = () => {
    if (typeof icon !== 'string') {
      return icon;
    }

    return counter ? (
      <Icon
        className="ui-secondary-button__icon ui-secondary-button__icon--with-counter"
        data-counter={counter}
        icon={icon}
      />
    ) : (
      <Icon className="ui-secondary-button__icon" icon={icon} />
    );
  };

  return (
    <button
      ref={buttonRef}
      type={type || 'button'}
      className={buttonClassName}
      disabled={disabled || loading}
      onClick={onClick}
      {...otherProps}
    >
      {loading && <Spin className="ui-secondary-button__loader" />}
      {icon && !loading && renderIcon()}
      {!loading && iconComponent}
      {children}
      {tooltip && <span className="ui-secondary-button__tooltip">{tooltip}</span>}
    </button>
  );
};

SecondaryButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'purple']),
  counter: PropTypes.number,
  dashed: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  link: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.string,
  tooltip: PropTypes.string,
  uppercase: PropTypes.bool,
  iconComponent: PropTypes.node,
  iconComponentWidth: PropTypes.number,
};

export default SecondaryButton;
