import axios from 'axios';
import {
  SetAssessment,
  GetNewProfileFindings,
  GetProfileSubjectsFindings,
  AppsFilters,
} from 'api/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthenticationToken, ORG_ID_AUTH_KEY } from 'utils/auth';
import { getCookie } from 'utils/apiHelpers';
import { getSummaryCustomersRequest } from 'api/summary';

const saveFindingRequest = async ({ control, finding }, { getState }) => {
  const { assessment } = getState();

  const data = {
    _assessment: {
      categories: [
        {
          id: control.categoryId,
          controls: [
            {
              id: control.id,
              findings: [finding],
            },
          ],
        },
      ],
    },
  };

  const payload = {
    'set-assessment-assessment-token': assessment.assessment.id,
    'set-assessment-_assessment-json': JSON.stringify(data),
  };
  return axios.post(SetAssessment.url, payload, { withCredentials: true });
};

export const saveFinding = createAsyncThunk('saveFinding', saveFindingRequest);

const getProfileFindingsRequest = async (args, { getState }) => {
  const {
    findingsPage: { pagination: paginationRedux, count, search, filters, sorter },
    app: { isMyVendorsTabSelected },
  } = getState();

  const { pagination } = args;

  const token = getAuthenticationToken();
  const orgId = getCookie(ORG_ID_AUTH_KEY);

  const payload = {
    count,
    upstream: !isMyVendorsTabSelected,
    search: search || '',
    offset: pagination?.current || paginationRedux?.current,
    descending: sorter?.descending || false,
    token,
    org_id: orgId,
  };

  if (sorter.column) {
    payload.column = sorter.column;
  }

  if (filters) {
    filters.map((filter) => {
      let filterType = filter.type;
      if (filterType === 'vendor') filterType = 'customer';
      if (filterType === 'assessmentName') filterType = 'assessment';
      payload[filterType] = filter.type === 'subject' ? filter.value : filter.id;
      return null;
    });
  }

  return axios.get(GetNewProfileFindings.url, {
    params: payload,
    withCredentials: true });
};

export const getProfileFindings = createAsyncThunk('getProfileFindings', getProfileFindingsRequest);

export const getAllOrganizations = createAsyncThunk(
  'getAllOrganizations',
  getSummaryCustomersRequest,
);

export const getProfileSubjectsFindingsRequest = async () => {
  const token = getAuthenticationToken();
  const orgId = getCookie(ORG_ID_AUTH_KEY);

  const payload = {
    token,
    org_id: orgId,
  };

  return axios.get(GetProfileSubjectsFindings.url, {
    params: payload,
    withCredentials: true });
};

export const getProfileSubjectsFindings = createAsyncThunk(
  'getProfileSubjectsFindings',
  getProfileSubjectsFindingsRequest,
);

export const getAppsFiltersRequest = async (appId) => {
  const token = getAuthenticationToken();
  const orgId = getCookie(ORG_ID_AUTH_KEY);

  const payload = {
    token,
    org_id: orgId,
    'app-id': appId,
  };

  return axios.get(AppsFilters.url, {
    params: payload,
    withCredentials: true });
};

export const getAppsFilters = createAsyncThunk('getAppsFilters', getAppsFiltersRequest);
