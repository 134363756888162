import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';

import Icon from 'ui/Icon';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import { translate } from 'utils/index';
import { saveTemplateRecommendation } from 'api/editor';

import { messages } from './messages';
import './removeRecommendation.scss';

const RemoveRecommendation = ({ control, finding, id, onRemove, enableRemove }) => {
  const dispatch = useDispatch();
  const [popupOpened, setPopupOpened] = useState(false);
  const [loading, setLoading] = useState();

  const onRemoveRecommendation = async () => {
    setLoading(true);
    const payload = {
      control,
      finding,
      recommendation: {
        id,
      },
    };

    await dispatch(saveTemplateRecommendation(payload)).unwrap();
    onRemove();
    setLoading(false);
    setPopupOpened(false);
  };

  return (
    <div>
      <Tooltip title={translate(messages.removeRecommendation)}>
        <Button
          className="assessment-remove-recommendation"
          type="link"
          onClick={() => setPopupOpened(true)}
        >
          <Icon icon="remove" />
        </Button>
      </Tooltip>
      <RemoveConfirmationModal
        confirmLoading={loading}
        onOk={enableRemove ? onRemoveRecommendation : onRemove}
        onCancel={() => setPopupOpened(false)}
        text={translate(messages.removeRecommendationPopup)}
        open={!!popupOpened}
      />
    </div>
  );
};

RemoveRecommendation.propTypes = {
  control: PropTypes.object,
  finding: PropTypes.object,
  id: PropTypes.string,
  onRemove: PropTypes.func,
  enableRemove: PropTypes.bool,
};

export default RemoveRecommendation;
