import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  GetProfile,
  SetNewProfilePassword,
  SetProfilePassword,
  SetProfileMfa,
  TestProfileOtp,
  SetProfile,
  SetProfileLogo,
  GetOnboardingProfile,
  SetProfileMfaOnSession,
  SendMfaViaEmail,
} from './endpoints';

export const setProfilePassword = async (newPassword, currentPassword) => {
  const payload = {
    'set-password-current': currentPassword,
    'set-password-new': newPassword,
  };

  return axios.post(SetProfilePassword.url, payload, { withCredentials: true });
};

export const setNewProfilePassword = async ({ password, token }) => {
  const payload = {
    'set-new-password': password,
    token,
  };

  return axios.post(SetNewProfilePassword.url, payload, { withCredentials: true });
};

export const getProfileRequest = async ({ token, upstream, org_id }) => {
  return axios.post(GetProfile.url, { token, upstream, org_id }, { withCredentials: true });
};

export const getOnboardingProfile = async ({ token, org_id }) => {
  return axios.post(GetOnboardingProfile.url, { token, org_id }, { key: GetOnboardingProfile.key, withCredentials: true });
};

export const setProfileMfa = async (token, type) => {
  const data = {
    token,
    type,
  };

  return axios.post(SetProfileMfa.url, data, { withCredentials: true });
};

export const testProfileOtp = async (token, code) => {
  const data = {
    token,
  };

  if (code) {
    data['test-profile-otp-code'] = code;
  }

  return axios.post(TestProfileOtp.url, data, { withCredentials: true });
};

export const setProfileRequest = async ({ user, token, isOnBoarding }) => {
  const data = {
    token,
    'set-fullname': user.fullname,
    'set-email': user.email,
    'set-lang': user.lang,
    'set-phone': user.phone,
    // passing a 0 value prevents from backend errors and makes corporateGeography non required from frontend perspective
    'set-corporateGeography': user.corporateGeography || 0,
  };

  if (isOnBoarding) {
    data.onboarding = true;
  }

  // Safety blocks - prevent from passing undefined to backend
  if (user.corporateLogo) {
    data['set-fileToUpload'] = user.corporateLogo;
  }

  if (user.corporateName) {
    data['set-corporateName'] = user.corporateName;
  }
  if (user.mfaMethod) {
    data['mfa-method'] = user.mfaMethod;
  }

  return axios.post(SetProfile.url, data, { withCredentials: true });
};

export const setProfile = createAsyncThunk('setProfile', setProfileRequest);

export const getProfile = createAsyncThunk('getProfile', getProfileRequest);

export const setProfileLogoRequest = async ({ file, token }) => {
  const data = {
    'set-logo-corporate-logo': file,
  };

  const response = await axios.post(SetProfileLogo.url, data, {
    forcedAuthorization: { token },
    withCredentials: true,
  });
  return { ...response };
};

export const setProfileLogo = createAsyncThunk('setProfileLogo', setProfileLogoRequest);

export const setProfileMfaOnSessionRequest = async (payload) => {
  return axios.post(SetProfileMfaOnSession.url, payload, { withCredentials: true });
};

export const setProfileMfaOnSession = createAsyncThunk(
  'setProfileMfaOnSession',
  setProfileMfaOnSessionRequest,
);

export const sendMfaViaEmailRequest = async (payload) => {
  return axios.post(SendMfaViaEmail.url, payload, { withCredentials: true });
};

export const sendMfaViaEmail = createAsyncThunk('sendMfaViaEmail', sendMfaViaEmailRequest);
