import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigationType } from 'react-router';

import PageSubheader from 'components/PageSubheader';
import { getRecentFindings } from 'api/recent';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { createSorterByString } from 'utils/strings';
import { translate } from 'utils/index';
import Table from 'ui/Table';
import Button from 'ui/Button';
import ROUTES from 'constants/routes';
import Tag from 'ui/Tag';
import { selectIsViewAggregated } from 'pages/Organization/selectors';
import SbomIcon from 'assets/svgs/sbom-icon.svg';
import StrengthenAuth from 'components/StrengthenAuth';

import { selectFinding } from 'pages/Assessment/finalized/findings/reducers';
import { FindingsRisk } from 'constants/findingsRisk';
import { Sorter } from 'ui/Table/sorter';

import Risk from './findingsColumns/risk';
import Status from './findingsColumns/status';
import { setFindingsSorter } from './reducers';
import { messages } from './messages';
import { TableSource } from 'ui/Table/lib';
import { getAppIconById, shouldAppNavigateToContinuousMonitoring } from 'utils/appsUtils';
import { Apps } from 'constants/apps';
import { mixpanelTrackFindingViewed } from 'utils/mixpanel';

import './findings.scss';

const Findings = () => {
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const navigationType = useNavigationType();
  const { findings, findingsLoading, findingsSorter } = useSelector((state) => state.recent);
  const isViewAggregated = useSelector(selectIsViewAggregated);

  useEffect(() => {
    if (navigationType !== 'POP') {
      dispatch(setFindingsSorter());
    }
  }, []);

  const sortOrder = findingsSorter.descending ? 'descend' : 'ascend';

  const columns = [
    {
      title: translate(isMyVendorsTabSelected ? messages.vendor : messages.customer),
      dataIndex: 'corporateName',
      sorter: createSorterByString('corporateName'),
      sortOrder: findingsSorter.column === 'corporateName' ? sortOrder : null,
    },
    {
      title: translate(messages.assessment),
      dataIndex: 'assessmentName',
      render: (_, finding) => {
        return (
          <div className="findings-table-assessment-container">
            {finding.app && shouldAppNavigateToContinuousMonitoring(finding.app) ? (
              <Tag
                icon={<img src={getAppIconById(finding.app)} alt="" />}
                name={finding.assessmentName}
                color="blue"
              />
            ) : (
              <Tag name={finding.assessmentName} color="blue" />
            )}
          </div>
        );
      },
      sorter: createSorterByString('assessmentName'),
      sortOrder: findingsSorter.column === 'assessmentName' ? sortOrder : null,
    },
    {
      title: translate(messages.subject),
      dataIndex: 'subject',
      render: (_, finding) => {
        return (
          <span className="finding-subject">
            {finding.app === Apps.sbom ? <img src={SbomIcon} alt="sbom icon" /> : null}
            {finding.subject}
          </span>
        );
      },
      sorter: createSorterByString('subject'),
      sortOrder: findingsSorter.column === 'subject' ? sortOrder : null,
    },
    {
      title: translate(messages.description),
      dataIndex: 'findingDesc',
      render: (description) => {
        const text =
          description?.length > 130 ? `${description.substring(0, 130)}...` : description;
        return description ? (
          <div className="findings-page__list-description-column">{text}</div>
        ) : (
          translate(messages.noDescription)
        );
      },
      sorter: createSorterByString('findingDesc'),
      sortOrder: findingsSorter.column === 'findingDesc' ? sortOrder : null,
    },
    {
      title: translate(messages.risk),
      dataIndex: 'risk',
      render: (risk) => <Risk risk={FindingsRisk.getRiskValue(risk).toString()} />,
      sorter: Sorter.RISK,
      sortOrder: findingsSorter.column === 'risk' ? sortOrder : null,
    },
    {
      title: translate(messages.status),
      dataIndex: 'status',
      width: 140,
      render: (status, finding) => <Status status={status} finding={finding} />,
      sorter: (a, b) => a.status - b.status,
      sortOrder: findingsSorter.column === 'status' ? sortOrder : null,
    },
  ];

  if (isViewAggregated) {
    const column = {
      title: translate(messages.organization),
      dataIndex: 'parentOrganizationCorporateName',
      sorter: createSorterByString('parentOrganizationCorporateName'),
      sortOrder: findingsSorter.column === 'parentOrganizationCorporateName' ? sortOrder : null,
    };
    columns.splice(0, 0, column);
  }

  const onRow = ({ id: findingId, assessmentId, app, customerAssessmentGroup, relation }) => ({
    onClick: async () => {
      await dispatch(selectFinding(findingId));

      mixpanelTrackFindingViewed({
        relation: relation || 'none',
        source: 'findings table',
        streamDirection: isMyVendorsTabSelected ? 'downstream' : 'upstream',
      });

      if (customerAssessmentGroup) {
        return appNavigate(
          isMyVendorsTabSelected
            ? ROUTES.VENDOR_SHOWCASE_PREVIEW
            : ROUTES.CUSTOMER_SHOWCASE_PREVIEW,
          {
            showcaseId: customerAssessmentGroup,
          },
        );
      }

      if (shouldAppNavigateToContinuousMonitoring(app)) {
        return appNavigate(
          isMyVendorsTabSelected
            ? ROUTES.VENDOR_CONTINUOUS_MONITORING
            : ROUTES.CUSTOMER_CONTINUOUS_MONITORING,
          { assessmentId, appId: app },
        );
      }

      return appNavigate(
        isMyVendorsTabSelected
          ? ROUTES.VENDOR_ASSESSMENT_FINDINGS
          : ROUTES.CUSTOMER_ASSESSMENT_FINDINGS,
        { assessmentId },
        undefined,
        false,
        { findingId: findingId },
      );
    },
  });

  const dataSource = (findings || []).map((finding) => ({
    ...finding,
    key: finding.id,
  }));

  const onTableChange = (newPagination, filters, sorter) => {
    const sorterParams = {
      column: sorter.field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? sorterParams : {};
    dispatch(setFindingsSorter(params));
  };

  return (
    <div className="recent-page-findings">
      <div className="recent-page-findings__top">
        <PageSubheader className="recent-page-findings__page-subheader">
          {translate(messages.recentFindings)}:
        </PageSubheader>
        <div className="recent-page-findings__menu">
          <Button
            data-test="recent-page-findings-button-view-all"
            className="recent-page-findings__view-all-button"
            link
            color="black"
            size="xs"
            onClick={() =>
              appNavigate(
                isMyVendorsTabSelected ? ROUTES.VENDOR_FINDINGS : ROUTES.CUSTOMER_FINDINGS,
              )
            }
          >
            {translate(messages.viewAll)}
          </Button>
        </div>
      </div>
      <Table
        className="recent-page-findings__table"
        columns={columns}
        dataSource={dataSource}
        loading={findingsLoading}
        pagination={false}
        onChange={onTableChange}
        onRow={onRow}
        scroll={{
          y: 300,
          x: 1160,
        }}
        emptyStateSource={TableSource.findingsRecent}
      />
      <StrengthenAuth request={() => dispatch(getRecentFindings())} />
    </div>
  );
};

export default Findings;
