import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import Notification from 'components/Messenger/notification';
import Button from 'ui/Button';
import Loader from 'ui/Loader';
import ReadAll from 'components/Messenger/readAll';
import EmptyState from 'components/Messenger/emptyState';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/Messenger/messages';
import { DATE_FORMAT } from 'constants/date';
import { trimMessage } from 'components/Messenger/lib';
import { useRedirectToMessage } from 'components/Messenger/useRedirectToMessage';
import { getUnreadChatThreadsNotifications } from 'api/messenger';

// REDUX
import { selectUnreadMessages } from 'components/Messenger/selectors';
import { setParameter } from 'components/Messenger/reducers';
import { selectProfile } from 'pages/Profile/selectors';

// STYLE
import './notificationsList.scss';

const NotificationsList = ({ setPopupVisible, toggleMessagesDropdown }) => {
  const redirectToMessage = useRedirectToMessage();
  const { threads, totalThreads, offset, totalOffset } = useSelector(selectUnreadMessages);
  const {
    user: { corporateName },
  } = useSelector(selectProfile);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();
  const unreadCount = useMemo(() => (totalThreads < 100 ? totalThreads : '99+'), [totalThreads]);

  useEffect(() => {
    if (totalThreads === threads.length || offset === totalOffset) setHasMore(false);
  }, [totalThreads, offset, totalOffset]);

  const onClickNotification = (thread) => {
    return redirectToMessage({
      assessmentId: thread.assessmentId,
      categoryId: thread['category-id'],
      controlId: thread['control-id'],
      findingId: thread['finding-id'],
      recommendationId: thread['recommendation-id'],
      app: thread.app,
    });
  };

  const notificationsList = useMemo(() => {
    return threads.map((thread) => {
      const date = moment(thread?.timestampLastWrite).format(DATE_FORMAT);
      const message = trimMessage(thread.msg || '');
      const name = thread?.participants?.filter((participant) => participant !== corporateName);

      return (
        <Notification
          key={thread.id}
          nameOfParticipant={name[0]}
          onClick={() => onClickNotification(thread)}
          assessmentName={thread?.assessmentName}
          message={message}
          date={date}
        />
      );
    });
  }, [threads]);

  const fetchNextData = async (nextOffset) => {
    dispatch(setParameter({ key: 'offset', value: nextOffset }));
    if (nextOffset <= totalOffset) {
      await dispatch(getUnreadChatThreadsNotifications({ offset: nextOffset }))
        .unwrap()
        .then(() => {
          if (nextOffset === totalOffset) {
            setHasMore(false);
          }
        });
    }
  };

  return (
    <div className="messenger-notifications-list">
      <div className="messenger-notifications-list__header">
        <div className="messenger-notifications-list__header-title">
          <h3>{translate(messages.messages)}</h3>
          <span>({unreadCount})</span>
        </div>
        <div className="messenger-notifications-list__header-read-all">
          <ReadAll />
        </div>
      </div>
      <div className="messenger-notifications-list__list" id="messenger-notifications-list">
        {totalThreads ? (
          <InfiniteScroll
            dataLength={threads.length}
            next={() => fetchNextData(offset + 1)}
            hasMore={hasMore}
            loader={<Loader />}
            scrollableTarget="messenger-notifications-list"
          >
            {notificationsList}
          </InfiniteScroll>
        ) : (
          <EmptyState />
        )}
      </div>
      <Button
        data-test="messenger-notifications-list-button-view-all"
        className="messenger-notifications-list__view-all"
        name={translate(messages.viewAll)}
        link
        color="blue"
        onClick={() => {
          setPopupVisible(true);
          toggleMessagesDropdown();
        }}
      />
    </div>
  );
};

NotificationsList.propTypes = {
  setPopupVisible: PropTypes.func.isRequired,
  toggleMessagesDropdown: PropTypes.func,
};

export default NotificationsList;
