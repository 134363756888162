import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { generatePath, Navigate, Routes } from 'react-router';

import ROUTES from 'constants/routes';
import { DEFAULT_ORGANIZATION_INDEX, getOrganizationIdFromEmailUrl } from 'utils/organizations';
import { getAuthenticationToken } from 'utils/auth';

import NotFound from 'pages/NotFound';
import OldRoutesPreloader from 'components/OldRoutesPreloader';
import EmailLinksRedirect from 'components/EmailLinksRedirect';

import { OLD_ROUTES } from './oldRoutes';
import { PUBLIC_ROUTES } from './publicRoutes';
import { PRIVATE_ROUTES } from './privateRoutes';
import { getTranslations } from 'api/translation';
import { useDispatch, useSelector } from 'react-redux';
import { TRANSLATIONS_LS_KEY } from 'utils/index';
import { selectProfile } from 'pages/Profile/selectors';
import { LANGUAGES } from 'utils/languages';
import NotSupportedOnMobile from 'pages/NotSupportedOnMobile';

const ALL_ROUTES = {
  ...OLD_ROUTES,
  ...PUBLIC_ROUTES,
  ...PRIVATE_ROUTES,
};

const App = () => {
  const dispatch = useDispatch();
  const {
    user: { lang },
  } = useSelector(selectProfile);

  const setTranslations = async () => {
    const currentCode = LANGUAGES.find((item) => item.value === lang)?.code;
    const translations = await dispatch(getTranslations(currentCode)).unwrap();
    if (translations) {
      localStorage.setItem(TRANSLATIONS_LS_KEY, JSON.stringify(translations));
    }
  };

  useEffect(() => {
    if (lang !== undefined) {
      setTranslations();
    }
  }, [lang]);

  if (/Android|iPhone/i.test(navigator.userAgent)) {
    return <NotSupportedOnMobile />;
  }

  if (window.location.search.includes('ret') && !window.location.pathname.includes('login')) {
    window.location.replace(`${ROUTES.LOGIN}${window.location.search}`);
  }
  if (window.location.hash) {
    return <OldRoutesPreloader />;
  }

  if (getOrganizationIdFromEmailUrl() && getAuthenticationToken()) {
    return <EmailLinksRedirect />;
  }

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Navigate
            to={generatePath(ROUTES.VENDOR_RECENT, {
              organization: DEFAULT_ORGANIZATION_INDEX,
            })}
          />
        }
      />
      {Object.entries(ALL_ROUTES).map(([, route]) => (
        <Route key={route.path} exact path={route.path} element={route.element} />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default App;
