import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

// CONSTANTS
import {
  AddProfileChatThreadMsg,
  GetProfileChatThread,
  GetProfileChatThreads,
  GetUnreadChatThreads,
  MarkAllAsRead,
  NewProfileChatThread,
  GetCategoryUnreadCount,
} from 'api/endpoints';
import { API_STATUS } from 'constants/api';

// UTILS
import { getAuthenticationToken, ORG_ID_AUTH_KEY } from 'utils/auth';
import { getCookie } from 'utils/apiHelpers';
import { calcFilters } from 'components/Messenger/lib';
import { getSummaryCustomersRequest } from 'api/summary';
import { getAssessmentsTemplatesRequest } from 'api/assessmentsTemplates';

const getChatRequest = async ({ threadId }) => {
  const payload = {
    'get-chat-thread-id': threadId,
  };
  return axios.post(GetProfileChatThread.url, payload, { withCredentials: true });
};

export const getChat = createAsyncThunk('getChat', getChatRequest);

const getCategoryUnreadCountRequest = async ({ assessmentId, categoryId }) => {
  return axios.post(GetCategoryUnreadCount.url, { assessmentId, categoryId }, { withCredentials: true });
};

export const getCategoryUnreadCount = createAsyncThunk(
  'getCategoryUnreadCount',
  getCategoryUnreadCountRequest,
);

export const getProfileChatThreadsRequest = async (args, { getState }) => {
  const { allMessages: reduxState } = getState();

  const { offset, isAssessor } = args;

  const payload = {
    count: reduxState.count,
    offset: offset || reduxState.offset,
    search: reduxState.searchValue || '',
    is_assessor: isAssessor || false,
    ...calcFilters(reduxState.filters),
  };
  return axios.post(GetProfileChatThreads.url, payload, { withCredentials: true });
};

export const getProfileChatThreads = createAsyncThunk(
  'getProfileChatThreads',
  getProfileChatThreadsRequest,
);

export const getUnreadChatThreadsRequest = async (args, { getState }) => {
  const {
    unreadMessages: { count, offset: reduxOffset },
    allMessages: { filters, searchValue },
  } = getState();

  const token = getAuthenticationToken();
  const orgId = getCookie(ORG_ID_AUTH_KEY);

  const { offset, isAssessor } = args;

  const payload = {
    count,
    offset: offset || reduxOffset,
    token,
    org_id: orgId,
    is_assessor: isAssessor,
    search: searchValue || '',
    ...calcFilters(filters),
  };

  const response = await axios.get(GetUnreadChatThreads.url, { params: payload ,withCredentials: true});
  return { nextOffset: offset, ...response };
};

export const getUnreadChatThreads = createAsyncThunk(
  'getUnreadChatThreads',
  getUnreadChatThreadsRequest,
);

export const getUnreadChatThreadsNotifications = createAsyncThunk(
  'getUnreadChatThreadsNotifications',
  getUnreadChatThreadsRequest,
);

const createChatRequest = async ({
  assessmentId,
  threadId,
  threadTitle,
  categoryId,
  controlId,
  findingId,
  recommendationId,
}) => {
  const payload = {
    'new-chat-thread-id': threadId || '',
    'new-chat-thread-assessment-id': assessmentId || '',
    'new-chat-thread-title': threadTitle || '',
    'category-id': categoryId || '',
    'control-id': controlId || '',
    'finding-id': findingId || '',
    'recommendation-id': recommendationId || '',
  };
  return axios.post(NewProfileChatThread.url, payload, { withCredentials: true });
};

export const createChat = createAsyncThunk('createChat', createChatRequest);

export const openOrCreateChatRequest = async (
  { assessmentId, threadId, threadTitle, controlId, findingId, recommendationId, categoryId },
  { dispatch },
) => {
  const response = await dispatch(getChat({ threadId })).unwrap();

  if (response.status !== API_STATUS.NOT_FOUND) return response;

  await dispatch(
    createChat({
      assessmentId,
      threadId,
      threadTitle,
      controlId,
      findingId,
      recommendationId,
      categoryId,
    }),
  ).unwrap();
  return dispatch(getChat({ threadId })).unwrap();
};

export const openOrCreateChat = createAsyncThunk('openOrCreateChat', openOrCreateChatRequest);

const sendMessageRequest = async ({ threadId, msg }) => {
  const payload = {
    'add-chat-thread-msg-thread-id': threadId,
    'add-chat-thread-msg': msg,
  };
  return axios.post(AddProfileChatThreadMsg.url, payload, { withCredentials: true });
};

export const sendMessage = createAsyncThunk('sendMessage', sendMessageRequest);

export const markAllAsRead = async () => {
  const { status } = await axios.post(MarkAllAsRead.url, {}, { withCredentials: true });
  return status;
};

export const getAllParticipants = createAsyncThunk(
  'getAllParticipants',
  getSummaryCustomersRequest,
);
export const getAllAssessments = createAsyncThunk(
  'getAllAssessments',
  getAssessmentsTemplatesRequest,
);
