import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui/Button';
import { translate } from 'utils/index';
import { useTheme } from 'hooks/theme';

import { setFilter } from '../reducers';
import { selectAvailableFilters } from '../selectors';
import { messages } from './messages';
import './addFilter.scss';

const AddFilter = ({ className }) => {
  const { colorTheme } = useTheme();
  const availableFilters = useSelector(selectAvailableFilters);
  const dispatch = useDispatch();

  const menuItems = availableFilters.map(({ name, type }) => {
    return {
      key: `filter-item-${type}`,
      onClick: () => dispatch(setFilter({ type })),
      label: translate(name),
      'data-test': `filter-item-${type}`,
    };
  });

  return (
    <Dropdown
      className={classNames('customers-list-add-filter', className)}
      menu={{ items: menuItems, className: 'customers-list-add-filter__menu' }}
      trigger="click"
    >
      <Button
        className="customers-list-add-filter__button"
        beforeIcon="filter"
        afterIcon="arrow-down2"
        name={translate(messages.addFilter)}
        color={colorTheme}
        size="md"
        data-test="add-filter-button-dropdown"
        outline
        lowercase
      />
    </Dropdown>
  );
};

AddFilter.propTypes = {
  className: PropTypes.string,
};

export default AddFilter;
