import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Tooltip, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'ui/Icon';
import Select from 'ui/Select';
import SecondaryButton from 'ui/SecondaryButton';
import { translate } from 'utils/index';
import { getOrganizationProfiles } from 'api/organizations';
import { selectCurrentOrganization } from 'pages/Profile/selectors';
import { Roles } from 'constants/roles';
import { ReactComponent as CloseIcon } from 'assets/svgs/close-light-weight.svg';

import InviteTeamMember from './inviteTeamMember';
import { messages } from '../../messages';
import { setVendorBoUsers } from 'api/vendor';
import { useRole } from 'hooks/useRole';
import './index.scss';

const modes = {
  selectUser: 'selectUser',
  list: 'list',
};

const BusinessOwner = ({ boUsers, vendor }) => {
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState((boUsers || []).map(({ email }) => email));
  const [mode, setMode] = useState(modes.list);
  const [showAddUserPopup, setShowAddUserPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [initUsers, setInitUsers] = useState([]);
  const { id: organizationId } = useSelector(selectCurrentOrganization);
  const { isOwnerRole, isAdminRole, isBoRole } = useRole();

  useEffect(async () => {
    if (mode !== modes.selectUser) {
      return null;
    }
    setLoading(true);
    const { profiles } = await dispatch(getOrganizationProfiles({ organizationId })).unwrap();
    const usrs = profiles.filter(({ role }) => [Roles.Admin, Roles.Owner, Roles.BO].includes(role));
    setUsers(usrs);
    setInitUsers(usrs);
    setLoading(false);
  }, [mode]);

  useEffect(() => {
    setSelectedUsers((boUsers || []).map(({ email }) => email));
  }, [boUsers, vendor]);

  useEffect(() => {
    const closeList = (event) => {
      if (
        event.target.classList.contains('ant-select-selection-overflow') ||
        event.target.classList.contains('ant-select-item-option-content') ||
        event.target.classList.contains('ant-select-selector') ||
        event.target.classList.contains('ant-select-selection-item-content') ||
        event.target.classList.contains('recent-page-vendors-business-owner__bo-icon') ||
        event.target.classList.contains('recent-page-vendors-business-owner__invite-user-button')
      ) {
        return;
      }
      setMode(modes.list);
    };
    document.addEventListener('mousedown', closeList);

    return () => {
      document.removeEventListener('mousedown', closeList);
    };
  }, []);

  const selectUsers = async (selected) => {
    setSelectedUsers(selected);
    await dispatch(setVendorBoUsers({ vendorId: vendor.id, vendorBoEmails: selected }));
  };

  const addNewUser = async (email) => {
    const selected = [...selectedUsers, email];
    await dispatch(setVendorBoUsers({ vendorId: vendor.id, vendorBoEmails: selected }));
    const { profiles } = await dispatch(getOrganizationProfiles({ organizationId })).unwrap();
    setUsers(profiles.filter(({ role }) => [Roles.Admin, Roles.Owner, Roles.BO].includes(role)));
    setSelectedUsers(selected);
  };

  const filteredUsers = selectedUsers
    .map((email) =>
      users.length === 0
        ? boUsers.find((user) => user.email === email)
        : users.find((user) => user.email === email),
    )
    .filter((user) => user);

  const renderUsers = () => (
    <div className="recent-page-vendors-business-owner__bo-list" data-test="vendor-bo-list">
      {filteredUsers?.length > 2 ? (
        <>
          <Tooltip
            key={filteredUsers[0].email}
            title={filteredUsers[0].name || filteredUsers[0].email}
          >
            <div
              className="recent-page-vendors-business-owner__bo-list-user"
              onClick={() => setMode(modes.selectUser)}
            >
              {filteredUsers[0].email[0]}
            </div>
          </Tooltip>
          <Tooltip
            title={
              <ul>
                {filteredUsers.slice(1).map((user) => (
                  <li key={user.email}>{user.name || user.email}</li>
                ))}
              </ul>
            }
          >
            <div
              className="recent-page-vendors-business-owner__bo-list-user"
              onClick={() => setMode(modes.selectUser)}
            >
              +{filteredUsers.length - 1}
            </div>
          </Tooltip>
        </>
      ) : null}
      {filteredUsers?.length && filteredUsers?.length <= 2
        ? filteredUsers?.map(({ email, name }) => {
            return (
              <Tooltip key={email} title={name || email}>
                <div
                  className="recent-page-vendors-business-owner__bo-list-user"
                  onClick={() => setMode(modes.selectUser)}
                >
                  {email[0]}
                </div>
              </Tooltip>
            );
          })
        : null}

      {filteredUsers?.length === 0 && (
        <Button
          className="recent-page-vendors-business-owner__empty-state"
          type="link"
          onClick={() => setMode(modes.selectUser)}
        >
          <Icon icon="bo-empty-state" />
        </Button>
      )}

      {(isOwnerRole || isAdminRole || isBoRole) && (
        <Button
          className="recent-page-vendors-business-owner__add-user"
          type="link"
          onClick={() => setMode(modes.selectUser)}
          data-test="add-bo-user"
        >
          <Icon icon="add4" />
        </Button>
      )}
    </div>
  );

  const dropdownRender = (menu) => {
    return (
      <div className="recent-page-vendors-business-owner__ui-select-dropdown">
        {menu}
        <Divider style={{ margin: '0 0' }} />
        <SecondaryButton
          className="recent-page-vendors-business-owner__invite-user-button"
          link
          onClick={() => setShowAddUserPopup(true)}
          data-test="business-owner-invite-user-button"
        >
          + {translate(messages.inviteTeamMember)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <div className="recent-page-vendors-business-owner">
      {mode === modes.list && renderUsers()}
      {mode === modes.selectUser && (
        <Select
          tagRender={(data) => {
            const { value, onClose } = data;
            return (
              <div className="recent-page-vendors-business-owner__tag">
                {value[0]}
                <CloseIcon onClick={onClose} />
              </div>
            );
          }}
          defaultOpen
          className="recent-page-vendors-business-owner__user-selector"
          dropdownRender={dropdownRender}
          placeholder={translate(messages.selectBO)}
          options={users.map(({ fullname, email, id }) => ({
            icon: <span className="recent-page-vendors-business-owner__bo-icon">{email[0]}</span>,
            label: fullname || email,
            value: email,
          }))}
          onSearch={(value) => {
            if (!value) {
              setUsers(initUsers);
              return;
            }

            const filtered = users.filter(
              (user) =>
                user.email.toLowerCase().includes(value.toLowerCase()) ||
                user.fullname.toLowerCase().includes(value.toLowerCase()),
            );

            setUsers(filtered);
          }}
          value={selectedUsers}
          onChange={selectUsers}
          data-test="business-owner-user-selector"
          style={{ width: 200 }}
          mode="multiple"
          loading={loading}
          setItemDataTest={(option) => `business-owner-user-${option.value}`}
          // overwrite filterOption to prevent filtering by default
          filterOption={undefined}
        />
      )}
      <InviteTeamMember
        open={showAddUserPopup}
        setOpen={setShowAddUserPopup}
        addNewUser={addNewUser}
      />
    </div>
  );
};

BusinessOwner.propTypes = {
  boUsers: PropTypes.array,
  vendor: PropTypes.object,
};

export default BusinessOwner;
