import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton } from 'antd';
import classNames from 'classnames';

import TextArea from 'ui/TextArea';
import Checkbox from 'ui/Checkbox';
import Icon from 'ui/Icon';
import Select from 'ui/Select';
import { translate } from 'utils/index';

import RemoveRecommendation from './removeRecommendation';
import { messages } from './messages';
import Input from 'ui/Input';

const Recommendation = ({
  recommendation,
  index,
  control,
  errors,
  finding,
  handleChange,
  note,
  setFieldValue,
  setNote,
  touched,
  auditCss,
  enableRemove,
  handleRemove,
}) => {
  const [showCustomDaysToSubmit, setShowCustomDaysToSubmit] = useState(false);

  const descriptionClassNames = classNames(
    'assessment-recommendations__recommendation-description',
    {
      [`${auditCss?.recommendations && auditCss?.recommendations[index]?.classDescription}`]:
        auditCss?.recommendations && auditCss?.recommendations[index]?.showDescription,
    },
  );

  const dueDateClassNames = classNames('assessment-recommendations__recommendation-due-date', {
    [`${auditCss?.recommendations && auditCss?.recommendations[index]?.classDueDate}`]:
      auditCss?.recommendations && auditCss?.recommendations[index]?.showDueDate,
  });

  const forceEvidenceClassNames = classNames(
    'assessment-recommendations__recommendation-force-evidence',
    {
      [`${auditCss?.recommendations && auditCss?.recommendations[index]?.classForceEvidence}`]:
        auditCss?.recommendations && auditCss?.recommendations[index]?.showForceEvidence,
    },
  );

  const getDueDeltaValue = useCallback(() => {
    if (showCustomDaysToSubmit) {
      return daysToSubmitOptions[daysToSubmitOptions.length - 1].value;
    }
    if (recommendation?.due_delta) {
      let selectedOption = daysToSubmitOptions.find(
        (option) => option.value === recommendation.due_delta,
      );

      if (!selectedOption) {
        selectedOption = daysToSubmitOptions[daysToSubmitOptions.length - 1];
        setShowCustomDaysToSubmit(true);
      }

      return selectedOption.value;
    } else return undefined;
  }, [recommendation?.due_delta, showCustomDaysToSubmit]);

  const daysToSubmitOptions = useMemo(
    () => [
      { label: translate(messages.oneWeek), value: 7 },
      { label: translate(messages.twoWeeks), value: 14 },
      { label: translate(messages.oneMonth), value: 30 },
      { label: translate(messages.customDueDate), value: 'custom' },
    ],
    [],
  );

  return (
    <div className="assessment-recommendations__recommendation">
      <div className="assessment-recommendations__recommendation-header">
        <h4 className="assessment-recommendations__recommendation-header-text">
          {translate(messages.recommendation)} ({index + 1})
        </h4>
        {index > 0 && (
          <RemoveRecommendation
            enableRemove={enableRemove}
            control={control}
            finding={finding}
            id={recommendation.id}
            onRemove={handleRemove}
          />
        )}
      </div>
      <TextArea
        data-test={`recommendation-description-textarea-${index}`}
        className={descriptionClassNames}
        name={`finding.recommendations[${index}].description`}
        onChange={handleChange}
        placeholder={translate(messages.enterDescription)}
        value={recommendation?.description}
        error={touched?.[index]?.description && errors?.[index]?.description}
      />
      {note[index] && (
        <div>
          <label>{translate(messages.noteTitle)}</label>
          <TextArea
            data-test={`recommendation-note-textarea-${index}`}
            className="assessment-recommendations__recommendation-note"
            name={`finding.recommendations[${index}].comment`}
            onChange={handleChange}
            placeholder={translate(messages.enterNote)}
            value={recommendation.comment}
          />
        </div>
      )}
      <Select
        block
        className={dueDateClassNames}
        innerLabel={translate(messages.selectLabel)}
        options={daysToSubmitOptions}
        value={getDueDeltaValue()}
        data-test="due-date-select"
        onChange={(value) => {
          if (value === 'custom') {
            setShowCustomDaysToSubmit(true);
          } else {
            setShowCustomDaysToSubmit(false);
            setFieldValue(`finding.recommendations[${index}].due_delta`, value);
          }
        }}
      />
      {showCustomDaysToSubmit ? (
        <Input
          className="assessment-recommendations__recommendation-due-date-custom"
          label={translate(messages.customDaysInputLabel)}
          data-test="custom-days-to-submit-input"
          type="number"
          placeholder={translate(messages.customDaysInputPlaceholder)}
          value={recommendation?.due_delta || undefined}
          onChange={(event) => {
            setFieldValue(`finding.recommendations[${index}].due_delta`, event.target.value);
          }}
        />
      ) : null}
      <Checkbox
        checked={recommendation.force_evidence}
        className={forceEvidenceClassNames}
        onChange={({ target: { checked } }) =>
          setFieldValue(`finding.recommendations[${index}].force_evidence`, checked)
        }
        name={`force_evidence-${index}`}
        mode="checkbox"
      >
        {translate(messages.vendorMustUploadEvidence)}
      </Checkbox>
      {!note[index] && (
        <AntButton
          data-test="add-note-button"
          type="link"
          className="assessment-recommendations__recommendation-note-button"
          onClick={() => setNote((state) => ({ ...state, [index]: true }))}
        >
          <Icon icon="note" />
          {translate(messages.note)}
        </AntButton>
      )}
    </div>
  );
};

Recommendation.propTypes = {
  recommendation: PropTypes.object,
  index: PropTypes.number,
  control: PropTypes.object,
  errors: PropTypes.array,
  finding: PropTypes.object,
  handleChange: PropTypes.func,
  note: PropTypes.object,
  recommendations: PropTypes.array,
  setFieldValue: PropTypes.func,
  setNote: PropTypes.func,
  touched: PropTypes.array,
  auditCss: PropTypes.object,
  enableRemove: PropTypes.bool,
  arrayHelpers: PropTypes.any,
  handleRemove: PropTypes.func,
};

export default Recommendation;
