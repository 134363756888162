export const messages = {
  vendorMustUploadEvidence: {
    key: 'AssessmentRecommendations.vendorMustUploadEvidence',
    defaultMessage: 'Vendor must upload evidence',
  },
  recommendation: {
    key: 'AssessmentRecommendations.recommendation',
    defaultMessage: 'Recommendation',
  },
  enterDescription: {
    key: 'AssessmentRecommendations.enterDescription',
    defaultMessage: 'Enter description',
  },
  enterNote: {
    key: 'AssessmentRecommendations.enterNote',
    defaultMessage: 'Enter note',
  },
  completed: {
    key: 'AssessmentRecommendations.completed',
    defaultMessage: 'COMPLETED',
  },
  markComplete: {
    key: 'AssessmentRecommendations.markComplete',
    defaultMessage: 'MARK COMPLETE',
  },
  vendorCompletion: {
    key: 'AssessmentRecommendations.vendorCompletion',
    defaultMessage: 'Vendor completion',
  },
  upload: {
    key: 'AssessmentRecommendations.upload',
    defaultMessage: 'UPLOAD',
  },
  comment: {
    key: 'AssessmentRecommendations.comment',
    defaultMessage: 'COMMENT',
  },
  note: {
    key: 'AssessmentRecommendations.note',
    defaultMessage: 'NOTE',
  },
  removeRecommendationPopup: {
    key: 'AssessmentRecommendations.removeRecommendationPopup',
    defaultMessage: 'Are you sure you want to remove the recommendation?',
  },
  removeRecommendation: {
    key: 'AssessmentRecommendations.removeRecommendation',
    defaultMessage: 'Remove recommendation',
  },
  noteTitle: {
    key: 'AssessmentRecommendations.noteTitle',
    defaultMessage: 'Note',
  },
  noteDescription: {
    key: 'AssessmentRecommendations.noteDescription',
    defaultMessage: 'Here you can write note that will be visible only to you.',
  },
  dueDate: {
    key: 'AssessmentRecommendations.dueDate',
    defaultMessage: 'DUE DATE',
  },
  addRecommendation: {
    key: 'AssessmentRecommendations.addRecommendation',
    defaultMessage: 'ADD RECOMMENDATION',
  },
  pendingApproval: {
    key: 'AssessmentRecommendations.pendingApproval',
    defaultMessage: 'PENDING APPROVAL',
  },
  dueDateLabel: {
    key: 'AssessmentRecommendations.dueDateLabel',
    defaultMessage: 'Due date',
  },
  dueDateSelectPlaceholder: {
    key: 'AssessmentRecommendations.dueDateSelectPlaceholder',
    defaultMessage: 'Select due date',
  },
  oneWeek: {
    key: 'AssessmentRecommendations.oneWeek',
    defaultMessage: '1 Week',
  },
  twoWeeks: {
    key: 'AssessmentRecommendations.twoWeeks',
    defaultMessage: '2 Weeks',
  },
  oneMonth: {
    key: 'AssessmentRecommendations.oneMonth',
    defaultMessage: '1 Month',
  },
  customDueDate: {
    key: 'AssessmentRecommendations.customDueDate',
    defaultMessage: 'Custom...',
  },
  customDaysInputPlaceholder: {
    key: 'AssessmentRecommendations.customDaysInputPlaceholder',
    defaultMessage: 'Enter number of days',
  },
  customDaysInputLabel: {
    key: 'AssessmentRecommendations.customDaysInputLabel',
    defaultMessage: 'Days to submit',
  },
  sbomRecommendationsTitle: {
    key: 'AssessmentRecommendations.sbomRecommendationsTitle',
    defaultMessage: 'Vulnerability Details and Technical Analysis',
  },
  forMoreSBOMInfo: {
    key: 'AssessmentRecommendations.forMoreSBOMInfo',
    defaultMessage: 'For more informations please check out the',
  },
  mitreWebsite: {
    key: 'AssessmentRecommendations.mitreWebsite',
    defaultMessage: 'MITRE website',
  },
};

export default {
  prefix: 'AssessmentRecommendations',
  messages,
};
