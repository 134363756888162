import { createSelector } from '@reduxjs/toolkit';

export const selectFindingsPage = createSelector(
  ({ findingsPage }) => findingsPage,
  (state) => state,
);

export const selectFindingsActiveFilters = createSelector(
  ({ findingsPage: { filters } }) => filters,
  (state) => state,
);

export const selectFindingsActiveFiltersValues = createSelector(
  ({ findingsPage: { filtersValues } }) => filtersValues,
  (state) => state,
);
