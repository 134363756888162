import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Dropdown } from 'antd';

import Icon from 'ui/Icon';
import { translate } from 'utils/index';
import { messages } from './messages';
import './index.scss';

const Approve2 = ({
  approveState,
  className,
  disabled,
  onClear,
  onApprove,
  onReject,
  rejectState,
  value,
}) => {
  const approveClassName = classNames('ui-approve-2', className, {
    'ui-approve-2--approved': value === approveState,
    'ui-approve-2--reject': value === rejectState,
    'ui-approve-2--disabled': disabled,
  });

  const menuItems = [
    {
      key: 'none',
      onClick: onClear,
      icon: <Icon icon="disabled" className="ui-approve-2__ico-disabled" />,
      label: translate(messages.deselect),
      'data-test': 'approve-dropdown-clear',
    },
    {
      key: 'approve',
      onClick: onApprove,
      icon: <Icon icon="check2" className="ui-approve-2__ico-approved" />,
      label: translate(messages.approve),
      'data-test': 'approve-dropdown-approve',
    },
    {
      key: 'reject',
      onClick: onReject,
      icon: <Icon icon="close" className="ui-approve-2__ico-reject" />,
      label: translate(messages.reject),
      'data-test': 'approve-dropdown-reject',
    },
  ];

  const renderButton = () => {
    switch (value) {
      case 0:
        return (
          <Button type="link" className="ui-approve-2__button">
            <Icon icon="check2" />
          </Button>
        );
      case approveState:
        return (
          <Button type="link" className="ui-approve-2__button ui-approve-2__button--approved">
            <Icon icon="check2" />
          </Button>
        );
      case rejectState:
        return (
          <Button type="link" className="ui-approve-2__button ui-approve-2__button--reject">
            <Icon icon="close3" />
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <div className={approveClassName}>
      <Dropdown
        menu={{ items: menuItems, className: 'ui-approve-2__menu' }}
        trigger="click"
        disabled={disabled}
      >
        {renderButton()}
      </Dropdown>
    </div>
  );
};

Approve2.propTypes = {
  approveState: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClear: PropTypes.func,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  rejectState: PropTypes.number,
  value: PropTypes.number,
};

export default Approve2;
