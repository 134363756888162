import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigationType } from 'react-router';
import classNames from 'classnames';
import moment from 'moment';

import PageSubheader from 'components/PageSubheader';
import { getRecentEvidences } from 'api/recent';
import { useGetPath, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { selectIsViewAggregated } from 'pages/Organization/selectors';
import { createSorterByString } from 'utils/strings';
import { translate } from 'utils/index';
import Table from 'ui/Table';
import Icon from 'ui/Icon';
import { DATE_FORMAT } from 'constants/date';
import ROUTES from 'constants/routes';

import Status from './evidencesColumns/status';
import FileComponent from './evidencesColumns/file-component';
import { setEvidencesSorter } from './reducers';
import { messages } from './messages';
import { TableSource } from 'ui/Table/lib';

import './evidences.scss';

const Evidences = () => {
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const dispatch = useDispatch();
  const { evidences, evidencesLoading, evidencesSorter } = useSelector((state) => state.recent);
  const getPath = useGetPath();
  const navigationType = useNavigationType();
  const isViewAggregated = useSelector(selectIsViewAggregated);

  useEffect(() => {
    if (navigationType !== 'POP') {
      dispatch(setEvidencesSorter({}));
    }
    dispatch(getRecentEvidences());
  }, []);

  const sortOrder = evidencesSorter.descending ? 'descend' : 'ascend';

  const columns = [
    {
      title: translate(messages.lastModified),
      dataIndex: 'lastModified',
      width: 120,
      render: (lastModified) => moment(lastModified).format(DATE_FORMAT),
      sorter: (a, b) => new Date(a.lastModified) - new Date(b.lastModified),
      sortOrder: evidencesSorter.column === 'lastModified' ? sortOrder : null,
    },
    {
      title: isMyVendorsTabSelected ? translate(messages.vendor) : translate(messages.customer),
      dataIndex: 'profileCorporateName',
      render: (name, evidence) =>
        isMyVendorsTabSelected ? (
          <Link
            className="recent-page-evidences__table-link"
            to={getPath(ROUTES.VENDOR_EDIT, {
              vendorId: evidence?.corporateId,
            })}
          >
            {name}
          </Link>
        ) : (
          name
        ),
      sorter: createSorterByString('profileCorporateName'),
      sortOrder: evidencesSorter.column === 'profileCorporateName' ? sortOrder : null,
    },
    {
      title: translate(messages.assessment),
      dataIndex: 'assessmentTitle',
      render: (assessmentTitle, evidence) => (
        <Link
          className="recent-page-evidences__table-link"
          to={getPath(
            isMyVendorsTabSelected ? ROUTES.VENDOR_ASSESSMENT : ROUTES.CUSTOMER_ASSESSMENT,
            { assessmentId: evidence.assessmentId },
          )}
        >
          {assessmentTitle}
        </Link>
      ),
      sorter: createSorterByString('assessmentTitle'),
      sortOrder: evidencesSorter.column === 'assessmentTitle' ? sortOrder : null,
    },
    {
      title: translate(messages.title),
      dataIndex: 'fileTitle',
      render: (_, { fileTitle, assessmentId, evidenceId }) => (
        <FileComponent fileTitle={fileTitle} assessmentId={assessmentId} evidenceId={evidenceId} />
      ),
      sorter: createSorterByString('fileTitle'),
      sortOrder: evidencesSorter.column === 'fileTitle' ? sortOrder : null,
    },
    {
      title: translate(messages.status),
      dataIndex: 'evidenceStatus',
      width: 120,
      render: (status) => <Status status={status} />,
      sorter: (a, b) => a.evidenceStatus - b.evidenceStatus,
      sortOrder: evidencesSorter.column === 'evidenceStatus' ? sortOrder : null,
    },
    {
      title: translate(messages.issued),
      dataIndex: 'issueDate',
      width: 120,
      render: (issueDate) => {
        return (
          <div className="recent-page-evidences__issued-date">
            <Icon icon="date" className="recent-page-evidences__issued-date-ico" />
            {issueDate ? moment(issueDate).format(DATE_FORMAT) : 'None'}
          </div>
        );
      },
      sorter: (a, b) => new Date(a.issueDate) - new Date(b.issueDate),
      sortOrder: evidencesSorter.column === 'issueDate' ? sortOrder : null,
    },
    {
      title: translate(messages.expiration),
      dataIndex: 'expiryDate',
      width: 120,
      render: (expiryDate) => {
        const className = classNames('recent-page-evidences__expiration-date', {
          'recent-page-evidences__expiration-date--expired': moment().isAfter(moment(expiryDate)),
        });
        return (
          <div className={className}>
            <Icon icon="date" className="recent-page-evidences__expiration-date-ico" />
            {expiryDate ? moment(expiryDate).format(DATE_FORMAT) : 'None'}
          </div>
        );
      },
      sorter: (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate),
      sortOrder: evidencesSorter.column === 'expiryDate' ? sortOrder : null,
    },
  ];

  if (isViewAggregated) {
    const column = {
      title: translate(messages.organization),
      dataIndex: 'parentOrganizationCorporateName',
      sorter: createSorterByString('parentOrganizationCorporateName'),
      sortOrder: evidencesSorter.column === 'parentOrganizationCorporateName' ? sortOrder : null,
    };
    columns.splice(1, 0, column);
  }

  const dataSource = (evidences || []).map((evidence) => ({
    ...evidence,
    key: evidence.evidenceId,
  }));

  const onTableChange = (newPagination, filters, sorter) => {
    const sorterParams = {
      column: sorter.field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? sorterParams : {};
    dispatch(setEvidencesSorter(params));
  };

  return (
    <div className="recent-page-evidences">
      <div className="recent-page-evidences__top">
        <PageSubheader className="recent-page-evidences__page-subheader">
          {translate(messages.recentEvidences)}
        </PageSubheader>
      </div>
      <Table
        className="recent-page-evidences__table"
        columns={columns}
        dataSource={dataSource}
        loading={evidencesLoading}
        pagination={false}
        onChange={onTableChange}
        scroll={{
          y: 300,
        }}
        emptyStateSource={TableSource.evidenceRecent}
      />
    </div>
  );
};

export default Evidences;
