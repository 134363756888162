import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  addOrganizationProfile,
  getOrganizationApps,
  getOrganizationProfiles,
  getOrganizationSecurity,
  getProfileOrganizations,
  removeOrganizationProfile,
  removeSBOMFile,
  setOrganizationLogo,
  uploadSBOMFile,
} from 'api/organizations';
import { Apps } from 'constants/apps';
import { MixpanelLogoutTypes } from 'constants/mixpanel';
import { logout } from 'utils/logout';
import { mixpanelTrackLogout } from 'utils/mixpanel';

const initialState = {
  organizations: [],
  defaultApps: [],
  apps: [],
  loading: false,
  appsLoading: false,
  sbomFileLoading: false,
  editOrganizationId: '',
  enforce2fa: false,
  strengthenAuthentication: false,
};

const reducers = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setEditOrganizationId(state, { payload }) {
      state.editOrganizationId = payload;
    },
    resetEditOrganizationId(state) {
      state.editOrganizationId = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileOrganizations.fulfilled, (state, { payload }) => {
        state.organizations = payload || [];
        state.loading = false;
        if (payload?.length === 0) {
          mixpanelTrackLogout(false, MixpanelLogoutTypes.UserAction);
          logout();
        }
      })
      .addCase(setOrganizationLogo.fulfilled, (state, { payload }) => {
        if (payload?.status && payload?.index && payload?.corporateLogo) {
          state.organizations[payload.index].corporateLogo = payload.corporateLogo;
        }
      })
      .addCase(getOrganizationApps.pending, (state) => {
        state.appsLoading = true;
      })
      .addCase(uploadSBOMFile.pending, (state) => {
        state.sbomFileLoading = true;
      })
      .addCase(removeSBOMFile.pending, (state) => {
        state.sbomFileLoading = true;
      })
      .addCase(uploadSBOMFile.fulfilled, (state, { payload }) => {
        state.sbomFileLoading = false;
        const sbomIndex = state.apps?.findIndex((app) => app.id === Apps.sbom);
        if (sbomIndex !== -1 && payload?.file) {
          state.apps[sbomIndex]['sbom-file'] = payload.file;
        }
      })
      .addCase(removeSBOMFile.fulfilled, (state) => {
        state.sbomFileLoading = false;

        const sbomIndex = state.apps?.findIndex((app) => app.id === Apps.sbom);
        if (sbomIndex !== -1) {
          state.apps[sbomIndex]['sbom_file'] = undefined;
        }
      })
      .addMatcher(
        isAnyOf(
          getProfileOrganizations.pending,
          getOrganizationProfiles.pending,
          addOrganizationProfile.pending,
          removeOrganizationProfile.pending,
        ),
        (state) => {
          state.loading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          addOrganizationProfile.fulfilled,
          getOrganizationProfiles.fulfilled,
          removeOrganizationProfile.fulfilled,
        ),
        (state, { payload, meta }) => {
          const orgIn =
            payload?.orgIndex !== null && payload.orgIndex !== undefined
              ? payload.orgIndex
              : meta?.arg?.orgIndex !== undefined && meta.arg.orgIndex !== null
              ? meta.arg.orgIndex
              : undefined;

          if (orgIn !== undefined) {
            state.organizations[orgIn]['profiles'] = payload?.profiles || [];
          }
          state.loading = false;
        },
      )
      .addMatcher(
        isAnyOf(getOrganizationApps.fulfilled, getOrganizationApps.rejected),
        (state, { payload: { apps, default_apps = {} } }) => {
          state.apps = Object.entries(apps).map(([id, rest]) => ({ id, ...rest }));
          state.defaultApps = Object.entries(default_apps).map(([id, rest]) => ({ id, ...rest }));
          state.appsLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(getOrganizationSecurity.fulfilled, getOrganizationSecurity.rejected),
        (state, { payload }) => {
          state.enforce2fa = payload.enforce2fa || false;
          state.strengthenAuthentication = payload.strengthenAuthentication || false;
        },
      );
  },
});

export const { setEditOrganizationId, resetEditOrganizationId } = reducers.actions;

export default reducers;
