import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

import { selectCustomers, selectCustomersLoading } from 'components/AssessmentsFilters/selectors';
import { getSummaryCustomers } from 'api/summary';
import { createSorterByString } from 'utils/strings';
import { translate } from 'utils/index';
import { messages } from 'pages/Vendors/VendorsList/messages';
import Filter from './filter';

const { Option } = Select;

const FilterVendor = ({ fetchVendors, filter }) => {
  const loading = useSelector(selectCustomersLoading);
  const customers = useSelector(selectCustomers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customers.length === 0) {
      dispatch(getSummaryCustomers());
    }
  }, []);

  const sortedCustomers = useMemo(() =>
    customers.slice().sort(createSorterByString('profileCorporateName'), [customers]),
  );

  return (
    <Filter
      filter={filter}
      fetchVendors={fetchVendors}
      loading={loading}
      innerLabel={translate(messages.filterCompany)}
    >
      {sortedCustomers?.map(({ id, profileCorporateName }) => (
        <Option key={id} id={id} value={profileCorporateName}>
          {profileCorporateName}
        </Option>
      ))}
    </Filter>
  );
};

FilterVendor.propTypes = {
  fetchVendors: PropTypes.func,
  filter: PropTypes.object,
};

export default FilterVendor;
