import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

import {
  selectAssessmentsTemplates,
  selectAssessmentsTemplatesLoading,
} from 'components/AssessmentsFilters/selectors';
import { createSorterByString } from 'utils/strings';
import { getAssessmentsPageTypeFilter } from 'api/filtersData';
import { translate } from 'utils/index';
import { messages } from 'pages/Vendors/VendorsList/messages';
import Filter from './filter';
import './index.scss';

const { Option } = Select;

const FilterType = ({ fetchVendors, filter }) => {
  const loading = useSelector(selectAssessmentsTemplatesLoading);
  const types = useSelector(selectAssessmentsTemplates);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssessmentsPageTypeFilter());
  }, []);

  const sortedTypes = useMemo(() => types.slice().sort(createSorterByString('name'), [types]));

  return (
    <Filter
      filter={filter}
      fetchVendors={fetchVendors}
      loading={loading}
      innerLabel={translate(messages.filterAssessment)}
    >
      {sortedTypes?.map(({ name, id }) => (
        <Option key={id} value={name} id={id}>
          {name}
        </Option>
      ))}
    </Filter>
  );
};

FilterType.propTypes = {
  fetchVendors: PropTypes.func,
  filter: PropTypes.object,
};

export default FilterType;
