import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

import { ALLOW_FILES } from 'constants/general';
import { DATE_FORMAT } from 'constants/date';
import { translate } from 'utils/index';
import { getOffset, stripHtml } from 'utils/html';
import { removeEvidence, saveNote, saveRecommendation, uploadEvidences } from 'api/recommendation';
import { selectAssessment } from 'pages/Assessment/selectors';
import Chat from 'components/Chat';
import UpdateAnswerConfirmationModal from 'components/UpdateAnswerConfirmationModal';
import ChatButton from 'pages/Assessment/chatButton';
import TextArea from 'ui/TextArea';
import DatePicker from 'ui/DatePicker';
import Checkbox from 'ui/Checkbox';
import Approve2 from 'ui/Approve2';
import Icon from 'ui/Icon';
import FileList from 'ui/FileList';
import FileUpload from 'ui/FileUpload';
import SecondaryButton from 'ui/SecondaryButton';
import Note from 'pages/Assessment/note';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import RecommendationStatus from 'constants/recommendationStatus';
import { getStatusFromRecommendations } from 'utils/findings';
import FindingStatus from 'constants/findingStatus';
import { selectFinding } from 'pages/Assessment/finalized/findings/selectors';
import { setIsEditingAnswer } from 'pages/Assessment/reducers';
import { useRole } from 'hooks/useRole';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';
import { Apps } from 'constants/apps';
import { AssessmentStatus } from 'constants/assessmentStatus';
import RemoveRecommendation from './removeRecommendation';
import { messages } from './messages';
import Select from 'ui/Select';
import Input from 'ui/Input';
import { API_STATUS } from 'constants/api';
import { chatSources } from 'components/Chat/lib';
import { selectCurrentOrganization } from 'pages/Profile/selectors';
import './recommendation.scss';

const Recommendation = ({ recommendation, index }) => {
  const [values, setValues] = useState(recommendation);
  const [updateAnswerModal, setUpdateAnswerModal] = useState(false);
  const [searchParams] = useSearchParams();
  const [showNote, setShowNote] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const dispatch = useDispatch();
  const {
    assessor,
    assesse,
    status: assessmentStatus,
    id: assessmentId,
    customerId,
  } = useSelector(selectAssessment);
  const finding = useSelector(selectFinding);
  const loading = useSelector(
    ({ assessmentRecommendations }) => assessmentRecommendations.recommendationLoading,
  );
  const { isRestrictedBoSoViewerRole } = useRole();
  const [evidenceLoading, setEvidenceLoading] = useState(false);
  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();
  const [showCustomDaysToSubmit, setShowCustomDaysToSubmit] = useState(false);
  const { id: organizationId } = useSelector(selectCurrentOrganization);

  const { control, id: findingId } = finding;
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const {
    comment,
    commentTimestampEdited,
    description,
    due,
    force_evidence: forceEvidence,
    id,
  } = recommendation;
  const recommendationIdParam = searchParams.get('recommendationId');
  const status = getStatusFromRecommendations(finding);

  useEffect(() => {
    setValues(recommendation);
  }, [recommendation]);

  useEffect(() => {
    if (recommendationIdParam === id && finding.app !== Apps.sbom) {
      setShowChat(true);
    }
  }, [recommendationIdParam]);

  const onSaveTextDebounced = useCallback(
    debounce((field, newValue) => {
      const payload = {
        control,
        findingId,
        recommendation: {
          id,
          [field]: newValue,
        },
      };
      dispatch(saveRecommendation(payload));
    }, 1000),
    [findingId, control, id],
  );

  const onChange = async (field, value) => {
    setValues((state) => ({ ...state, [field]: value }));

    const payload = {
      control,
      findingId,
      recommendation: {
        id,
        [field]: value,
      },
    };

    await dispatch(saveRecommendation(payload)).unwrap();
  };

  const onChangeStatus = async (value) => {
    await onChange('status', value);
    setUpdateAnswerModal(true);
  };

  const updateAnswer = () => {
    dispatch(setIsEditingAnswer(true));
    setUpdateAnswerModal(false);

    const answerElement = document.getElementById('assessment-finalized-finding-answer');
    const answerElementOffset = getOffset(answerElement);

    window.scroll({
      top: answerElementOffset.top - 142,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onTextChange = (field, { target: { value } }) => {
    setValues((state) => ({ ...state, [field]: value }));
    onSaveTextDebounced(field, value);
  };

  const onNoteSave = async (comment) => {
    const payload = {
      note: {
        id,
        comment,
      },
    };
    await dispatch(saveNote(payload));
  };

  const onEvidenceUpload = async (files) => {
    const payload = {
      control,
      findingId,
      recommendationId: id,
      files,
      abortController,
    };

    startUploading();
    setEvidenceLoading(true);
    const linkFilesResp = await dispatch(uploadEvidences(payload)).unwrap();
    finishUploding();
    if (linkFilesResp?.status === API_STATUS.SUCCESS) {
      setValues((state) => ({
        ...state,
        evidences: [...state.evidences, ...files],
      }));
    }
    setEvidenceLoading(false);
  };

  const onEvidenceRemove = async (fileId) => {
    setEvidenceLoading(true);
    await dispatch(removeEvidence({ fileId, objectId: id })).unwrap();
    setEvidenceLoading(false);
  };

  const daysToSubmitOptions = useMemo(
    () => [
      { label: translate(messages.oneWeek), value: 7 },
      { label: translate(messages.twoWeeks), value: 14 },
      { label: translate(messages.oneMonth), value: 30 },
      { label: translate(messages.customDueDate), value: 'custom' },
    ],
    [],
  );

  const getDueDeltaValue = useCallback(() => {
    if (showCustomDaysToSubmit) {
      return daysToSubmitOptions[daysToSubmitOptions.length - 1].value;
    }
    if (values?.due_delta) {
      let selectedOption = daysToSubmitOptions.find((option) => option.value === values.due_delta);

      if (!selectedOption) {
        selectedOption = daysToSubmitOptions[daysToSubmitOptions.length - 1];
        setShowCustomDaysToSubmit(true);
      }

      return selectedOption.value;
    } else return undefined;
  }, [values?.due_delta, showCustomDaysToSubmit]);

  return (
    <div key={id} className="assessment-finalized-recommendation">
      <div className="assessment-finalized-recommendation__header">
        <h4 className="assessment-finalized-recommendation__header-text">
          {translate(messages.recommendation)} ({index + 1})
        </h4>
        {assessor && finding.app !== Apps.sbom && (
          <RemoveRecommendation disabled={isRestrictedBoSoViewerRole} id={id} />
        )}
      </div>
      {finding.app === Apps.sbom ? (
        <div className="assessment-finalized-recommendation__description--app-description">
          <h5>{translate(messages.sbomRecommendationsTitle)}:</h5>
          <ul>
            {Object.keys(values?.description).map((key) => {
              return (
                <li key={`app-recommendation-description-key-${key}`}>
                  <span className="app-recommendation-key">{key}:</span>
                  <span className="app-recommendation-value">{values.description[key]}</span>
                </li>
              );
            })}
          </ul>
          <p>
            {translate(messages.forMoreSBOMInfo)}{' '}
            <a
              href="https://cve.mitre.org/cve/search_cve_list.html "
              target="_blank"
              rel="noreferrer"
            >
              {translate(messages.mitreWebsite)}
            </a>
          </p>
        </div>
      ) : assessor ? (
        <TextArea
          className="assessment-finalized-recommendation__description"
          disabled={isRestrictedBoSoViewerRole}
          value={stripHtml(values.description)}
          onChange={(event) => onTextChange('description', event)}
          placeholder={translate(messages.enterDescription)}
          loading={loading && values.description !== description}
          data-test={`recommendation-description-textarea-${index}`}
        />
      ) : (
        <p>{stripHtml(description)}</p>
      )}

      {isMyVendorsTabSelected && (showNote || comment) && (
        <Note
          description={comment}
          date={commentTimestampEdited}
          editMode={showNote}
          setEditMode={setShowNote}
          onSave={onNoteSave}
          data-test={`recommendation-note-${index}`}
        />
      )}
      {assessor ? (
        <>
          {AssessmentStatus.reviewed === assessmentStatus ? (
            <>
              <Select
                data-test="due-date-select"
                label={translate(messages.dueDateLabel)}
                placeholder={translate(messages.dueDateSelectPlaceholder)}
                options={daysToSubmitOptions}
                value={getDueDeltaValue()}
                color="gray"
                disabled={isRestrictedBoSoViewerRole}
                onChange={(value) => {
                  if (value === 'custom') {
                    setShowCustomDaysToSubmit(true);
                  } else {
                    setShowCustomDaysToSubmit(false);
                    onChange('due_delta', value);
                  }
                }}
              />
              {showCustomDaysToSubmit ? (
                <Input
                  disabled={isRestrictedBoSoViewerRole}
                  className="custom-days-input"
                  color="gray"
                  placeholder={translate(messages.customDaysInputPlaceholder)}
                  label={translate(messages.customDaysInputLabel)}
                  data-test="custom-days-to-submit-input"
                  type="number"
                  value={values?.due_delta ? values?.due_delta.toString() : ''}
                  onBlur={() => {
                    onChange('due_delta', values.due_delta);
                  }}
                  onChange={(event) => {
                    setValues((prev) => {
                      return { ...prev, due_delta: event.target.value };
                    });
                  }}
                />
              ) : null}
            </>
          ) : (
            <DatePicker
              className="assessment-finalized-recommendation__due-date"
              disabled={isRestrictedBoSoViewerRole}
              value={values.due}
              onChange={(date) => onChange('due', date)}
              allowClear={false}
            />
          )}
          {finding.app === Apps.sbom ? null : (
            <Checkbox
              data-test="force-evidence-checkbox"
              checked={values.force_evidence}
              className="assessment-finalized-recommendation__force-evidence"
              disabled={isRestrictedBoSoViewerRole}
              onChange={({ target: { checked } }) => onChange('force_evidence', checked)}
              name="force_evidence"
              mode="checkbox"
            >
              {translate(messages.vendorMustUploadEvidence)}
            </Checkbox>
          )}
        </>
      ) : (
        <div className="assessment-finalized-recommendation__due-date-wrapper">
          <label className="assessment-finalized-recommendation__due-date-label">
            <Icon icon="date" />
            {translate(messages.dueDate)}:
          </label>
          <div className="assessment-finalized-recommendation__due-date-text">
            {moment(due).format(DATE_FORMAT)}
          </div>
          {forceEvidence && (
            <div className="assessment-finalized-recommendation__evidence">
              - {translate(messages.vendorMustUploadEvidence)} -
            </div>
          )}
        </div>
      )}

      <FileList
        files={values.evidences}
        handleRemove={onEvidenceRemove}
        loading={evidenceLoading}
      />
      <div className="assessment-finalized-recommendation__action-bar">
        <FileUpload
          accept={ALLOW_FILES}
          className="assessment-finalized-recommendation__upload-button"
          disabled={isRestrictedBoSoViewerRole}
          handleUpload={onEvidenceUpload}
          button={
            <SecondaryButton
              className="assessment-finalized-recommendation__upload-button"
              icon="upload"
              tooltip={translate(messages.upload)}
              link
            />
          }
          openMyFiles
        />
        {renderConfirmationModal()}
        {finding.app === Apps.sbom ? null : (
          <ChatButton
            data-test-suffix={`recommendation-chat-button-${index}`}
            toggleChat={() => setShowChat((state) => !state)}
            threadId={id}
          />
        )}
        {isMyVendorsTabSelected && (
          <SecondaryButton
            className="assessment-finalized-recommendation__note-button"
            onClick={() => setShowNote((state) => !state)}
            icon="note"
            tooltip={translate(messages.note)}
            link
            data-test={`recommendation-note-button-${index}`}
          />
        )}
        {assesse && (
          <Tooltip title={translate(messages.vendorCompletion)}>
            <Checkbox.CompletedCheckbox
              defaultLabel={translate(messages.markComplete)}
              checkedLabel={translate(messages.completed)}
              checked={
                values.status === RecommendationStatus.approved ||
                values.status === RecommendationStatus.closed
              }
              className="assessment-finalized-recommendation__mark-complete-button"
              disabled={isRestrictedBoSoViewerRole}
              onChange={({ target: { checked } }) =>
                onChange(
                  'status',
                  checked ? RecommendationStatus.approved : RecommendationStatus.open,
                )
              }
              name={`recommendationComplete-${id}`}
            />
          </Tooltip>
        )}
        {assessor && (
          <div className="assessment-finalized-recommendation__approve-button-wrapper">
            {values.status === RecommendationStatus.approved && (
              <span className="assessment-finalized-recommendation__pending-status">
                {translate(messages.pendingApproval)}
              </span>
            )}
            <Approve2
              className="assessment-finalized-recommendation__approve-button"
              onApprove={() => onChangeStatus(RecommendationStatus.closed)}
              onReject={() => onChangeStatus(RecommendationStatus.reject)}
              onClear={() => onChangeStatus(RecommendationStatus.approved)}
              approveState={RecommendationStatus.closed}
              rejectState={RecommendationStatus.reject}
              disabled={values.status === RecommendationStatus.open || isRestrictedBoSoViewerRole}
              value={values.status === 2 ? 0 : values.status}
            />
          </div>
        )}
      </div>
      {showChat && (
        <Chat
          disabled={isRestrictedBoSoViewerRole}
          threadId={id}
          threadTitle={stripHtml(values.description)}
          close={() => setShowChat(false)}
          findingId={findingId}
          recommendationId={id}
          assessmentId={assessmentId}
          source={chatSources.recommendation}
          relation={customerId || organizationId}
        />
      )}
      <UpdateAnswerConfirmationModal
        onCancel={() => setUpdateAnswerModal(false)}
        onOk={updateAnswer}
        open={updateAnswerModal && status === FindingStatus.closed}
      />
    </div>
  );
};

Recommendation.propTypes = {
  index: PropTypes.number,
  recommendation: PropTypes.object,
};

export default Recommendation;
