import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigationType } from 'react-router';
import { useSearchParams } from 'react-router-dom';

// COMPONENTS
import PageHeader from 'components/PageHeader';
import Card from 'ui/Card';
import ActionsBar from 'pages/Findings/actionsBar';
import FindingsList from 'pages/Findings/findingsList';
import StrengthenAuth from 'components/StrengthenAuth';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Findings/messages';

// REDUX
import { getAllOrganizations, getProfileFindings } from 'api/finding';
import { resetState, setFilter } from 'pages/Findings/reducers';

// STYLES
import './index.scss';

const Findings = () => {
  const dispatch = useDispatch();
  const navigationType = useNavigationType();
  const [searchParams] = useSearchParams();
  const vendorId = searchParams.get('vendorId');
  const vendorName = searchParams.get('vendorName');
  const customerId = searchParams.get('customerId');
  const customerName = searchParams.get('customerName');
  const assessmentName = searchParams.get('assessmentName');
  const assessmentId = searchParams.get('assessment');

  useEffect(() => {
    if (navigationType !== 'POP') {
      dispatch(resetState());
    }
    if (vendorId) {
      dispatch(getAllOrganizations());
      dispatch(setFilter({ type: 'vendor', value: vendorName, id: vendorId }));
    }
    if (customerId) {
      dispatch(getAllOrganizations());
      dispatch(setFilter({ type: 'customer', value: customerName, id: customerId }));
    }
    if (assessmentName) {
      dispatch(setFilter({ type: 'assessment', value: assessmentName, id: assessmentId }));
    }
  }, []);

  return (
    <div className="findings-page">
      <PageHeader className="findings-page__header">{translate(messages.pageTitle)}</PageHeader>
      <Card>
        <ActionsBar />
        <FindingsList />
      </Card>
      <StrengthenAuth request={() => dispatch(getProfileFindings({}))} />
    </div>
  );
};

export default Findings;
