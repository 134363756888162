const FINDINGS_DOMAIN = 'findings.co';

const trustedUsers = process.env.REACT_APP_TRUSTED_USERS?.split(',');
export const checkIfFindingsUser = (email) => {
  if (trustedUsers?.includes(email)) {
    return true;
  }

  return isFindingsUser(email);
};

export const isFindingsUser = (email) => {
  return email.split('@')[1] === FINDINGS_DOMAIN;
};
