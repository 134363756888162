import axios from 'axios';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddAppTrigger,
  AppTriggers,
  GetAnswerTypes,
  GetProfileAssessmentsTemplate,
  GetTemplateReport,
  LinkFiles,
  MoveControlBetweenCategories,
  RemoveAppTrigger,
  UnlinkFile,
  RemoveTemplateReport,
  SetAnswerType,
  SetAssessmentTemplate,
  UploadProfileAssessmentsTemplateLogo,
  UploadTemplateReport,
} from 'api/endpoints';
import { AssessmentType } from 'constants/assessmentType';
import { getCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY, TOKEN_AUTH_KEY } from 'utils/auth';
import { FileObjectType } from 'constants/FileObjectType';
import { extractFileIdsFromFiles } from 'utils/files';

const getTemplateRequest = async ({ id, orgId }) => {
  const payload = {
    'get-assessments-template-template-token': id,
  };

  if (orgId) {
    payload.organizationId = orgId;
  }

  return axios.post(GetProfileAssessmentsTemplate.url, payload, { withCredentials: true });
};

export const getTemplate = createAsyncThunk('getTemplate', getTemplateRequest);

const saveTemplateRequest = async (data, { getState }) => {
  const {
    editor,
    app: { isMyVendorsTabSelected },
    organizations: { editOrganizationId },
  } = getState();
  const notEmptyData = omitBy(data, isNil);

  const templatePayload =
    notEmptyData.group === null
      ? omit(notEmptyData, ['group', 'due'])
      : {
          ...notEmptyData,
          upstream: !isMyVendorsTabSelected, // vendors selected = true => upstream = false (downstream), customers selected = true => upstream = true (upstream)
        };

  const payload = {
    'assessment-token': editor.template.id,
    template: JSON.stringify(templatePayload),
  };

  if (data.title) {
    payload.name = data.title;
  }

  if (editOrganizationId) {
    payload.organizationId = editOrganizationId;
  }

  if (data.description) {
    payload.description = data.description;
  }

  return axios.post(SetAssessmentTemplate.url, payload, { withCredentials: true });
};

export const saveTemplate = createAsyncThunk('saveTemplate', saveTemplateRequest);

export const uploadControlFilesRequest = async ({ controlId, files }) => {
  const payload = {
    'object-id': controlId,
    'object-type': FileObjectType.control,
    'file-ids': extractFileIdsFromFiles(files),
  };

  return axios.post(LinkFiles.url, payload, { withCredentials: true });
};

export const uploadControlFiles = createAsyncThunk('uploadControlFiles', uploadControlFilesRequest);

const removeControlFileRequest = async ({ fileId, objectId }, { getState }) => {
  const { editor } = getState();
  const payload = {
    'remove-assessment-file-_assessment-token': editor.template.id,
    'remove-assessment-file-file-id': fileId,
    'assessment-type': AssessmentType.assessmentTemplate,
    objectId,
  };

  return axios.post(UnlinkFile.url, payload, { withCredentials: true });
};

export const removeControlFile = createAsyncThunk(
  'editorRemoveControlFile',
  removeControlFileRequest,
);

const addAppTriggerRequest = async ({ appId, categoryId }, { getState }) => {
  const { editor } = getState();

  const payload = {
    'template-id': editor.template.id,
    'category-id': categoryId,
    'app-id': appId,
  };

  return axios.post(AddAppTrigger.url, payload, { withCredentials: true });
};

export const addAppTrigger = createAsyncThunk('addAppTrigger', addAppTriggerRequest);

const removeAppTriggerRequest = async ({ appId, categoryId }, { getState }) => {
  const { editor } = getState();

  const payload = {
    'template-id': editor.template.id,
    'category-id': categoryId,
    'app-id': appId,
  };

  return axios.post(RemoveAppTrigger.url, payload, { withCredentials: true });
};

export const removeAppTrigger = createAsyncThunk('removeAppTrigger', removeAppTriggerRequest);

const getAnswerTypesRequest = async () => {
  const params = {
    org_id: getCookie(ORG_ID_AUTH_KEY),
    token: getCookie(TOKEN_AUTH_KEY),
    withCredentials: true,
  };
  return axios.get(GetAnswerTypes.url, { params });
};

export const getAnswerTypes = createAsyncThunk('getAnswerTypes', getAnswerTypesRequest);

const saveAnswerTypeRequest = async ({ id, ...answerType }, { getState }) => {
  const { editor } = getState();
  const payload = {
    'assessment-token': editor.template.id,
    'answer-type-json': JSON.stringify(answerType),
    id,
  };

  return axios.post(SetAnswerType.url, payload, { withCredentials: true });
};

export const saveAnswerType = createAsyncThunk('saveAnswerType', saveAnswerTypeRequest);

const saveTemplateFindingRequest = async ({ control, finding }, { getState }) => {
  const { editor } = getState();

  const data = {
    categories: [
      {
        id: control.categoryId,
        controls: [
          {
            id: control.id,
            findings: [finding],
          },
        ],
      },
    ],
  };

  const payload = {
    'assessment-token': editor.template.id,
    template: JSON.stringify(data),
  };
  return axios.post(SetAssessmentTemplate.url, payload, { withCredentials: true });
};

export const saveTemplateFinding = createAsyncThunk(
  'saveTemplateFinding',
  saveTemplateFindingRequest,
);

const saveTemplateRecommendationRequest = async (
  { control, finding, recommendation },
  { getState },
) => {
  const { editor } = getState();

  const data = {
    categories: [
      {
        id: control.categoryId,
        controls: [
          {
            id: control.id,
            findings: [
              {
                id: finding.id,
                recommendations: [recommendation],
              },
            ],
          },
        ],
      },
    ],
  };

  const payload = {
    'assessment-token': editor.template.id,
    template: JSON.stringify(data),
  };
  return axios.post(SetAssessmentTemplate.url, payload, { withCredentials: true });
};

export const saveTemplateRecommendation = createAsyncThunk(
  'saveTemplateRecommendation',
  saveTemplateRecommendationRequest,
);

export const uploadProfileAssessmentsTemplateLogoRequest = (file, { getState }) => {
  const { editor } = getState();
  const payload = {
    'upload-assessments-template-logo-assessment-token': editor.template.id,
    'upload-assessments-template-logo-assessment-file': file,
  };

  return axios.post(UploadProfileAssessmentsTemplateLogo.url, payload, { withCredentials: true });
};

export const uploadProfileAssessmentsTemplateLogo = createAsyncThunk(
  'uploadProfileAssessmentsTemplateLogo',
  uploadProfileAssessmentsTemplateLogoRequest,
);

export const uploadTemplateReport = (file, templateId, abortController) => {
  const payload = {
    'template-id': templateId,
    file,
  };
  return axios.post(UploadTemplateReport.url, payload, { signal: abortController?.signal, withCredentials: true });
};

export const uploadAssessmentFiles = async (files, templateId, abortController) => {
  const payload = {
    'object-id': templateId,
    'object-type': FileObjectType.assessment,
    'file-ids': extractFileIdsFromFiles(files),
  };

  return axios.post(LinkFiles.url, payload, { signal: abortController?.signal, withCredentials: true });
};

export const removeAssessmentFileRequest = async (fileId, { getState }) => {
  const { editor } = getState();

  const payload = {
    'remove-assessment-file-_assessment-token': editor.template.id,
    'remove-assessment-file-file-id': fileId,
    objectId: editor.template.id,
  };

  return axios.post(UnlinkFile.url, payload, { withCredentials: true });
};

export const removeAssessmentFile = createAsyncThunk(
  'removeAssessmentFile',
  removeAssessmentFileRequest,
);

export const removeTemplateReport = async (templateId) => {
  const payload = {
    'template-id': templateId,
  };

  return axios.post(RemoveTemplateReport.url, payload, { withCredentials: true });
};

export const appTriggersRequest = async (appId) => {
  const payload = {
    'app-id': appId,
  };

  return axios.post(AppTriggers.url, payload, { withCredentials: true });
};

export const appTriggers = createAsyncThunk('appTriggers', appTriggersRequest);

export const getTemplateReport = async ({ assessmentId }) => {
  const payload = {
    'template-id': assessmentId,
  };

  return axios.post(GetTemplateReport.url, payload, { withCredentials: true });
};

export const moveControlBetweenCategoriesRequest = async ({ categoryId, controlId }) => {
  const payload = {
    categoryGets: categoryId,
    control: controlId,
    copy: false,
  };

  return axios.post(MoveControlBetweenCategories.url, payload, { withCredentials: true });
};

export const moveControlBetweenCategories = createAsyncThunk(
  'moveControlBetweenCategories',
  moveControlBetweenCategoriesRequest,
);
