import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// COMPONENTS
import Table from 'ui/Table';

// CONSTANTS
import ROUTES from 'constants/routes';
import { TableSource } from 'ui/Table/lib';

// HOOKS
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useFindingsTable } from 'pages/Findings/useFindingsTable';

// REDUX-API
import { selectFindingsPage } from 'pages/Findings/selectors';
import { setParam, setSorter } from 'pages/Findings/reducers';
import { getProfileFindings } from 'api/finding';
import { selectFinding } from 'pages/Assessment/finalized/findings/reducers';

// UTILS
import { shouldAppNavigateToContinuousMonitoring } from 'utils/appsUtils';
import { mixpanelTrackFindingViewed } from 'utils/mixpanel';

const FindingsList = () => {
  const { pagination, loading } = useSelector(selectFindingsPage);
  const appNavigate = useAppNavigate();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { columns, dataSource } = useFindingsTable();
  const dispatch = useDispatch();

  const onRow = ({ id: findingId, assessmentId, app, customerAssessmentGroup, relation }) => ({
    onClick: async () => {
      await dispatch(selectFinding(findingId));
      mixpanelTrackFindingViewed({
        relation: relation || 'none',
        source: 'findings table',
        streamDirection: isMyVendorsTabSelected ? 'downstream' : 'upstream',
      });
      if (customerAssessmentGroup) {
        return appNavigate(
          isMyVendorsTabSelected
            ? ROUTES.VENDOR_SHOWCASE_PREVIEW
            : ROUTES.CUSTOMER_SHOWCASE_PREVIEW,
          {
            showcaseId: customerAssessmentGroup,
          },
        );
      }

      if (shouldAppNavigateToContinuousMonitoring(app)) {
        return appNavigate(
          isMyVendorsTabSelected
            ? ROUTES.VENDOR_CONTINUOUS_MONITORING
            : ROUTES.CUSTOMER_CONTINUOUS_MONITORING,
          { assessmentId, appId: app },
        );
      }

      return appNavigate(
        isMyVendorsTabSelected
          ? ROUTES.VENDOR_ASSESSMENT_FINDINGS
          : ROUTES.CUSTOMER_ASSESSMENT_FINDINGS,
        { assessmentId },
        undefined,
        false,
        { findingId: findingId },
      );
    },
  });

  const onTableChange = (newPagination, filters, sorter) => {
    if (newPagination) dispatch(setParam({ key: 'pagination', value: newPagination }));

    const payload = {
      upstream: !isMyVendorsTabSelected,
      pagination: newPagination,
    };

    const field = sorter.field === 'assessmentName' ? 'assessment_name' : sorter.field;
    const sorterParams = {
      column: field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? sorterParams : {};
    dispatch(setSorter(params));

    dispatch(getProfileFindings(payload));
  };

  return (
    <Table
      className="findings-page__list"
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
      onChange={onTableChange}
      loading={loading}
      sortable
      onRow={onRow}
      emptyStateSource={TableSource.findingsList}
      scroll={{
        x: 1160,
      }}
    />
  );
};

export default FindingsList;
