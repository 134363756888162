// Authentication
export const Login = {
  url: 'https://api.najz-jonatan.findings-dev.co/Login',
  nonAuthorized: true,
};

export const Logout = {
  url: 'https://api.najz-jonatan.findings-dev.co/Logout',
  nonAuthorized: true,
};

export const ResetProfilePassword = {
  url: 'https://api.najz-jonatan.findings-dev.co/ResetProfilePassword',
  nonAuthorized: true,
};

export const SetProfilePassword = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfilePassword',
};

export const SetNewProfilePassword = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetNewProfilePassword',
  nonAuthorized: true,
};

// Profile
export const GetProfile = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfile',
};

export const GetOnboardingProfile = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfile',
  nonAuthorized: true,
  key: 'GetOnboardingProfile',
};

export const GetFilteredProfileAssessments = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetFilteredProfileAssessments',
};

export const GetNewProfileCustomersAssessmentsTemplates = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetNewProfileCustomersAssessmentsTemplates',
};

export const SetProfileMfa = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfileMfa',
};

export const TestProfileOtp = {
  url: 'https://api.najz-jonatan.findings-dev.co/TestProfileOtp',
};

export const SetProfile = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfile',
};

export const GetProfileSummaryCustomers = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileSummaryCustomers',
};

export const GetProfileSummarySelf = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileSummarySelf',
};

export const GetProfileAssessmentsTemplates = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileAssessmentsTemplates',
};

export const GetProfileCustomersAssessmentsTemplates = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileCustomersAssessmentsTemplates',
};

export const GetProfileCustomersGroups = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileCustomersGroups',
};

export const SetProfileLogo = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfileLogo',
};

// organizations
export const GetProfileOrganizations = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileOrganizations',
};

export const CreateOrganization = {
  url: 'https://api.najz-jonatan.findings-dev.co/CreateOrganization',
};

export const CreateOrganizationFromPending = {
  url: 'https://api.najz-jonatan.findings-dev.co/CreateOrganizationFromPending',
};

export const SetSubOrganization = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetSubOrganization',
};

export const RemoveProfileOrganization = {
  url: 'https://api.najz-jonatan.findings-dev.co/RemoveOrganizationProfile',
};

export const GetOrganizationProfiles = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetOrganizationProfiles',
  nonAuthorized: true,
};

export const RemoveOrganizationProfile = {
  url: 'https://api.najz-jonatan.findings-dev.co/RemoveOrganizationProfile',
  nonAuthorized: true,
};

export const SetProfileOrganizationRole = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfileOrganizationRole',
  nonAuthorized: true,
};

export const SetOrganizationName = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetOrganizationName',
};

export const GetProfileNotificationsTemplates = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileNotificationsTemplates',
  nonAuthorized: true,
};

export const GetOrganizationPages = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetOrganizationPages',
  nonAuthorized: true,
};

export const GetOrganizationData = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetOrganizationData',
  nonAuthorized: true,
};

export const SetOrganizationOkta = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetOrganizationOkta',
  nonAuthorized: true,
};

export const AddOrganizationProfile = {
  url: 'https://api.najz-jonatan.findings-dev.co/AddOrganizationProfile',
  nonAuthorized: true,
};

export const SetDefaultNotification = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetDefaultNotification',
  nonAuthorized: true,
};

export const SetProfileNotificationTemplate = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfileNotificationTemplate',
  nonAuthorized: true,
};

export const SetOrganizationPage = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetOrganizationPage',
  nonAuthorized: true,
};

export const AddOrganizationVendor = {
  url: 'https://api.najz-jonatan.findings-dev.co/AddOrganizationVendor',
};

export const AddOrganizationCustomer = {
  url: 'https://api.najz-jonatan.findings-dev.co/AddOrganizationCustomer',
};

// Event Logs
export const GetOrganizationAssessmentsLog = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetOrganizationAssessmentsLog',
};

// Messages
export const GetProfileChatThreads = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileChatThreads',
};

export const GetUnreadChatThreads = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetUnreadChatMsgs',
};

export const MarkAllAsRead = {
  url: 'https://api.najz-jonatan.findings-dev.co/MarkAsRead',
};

export const TrustPartners = {
  url: 'https://api.najz-jonatan.findings-dev.co/TrustPartners',
};

export const RequestTrust = {
  url: 'https://api.najz-jonatan.findings-dev.co/RequestTrust',
};

export const EstablishTrust = {
  url: 'https://api.najz-jonatan.findings-dev.co/EstablishTrust',
};

export const RejectTrustRequest = {
  url: 'https://api.najz-jonatan.findings-dev.co/RejectTrustRequest',
};

// Assessment, Recent, Vendors
export const GetFilteredProfileCustomersAssessments = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetFilteredProfileCustomersAssessments',
};

export const Webconsts = {
  url: 'https://api.najz-jonatan.findings-dev.co/Webconsts',
  nonAuthorized: true,
};

export const CloneAssessmentTemplate = {
  url: 'https://api.najz-jonatan.findings-dev.co/CloneAssessmentTemplate',
};

export const RemoveProfileAssessmentsTemplate = {
  url: 'https://api.najz-jonatan.findings-dev.co/RemoveProfileAssessmentsTemplate',
};

export const LinkFile = {
  url: 'https://api.najz-jonatan.findings-dev.co/LinkFile',
};

export const LinkFiles = {
  url: 'https://api.najz-jonatan.findings-dev.co/LinkFiles',
};

export const DownloadFile = {
  url: 'https://api.najz-jonatan.findings-dev.co/DownloadFile',
};

export const AddProfileVendor = {
  url: 'https://api.najz-jonatan.findings-dev.co/AddProfileVendor',
};

export const GetProfileVendors = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileVendors',
};

export const GetProfileVendor = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileVendor',
};

export const SetProfileVendor = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfileVendor',
};

export const GetRecentAssessments = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetRecentAssessments',
};

export const GetEvidence = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetEvidence',
};

export const GetRecentVendors = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetRecentVendors',
};

export const GetRecentCustomers = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetRecentCustomers',
};

export const GetRecentFindings = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetRecentFindings',
};

export const GetAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetAssessment',
};

export const SetAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetAssessment',
};

export const SetNotes = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetNotes',
};

export const FinalizeProfileAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/FinalizeProfileAssessment',
};

export const ReviewApprovedProfileAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/ReviewApprovedProfileAssessment',
};

export const UnlinkFile = {
  url: 'https://api.najz-jonatan.findings-dev.co/UnlinkFile',
};

export const ShareCMResults = {
  url: 'https://api.najz-jonatan.findings-dev.co/ShareCMResults',
};

export const ReinviteProfileAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/ReinviteProfileAssessment',
};

export const AddProfileCustomerAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/AddProfileCustomerAssessment',
};

export const AddProfileAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/AddProfileAssessment',
};

export const GetProfileAssessmentReport = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileAssessmentReport',
};

export const RemoveProfileVendor = {
  url: 'https://api.najz-jonatan.findings-dev.co/RemoveProfileVendor',
};

export const SetProfileVendorStatus = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfileVendorStatus',
};

export const SetProfileVendorEnableStatus = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfileVendorEnableStatus',
};

export const SetProfileCustomerStatus = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfileCustomerStatus',
};

export const CollabControl = {
  url: 'https://api.najz-jonatan.findings-dev.co/CollabControl',
};

export const GetProfileChatThread = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileChatThread',
};

export const GetCategoryUnreadCount = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetCategoryUnreadCount',
};

export const NewProfileChatThread = {
  url: 'https://api.najz-jonatan.findings-dev.co/NewProfileChatThread',
};

export const AddProfileChatThreadMsg = {
  url: 'https://api.najz-jonatan.findings-dev.co/AddProfileChatThreadMsg',
};

export const GetProfileAssessmentsTemplate = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileAssessmentsTemplate',
};

export const SetAssessmentTemplate = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetAssessmentTemplate',
};

// Import Wizard
export const GetExcelSuggestedQuestions = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetExcelSuggestedQuestions',
};

export const UploadOfflineAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/UploadOfflineAssessment',
};

export const GetDocumentSuggestedQuestions = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetDocumentSuggestedQuestions',
};

export const FillDocumentAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/FillDocumentAssessment',
};

export const GetAnswerTypes = {
  url: 'https://api.najz-jonatan.findings-dev.co/administrator/GetAnswerTypes',
};

export const SetAnswerType = {
  url: 'https://api.najz-jonatan.findings-dev.co/administrator/SetAnswerType',
};

export const UploadProfileAssessmentsTemplateLogo = {
  url: 'https://api.najz-jonatan.findings-dev.co/UploadProfileAssessmentsTemplateLogo',
};

export const UploadTemplateReport = {
  url: 'https://api.najz-jonatan.findings-dev.co/UploadTemplateReport',
};

export const RemoveTemplateReport = {
  url: 'https://api.najz-jonatan.findings-dev.co/RemoveTemplateReport',
};

export const AddProfileCustomer = {
  url: 'https://api.najz-jonatan.findings-dev.co/AddProfileCustomer',
};

export const GetProfileCustomers = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileCustomers',
};

export const GetProfileCustomer = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileCustomer',
};

export const SetProfileCustomer = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfileCustomer',
};

export const RemoveProfileCustomer = {
  url: 'https://api.najz-jonatan.findings-dev.co/RemoveProfileCustomer',
};

export const GetAnswerStaticRepository = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetAnswerStaticRepository',
};

export const EditFileMetaData = {
  url: 'https://api.najz-jonatan.findings-dev.co/EditFileMetaData',
};

export const GetFileMetaData = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetFileMetaData',
};

export const AppTriggers = {
  url: 'https://api.najz-jonatan.findings-dev.co/AppTriggers',
};

export const Apps = {
  url: 'https://api.najz-jonatan.findings-dev.co/apps/list',
};

export const SetAppsParams = {
  url: 'https://api.najz-jonatan.findings-dev.co/apps/SetAppsParams',
};

export const ChangeAppState = {
  url: 'https://api.najz-jonatan.findings-dev.co/apps/ChangeServiceState',
};

export const AppsFilters = {
  url: 'https://api.najz-jonatan.findings-dev.co/AppsFilters',
};

export const UploadSBOMFile = {
  url: 'https://api.najz-jonatan.findings-dev.co/UploadSBOMFile',
};

export const RemoveSBOMFile = {
  url: 'https://api.najz-jonatan.findings-dev.co/RemoveSBOMFile',
};

export const GetProfileAssessmentReports = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileAssessmentReports',
};

export const GetAssessmentCsv = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetAssessmentCsv',
};

export const ArchiveProfileAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/ArchiveProfileAssessment',
};

export const SetAssessmentsOrganization = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetAssessmentsOrganization',
};

export const RestoreProfileAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/RestoreProfileAssessment',
};

export const GetTemplateReport = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetTemplateReport',
};

// FINDINGS
export const GetNewProfileFindings = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetNewProfileFindings',
};

export const GetProfileSubjectsFindings = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileSubjectsFindings',
};

// Showcase

export const GetAssignedShowcase = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetAssignedShowcase',
};
export const AssignShowcase = {
  url: 'https://api.najz-jonatan.findings-dev.co/AssignShowcase',
};
export const EditShowcaseTemplate = {
  url: 'https://api.najz-jonatan.findings-dev.co/EditShowcaseTemplate',
};

export const AddShowcaseTemplate = {
  url: 'https://api.najz-jonatan.findings-dev.co/AddShowcaseTemplate',
};

export const GetShowcasesTemplates = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetShowcasesTemplates',
};

export const GetAssignedShowcases = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetAssignedShowcases',
};

export const MoveControlBetweenCategories = {
  url: 'https://api.najz-jonatan.findings-dev.co/MoveControlBetweenCategories',
};

export const GetOrganizationAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetOrganizationAssessment',
};

export const AddProfileCustomerBulk = {
  url: 'https://api.najz-jonatan.findings-dev.co/AddProfileCustomerBulk',
};

export const AddAppTrigger = {
  url: 'https://api.najz-jonatan.findings-dev.co/AddAppTrigger',
};

export const RemoveAppTrigger = {
  url: 'https://api.najz-jonatan.findings-dev.co/RemoveAppTrigger',
};

export const RevertFinalizeAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/RevertFinalizeAssessment',
};

export const RevertReviewAssessment = {
  url: 'https://api.najz-jonatan.findings-dev.co/RevertReviewAssessment',
};

// Upstream AI
export const GetExplainedControl = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetExplainedControl',
};

export const GetAnswerExample = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetAnswerExample',
};

export const GetImprovedAnswer = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetImprovedAnswer',
};
export const GetProfileCustomerBulkLog = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetProfileCustomerBulkLog',
};

// Downstream AI
export const AuditControl = {
  url: 'https://api.najz-jonatan.findings-dev.co/AuditControl',
};

export const ConfirmAuditFinding = {
  url: 'https://api.najz-jonatan.findings-dev.co/ConfirmAuditFinding',
};

export const RunContinuousMonitoring = {
  url: 'https://api.najz-jonatan.findings-dev.co/RunContinuousMonitoring',
};

export const RRFreemiumProgress = {
  url: 'https://api.najz-jonatan.findings-dev.co/RRFreemiumProgress',
};

export const FreemiumDetails = {
  url: 'https://api.najz-jonatan.findings-dev.co/FreemiumDetails',
};

export const PurchaseRRTokens = {
  url: 'https://api.najz-jonatan.findings-dev.co/PurchaseRRTokens',
};

export const RRFull = {
  url: 'https://api.najz-jonatan.findings-dev.co/RRFull',
};

export const RRPart = {
  url: 'https://api.najz-jonatan.findings-dev.co/RRPart',
};

export const SetProfileVendorBoUsers = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfileVendorBoUsers',
};

export const GetCustomersAssessmentsForShowcase = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetCustomersAssessmentsForShowcase',
};

// translations
export const SetTranslationFile = {
  url: 'https://api.najz-jonatan.findings-dev.co/administrator/SetTranslationFile',
};
export const GetTranslationFile = {
  url: 'https://api.najz-jonatan.findings-dev.co/administrator/GetTranslationFile',
};

export const GenerateCloudAnswers = {
  url: 'https://api.najz-jonatan.findings-dev.co/GenerateCloudAnswers',
};

export const SaveNoteOnRelation = {
  url: 'https://api.najz-jonatan.findings-dev.co/SaveNoteOnRelation',
};

export const RemoveCloudAnswers = {
  url: 'https://api.najz-jonatan.findings-dev.co/RemoveCloudAnswers',
};

// Files
export const GetOrganizationFileGallery = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetOrganizationFileGallery',
};

export const UploadOrganizationFileGallery = {
  url: 'https://api.najz-jonatan.findings-dev.co/gallery/file',
};

export const SetFileName = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetFileName',
};

export const DeleteFile = {
  url: 'https://api.najz-jonatan.findings-dev.co/DeleteFile',
};

export const GetOrganizationSecurity = {
  url: 'https://api.najz-jonatan.findings-dev.co/GetOrganizationSecurity',
};

export const SetOrganizationSecurity = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetOrganizationSecurity',
};

export const SetProfileMfaOnSession = {
  url: 'https://api.najz-jonatan.findings-dev.co/SetProfileMfaOnSession',
};

export const SendMfaViaEmail = {
  url: 'https://api.najz-jonatan.findings-dev.co/SendMfaViaEmail',
};
