import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FillDocumentAssessment,
  GetDocumentSuggestedQuestions,
  GetExcelSuggestedQuestions,
  UploadOfflineAssessment,
} from 'api/endpoints';

const getExcelSuggestedQuestionsRequest = ({ file }) => {
  const payload = {
    assessment: file,
  };

  return axios.post(GetExcelSuggestedQuestions.url, payload, { withCredentials: true });
};

export const getExcelSuggestedQuestions = createAsyncThunk(
  'getExcelSuggestedQuestions',
  getExcelSuggestedQuestionsRequest,
);

const saveOfflineAssessmentRequest = ({ file, structure }, { getState }) => {
  const { app } = getState();

  const payload = {
    assessment: file,
    structure: JSON.stringify(structure),
    upstream: !app?.isMyVendorsTabSelected,
  };

  return axios.post(UploadOfflineAssessment.url, payload, { withCredentials: true });
};

export const saveOfflineAssessment = createAsyncThunk(
  'saveOfflineAssessment',
  saveOfflineAssessmentRequest,
);

export const getDocumentSuggestedQuestions = ({ file }) => {
  const payload = {
    assessment: file,
  };
  return axios.post(GetDocumentSuggestedQuestions.url, payload, { withCredentials: true });
};

export const fillDocumentAssessment = ({ file, upstream }) => {
  const payload = {
    assessment: file,
    questions: '[]',
    upstream,
  };
  return axios.post(FillDocumentAssessment.url, payload, { withCredentials: true });
};
