import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { translate } from 'utils/index';
import Select from 'ui/Select';
import PageHeader from 'components/PageHeader';
import { setDisplayAmount } from 'pages/Recent/reducers';
import { useIsMyVendorsTabSelected, useAppNavigate } from 'hooks/navigation';
import { getRecentAssessments, getRecentFindings, getRecentVendors } from 'api/recent';

import RecentVendors from './vendors';
import RecentCustomers from './customers';
import RecentAssessments from './assessments';
import RecentFindings from './findings';
import RecentEvidences from './evidences';
import { messages } from './messages';
import ROUTES, { ROUTES_TAB } from 'constants/routes';
import { useRole } from 'hooks/useRole';
import './index.scss';

const Recent = () => {
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const { userRole, isBoRole, isSoRole } = useRole();

  useEffect(() => {
    const pathName = window?.location?.pathname;
    if (isBoRole && pathName?.includes(ROUTES_TAB.CUSTOMER)) {
      appNavigate(ROUTES.VENDOR_RECENT);
    }
    if (isSoRole && window?.location?.pathname?.includes(ROUTES_TAB.VENDOR)) {
      appNavigate(ROUTES.CUSTOMER_RECENT);
    }
  }, [userRole]);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const options = [
    {
      key: 'week',
      value: 7,
      label: translate(messages.week),
    },
    {
      key: 'month',
      value: 30,
      label: translate(messages.month),
    },
  ];

  const onSelect = async (value) => {
    await dispatch(setDisplayAmount(value));
    dispatch(getRecentVendors()).unwrap();
    dispatch(getRecentAssessments()).unwrap();
    dispatch(getRecentFindings()).unwrap();
  };

  return (
    <div className="recent-page">
      <PageHeader className="recent-page__page-header">
        <span>{translate(messages.recentHeader)}</span>
        <Select
          className="recent-page__period-selector"
          options={options}
          defaultValue="week"
          onChange={onSelect}
          showSearch={false}
        />
      </PageHeader>
      {isMyVendorsTabSelected ? <RecentVendors /> : <RecentCustomers />}
      <RecentAssessments />
      <RecentFindings />
      <RecentEvidences />
    </div>
  );
};

export default Recent;
