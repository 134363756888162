import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from 'assets/svgs/close-x-cancel.svg';

// COMPONENTS
import FileIcon from 'ui/FileIcon';
import Icon from 'ui/Icon';

// HOOKS
import { useRole } from 'hooks/useRole';

// STYLES
import './index.scss';

const FileUploadItem = ({ name, type, handleEdit, handleRemove, openFileBrowser, className }) => {
  const { isRestrictedBoSoViewerRole } = useRole();
  const fileUploadItemClasses = classNames('file-upload-item', className);

  return (
    <div className={fileUploadItemClasses}>
      {!isRestrictedBoSoViewerRole && (
        <>
          {handleRemove && (
            <div className="file-upload-item__remove-icon" onClick={handleRemove}>
              <CloseIcon width={12} height={12} />
            </div>
          )}
          {handleEdit && (
            <div className="file-upload-item__edit-icon" onClick={handleEdit}>
              <Icon icon="edit" />
            </div>
          )}
        </>
      )}
      <div className="file-upload-item__body" onClick={openFileBrowser}>
        <div className="file-upload-item__file">
          <FileIcon type={type} />
        </div>
        <div className="file-upload-item__name">{name}</div>
      </div>
    </div>
  );
};

FileUploadItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  handleEdit: PropTypes.func,
  handleRemove: PropTypes.func,
  openFileBrowser: PropTypes.func,
};

export default FileUploadItem;
