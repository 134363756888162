export const messages = {
  pageTitle: {
    key: 'FindingsPage.pageTitle',
    defaultMessage: 'Findings',
  },
  organization: {
    key: 'FindingsPage.organization',
    defaultMessage: 'ORGANIZATION',
  },
  vendor: {
    key: 'FindingsPage.vendor',
    defaultMessage: 'vendor',
  },
  customer: {
    key: 'FindingsPage.customer',
    defaultMessage: 'customer',
  },
  assessment: {
    key: 'FindingsPage.assessment',
    defaultMessage: 'assessment',
  },
  subject: {
    key: 'FindingsPage.subject',
    defaultMessage: 'subject',
  },
  description: {
    key: 'FindingsPage.description',
    defaultMessage: 'description',
  },
  risk: {
    key: 'FindingsPage.risk',
    defaultMessage: 'risk',
  },
  status: {
    key: 'FindingsPage.status',
    defaultMessage: 'status',
  },
  noDescription: {
    key: 'FindingsPage.noDescription',
    defaultMessage: 'No description',
  },
  riskLow: {
    key: 'FindingsPage.riskLow',
    defaultMessage: 'Low',
  },
  riskMedium: {
    key: 'FindingsPage.riskMedium',
    defaultMessage: 'Medium',
  },
  riskhigh: {
    key: 'FindingsPage.riskhigh',
    defaultMessage: 'High',
  },
  riskCritical: {
    key: 'FindingsPage.riskCritical',
    defaultMessage: 'Critical',
  },
  riskShowstopper: {
    key: 'FindingsPage.riskShowstopper',
    defaultMessage: 'Showstopper',
  },
  statusOpen: {
    key: 'FindingsPage.statusOpen',
    defaultMessage: 'Open',
  },
  statusClosed: {
    key: 'FindingsPage.statusClosed',
    defaultMessage: 'Closed',
  },
  statusPending: {
    key: 'FindingsPage.statusPending',
    defaultMessage: 'Pending',
  },
};

export default {
  prefix: 'FindingsPage',
  messages,
};
