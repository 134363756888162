import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Tabs from 'ui/Tabs';
import PageSubheader from 'components/PageSubheader';
import { translate } from 'utils/index';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';

import ROUTES from 'constants/routes';
import { AssessmentStatus } from 'constants/assessmentStatus';

import Divider from 'ui/Divider';
import ScoreChip from 'ui/ScoreChip';

import VendorName from 'pages/Assessment/vendorName';
import Controls from 'pages/Assessment/controls';
import CategoryHeader from 'pages/Assessment/categoryHeader';
import Actions from 'pages/Assessment/actions';
import DueDate from 'pages/Assessment/dueDate';
import { countAssessmentFindings } from 'pages/Assessment/lib';
import { selectAssessment } from 'pages/Assessment/selectors';
import { messages } from 'pages/Assessment/messages';
import { getOffset } from 'utils/html';
import { selectCategory as selectCategoryReducer } from 'pages/Assessment/reducers';

import { useTabsActiveKey } from './hooks';
import Categories from './categories';
import StatusBar from './statusBar';
import Findings from './findings';
import Finding from './findings/finding';
import Status from './status';
import Recommendations from './recommendations';
import AddRecommendation from './recommendations/addRecommendation';
import SecuredEvidence from './securedEvidence';
import MarkAsReview from './markAsReview';
import { tabs } from './lib';
import { selectCurrentOrganization } from 'pages/Profile/selectors';
import { getAssessment } from 'api/assessment';

import './index.scss';

const Finalized = () => {
  const [searchParams] = useSearchParams();
  const assessment = useSelector(selectAssessment);
  const { due, title, status, assessor, categories, customerName, customerId, total_score } =
    assessment;
  const { id: organizationId } = useSelector(selectCurrentOrganization);
  const dispatch = useDispatch();

  const appNavigate = useAppNavigate();
  const activeKey = useTabsActiveKey();
  const { assessmentId } = useParams();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const categoryId = searchParams.get('categoryId');
  const findingId = searchParams.get('findingId');
  const questionnaireRoute = isMyVendorsTabSelected
    ? ROUTES.VENDOR_ASSESSMENT_QUESTIONNAIRE
    : ROUTES.CUSTOMER_ASSESSMENT_QUESTIONNAIRE;
  const findingsRoute = isMyVendorsTabSelected
    ? ROUTES.VENDOR_ASSESSMENT_FINDINGS
    : ROUTES.CUSTOMER_ASSESSMENT_FINDINGS;

  const handleGetAssessment = useCallback(async () => {
    await dispatch(
      getAssessment({
        id: assessmentId,
      }),
    ).unwrap();
  }, [assessmentId]);

  useEffect(() => {
    if (categoryId) {
      appNavigate(
        `${questionnaireRoute}/?${searchParams.toString()}`,
        { assessmentId },
        { replace: true },
      );
    }
    if (findingId) {
      appNavigate(
        `${findingsRoute}/?${searchParams.toString()}`,
        { assessmentId },
        { replace: true },
      );
    }
  }, [categoryId, findingId]);

  useEffect(() => {
    const controlId = searchParams.get('control');
    if (Object.keys(assessment).length === 0 || !controlId) {
      return null;
    }

    const control = assessment.categories
      .flatMap((category) => category.controls)
      .find((control) => control.id === controlId);

    dispatch(selectCategoryReducer(control.categoryId));

    setTimeout(() => {
      const controlElement = document.getElementById(`control-${controlId}`);
      const controlElementOffsetTop = getOffset(controlElement).top;

      window.scroll({
        top: controlElementOffsetTop - 70,
        left: 0,
        behavior: 'smooth',
      });
    }, 100);
  }, [assessment]);

  const onChange = (key) => {
    switch (key) {
      case tabs.overview:
        return appNavigate(
          isMyVendorsTabSelected ? ROUTES.VENDOR_ASSESSMENT : ROUTES.CUSTOMER_ASSESSMENT,
          { assessmentId },
          { replace: true },
        );
      case tabs.questionnaire:
        return appNavigate(questionnaireRoute, { assessmentId }, { replace: true });
      case tabs.findings:
        return appNavigate(
          isMyVendorsTabSelected
            ? ROUTES.VENDOR_ASSESSMENT_FINDINGS
            : ROUTES.CUSTOMER_ASSESSMENT_FINDINGS,
          { assessmentId },
          { replace: true },
        );
      case tabs.securedEvidence:
        return appNavigate(
          isMyVendorsTabSelected
            ? ROUTES.VENDOR_ASSESSMENT_SECURED_EVIDENCE
            : ROUTES.CUSTOMER_ASSESSMENT_SECURED_EVIDENCE,
          { assessmentId },
          { replace: true },
        );
      default:
        return null;
    }
  };

  const findingsLength = countAssessmentFindings(categories);

  return (
    <div
      className={classNames('assessment-finalized', {
        'assessment-finalized--is-for-review': AssessmentStatus.reviewed === status,
      })}
    >
      <div className="assessment-finalized__header">
        <div className="assessment-finalized__header-left">
          <PageSubheader withBackArrow className="assessment__page_header">
            {title}
          </PageSubheader>
          <div className="assessment-finalized__details">
            <DueDate due={due} />
            <Divider vertical />
            <VendorName name={customerName} id={customerId} />
          </div>
        </div>
        <div className="assessment-finalized__header-right">
          <ScoreChip value={total_score?.score || 0} />
          <Status status={status} />
          <Actions />
        </div>
      </div>
      <Tabs activeKey={activeKey} onChange={onChange} rounded>
        <Tabs.Pane data-test={tabs.overview} tab={translate(messages.overview)} key={tabs.overview}>
          <div className="assessment-finalized__overview">
            <StatusBar />
            <Categories />
          </div>
        </Tabs.Pane>
        <Tabs.Pane
          data-test={tabs.questionnaire}
          tab={translate(messages.questionnaire)}
          key={tabs.questionnaire}
        >
          <div className="assessment-finalized__questionnaire">
            <div className="assessment-finalized__questionnaire-left">
              <Categories isQuestionnaireTab />
            </div>
            <div className="assessment-finalized__questionnaire-right">
              <CategoryHeader handleGetAssessment={handleGetAssessment} />
              <Controls />
            </div>
          </div>
        </Tabs.Pane>
        {findingsLength > 0 && (
          <Tabs.Pane
            data-test={tabs.findings}
            tab={translate(messages.findings)}
            key={tabs.findings}
          >
            <div className="assessment-finalized__findings" id="assessment-finalized-findings">
              <div className="assessment-finalized__findings-left">
                <Findings />
              </div>
              <div className="assessment-finalized__findings-right">
                <Finding />
                <Recommendations />
                {assessor && <AddRecommendation />}
              </div>
            </div>
          </Tabs.Pane>
        )}
        <Tabs.Pane
          data-test={tabs.securedEvidence}
          tab={translate(messages.securedEvidence)}
          key={tabs.securedEvidence}
        >
          <SecuredEvidence relation={customerId || organizationId} />
        </Tabs.Pane>
      </Tabs>
      <MarkAsReview />
    </div>
  );
};

export default Finalized;
