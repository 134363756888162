import axios from 'axios';
import {
  GetCustomersAssessmentsForShowcase,
  GetNewProfileCustomersAssessmentsTemplates,
} from 'api/endpoints';

// UTILS
import { getAuthenticationToken, ORG_ID_AUTH_KEY } from 'utils/auth';
import { getCookie } from 'utils/apiHelpers';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAssessmentFilterDataRequest = async (args, { getState }) => {
  const {
    app: { isMyVendorsTabSelected },
  } = getState();

  const token = getAuthenticationToken();
  const orgId = getCookie(ORG_ID_AUTH_KEY);

  const payload = {
    upstream: !isMyVendorsTabSelected,
    archive: args?.archive || false,
    token,
    org_id: orgId,
  };

  return axios.get(GetNewProfileCustomersAssessmentsTemplates.url, { params: payload ,withCredentials: true});
};

export const getAssessmentsPageTypeFilter = createAsyncThunk(
  'getAssessmentsPageTypeFilter',
  getAssessmentFilterDataRequest,
);
export const getMessagesAssessmentFilter = createAsyncThunk(
  'getMessagesAssessmentFilter',
  getAssessmentFilterDataRequest,
);
export const getFindingsPageAssessmentFilter = createAsyncThunk(
  'getFindingsPageAssessmentFilter',
  getAssessmentFilterDataRequest,
);

export const getCustomersAssessmentsFilterDataRequest = async (args, { getState }) => {
  const {
    app: { isMyVendorsTabSelected },
  } = getState();

  const payload = {
    upstream: !isMyVendorsTabSelected,
  };

  return axios.get(GetCustomersAssessmentsForShowcase.url, { params: payload ,withCredentials: true});
};

export const getCustomersAssessmentsFilterData = createAsyncThunk(
  'getCustomersAssessmentsFilterDataRequest',
  getCustomersAssessmentsFilterDataRequest,
);
