import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Checkbox } from 'antd';

import Table from 'ui/Table';
import OrganizationLogo from 'ui/OrganizationLogo';
import { Sorter } from 'ui/Table/sorter';
import { getProfileOrganizations, setSubOrganization } from 'api/organizations';
import { translate } from 'utils/index';
import { useAppNavigate } from 'hooks/navigation';
import { selectOrganizationsExtended } from 'pages/Organization/selectors';
import { messages } from 'pages/Organization/messages';
import { selectIsMyVendorsTabSelected } from 'pages/App/selectors';
import { selectProfile } from 'pages/Profile/selectors';
import ROUTES, { ROUTES_TAB } from 'constants/routes';
import { DATE_FORMAT } from 'constants/date';
import { useRole } from 'hooks/useRole';
import { Roles } from 'constants/roles';
import { API_STATUS } from 'constants/api';

const OrganizationsList = ({ defaultOrganization }) => {
  const { organizations, loading } = useSelector(selectOrganizationsExtended);
  const { isRestrictedBoSoViewerRole } = useRole();
  const {
    user: {
      current_organization: { id: currentOrgId },
    },
  } = useSelector(selectProfile);
  const appNavigate = useAppNavigate();
  const isMyVendorsTabSelected = useSelector(selectIsMyVendorsTabSelected);
  const dispatch = useDispatch();

  const editOrganization = ({ id, index, role }) => {
    return appNavigate(`${ROUTES.ORGANIZATION_EDIT}/*`, {
      baseOrganization: currentOrgId,
      type: isMyVendorsTabSelected ? ROUTES_TAB.VENDOR : ROUTES_TAB.CUSTOMER,
      organizationId: id,
      '*': `?orgIndex=${index}`,
    });
  };

  const onRow = (organization) => {
    return {
      onClick: () => editOrganization(organization),
    };
  };

  const handleSubOrganizationSet = async (id) => {
    const { status } = await setSubOrganization(id);
    if (status === API_STATUS.SUCCESS) {
      dispatch(getProfileOrganizations());
    }
  };

  const columns = useMemo(() => {
    return [
      {
        title: translate(messages.orgSettingsTableDate),
        key: 'timestampCreated',
        dataIndex: 'timestampCreated',
        width: 80,
        render: (timestampCreated) => moment(timestampCreated).format(DATE_FORMAT),
        sorter: {
          compare: Sorter.DEFAULT,
        },
      },
      {
        title: translate(messages.orgSettingsTableName),
        key: 'label',
        dataIndex: 'label',
        width: 120,
        render: (label, { corporateLogo }) => (
          <div className="organizations-settings-page__organization-table-label ">
            <OrganizationLogo circle defaultLogo={!corporateLogo} size="xs" path={corporateLogo} />
            {label}
          </div>
        ),
        defaultSortOrder: 'ascend',
        sorter: {
          compare: Sorter.STRING,
        },
      },
      {
        title: translate(messages.orgSettingsTableOwner),
        key: 'email',
        dataIndex: 'email',
        width: 120,
        render: (email) => email,
        sorter: {
          compare: Sorter.STRING,
        },
      },
      {
        title: translate(messages.orgSettingsTableYourRole),
        key: 'role',
        dataIndex: 'role',
        width: 100,
        render: (role) => {
          switch (role) {
            case Roles.Admin:
              return <div> {translate(messages.Admin)} </div>;
            case Roles.BO:
              return <div> {translate(messages.BO)} </div>;
            case Roles.SO:
              return <div> {translate(messages.SO)} </div>;
            case Roles.Owner:
              return <div> {translate(messages.Owner)} </div>;
            case Roles.Reporter:
              return <div> {translate(messages.Reporter)} </div>;
            case Roles.Viewer:
              return <div> {translate(messages.Viewer)} </div>;
            default:
              return <div />;
          }
        },
        sorter: {
          compare: Sorter.DEFAULT,
        },
      },
      {
        title: translate(messages.orgSettingsTableAggregatedView),
        key: 'sub_organization',
        dataIndex: 'sub_organization',
        width: 50,
        render: (subOrganization, { id }) => {
          return (
            !isRestrictedBoSoViewerRole && (
              <div id={id}>
                {defaultOrganization !== id && (
                  <Checkbox
                    name={id}
                    defaultChecked={subOrganization}
                    onChange={() => handleSubOrganizationSet(id)}
                  />
                )}
              </div>
            )
          );
        },
      },
    ];
  }, [defaultOrganization, isRestrictedBoSoViewerRole, organizations]);

  const dataSource = (organizations || []).map((organization, index) => {
    return {
      id: organization.id,
      timestampCreated: organization.timestampCreated,
      label: organization.label,
      email: organization.email,
      role: organization.role,
      sub_organization: organization.sub_organization,
      corporateLogo: organization.corporateLogo,
      key: organization.id,
      disabled: organization.role !== Roles.Owner && organization.role !== Roles.Admin,
      index,
    };
  });

  return (
    <div className="organizations-settings-page__list">
      <Table
        className="organizations-table"
        sortDirections={['ascend', 'descend', 'ascend']}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        loading={loading}
        rowClassName={(record) => (record.disabled === true ? 'disabled-row' : '')}
        onRow={onRow}
      />
    </div>
  );
};

OrganizationsList.propTypes = {
  defaultOrganization: PropTypes.string,
};

export default OrganizationsList;
