export const messages = {
  categories: {
    key: 'Assessment.categories',
    defaultMessage: 'Categories',
  },
  back: {
    key: 'Assessment.back',
    defaultMessage: 'Back',
  },
  nextCategory: {
    key: 'Assessment.nextCategory',
    defaultMessage: 'Next category',
  },
  submitAssessment: {
    key: 'Assessment.submitAssessment',
    defaultMessage: 'Submit assessment',
  },
  dueBy: {
    key: 'Assessment.dueBy',
    defaultMessage: 'Due by:',
  },
  dueIn: {
    key: 'Assessment.dueIn',
    defaultMessage: 'Due in:',
  },
  days: {
    key: 'Assessment.days',
    defaultMessage: 'days',
  },
  today: {
    key: 'Assessment.today',
    defaultMessage: 'Today',
  },
  sharedUntil: {
    key: 'Assessment.sharedUntil',
    defaultMessage: 'Shared Until:',
  },
  vendor: {
    key: 'Assessment.vendor',
    defaultMessage: 'Vendor',
  },
  customer: {
    key: 'Assessment.customer',
    defaultMessage: 'Customer',
  },
  more: {
    key: 'Assessment.more',
    defaultMessage: 'MORE',
  },
  resendInvitation: {
    key: 'Assessment.resendInvitation',
    defaultMessage: 'Resend invitation',
  },
  shareMultipleControls: {
    key: 'Assessment.shareMultipleControls',
    defaultMessage: 'Share multiple controls',
  },
  shareWithTeammate: {
    key: 'Assessment.shareWithTeammate',
    defaultMessage: 'Share with teammate',
  },
  forceSubmitAssessment: {
    key: 'Assessment.forceSubmitAssessment',
    defaultMessage: 'Force-submit assessment',
  },
  editAssessment: {
    key: 'Assessment.editAssessment',
    defaultMessage: 'Edit assessment',
  },
  revertToInProgressState: {
    key: 'Assessment.revertToInProgressState',
    defaultMessage: 'Revert to In-Progress state',
  },
  revertToSubmittedState: {
    key: 'Assessment.revertToSubmittedState',
    defaultMessage: 'Revert to Submitted state',
  },
  invitationHasBeenSentSuccessfully: {
    key: 'Assessment.invitationHasBeenSentSuccessfully',
    defaultMessage: 'Invitation has been sent successfully',
  },
  missingPusherAppKey: {
    key: 'Assessment.missingPusherAppKey',
    defaultMessage: 'This option is currently disabled',
  },
  responseAutomationTitle: {
    key: 'Assessment.responseAutomationTitle',
    defaultMessage: 'Response Automation',
  },
  useAutomation: {
    key: 'Assessment.useAutomation',
    defaultMessage: 'Use automation',
  },
  useAlSuggestions: {
    key: 'Assessment.useAlSuggestions',
    defaultMessage: 'Use all suggestions',
  },
  noteDescription: {
    key: 'Assessment.noteDescription',
    defaultMessage:
      'Write internal note that will be visible only to members of your organization.',
  },
  lastUpdated: {
    key: 'Assessment.lastUpdated',
    defaultMessage: 'Last updated',
  },
  internal: {
    key: 'Assessment.internal',
    defaultMessage: 'internal',
  },
  saveAndClose: {
    key: 'Assessment.saveAndClose',
    defaultMessage: 'Save & Close',
  },
  activateApp: {
    key: 'Assessment.activateApp',
    defaultMessage: 'Activate app',
  },
  activateAppDescription: {
    key: 'Assessment.activateAppDescription',
    defaultMessage:
      'Activate the app now to review your responses and chose what to share before submitting the assessment.',
  },
  emailRequestSentToCloudAdmin: {
    key: 'Assessment.emailRequestSentToCloudAdmin',
    defaultMessage: 'Email request sent to cloud admin.',
  },
  weWillNotifyYouViaEmail: {
    key: 'Assessment.weWillNotifyYouViaEmail',
    defaultMessage:
      'We will notify you via email once the request is approved and the results are back for your review.',
  },
  viewResults: {
    key: 'Assessment.viewResults',
    defaultMessage: 'VIEW RESULTS',
  },
  cancelRequest: {
    key: 'Assessment.cancelRequest',
    defaultMessage: 'CANCEL REQUEST',
  },
  shareResponse: {
    key: 'Assessment.shareResponse',
    defaultMessage: 'Share response',
  },
  okGotIt: {
    key: 'Assessment.okGotIt',
    defaultMessage: 'OK, GOT IT',
  },
  reviewAndShareCloudMonitoring: {
    key: 'Assessment.reviewAndShareCloudMonitoring',
    defaultMessage: 'Review and share cloud monitoring results',
  },
  reviewAndShareCloudMonitoringDescription: {
    key: 'Assessment.reviewAndShareCloudMonitoringDescription',
    defaultMessage:
      'You can review your cloud security posture and decide which controls to share with your customer. Simply toggle the "Share response" option off for any controls you prefer not to share.',
  },
  reviewAndShareCloudMonitoringDescription2: {
    key: 'Assessment.reviewAndShareCloudMonitoringDescription2',
    defaultMessage:
      'Please note: While only the responses you chose to share will be visible to the customer, non-shared controls will still appear in the report without their specific responses.',
  },
  additionalControlsNotShared: {
    key: 'Assessment.additionalControlsNotShared',
    defaultMessage: 'Additional controls not shared by the vendor:',
  },
  afterTriggeringThisApp: {
    key: 'Assessment.afterTriggeringThisApp',
    defaultMessage:
      'After triggering this app, a handshake request will be sent to your cloud provider console. We’ll also notify your cloud administrator, to ensure this handshake is approved.',
  },
  save: {
    key: 'Assessment.save',
    defaultMessage: 'SAVE',
  },
  cancel: {
    key: 'Assessment.cancel',
    defaultMessage: 'CANCEL',
  },
  question: {
    key: 'Assessment.question',
    defaultMessage: 'QUESTION',
  },
  continouosMonitoring: {
    key: 'Assessment.continouosMonitoring',
    defaultMessage: 'Continuous Monitoring',
  },
  questionLabel: {
    key: 'Assessment.questionLabel',
    defaultMessage: 'Question',
  },
  answer: {
    key: 'Assessment.answer',
    defaultMessage: 'ANSWER',
  },
  finding: {
    key: 'Assessment.finding',
    defaultMessage: 'Finding',
  },
  risk: {
    key: 'Assessment.risk',
    defaultMessage: 'RISK',
  },
  recommendation: {
    key: 'Assessment.recommendation',
    defaultMessage: 'Recommendation',
  },
  vendorMustUploadEvidence: {
    key: 'Assessment.vendorMustUploadEvidence',
    defaultMessage: 'Vendor must upload evidence',
  },
  noteTitle: {
    key: 'Assessment.noteTitle',
    defaultMessage: 'Note',
  },
  enterNote: {
    key: 'Assessment.enterNote',
    defaultMessage: 'Enter note',
  },
  note: {
    key: 'Assessment.note',
    defaultMessage: 'NOTE',
  },
  enterDescription: {
    key: 'Assessment.enterDescription',
    defaultMessage: 'Enter description',
  },
  selectQuestion: {
    key: 'Assessment.selectQuestion',
    defaultMessage: 'Select question',
  },
  findingsText: {
    key: 'Assessment.findingsText',
    defaultMessage: 'FINDINGS',
  },
  addFinding: {
    key: 'Assessment.addFinding',
    defaultMessage: 'ADD FINDING',
  },
  chat: {
    key: 'Assessment.chat',
    defaultMessage: 'CHAT',
  },
  addRecommendation: {
    key: 'Assessment.addRecommendation',
    defaultMessage: 'Add recommendation',
  },
  removeRecommendation: {
    key: 'Assessment.removeRecommendation',
    defaultMessage: 'Remove recommendation',
  },
  removeRecommendationPopup: {
    key: 'Assessment.removeRecommendationPopup',
    defaultMessage: 'Are you sure you want to remove the recommendation?',
  },
  removeFinding: {
    key: 'Assessment.removeFinding',
    defaultMessage: 'Remove finding',
  },
  removeFindingPopup: {
    key: 'Assessment.removeFindingPopup',
    defaultMessage: 'Are you sure you want to remove the finding?',
  },
  descriptionIsRequired: {
    key: 'Assessment.descriptionIsRequired',
    defaultMessage: 'Description is required',
  },
  questionIsRequired: {
    key: 'Assessment.questionIsRequired',
    defaultMessage: 'Question is required',
  },
  selectLabel: {
    key: 'Assessment.selectLabel',
    defaultMessage: 'DUE DATE',
  },
  submit: {
    key: 'Assessment.submit',
    defaultMessage: 'Your assessment is completed. Submit to {{customer}} for review.',
  },
  submitDescription: {
    key: 'Assessment.submitDescription',
    defaultMessage: "After submit you won't be able to change answer",
  },
  didntFinishYet: {
    key: 'Assessment.didntFinishYet',
    defaultMessage: "Didn't finish yet",
  },
  assessmentLockdown: {
    key: 'Assessment.assessmentLockdown',
    defaultMessage: 'Assessment Lockdown',
  },
  calculatingScore: {
    key: 'Assessment.calculatingScore',
    defaultMessage: 'Calculating Score',
  },
  calculatingGaps: {
    key: 'Assessment.calculatingGaps',
    defaultMessage: 'Calculating Gaps',
  },
  detectingAdvice: {
    key: 'Assessment.detectingAdvice',
    defaultMessage: 'Detecting Advice',
  },
  determiningAssessmentResult: {
    key: 'Assessment.determiningAssessmentResult',
    defaultMessage: 'Determining Assessment Result',
  },
  pleaseWaitWhileFindingsCalculatesYourPosture: {
    key: 'Assessment.pleaseWaitWhileFindingsCalculatesYourPosture',
    defaultMessage: 'Please wait while Findings calculates your posture!',
  },
  submittingAssessment: {
    key: 'Assessment.submittingAssessment',
    defaultMessage: 'Submitting assessment',
  },
  stepOneOf: {
    key: 'Assessment.stepOneOf',
    defaultMessage: 'Step {{step}} of {{countSteps}}',
  },
  submitCompleteStep1CustomerExist: {
    key: 'Assessment.submitCompleteStep1CustomerExist',
    defaultMessage: 'Your assessment is submitted to {{customerName}} review.',
  },
  continue: {
    key: 'Assessment.continue',
    defaultMessage: 'Continue',
  },
  saveHoursSentence: {
    key: 'Assessment.saveHoursSentence',
    defaultMessage: 'We are here to help you!',
  },
  ownAnswersSentence: {
    key: 'Assessment.ownAnswersSentence',
    defaultMessage: 'Findings helps you',
  },
  ownAnswersSentence2: {
    key: 'Assessment.ownAnswersSentence2',
    defaultMessage: 'optimize RFP & Security questionnaires',
  },
  ownAnswersSentence3: {
    key: 'Assessment.ownAnswersSentence3',
    defaultMessage: 'response time',
  },
  customizableSentence: {
    key: 'Assessment.customizableSentence',
    defaultMessage: 'Have your next RFP or Security questionnaire ready',
  },
  customizableSentence2: {
    key: 'Assessment.customizableSentence2',
    defaultMessage: '60% faster',
  },
  customizableSentence3: {
    key: 'Assessment.customizableSentence3',
    defaultMessage: 'with Findings AutoSuggest',
  },
  tryForFree: {
    key: 'Assessment.tryForFree',
    defaultMessage: 'Learn how to do that',
  },
  noThanks: {
    key: 'Assessment.noThanks',
    defaultMessage: 'No, thank you',
  },
  pleaseAnswerAllQuestions: {
    key: 'Assessment.pleaseAnswerAllQuestions',
    defaultMessage: 'Please answer all mandatory questions',
  },
  assessmentIsFinalized: {
    key: 'Assessment.assessmentIsFinalized',
    defaultMessage: 'Assessment is submitted and pending for review',
  },
  customDaysInputLabel: {
    key: 'Assessment.customDaysInputLabel',
    defaultMessage: 'DAYS TO SUBMIT:',
  },
  customDaysInputPlaceholder: {
    key: 'Assessment.customDaysInputPlaceholder',
    defaultMessage: 'Enter number',
  },
  oneWeek: {
    key: 'Assessment.oneWeek',
    defaultMessage: '1 Week',
  },
  twoWeeks: {
    key: 'Assessment.twoWeeks',
    defaultMessage: '2 Weeks',
  },
  oneMonth: {
    key: 'Assessment.oneMonth',
    defaultMessage: '1 Month',
  },
  customDueDate: {
    key: 'Assessment.customDueDate',
    defaultMessage: 'Custom...',
  },
  submitWithoutCloudMonitoringReview: {
    key: 'Assessment.submitWithoutCloudMonitoringReview',
    defaultMessage: 'Submit without cloud monitoring review',
  },
  submitWithoutCloudMonitoringReviewDescription: {
    key: 'Assessment.submitWithoutCloudMonitoringReviewDescription',
    defaultMessage:
      "By submitting now, you'll share all cloud monitoring results as they are. If you'd like to review and choose what to share, you can still do so. Would you like to submit now or review first?",
  },
  reviewFirst: {
    key: 'Assessment.reviewFirst',
    defaultMessage: 'REVIEW FIRST',
  },
  submitNow: {
    key: 'Assessment.submitNow',
    defaultMessage: 'SUBMIT NOW',
  },
  submitWithoutCloudMonitoringActivateDescription: {
    key: 'Assessment.submitWithoutCloudMonitoringActivateDescription',
    defaultMessage:
      "Looks like you haven't activated the cloud monitoring review yet. This feature helps you to selectively share specific results with your customer. Would you like to activate it now and customize what to share, or proceed without this step?",
  },
  activateReview: {
    key: 'Assessment.activateReview',
    defaultMessage: 'ACTIVATE REVIEW',
  },
  proceedAnyway: {
    key: 'Assessment.proceedAnyway',
    defaultMessage: 'PROCEED ANYWAY',
  },
  overview: {
    key: 'Assessment.overview',
    defaultMessage: 'Overview',
  },
  questionnaire: {
    key: 'Assessment.questionnaire',
    defaultMessage: 'Questionnaire',
  },
  findings: {
    key: 'Assessment.findings',
    defaultMessage: 'Findings',
  },
  securedEvidence: {
    key: 'Assessment.securedEvidence',
    defaultMessage: 'Secured evidence',
  },
  score: {
    key: 'Assessment.score',
    defaultMessage: 'Score',
  },
  status: {
    key: 'Assessment.status',
    defaultMessage: 'Status',
  },
  openFindings: {
    key: 'Assessment.openFindings',
    defaultMessage: 'Open findings',
  },
  recommendations: {
    key: 'Assessment.recommendations',
    defaultMessage: 'Recommendations',
  },
  overdueRecommendations: {
    key: 'Assessment.overdueRecommendations',
    defaultMessage: 'Overdue recommendations',
  },
  name: {
    key: 'Assessment.name',
    defaultMessage: 'NAME',
  },
  approved: {
    key: 'Assessment.approved',
    defaultMessage: 'APPROVED',
  },
  notApproved: {
    key: 'Assessment.notApproved',
    defaultMessage: 'NOT APPROVED',
  },
  scoreBenchmark: {
    key: 'Assessment.scoreBenchmark',
    defaultMessage: 'SCORE/BENCHMARK',
  },
  findingsColumn: {
    key: 'Assessment.findingsColumn',
    defaultMessage: 'FINDINGS',
  },
  viewEditFindings: {
    key: 'Assessment.viewEditFindings',
    defaultMessage: 'View/edit findings',
  },
  all: {
    key: 'Assessment.all',
    defaultMessage: 'All',
  },
  generalFiles: {
    key: 'Assessment.generalFiles',
    defaultMessage: 'General files',
  },
  browse: {
    key: 'Assessment.browse',
    defaultMessage: 'Browse',
  },
  filesAssociatedToQuestions: {
    key: 'Assessment.filesAssociatedToQuestions',
    defaultMessage: 'Files associated to questions',
  },
  completed: {
    key: 'Assessment.completed',
    defaultMessage: 'COMPLETED',
  },
  submitted: {
    key: 'Assessment.submitted',
    defaultMessage: 'SUBMITTED',
  },
  customerReview: {
    key: 'Assessment.customerReview',
    defaultMessage: 'CUSTOMER REVIEW',
  },
  notStarted: {
    key: 'Assessment.notStarted',
    defaultMessage: 'NOT STARTED',
  },
  inProgress: {
    key: 'Assessment.inProgress',
    defaultMessage: 'IN PROGRESS',
  },
  assessment: {
    key: 'Assessment.assessment',
    defaultMessage: 'ASSESSMENT',
  },
  markAsReview: {
    key: 'Assessment.markAsReview',
    defaultMessage: 'Mark as review',
  },
  controlsSelected: {
    key: 'Assessment.controlsSelected',
    defaultMessage: 'controls selected',
  },
  clickToContinue: {
    key: 'Assessment.clickToContinue',
    defaultMessage: 'CLICK TO CONTINUE',
  },
  addComment: {
    key: 'Assessment.addComment',
    defaultMessage: 'Add a comment',
  },
  enterEmailAddress: {
    key: 'Assessment.enterEmailAddress',
    defaultMessage: 'Enter email address',
  },
  share: {
    key: 'Assessment.share',
    defaultMessage: 'SHARE',
  },
  shareControls: {
    key: 'Assessment.shareControls',
    defaultMessage: 'Share controls',
  },
  controls: {
    key: 'Assessment.controls',
    defaultMessage: 'CONTROLS',
  },
  emailErrorFormat: {
    key: 'Assessment.emailErrorFormat',
    defaultMessage: 'Email format is invalid',
  },
  controlErrorFormat: {
    key: 'Assessment.controlErrorFormat',
    defaultMessage: 'Control is required',
  },
  requiredEmail: {
    key: 'Assessment.requiredEmail',
    defaultMessage: 'Email is required',
  },
  questionCollaboratedSuccessfully: {
    key: 'Assessment.questionCollaboratedSuccessfully',
    defaultMessage: 'Question collaborated successfully',
  },
  toSharePleaseSelectControls: {
    key: 'Assessment.toSharePleaseSelectControls',
    defaultMessage: 'To share please select controls &',
  },
  questionInputPlaceholder: {
    key: 'Assessment.questionInputPlaceholder',
    defaultMessage: 'Type your answer...',
  },
  chatInputPlaceholderVensors: {
    key: 'Assessment.chatInputPlaceholderVensors',
    defaultMessage: 'Write a message to vendor',
  },
  chatInputPlaceholderCustomers: {
    key: 'Assessment.chatInputPlaceholderCustomers',
    defaultMessage: 'Write a message to customer',
  },
  importWizardModalSuccessTitle: {
    key: 'Assessment.importWizardModalSuccessTitle',
    defaultMessage: 'Your assessment has been successfully uploaded.',
  },
  importWizardModalCloseButton: {
    key: 'Assessment.importWizardModalCloseButton',
    defaultMessage: 'Continue to edtior',
  },
  errorNavigationText: {
    key: 'Assessment.errorNavigationText',
    defaultMessage: 'Please answer all required questions',
  },
  errorNavigationRequired: {
    key: 'Assessment.errorNavigationRequired',
    defaultMessage: 'Required',
  },
  pleaseEnterValidWebsite: {
    key: 'Assessment.pleaseEnterValidWebsite',
    defaultMessage: 'Please enter a valid website address',
  },
  pleaseEnterValidEmail: {
    key: 'Assessment.pleaseEnterValidEmail',
    defaultMessage: 'Please enter a valid email address',
  },
  pleaseEnterValidAwsAccountId: {
    key: 'Assessment.pleaseEnterValidAwsAccountId',
    defaultMessage: 'Please enter a valid AWS account ID',
  },
  pleaseEnterValidAzureSubscriptionId: {
    key: 'Assessment.pleaseEnterValidAzureSubscriptionId',
    defaultMessage: 'Please enter a valid Azure subscription ID',
  },
  forceSubmitHeader: {
    key: 'Assessment.forceSubmitHeader',
    defaultMessage: 'Submit assessment?',
  },
  collabSharedWith: {
    key: 'Assessment.collabSharedWith',
    defaultMessage: 'Shared with',
  },
  collabOn: {
    key: 'Assessment.collabOn',
    defaultMessage: 'on',
  },
  responseAutomationText: {
    key: 'Assessment.responseAutomationText',
    defaultMessage: `Yay! We found answers to {{number}} controls in this assessment, based on your previous answers in the system. You can accept all suggestions, go over them one-by-one, or edit them as you wish.`,
  },
  forceSubmitDescription: {
    key: 'Assessment.forceSubmitDescription',
    defaultMessage: `You can submit this assessment even though not all controls were responded by the vendor. This will allow you to start the audit process and to trigger any findings and apps, and will prevent the vendor from continuing to respond.`,
  },
  shareControlsDescription: {
    key: 'Assessment.shareControlsDescription',
    defaultMessage:
      'Findings allows you to share controls via email, without inviting additional users to the system, to help you respond as quickly and easily as possible. We’ll <strong>automatically insert their reply to the email into the assessment.</strong>',
  },
};

export default {
  prefix: 'Assessment',
  messages,
};
