import React from 'react';
import PropTypes from 'prop-types';

const ErrorFilledIcon = ({ color = 'currentColor', height = 24, width = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5668 2C7.03683 2 2.56683 6.47 2.56683 12C2.56683 17.53 7.03683 22 12.5668 22C18.0968 22 22.5668 17.53 22.5668 12C22.5668 6.47 18.0968 2 12.5668 2ZM17.2738 15.293C17.6648 15.684 17.6648 16.316 17.2738 16.707C17.0788 16.902 16.8228 17 16.5668 17C16.3108 17 16.0548 16.902 15.8598 16.707L12.5668 13.414L9.27383 16.707C9.07883 16.902 8.82283 17 8.56683 17C8.31083 17 8.05483 16.902 7.85983 16.707C7.46883 16.316 7.46883 15.684 7.85983 15.293L11.1528 12L7.85983 8.707C7.46883 8.316 7.46883 7.684 7.85983 7.293C8.25083 6.902 8.88283 6.902 9.27383 7.293L12.5668 10.586L15.8598 7.293C16.2508 6.902 16.8828 6.902 17.2738 7.293C17.6648 7.684 17.6648 8.316 17.2738 8.707L13.9808 12L17.2738 15.293Z"
      fill={color}
    />
  </svg>
);

ErrorFilledIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ErrorFilledIcon;
