import { createSelector } from '@reduxjs/toolkit';
import { availableFilters } from './VendorsList/lib';
import { selectIsViewAggregated } from 'pages/Organization/selectors';

export const selectAvailableFilters = createSelector(
  ({ vendors }) => vendors,
  selectIsViewAggregated,
  ({ filters }, isViewAggregated) => {
    const filteredAvailableFilters = isViewAggregated
      ? availableFilters
      : availableFilters.filter(({ type }) => type !== 'parent-organization-filter');

    return filteredAvailableFilters.filter(
      ({ type }) => !filters.some((filter) => filter.type === type),
    );
  },
);

export const selectFilters = createSelector(
  ({ vendors }) => vendors,
  ({ filters }) => filters,
);
