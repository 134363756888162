import axios from 'axios';
import * as XLSX from 'xlsx';

export const convertExcelToJson = async (url) => {
  const options = {
    url,
    method: 'GET',
    responseType: 'arraybuffer',
  };
  const axiosResponse = await axios(options);
  const uint8Array = new Uint8Array(axiosResponse);
  const workbook = XLSX.read(uint8Array, {
    type: 'array',
  });

  return workbook.SheetNames.map((sheetName) => {
    const worksheet = workbook.Sheets[sheetName];
    const json = XLSX.utils.sheet_to_json(worksheet);

    const jsonSheetHeadings = json.reduce((acc, curr) => {
      const keys = Object.keys(curr);
      if (keys.length > acc.length) {
        return [keys.map((key) => ({ value: key }))];
      }
      return acc;
    }, []);

    const jsonSheetData = json.map((row) => {
      return Object.keys(row).map((key) => {
        return { value: row[key] };
      });
    });

    return { sheetName, sheetData: jsonSheetHeadings.concat(jsonSheetData) };
  });
};

export const convertDocToBlob = async (url) => {
  const options = {
    url,
    method: 'GET',
    responseType: 'blob',
  };
  const axiosResponse = await axios(options);
  const link = document.createElement('a');
  link.href = URL.createObjectURL(axiosResponse);

  return link;
};
