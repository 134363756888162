import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';

import { useIsMyVendorsTabSelected } from 'hooks/navigation';

import { translate } from 'utils/index';

import Tabs from 'ui/Tabs';
import Button from 'ui/Button';

import { ReactComponent as UploadIcon } from 'assets/svgs/upload-icon.svg';
import { ReactComponent as ArrowIconRight } from 'assets/svgs/arrowRight.svg';

import PageHeader from 'components/PageHeader';
import NewVendorFooter from 'components/NewVendorFooter';
import UploadFiles from 'components/UploadFiles';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';

import UploadStatisticsModal from 'pages/UploadList/uploadStatisticsModal';
import Assessments from 'pages/UploadList/assessments';

import { uploadVendorCustomerBulk } from 'api/vendor';
import { getAssessmentsTemplates } from 'api/assessmentsTemplates';
import { getTemplateFile } from 'api/assessment';

import { generalMessages } from 'constants/messages';
import { API_STATUS } from 'constants/api';

import { messages } from './messages';

import './index.scss';

const UPLOAD_LIST_TABS = {
  UPLOAD_LIST: 'uploadList',
  ASSIGN_ASSESSMENT: 'assignAssessment',
};

const UploadList = () => {
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const [currentTab, setCurrentTab] = useState(UPLOAD_LIST_TABS.UPLOAD_LIST);
  const [uploadedFile, setUploadedFiles] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const navigate = useNavigate();
  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();
  const assessmentJson = useSelector(({ profile }) => profile.user._assessmentJson);

  const dispatch = useDispatch();
  const handleTabChange = (activeKey) => {
    setCurrentTab(activeKey);
  };

  useEffect(() => {
    if (uploadedFile.length) {
      setIsFileUploading(false);
    }
  }, [uploadedFile]);

  const handleDownloadTemplate = async () => {
    const response = await dispatch(getTemplateFile()).unwrap();
    if (response) {
      fileDownload(response, 'template.csv');
    }
  };

  const handleUpload = (file) => {
    setIsFileUploading(true);
    setUploadedFiles(() => {
      return [].concat(file);
    });
  };
  const handleRemove = () => {
    setUploadedFiles([]);
  };

  const handleUploadList = async (assessments, assessmentsPeriod) => {
    setIsUploading(true);
    const payload = {
      'add-customer-bulk-customers-csv': JSON.stringify(uploadedFile[0]),
      'add-customer-bulk-_assessmentJson': JSON.stringify(assessmentJson),
      'add-customer-bulk-assessments': assessments,
      'add-customer-bulk-assessments-period': assessmentsPeriod || 0,
    };
    startUploading();
    const response = await dispatch(uploadVendorCustomerBulk(payload, abortController)).unwrap();
    finishUploding();

    if (response.status === API_STATUS.SUCCESS) {
      setModalData({
        ...response.statistics,
        file_name: response.file_name,
      });
    }

    setIsUploading(false);
  };

  const handleCancel = () => {
    setModalData(null);
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getAssessmentsTemplates());
  }, []);

  return (
    <div className="upload-list">
      <PageHeader className="upload-list__header">
        <UploadIcon width={24} height={24} />
        {isMyVendorsTabSelected
          ? translate(messages.uploadVendorHeader)
          : translate(messages.uploadCustomerHeader)}
        :
      </PageHeader>
      <Tabs centeredTabs activeKey={currentTab} onChange={handleTabChange}>
        <Tabs.Pane
          data-test="upload-list-tabs"
          tab={translate(messages.uploadListTab)}
          key={UPLOAD_LIST_TABS.UPLOAD_LIST}
        >
          <div className="upload-list__upload">
            <div className="upload-list__template">
              <div className="upload-list__title">{translate(messages.templateFileHeader)}</div>
              <div className="upload-list__description">
                {translate(messages.templateVendorFileDescription)}
              </div>
              <div className="upload-list__download-template">
                <Button
                  data-test="upload-list-button-download-template"
                  link
                  onClick={handleDownloadTemplate}
                  color="blue"
                  className="upload-list__download-template-button"
                >
                  <UploadIcon width={18} />
                  {translate(messages.templateFileDownload)}
                </Button>
              </div>
            </div>
            <div className="upload-list__template">
              <div className="upload-list__title">{translate(messages.uploadFileHeader)}</div>
              <div className="upload-list__upload-form">
                <UploadFiles
                  accept={'csv'}
                  multiple={false}
                  className="upload-list__upload-files"
                  uploadedFiles={uploadedFile}
                  handleUpload={handleUpload}
                  handleRemove={handleRemove}
                  loading={isFileUploading}
                  edit={false}
                />
              </div>
            </div>
            <NewVendorFooter
              nextAfterComponent={<ArrowIconRight width={13} height={14} />}
              nextAction={() => {
                handleTabChange(UPLOAD_LIST_TABS.ASSIGN_ASSESSMENT);
              }}
              prevAction={handleCancel}
              nextLabel={translate(generalMessages.next)}
              prevLabel={translate(generalMessages.cancel)}
              isNextLoading={false}
              dataTestPrev="vendor-assign-assessment-back"
              dataTestNext="vendor-assign-assessment-save"
            />
          </div>
        </Tabs.Pane>
        <Tabs.Pane
          tab={translate(messages.assignAssessmentTab)}
          key={UPLOAD_LIST_TABS.ASSIGN_ASSESSMENT}
        >
          <div className="upload-list__assessments">
            <Assessments
              onSubmit={handleUploadList}
              isNextDisabled={uploadedFile.length === 0}
              isLoading={isUploading}
              handleBackTab={() => {
                handleTabChange(UPLOAD_LIST_TABS.UPLOAD_LIST);
              }}
            />
          </div>
        </Tabs.Pane>
      </Tabs>
      <UploadStatisticsModal statistics={modalData} open={!!modalData} onHide={handleCancel} />
      {renderConfirmationModal()}
    </div>
  );
};

export default UploadList;
