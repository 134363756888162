import React from 'react';
import PropTypes from 'prop-types';

const AttentionIcon = ({ className, color = 'currentColor', height = 24, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C16.7033 3.5 20.5 7.2967 20.5 12C20.5 16.7033 16.7033 20.5 12 20.5C7.29669 20.5 3.5 16.7033 3.5 12C3.5 7.2967 7.29669 3.5 12 3.5ZM11.9883 6.48926C11.7895 6.49236 11.6002 6.57423 11.4617 6.71686C11.3233 6.8595 11.2471 7.05125 11.25 7.25V13.25C11.2486 13.3494 11.267 13.4481 11.304 13.5403C11.3411 13.6325 11.3961 13.7164 11.4659 13.7872C11.5357 13.858 11.6188 13.9142 11.7105 13.9526C11.8022 13.9909 11.9006 14.0107 12 14.0107C12.0994 14.0107 12.1978 13.9909 12.2895 13.9526C12.3812 13.9142 12.4643 13.858 12.5341 13.7872C12.6039 13.7164 12.6589 13.6325 12.696 13.5403C12.733 13.4481 12.7514 13.3494 12.75 13.25V7.25C12.7514 7.14962 12.7327 7.04997 12.6949 6.95695C12.6571 6.86394 12.6011 6.77946 12.53 6.70852C12.459 6.63758 12.3745 6.58161 12.2814 6.54395C12.1883 6.50629 12.0887 6.48769 11.9883 6.48926ZM12 15.5C11.7348 15.5 11.4804 15.6054 11.2929 15.7929C11.1054 15.9804 11 16.2348 11 16.5C11 16.7652 11.1054 17.0196 11.2929 17.2071C11.4804 17.3946 11.7348 17.5 12 17.5C12.2652 17.5 12.5196 17.3946 12.7071 17.2071C12.8946 17.0196 13 16.7652 13 16.5C13 16.2348 12.8946 15.9804 12.7071 15.7929C12.5196 15.6054 12.2652 15.5 12 15.5Z"
      fill={color}
    />
  </svg>
);

AttentionIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default AttentionIcon;
