import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'ui/Icon';
import { translate } from 'utils/index';
import { saveRecommendation } from 'api/recommendation';

import { selectFinding } from 'pages/Assessment/finalized/findings/selectors';
import { messages } from './messages';
import './removeRecommendation.scss';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';

const RemoveRecommendation = ({ disabled, id }) => {
  const dispatch = useDispatch();
  const [popupOpened, setPopupOpened] = useState(false);
  const { control, id: findingId } = useSelector(selectFinding);

  const onRemove = () => {
    const payload = {
      control,
      findingId,
      recommendation: {
        id,
      },
    };

    setPopupOpened(false);
    dispatch(saveRecommendation(payload));
  };

  return (
    <div>
      <Tooltip title={translate(messages.removeRecommendation)}>
        <Button
          className="assessment-finalized-remove-recommendation"
          disabled={disabled}
          type="link"
          onClick={() => setPopupOpened(true)}
        >
          <Icon icon="remove" />
        </Button>
      </Tooltip>
      <RemoveConfirmationModal
        onCancel={() => setPopupOpened(false)}
        onOk={onRemove}
        open={popupOpened}
        headerText={translate(messages.removeRecommendationPopup)}
      />
    </div>
  );
};

RemoveRecommendation.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default RemoveRecommendation;
