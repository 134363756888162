import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

// COMPONENTS
import Button from 'ui/Button';
import TextArea from 'ui/TextArea';
import ControlAnswerPreview from 'pages/Assessment/controlAnswerPreview';
import FindingStatuses from './findingStatuses';
import GoToQuestion from './goToQuestion';
import RemoveFinding from './removeFinding';
import StatusSelect from './statusSelect';
import ControlAnswerEdit from 'pages/Assessment/controlAnswerEdit';

// REDUX
import { saveControlWithTriggers } from 'api/assessment';
import { setIsEditingAnswer } from 'pages/Assessment/reducers';
import { selectFinding } from './selectors';
import { selectAssessment } from 'pages/Assessment/selectors';
import { saveFinding } from 'api/finding';

// UTILS
import { messages } from './messages';
import { generalMessages } from 'constants/messages';
import { sanitizer } from 'utils/sanitizer';
import { translate } from 'utils/index';
import { stripHtml } from 'utils/html';
import { useRole } from 'hooks/useRole';
import { Apps } from 'constants/apps';
import { getSbomFindingsSeverity } from 'utils/sbomFindingSeverity';

// STYLES
import './finding.scss';

const Finding = () => {
  const finding = useSelector(selectFinding);
  const findingLoading = useSelector(({ assessmentFindings }) => assessmentFindings.findingLoading);
  const { assessor } = useSelector(selectAssessment);
  const isEditingAnswer = useSelector(({ assessment }) => assessment.isEditingAnswer);
  const { isRestrictedBoSoViewerRole } = useRole();
  const dispatch = useDispatch();
  const [description, setDescription] = useState(finding.description);
  const [updatedAnswer, setUpdatedAnswer] = useState({
    value: '',
    id: '',
  });
  const prevFindingsRef = useRef();

  useEffect(() => {
    if (finding.id !== prevFindingsRef.current?.id) {
      dispatch(setIsEditingAnswer(false));
    }
    setDescription(finding.description);
    prevFindingsRef.current = finding;
    setUpdatedAnswer({
      value: finding.control?.answer,
      id: finding.control?.id,
    });
  }, [finding]);

  const onSaveDebounced = useCallback(
    debounce((newValue) => {
      const payload = {
        control: finding.control,
        finding: {
          id: finding.id,
          description: newValue,
        },
      };
      dispatch(saveFinding(payload));
    }, 1000),
    [finding],
  );

  const onDescriptionChange = ({ target: { value } }) => {
    setDescription(value);
    onSaveDebounced(value);
  };

  const onAnswerChange = async (value) => {
    const control = {
      ...finding.control,
      answer: value,
    };
    await dispatch(saveControlWithTriggers(control));
    dispatch(setIsEditingAnswer(false));
  };

  return (
    <div className="assessment-finalized-finding">
      <div className="assessment-finalized-finding__question-wrapper">
        <div className="assessment-finalized-finding__question-label">
          {translate(messages.question)}:
        </div>
        <div className="assessment-finalized-finding__question">
          <span dangerouslySetInnerHTML={{ __html: sanitizer(finding.control?.title) }} />
          {finding.app === Apps.sbom ? null : <GoToQuestion />}
        </div>
      </div>
      <div className="assessment-finalized-finding__answer-wrapper">
        <div className="assessment-finalized-finding__answer-label">
          {translate(messages.answer)}:
        </div>
        <div
          className="assessment-finalized-finding__answer"
          id="assessment-finalized-finding-answer"
        >
          {isEditingAnswer ? (
            <div className="assessment-finalized-finding__control-wrapper">
              <ControlAnswerEdit
                categoryId={finding?.control?.categoryId}
                control={finding?.control}
                forceEdit
                fromFindings={true}
                onAnswerChange={(value, id) => {
                  setUpdatedAnswer({ value, id });
                }}
              />
              <br />
              <Button
                size="sm"
                squared
                color="blue"
                name={translate(generalMessages.done)}
                onClick={() => onAnswerChange(updatedAnswer.value)}
              />
            </div>
          ) : (
            <ControlAnswerPreview control={finding.control} />
          )}
        </div>
      </div>
      <div className="assessment-finalized-finding__finding-header">
        <h4 className="assessment-finalized-finding__finding-header-text">
          {translate(messages.finding)}:
        </h4>
        {assessor && <StatusSelect editAnswer={() => dispatch(setIsEditingAnswer(true))} />}
      </div>
      <div className="assessment-finalized-finding__description-wrapper">
        {finding.app === Apps.sbom ? (
          <div className="assessment-finalized-finding__description-wrapper--app-description">
            <ul>
              {Object.keys(finding.description).map((key) => {
                return (
                  <li key={`app-finding-description-key-${key}`}>
                    <span className="app-severity-key">{key}:</span>
                    <span
                      className={`app-severity-value severity-level-${finding.description[key]}`}
                    >
                      {getSbomFindingsSeverity(finding.description[key])}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : assessor ? (
          <TextArea
            className="assessment-finalized-finding__description"
            disabled={isRestrictedBoSoViewerRole}
            value={stripHtml(description)}
            onChange={onDescriptionChange}
            loading={findingLoading && description !== finding.description}
          />
        ) : (
          <p>{stripHtml(description)}</p>
        )}
      </div>
      {finding.app === Apps.sbom ? null : (
        <>
          <FindingStatuses />
          {assessor && <RemoveFinding />}
        </>
      )}
    </div>
  );
};

export default Finding;
