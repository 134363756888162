import React from 'react';
import PropTypes from 'prop-types';

// API
import { downloadFile } from 'api/myFiles';

const FileComponent = ({ evidenceId, fileTitle }) => {
  const download = async () => {
    const { path } = await downloadFile({
      fileId: evidenceId,
    });

    window.open(path, '_blank');
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="recent-page-evidences__table-link" onClick={download}>
      {fileTitle}
    </a>
  );
};

FileComponent.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  evidenceId: PropTypes.string.isRequired,
  fileTitle: PropTypes.string.isRequired,
};

export default FileComponent;
