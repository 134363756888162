import { createSlice } from '@reduxjs/toolkit';
import { getAllOrganizations, getProfileFindings, getProfileSubjectsFindings } from 'api/finding';
import { getFindingsPageAssessmentFilter } from 'api/filtersData';
import { DEFAULT_PAGINATION } from 'constants/api';
import { removeFilterReducer, setFilterReducer } from 'components/AddFilterButton/filterReducers';
import { findingStatus, riskValues } from 'pages/Findings/lib';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  findings: [],
  count: 10,
  loading: false,
  totalFindings: 0,
  pagination: DEFAULT_PAGINATION,
  sorter: {},
  filters: [],
  filtersValues: {},
};

const reducers = createSlice({
  name: 'findingsPage',
  initialState,
  reducers: {
    setParam: (state, { payload: { key, value } }) => {
      state[key] = value;
    },
    setFilter: (state, { payload: { type, value, id } }) => {
      return setFilterReducer(state, type, value, id);
    },
    removeFilter: (state, { payload: { type } }) => removeFilterReducer(state, type),
    resetState: () => initialState,
    setSorter: (state, { payload }) => {
      state.sorter = payload || {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileFindings.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getProfileFindings.fulfilled,
        (state, { payload: { pages, total, ...otherParams } }) => {
          return {
            ...state,
            ...otherParams,
            totalFindings: total,
            loading: false,
            filtersValues: {
              ...state.filtersValues,
              risk: riskValues,
              findingStatus,
            },
            pagination: {
              ...state?.pagination,
              total,
            },
          };
        },
      )
      .addCase(getAllOrganizations.fulfilled, (state, { payload: { vendors, customers } }) => {
        if (vendors) state.filtersValues.vendor = vendors;
        if (customers) state.filtersValues.customer = customers;
      })
      .addCase(
        getFindingsPageAssessmentFilter.fulfilled,
        (state, { payload: { assessment_names: assessmentsTemplates } }) => {
          state.filtersValues.assessmentName = assessmentsTemplates;
        },
      )
      .addCase(getProfileSubjectsFindings.fulfilled, (state, { payload: { subjects } }) => {
        state.filtersValues.subject = subjects.map((subject) => ({ name: subject, id: uuidv4() }));
      });
  },
});

export const { setParam, resetState, setFilter, removeFilter, setSorter } = reducers.actions;

export default reducers;
