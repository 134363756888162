import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import classNames from 'classnames';

import Icon from 'ui/Icon';
import Select from 'ui/Select';
import { translate } from 'utils/index';

import { setFilter, removeFilter } from '../../reducers';
import { messages } from '../messages';
import './filter.scss';

const Filter = ({ children, filter, fetchVendors, ...props }) => {
  const dispatch = useDispatch();

  const onChange = (value, data) => {
    dispatch(
      setFilter({
        type: filter.type,
        value,
        id: Array.isArray(data) ? data?.map((d) => d.id) : data.id,
      }),
    );
    fetchVendors();
  };

  const onRemove = () => {
    dispatch(removeFilter({ type: filter.type }));
    fetchVendors();
  };

  return (
    <div
      className={classNames('vendors-filters-filter ', `vendors-filters-filter--${filter.type}`)}
    >
      <Select
        value={filter.value}
        onChange={onChange}
        placeholder={translate(messages.filterPlaceholder)}
        style={{ width: '100%' }}
        labelKey="value"
        {...props}
      >
        {children}
      </Select>

      <Button type="link" className="vendors-filters-filter__close-button" onClick={onRemove}>
        <Icon className="vendors-filters-filter__close-button-ico" icon="close" />
      </Button>
    </div>
  );
};

Filter.propTypes = {
  children: PropTypes.node,
  fetchVendors: PropTypes.func,
  filter: PropTypes.object,
};

export default Filter;
