import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import classNames from 'classnames';

import Icon from 'ui/Icon';
import Spin from 'ui/Spin';
import SecondaryButton from 'ui/SecondaryButton';
import { DEFAULT_ASSESSMENT_LOGO, LOGO_BASE_URL } from 'constants/general';
import { sanitizer } from 'utils/sanitizer';
import { translate } from 'utils/index';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { setCollaborate } from 'pages/Assessment/reducers';

import VendorDueDate from './vendorDueDate';
import Collaborate from './collaborate';
import Actions from './actions';
import {
  selectAssessment,
  selectCategory,
  selectCategoryKey,
  selectControlsWithUnAppliedSuggestion,
} from './selectors';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';

import './categoryHeader.scss';

const CategoryHeader = forwardRef(
  ({ handleAutomationResponse, handleGetAssessment, isScrolled }, forwardedRef) => {
    const dispatch = useDispatch();
    const { isRestrictedBoSoViewerRole } = useRole();
    const { title, overview, customer_logo: logo } = useSelector(selectCategory);
    const { collaborate } = useSelector((state) => state.assessment);
    const { finalized, assessor } = useSelector(selectAssessment);
    const key = useSelector(selectCategoryKey);
    const controls = useSelector(selectControlsWithUnAppliedSuggestion);
    const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
    const [automationResponseLoading, setAutomationResponseLoading] = useState(false);
    if (!title) {
      return null;
    }

    const logoUrl = logo ? `${LOGO_BASE_URL}${logo}` : DEFAULT_ASSESSMENT_LOGO;

    const onUseSuggestion = async () => {
      setAutomationResponseLoading(true);
      await handleAutomationResponse(controls);
      setAutomationResponseLoading(false);
    };

    const onCollaborate = () => {
      dispatch(setCollaborate(true));
    };

    return (
      <>
        <div
          className={classNames('assessment-category-actions-panel', { 'scrolled': isScrolled })}
        >
          <Collaborate handleGetAssessment={handleGetAssessment} />
          {controls?.length > 0 && (
            <Button
              type="link"
              className="assessment-category-header__suggestion-button"
              onClick={onUseSuggestion}
              disabled={isRestrictedBoSoViewerRole}
            >
              {automationResponseLoading ? (
                <Spin className="assessment-category-header__loader" />
              ) : (
                <Icon icon="suggestion" />
              )}
              {translate(messages.useAlSuggestions)}
            </Button>
          )}
          {!finalized && assessor && <Actions className="assessment-category-header__actions" />}
          {!finalized && !isMyVendorsTabSelected && !assessor && !collaborate && (
            <SecondaryButton
              className="assessment-category-header__share-controls-button"
              icon="share"
              link
              onClick={onCollaborate}
              disabled={isRestrictedBoSoViewerRole}
            >
              {translate(messages.shareMultipleControls)}
            </SecondaryButton>
          )}
          {!finalized && !isMyVendorsTabSelected && <VendorDueDate isScrolled={isScrolled} />}
        </div>

        <div className="assessment-category-header" ref={forwardedRef}>
          <div className="assessment-category-header__logo-container">
            <img src={logoUrl} className="assessment-category-header__logo" alt="Vendor logo" />
          </div>
          <div className="assessment-category-header__title">{`${key}. ${title}`}</div>
          <div
            className="assessment-category-header__overview"
            dangerouslySetInnerHTML={{
              __html: sanitizer(overview),
            }}
          ></div>
        </div>
      </>
    );
  },
);

CategoryHeader.displayName = 'CategoryHeader';

CategoryHeader.propTypes = {
  handleAutomationResponse: PropTypes.func,
  handleGetAssessment: PropTypes.func,
  isScrolled: PropTypes.bool,
};

export default CategoryHeader;
