import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { notification } from 'antd';

// COMPONENTS
import { useAppNavigate } from 'hooks/navigation';
import CardWithLogo from 'components/CardWithLogo';
import Button from 'ui/Button';
import Form from 'ui/Form';
import SecondAuthForm from 'components/SecondAuthForm';

// CONSTANTS
import ROUTES from 'constants/routes';
import { generalMessages } from 'constants/messages';
import { API_STATUS, MFA_METHOD } from 'constants/api';
import { LOGIN_FORM_MODE } from 'pages/Login/lib';
import { NOTIFICATION_DURATION } from 'constants/general';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Login/messages';

// API
import { loginUser } from 'api/authentication';
import { getProfileOrganizations } from 'api/organizations';
import { sendMfaViaEmail } from 'api/profile';

// REDUX
import { selectLogin } from 'pages/Login/selectors';
import { DEFAULT_ORGANIZATION_INDEX, getOrganizationIndex } from 'utils/organizations';
import { useSearchParams } from 'react-router-dom';
import { setCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY } from 'utils/auth';
import { sanitizer } from 'utils/sanitizer';
import SecondaryButton from 'ui/SecondaryButton';
import { AttentionIcon } from 'components/Icons';
import colors from 'ui/colors.scss';

import './secondAuthentication.scss';

const SecondAuthentication = ({ setMode }) => {
  const location = useLocation();
  const [otpCode, setOtpCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [setEmailMfaLoading, setSetEmailMfaLoading] = useState(false);
  const [setEmailMfa, setSetEmailMfa] = useState(false);
  const [error, setError] = useState('');
  const { state: originalPath } = location;
  const { mfaMethod, email, password, sso, ssoToken, phone } = useSelector(selectLogin);
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();

  // handle use case: assignment to new organization
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get('organization');

  const onClickBack = () => {
    setMode(LOGIN_FORM_MODE.LOGIN);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!otpCode || (otpCode && otpCode.toString().length < 6)) {
      return setError(translate(messages.invalidOtpCode));
    }

    setLoading(true);
    const loginBody = {
      otpCode,
    };
    if (sso) {
      loginBody.sso = true;
      loginBody.ssoToken = ssoToken;
    } else {
      loginBody.email = email;
      loginBody.password = password;
    }

    if (setEmailMfa) {
      loginBody.setEmailMfa = true;
    }

    const {
      payload: { status, redirect, org_id: orgIdFromLogin },
    } = await dispatch(loginUser(loginBody));
    setLoading(false);

    if (status === API_STATUS.SUCCESS) {
      // set profile organizations in organizations store
      const { payload: organizations } = await dispatch(getProfileOrganizations());

      notification.success({
        message: translate(messages.welcome),
        duration: NOTIFICATION_DURATION.ONE_SECOND,
        className: 'notification-login-form-success',
      });

      if (originalPath || redirect) {
        return appNavigate(originalPath || redirect);
      }

      // TODO: remove ret handling after migration to new login redirect flow
      if (location.search.includes('ret')) {
        return appNavigate(`/${location.search.split('ret=')[1]}`, {
          organization: DEFAULT_ORGANIZATION_INDEX,
        });
      }

      // handle use case: assignment to new organization - update cookie to the relevant organization
      if (organizationId) setCookie(ORG_ID_AUTH_KEY, organizationId);

      // default redirect
      const orgIndex = getOrganizationIndex({
        organizations,
        orgId: organizationId || orgIdFromLogin,
      });

      return appNavigate(ROUTES.VENDOR_RECENT, {
        organization: orgIndex || DEFAULT_ORGANIZATION_INDEX,
      });
    }

    return null;
  };

  const resendViaEmail = async () => {
    setSetEmailMfaLoading(true);
    setSetEmailMfa(true);
    await dispatch(sendMfaViaEmail({ email }));
    setSetEmailMfaLoading(false);
  };

  const onChangeOtpCode = (code) => {
    setError('');

    if (!Number.isNaN(+code)) {
      setOtpCode(code);
    }
  };

  const hashedEmail = `${email[0]}XXX${email?.slice(email.indexOf('@'))}`;

  return (
    <Form onSubmit={onSubmit} className="login-second-authentication">
      <CardWithLogo.Header
        className="login-second-authentication__header"
        icon="lock"
        title={translate(messages.secondAuthenticationTitle)}
        subtitle={
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                translate(
                  mfaMethod === MFA_METHOD.EMAIL ? messages.authMsgEmail : messages.authMsgSms,
                  {
                    email: hashedEmail,
                    phone,
                  },
                ),
              ),
            }}
          />
        }
        backButtonOnClick={onClickBack}
        beforeComponent={
          <div className="login-second-authentication__alert">
            <AttentionIcon
              color={colors.red600}
              className="login-second-authentication__alert-icon"
            />
            {translate(messages.yourAdminRequires2Fa)}
          </div>
        }
      />
      <SecondAuthForm error={error} otpCode={otpCode} setOtpCode={onChangeOtpCode}>
        <div className="login-second-authentication__footer">
          {mfaMethod === MFA_METHOD.EMAIL ? (
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(translate(messages.didntReceiveEmail)),
              }}
            />
          ) : (
            <>
              <span>{translate(messages.didntReceiveCode)}</span>
              <SecondaryButton link onClick={resendViaEmail} loading={setEmailMfaLoading}>
                {translate(messages.resendViaEmail)}
              </SecondaryButton>
            </>
          )}
        </div>
        <Form.Item>
          <Button
            centered
            type="submit"
            loading={loading}
            name={translate(generalMessages.submit)}
            color="pink"
            onClick={onSubmit}
            data-test="second-authentication-form-submit-button"
          />
        </Form.Item>
      </SecondAuthForm>
    </Form>
  );
};

SecondAuthentication.propTypes = {
  setMode: PropTypes.func,
};

export default SecondAuthentication;
