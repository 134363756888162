import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectProfile } from 'pages/Profile/selectors';
import { useAppNavigate } from 'hooks/navigation';
import { checkIfFindingsUser } from 'utils/isFindingsUser';
import { isProductionDomain, translate } from 'utils/index';
import { messages } from './messages';

import { ErrorFilledIcon } from 'components/Icons';

import './index.scss';

const Dashboard = () => {
  const {
    user: { email },
  } = useSelector(selectProfile);
  const appNavigate = useAppNavigate();

  useEffect(() => {
    if (!checkIfFindingsUser(email) || isProductionDomain()) {
      appNavigate('/404');
    }
  }, [email]);

  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <div className="dashboard__header__container">
          <h1>{translate(messages.dashboardPageTitle)}</h1>
          <div className="dashboard__header__container__seperator"></div>
          {/* Filters Button here*/}
        </div>
        {/* Download button here*/}
      </div>
      <div className="dashboard__body" style={{ color: 'red' }}>
        <h3>
          This page is currently available only for findings users in dev env, and will be populated
          with dashboard features soon!
        </h3>
        <ErrorFilledIcon />
      </div>
    </div>
  );
};

export default Dashboard;
