import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AuditControl, ConfirmAuditFinding } from 'api/endpoints';
import { buttonTypes } from 'components/UpstreamAI/lib';

const auditControlRequest = async ({ assessmentId, controlId }, { getState }) => {
  const {
    upstreamAI: { enableImprove },
  } = getState();

  const payload = {
    assessmentId,
    assessmentControlId: controlId,
  };

  if (enableImprove) {
    payload['thirdPartyConsent'] = enableImprove;
  }

  const response = await axios.post(AuditControl.url, payload, { withCredentials: true });
  return { response, controlId, source: buttonTypes.audit };
};

export const auditControl = createAsyncThunk('auditControl', auditControlRequest);

export const confirmAuditFindingRequest = async ({ controlId, findingId, assessmentId }) => {
  const payload = {
    assessmentControlId: controlId,
    findingId,
    assessmentId,
  };

  const response = await axios.post(ConfirmAuditFinding.url, payload, { withCredentials: true });
  return { response, controlId, findingId };
};

export const confirmAuditFinding = createAsyncThunk(
  'confirmAuditFinding',
  confirmAuditFindingRequest,
);
