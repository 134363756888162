import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Icon from 'ui/Icon';
import { translate } from 'utils/index';
import { saveFinding } from 'api/finding';
import ROUTES from 'constants/routes';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useRole } from 'hooks/useRole';

import { selectFinding } from './reducers';
import { selectFinding as selectorsSelectFinding, selectPreviousFinding } from './selectors';
import { messages } from './messages';
import './removeFinding.scss';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';

const RemoveFinding = () => {
  const selectedFinding = useSelector(selectorsSelectFinding);
  const previousFinding = useSelector(selectPreviousFinding);
  const { isRestrictedBoSoViewerRole } = useRole();
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { assessmentId } = useParams();
  const [popupOpened, setPopupOpened] = useState(false);

  const onRemove = () => {
    const payload = {
      control: selectedFinding.control,
      finding: {
        id: selectedFinding.id,
      },
    };
    setPopupOpened(false);
    dispatch(saveFinding(payload));

    if (previousFinding) {
      dispatch(selectFinding(previousFinding.id));
    }

    if (previousFinding === null) {
      appNavigate(
        isMyVendorsTabSelected ? ROUTES.VENDOR_ASSESSMENT : ROUTES.CUSTOMER_ASSESSMENT,
        { assessmentId },
        { replace: true },
      );
    }
  };
  return (
    <div>
      <Tooltip title={translate(messages.removeFinding)}>
        <Button
          className="assessment-finalized-remove-finding"
          disabled={isRestrictedBoSoViewerRole}
          type="link"
          onClick={() => setPopupOpened(true)}
        >
          <Icon icon="remove" />
        </Button>
      </Tooltip>
      <RemoveConfirmationModal
        onCancel={() => setPopupOpened(false)}
        onOk={onRemove}
        open={popupOpened}
        text={translate(messages.removeFindingPopupText)}
        headerText={translate(messages.removeFindingPopupHeaderText)}
      />
    </div>
  );
};

export default RemoveFinding;
