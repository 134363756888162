import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import EditableContent from 'ui/EditableContent';
import { translate } from 'utils/index';
import { saveTemplate } from 'api/editor';
import Select from 'ui/Select';

import { getBenchmarkDataSource, getWeightDataSource } from './lib';
import { selectSaveLoading, selectSubject, selectSubjectKey } from './selectors';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import './subjectHeader.scss';

const SubjectHeader = () => {
  const subjectKey = useSelector(selectSubjectKey);
  const subject = useSelector(selectSubject);
  const loading = useSelector(selectSaveLoading);
  const selectedSubjectId = useSelector((state) => state.editor.selectedSubject);
  const dispatch = useDispatch();
  const [values, setValues] = useState(subject);
  const { isRestrictedBoSoViewerRole } = useRole();

  useEffect(() => {
    setValues(subject);
  }, [selectedSubjectId, subject]);

  const { title, overview, id: categoryId } = subject;

  const onChange = (field, value) => {
    setValues((state) => ({ ...state, [field]: value }));
    onSave(field, value);
  };

  const onSave = (field, value) => {
    const payload = {
      categories: [
        {
          id: categoryId,
          [field]: value,
        },
      ],
    };

    dispatch(saveTemplate(payload));
  };

  const onSaveDebounced = useCallback(debounce(onSave, 1000), [categoryId]);

  const onTextChange = (field, value) => {
    setValues((state) => ({ ...state, [field]: value }));
    onSaveDebounced(field, value);
  };

  return (
    <div className="editor-subject-header">
      <div className="editor-subject-header__left">
        <div className="editor-subject-header__title-wrapper">
          <span className="editor-subject-header__key">{subjectKey}.</span>
          <EditableContent
            data-test={`editor-subject-header-edit-title-${subjectKey - 1}`}
            className="editor-subject-header__title"
            onChange={(value) => onTextChange('title', value)}
            placeholder={translate(messages.enterCategoryTitle)}
            loading={loading && values.title !== title}
            value={values.title || translate(messages.categoryPlaceholder)}
            disabled={isRestrictedBoSoViewerRole}
          />
        </div>
        <EditableContent
          className="editor-subject-header__description"
          data-test={`editor-subject-header-edit-description-${subjectKey - 1}`}
          onChange={(value) => onTextChange('overview', value)}
          placeholder={translate(messages.addSubjectDescription)}
          loading={loading && values.overview !== overview}
          value={values.overview}
          disabled={isRestrictedBoSoViewerRole}
        />
      </div>
      <Select
        className="editor-subject-header__weight-selector"
        color="gray"
        data-test={`editor-subject-header-weight-selector-${subjectKey - 1}`}
        innerLabel={translate(messages.weight)}
        placeholder={translate(messages.noWeight)}
        options={getWeightDataSource(values.weight)}
        onChange={(value) => onChange('weight', value)}
        value={values.weight || undefined}
        loading={loading && values.weight !== subject.weight}
        disabled={isRestrictedBoSoViewerRole}
      />
      <Select
        className="editor-subject-header__benchmark-selector"
        data-test={`editor-subject-header-benchmark-selector-${subjectKey - 1}`}
        color="gray"
        innerLabel={translate(messages.benchmark)}
        placeholder={translate(messages.noBenchmark)}
        options={getBenchmarkDataSource(values.benchmark)}
        onChange={(value) => onChange('benchmark', value)}
        value={values.benchmark || undefined}
        loading={loading && values.benchmark !== subject.benchmark}
        disabled={isRestrictedBoSoViewerRole}
      />
    </div>
  );
};

export default SubjectHeader;
