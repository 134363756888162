import React, { useEffect, useMemo, useState } from 'react';
import { translate } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useNavigate, useParams } from 'react-router';

import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import { useVendorManageContext } from 'hooks/useVendorManageContext';
import Tabs from 'ui/Tabs';
import Text from 'ui/Text';
import {
  CategoryTab,
  ContactInformationTab,
  AssignAssessmentTab,
} from 'pages/Vendors/VendorManage/Tabs';
import { resetCurrentVendor } from 'pages/Vendors/reducers';
import { getAssessmentsTemplates } from 'api/assessmentsTemplates';
import { getProfileVendor } from 'api/vendor';
import Modal from 'ui/Modal';
import Loader from 'ui/Loader';
import Icon from 'ui/Icon';
import ContinuousMonitoring from 'pages/Vendors/VendorManage/Tabs/ContinuousMonitoring';
import Notes from './Tabs/ContinuousMonitoring/Notes';
import { messages } from './messages';
import './index.scss';

const PLUS_ICON_SIZE = 14;
const CONTINUOUS_MONITORING_TAB_KEY = 'Continuous monitoring';
const CONTACT_INFORMATION_TAB_KEY = 'Contact Information';
const IMPACT_ANALYSIS_TAB_KEY = 'Impact analysis';
const ASSIGN_ASSESSMENT_TAB_KEY = 'Assign assessment';

const VendorManage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const {
    vendorAssessmentsData,
    currentVendor,
    handleTabChange,
    currentTab,
    sendNewVendor,
    isVendorDirty,
    updateVendor,
  } = useVendorManageContext();
  const dispatch = useDispatch();
  const { vendorId } = useParams();
  const { isVendorFetching } = useSelector((state) => state.vendors);

  useEffect(() => {
    if (vendorId) {
      dispatch(getProfileVendor(vendorId));
    } else {
      dispatch(resetCurrentVendor());
    }
  }, [vendorId]);

  useEffect(() => {
    dispatch(getAssessmentsTemplates());
    return () => {
      dispatch(resetCurrentVendor());
    };
  }, []);

  const shouldShowAdditionalInformation = useMemo(() => {
    const vendorDataLength = vendorAssessmentsData
      ?.map((data) => !isEmpty(data))
      .filter(Boolean).length;
    return !!vendorDataLength;
  }, []);

  const prevAction = () => {
    if ((currentVendor && isVendorDirty) || !currentVendor) {
      return setModalOpen(true);
    }
    navigate(-1);
  };

  const onModalOk = () => {
    if (currentVendor) {
      updateVendor();
    }

    setModalOpen(false);
  };

  const onModalCancel = () => {
    navigate(-1);
  };

  const title = (
    <span>
      <Icon icon="info" />{' '}
      {currentVendor ? translate(messages.saveChanges) : translate(messages.exitWithoutSaving)}
    </span>
  );

  const tabs = [
    {
      tab: translate(messages.overview),
      key: CONTINUOUS_MONITORING_TAB_KEY,
      dataTest: 'continuous-monitoring-tab',
      component: (
        <div>
          <ContinuousMonitoring
            handleTabChange={() => {
              handleTabChange(CONTACT_INFORMATION_TAB_KEY);
            }}
            prevAction={prevAction}
          />
          <Notes />
        </div>
      ),
      enabled: !!currentVendor,
    },
    {
      tab: translate(messages.contactInformation),
      key: CONTACT_INFORMATION_TAB_KEY,
      dataTest: 'contact-information-tab',
      component: (
        <ContactInformationTab
          handleTabChange={() => {
            handleTabChange(IMPACT_ANALYSIS_TAB_KEY);
          }}
          handleBackTab={() => {
            handleTabChange(CONTINUOUS_MONITORING_TAB_KEY);
          }}
          prevAction={prevAction}
        />
      ),
      enabled: true,
    },
    {
      tab: translate(messages.impactAnalysisInformation),
      key: IMPACT_ANALYSIS_TAB_KEY,
      dataTest: 'impact-analysis-tab',
      component: (
        <CategoryTab
          handleTabChange={() => {
            handleTabChange(ASSIGN_ASSESSMENT_TAB_KEY);
          }}
          handleBackTab={() => {
            handleTabChange(CONTACT_INFORMATION_TAB_KEY);
          }}
          prevAction={prevAction}
        />
      ),
      enabled: shouldShowAdditionalInformation,
    },
    {
      tab: translate(messages.assignAssessment),
      key: ASSIGN_ASSESSMENT_TAB_KEY,
      dataTest: 'assign-assessment-tab',
      component: (
        <AssignAssessmentTab
          addProfileVendor={sendNewVendor}
          handleBackTab={() => {
            handleTabChange(IMPACT_ANALYSIS_TAB_KEY);
          }}
          prevAction={prevAction}
        />
      ),
      enabled: true,
    },
  ];

  return (
    <>
      {isVendorFetching && <Loader />}
      {!isVendorFetching && (
        <section className="vendor-new-vendor">
          <Text className="vendor-new-vendor__header" level={1} color="text">
            {currentVendor ? (
              <Icon className="vendor-new-vendor__header-icon" icon="vendors" />
            ) : (
              <>
                <PlusIcon width={PLUS_ICON_SIZE} height={PLUS_ICON_SIZE} />{' '}
              </>
            )}
            {currentVendor?.profileCorporateName || translate(messages.createNewVendor)}
          </Text>
          <div className="vendor-new-vendor__tabs">
            <Tabs activeKey={currentTab} onChange={handleTabChange}>
              {tabs
                .filter(({ enabled }) => enabled)
                ?.map((tab, index) => {
                  if (tab.enabled) {
                    return (
                      <Tabs.Pane
                        tab={`${index + 1}. ${tab.tab}`}
                        key={tab.key}
                        data-test={tab.dataTest}
                      >
                        <div className="vendor-new-vendor__content">{tab.component}</div>
                      </Tabs.Pane>
                    );
                  }
                  return null;
                })}
            </Tabs>
          </div>
          <Modal
            className="vendor-new-vendor__confirm-modal"
            open={isModalOpen}
            onOk={onModalOk}
            okText={
              currentVendor
                ? translate(messages.saveChangesButton)
                : translate(messages.completeVendorButton)
            }
            onCancel={onModalCancel}
            cancelText={
              currentVendor
                ? translate(messages.dontSaveButton)
                : translate(messages.exitAnywayButton)
            }
            title={title}
            width={650}
            closable={false}
            maskClosable={false}
            keyboard={false}
          >
            {currentVendor
              ? translate(messages.wouldYouLikeToSaveChanges)
              : translate(messages.allVendorInformationWillBeLost)}
          </Modal>
        </section>
      )}
    </>
  );
};

export default VendorManage;
