import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { notification } from 'antd';
import PropTypes from 'prop-types';

// COMPONENTS
import Icon from 'ui/Icon';
import Button from 'ui/Button';
import Divider from 'ui/Divider';
import OrganizationLogo from 'ui/OrganizationLogo';
import Popup from 'ui/Popup';
import ImageEditor from 'components/ImageEditor';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/UploadLogo/messages';
import { API_STATUS } from 'constants/api';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from 'pages/Profile/selectors';
import { getProfile, setProfileLogo } from 'api/profile';

// HOOKS
import { useTheme } from 'hooks/theme';

// STYLES
import './index.scss';

const UploadLogo = ({ token }) => {
  const dispatch = useDispatch();
  const [isPopupOpen, setIsPopupOpen] = useState({
    remove: false,
    edit: false,
  });
  const { colorTheme } = useTheme();
  const {
    user: { corporateLogo },
  } = useSelector(selectProfile);
  const fileRef = useRef(null);

  const onDrop = async (acceptedFile) => {
    fileRef.current = acceptedFile[0];
    setIsPopupOpen((state) => ({ ...state, edit: true }));
  };

  const setLogo = useCallback(
    async (file) => {
      const { payload } = await dispatch(setProfileLogo({ file: file, token }));
      await dispatch(getProfile({ token }));

      if (payload?.msg.search('dimensions') !== -1)
        return notification.error({ message: translate(messages.invalidFileDimensions) });

      if (payload?.status === API_STATUS.FAILED)
        return notification.error({ message: translate(messages.logoUpdateFail) });

      return notification.success({ message: translate(messages.logoUpdateSuccess) });
    },
    [token],
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
  });

  const handleRemoveLogo = () => {
    dispatch(setProfileLogo({ file: null }));
    setPopupState('remove');
  };

  const setPopupState = (key) => {
    setIsPopupOpen((state) => ({ ...state, [key]: !state[key] }));
  };

  return (
    <div className="upload-logo">
      <div className={`upload-logo__area  ${isDragActive && 'dragged-area'}`} {...getRootProps()}>
        {corporateLogo ? (
          <OrganizationLogo size="none" path={corporateLogo} />
        ) : (
          <div className="upload-logo__logo-empty-state">
            <Icon icon="image" className="upload-logo__logo-icon" color={colorTheme} />
            <h4>{translate(messages.uploadLogo)}</h4>
          </div>
        )}
        <input {...getInputProps()} multiple={false} accept=".jpg, .jpeg, .png" />
      </div>
      <div className="upload-logo__upload-logo-text">
        <Button
          link
          data-test="upload-logo-browse-file-button"
          name={translate(messages.uploadNewLogo)}
          color="blue"
          className="upload-logo__upload-logo-browse-button"
          onClick={open}
        />
        <Divider vertical size="xs" />
        <Button
          link
          data-test="upload-logo-remove-file-button"
          name={translate(messages.removeLogo)}
          color="blue"
          className="upload-logo__logo-remove-button"
          onClick={() => setPopupState('remove')}
        />
      </div>
      <Popup.Remove
        width={300}
        visible={isPopupOpen.remove}
        onClickCancel={() => setPopupState('remove')}
        onClickOk={handleRemoveLogo}
        mainText={translate(messages.removeLogoPopup)}
      />
      <ImageEditor
        visibility={isPopupOpen.edit}
        setVisibility={() => setPopupState('edit')}
        image={fileRef.current}
        setFunction={setLogo}
      />
    </div>
  );
};

UploadLogo.propTypes = {
  token: PropTypes.string,
};

export default UploadLogo;
