import initializeConfig from './initializeConfig';
import initializeFonts from './initializeFonts';
import initializeReduxStore from './initializeReduxStore';
import initializeTranslations from './initializeTranslations';
import * as serviceWorker from './serviceWorker';

export default {
  initializeConfig,
  initializeFonts,
  initializeReduxStore,
  initializeTranslations,
  serviceWorker,
};
