import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'antd';

import Icon from 'ui/Icon';
import { translate } from 'utils/index';
import { getOffset } from 'utils/html';
import { saveRecommendation } from 'api/recommendation';
import { selectFinding } from 'pages/Assessment/finalized/findings/selectors';
import { useRole } from 'hooks/useRole';
import { Apps } from 'constants/apps';

import { messages } from './messages';
import './addRecommendation.scss';

const AddRecommendation = () => {
  const dispatch = useDispatch();
  const { isRestrictedBoSoViewerRole } = useRole();
  const { control, id: findingId, app } = useSelector(selectFinding);
  const [loading, setLoading] = useState();

  const onAdd = async () => {
    const id = uuidv4();
    const payload = {
      control,
      findingId,
      recommendation: {
        id,
        description: '',
      },
    };

    setLoading(true);
    await dispatch(saveRecommendation(payload)).unwrap();
    setLoading(false);

    setTimeout(() => {
      const recommendationElement = document.querySelector(
        '.assessment-finalized-recommendation:last-child',
      );

      const recommendationElementOffset = getOffset(recommendationElement);

      window.scroll({
        top: recommendationElementOffset.top - 70,
        left: 0,
        behavior: 'smooth',
      });
    }, 200);
  };

  return app === Apps.sbom ? null : (
    <Button
      type="link"
      className="assessment-finalized-add-recommendation__button"
      disabled={isRestrictedBoSoViewerRole}
      onClick={onAdd}
      loading={loading}
    >
      <Icon className="assessment-finalized-add-recommendation__button-ico" icon="add2" />
      {translate(messages.addRecommendation)}
    </Button>
  );
};

export default AddRecommendation;
