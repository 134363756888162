import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as yup from 'yup';

import { translate } from 'utils/index';
import TextArea from 'ui/TextArea';
import Button from 'ui/Button';
import Modal from 'ui/Modal';
import Icon from 'ui/Icon';
import Form from 'ui/Form';
import Tag from 'ui/Tag';
import { generalMessages } from 'constants/messages';
import { API_STATUS } from 'constants/api';
import { saveNoteOnRelation } from 'api/notes';
import { getProfileVendor } from 'api/vendor';
import { saveNote } from 'api/recommendation';
import { DATE_TIME_FORMAT } from 'constants/date';

import { messages } from '../../../messages';
import './noteModal.scss';

const initialValues = {
  note: '',
};

const NoteModal = ({ handleClickAssessment, note, open, setOpen, vendorId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { errors, values, touched, handleSubmit, handleChange, resetForm, setValues } = useFormik({
    initialValues,
    validationSchema: yup.object({
      note: yup.string().required(translate(messages.requiredNote)),
    }),
    onSubmit: async () => {
      setLoading(true);
      const payload = {
        note: {
          note: values.note,
        },
        relation_id: vendorId,
      };

      if (note) {
        payload.note.id = note.noteId;
      }

      const response = !note?.assessmentId
        ? await dispatch(saveNoteOnRelation(payload)).unwrap()
        : await dispatch(
            saveNote({
              note: { id: note.recommendationId || note.controlId, comment: values.note },
              assessmentId: note.assessmentId,
            }),
          ).unwrap();

      if (response.status === API_STATUS.SUCCESS) {
        dispatch(getProfileVendor(vendorId));
        resetForm();
        setOpen(false);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!note) {
      return;
    }

    setValues({
      note: note.note || '',
    });
  }, [note]);

  const onCancel = () => {
    setOpen(false);
    resetForm();
  };

  const footer = [
    <Button
      data-test="continuous-monitoring-tab-notes-note-modal-cancel-button"
      key="cancel-button"
      className="continuous-monitoring-tab-notes-note-modal__footer-cancel-button"
      size="sm"
      color="white"
      onClick={onCancel}
      fluid
    >
      {translate(generalMessages.cancel)}
    </Button>,
    <Button
      data-test="continuous-monitoring-tab-notes-note-modal-save-button"
      key="save-button"
      className="continuous-monitoring-tab-notes-note-modal__footer-save-button"
      size="sm"
      color="pink"
      onClick={handleSubmit}
      fluid
      type="submit"
      loading={loading}
    >
      {translate(messages.saveAndClose)}
    </Button>,
  ];

  const title = (
    <span>
      <Icon icon="notes" /> {translate(messages.note)}
      {!note?.assessmentId && (
        <span className="continuous-monitoring-tab-notes-note-modal__title-suffix">
          ({translate(messages.general)})
        </span>
      )}
    </span>
  );

  return (
    <Modal
      className="continuous-monitoring-tab-notes-note-modal"
      open={open}
      onCancel={onCancel}
      width={1000}
      title={title}
      footer={footer}
    >
      {note?.assessmentName && (
        <div className="continuous-monitoring-tab-notes-note-modal__assessment-wrapper">
          <span className="continuous-monitoring-tab-notes-note-modal__assessment-label">
            {translate(messages.assessment)}:
          </span>
          <Tag
            className="assessment-tag"
            color="blue"
            name={note?.assessmentName}
            onClick={(event) => {
              handleClickAssessment(note);
              event.stopPropagation();
            }}
          />
        </div>
      )}
      {note?.controlTitle && (
        <div className="continuous-monitoring-tab-notes-note-modal__control-wrapper">
          <span className="continuous-monitoring-tab-notes-note-modal__control-position">
            ({note.controlPosition})
          </span>
          {note.controlTitle}
        </div>
      )}
      {note?.findingDescription && (
        <div className="continuous-monitoring-tab-notes-note-modal__finding-wrapper">
          <Icon
            className="continuous-monitoring-tab-notes-note-modal__note-finding-flag"
            icon="flag"
          />
          {note.findingDescription}
        </div>
      )}
      <div className="continuous-monitoring-tab-notes-note-modal__date-wrapper">
        {note?.noteTimestampEdited && (
          <>
            <span className="continuous-monitoring-tab-notes-note-modal__date">
              {translate(messages.lastUpdated)}:{' '}
              {moment(note.noteTimestampEdited).format(DATE_TIME_FORMAT)}
            </span>
            <span className="continuous-monitoring-tab-notes-note-modal__divider" />
            <span>
              {translate(messages.by)}: {note.userEmail}
            </span>
          </>
        )}
        <span className="continuous-monitoring-tab-notes-note-modal__internal-label">
          {translate(messages.internal)}
        </span>
      </div>
      <Form onSubmit={handleSubmit}>
        <div>
          <TextArea
            data-test="note-modal-textarea"
            className="continuous-monitoring-tab-notes-note-modal__note"
            name="note"
            onChange={handleChange}
            value={values.note}
            error={touched.note && errors.note}
          />
        </div>
      </Form>
    </Modal>
  );
};

NoteModal.propTypes = {
  handleClickAssessment: PropTypes.func,
  note: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  vendorId: PropTypes.string,
};

export default NoteModal;
