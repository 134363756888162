import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import classNames from 'classnames';

import { translate } from 'utils/index';
import { getProfileCustomers } from 'api/customers';
import { vendorRisk } from 'pages/Vendors/VendorsList/lib';

import Filter from './filter';
import FilterType from './filterType';
import FilterCustomer from './filterCustomer';
import FilterParentOrganization from './filterParentOrganization';
import { setPagination } from '../../reducers';
import { selectFilters } from '../../selectors';
import './index.scss';

const { Option } = Select;

const CustomersFilters = ({ callback, className }) => {
  const filters = useSelector(selectFilters);
  const dispatch = useDispatch();

  const fetchCustomers = () => {
    return (
      callback ||
      (() => {
        dispatch(setPagination({ current: 1 }));
        dispatch(getProfileCustomers());
      })
    );
  };

  if (filters.length === 0) {
    return null;
  }

  return (
    <div className={classNames('customers-filters', className)}>
      {filters.map((filter) => {
        const generalProps = {
          key: filter.type,
          filter,
          fetchCustomers: fetchCustomers(),
        };

        switch (filter.type) {
          case 'vendor-risk-filter':
            return (
              <Filter {...generalProps}>
                {vendorRisk.map(({ name, value }) => (
                  <Option key={value} value={translate(name)} id={value}>
                    {translate(name)}
                  </Option>
                ))}
              </Filter>
            );
          case 'company-filter':
            return <FilterCustomer {...generalProps} />;
          case 'assessment-filter':
            return <FilterType {...generalProps} />;
          case 'parent-organization-filter':
            return <FilterParentOrganization {...generalProps} />;
          default:
            return null;
        }
      })}
    </div>
  );
};

CustomersFilters.propTypes = {
  callback: PropTypes.func,
  className: PropTypes.string,
};

export default CustomersFilters;
