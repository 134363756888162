import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { notification } from 'antd';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';

// COMPONENTS
import CardWithLogo from 'components/CardWithLogo';
import ApiUrlSwitch from 'components/ApiUrlSwitch';
import Button from 'ui/Button';
import Divider from 'ui/Divider';
import Form from 'ui/Form';
import Input from 'ui/Input';
import HrWithCildren from 'ui/HrWithCildren';

// CONSTANTS
import ROUTES from 'constants/routes';
import { NOTIFICATION_DURATION } from 'constants/general';
import { API_STATUS, MFA_METHOD } from 'constants/api';
import { LOGIN_FORM_MODE } from 'pages/Login/lib';

// API
import { loginUser } from 'api/authentication';
import { getProfileOrganizations } from 'api/organizations';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Login/messages';
import { setCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY } from 'utils/auth';
import { useAppNavigate } from 'hooks/navigation';
import { getOrganizationIndex, DEFAULT_ORGANIZATION_INDEX } from 'utils/organizations';
import { Roles } from 'constants/roles';

// REDUX
import { selectLogin } from 'pages/Login/selectors';
import { setLoginFormData, setLoginSSO } from 'pages/Login/reducers';

// VALIDATION
import { loginFullSchema } from 'pages/Login/schema';

// STYLES
import './login.scss';

const LoginForm = ({ setMode }) => {
  const location = useLocation();
  const appNavigate = useAppNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector(selectLogin);
  const { state: originalPath } = useLocation();

  // handle use case: assignment to new organization
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get('organization');

  const onSubmit = async () => {
    const {
      payload: {
        role,
        mfa_method: mfaMethod = MFA_METHOD.NONE,
        forced2fa,
        status,
        redirect,
        org_id: orgIdFromLogin,
        phone,
      },
    } = await dispatch(loginUser({ email, password }));

    if (status === API_STATUS.FAILED) return null;

    setCookie(ORG_ID_AUTH_KEY, organizationId || orgIdFromLogin);

    if (forced2fa) {
      dispatch(setLoginFormData({ key: 'forced2fa', value: forced2fa }));
    }

    if (mfaMethod !== MFA_METHOD.NONE) {
      dispatch(setLoginFormData({ key: 'phone', value: phone }));
      return setMode(LOGIN_FORM_MODE.OTP);
    }

    // set profile organizations in organizations store
    const { payload: organizations } = await dispatch(getProfileOrganizations());

    notification.success({
      message: translate(messages.welcome),
      duration: NOTIFICATION_DURATION.ONE_SECOND,
      className: 'notification-login-form-success',
    });

    // SOBO users should be redirected to the vendors/customers pages
    if (role === Roles.BO || role === Roles.SO) {
      const path = role === Roles.BO ? ROUTES.VENDOR_RECENT : ROUTES.CUSTOMER_RECENT;
      return appNavigate(path);
    }

    // In case a redirect is required
    if (originalPath || redirect) {
      return appNavigate(originalPath || redirect);
    }

    // TODO: if used only for redirect, remove this after the migration to the new login flow
    if (location.search.includes('ret')) {
      return appNavigate(`/${location.search.split('ret=')[1]}`, {
        organization: DEFAULT_ORGANIZATION_INDEX,
      });
    }

    // handle use case: assignment to new organization - update cookie to the relevant organization
    // if (organizationId) setCookie(ORG_ID_AUTH_KEY, organizationId); should be uncommented later on

    // default redirect
    const orgIndex = getOrganizationIndex({
      organizations,
      orgId: organizationId || orgIdFromLogin,
    });

    return appNavigate(ROUTES.VENDOR_RECENT, {
      organization: orgIndex,
    });
  };

  const handleGoogleLogin = async (credentialResponse) => {
    dispatch(setLoginSSO({ sso: true, ssoToken: credentialResponse?.credential }));

    const {
      payload: {
        role,
        mfa_method: mfaMethod = MFA_METHOD.NONE,
        status,
        redirect,
        phone,
        org_id: orgIdFromLogin,
      },
    } = await dispatch(loginUser({ sso: true, ssoToken: credentialResponse?.credential }));

    if (status === API_STATUS.FAILED) return null;

    setCookie(ORG_ID_AUTH_KEY, organizationId || orgIdFromLogin);

    if (mfaMethod !== MFA_METHOD.NONE) {
      dispatch(setLoginFormData({ key: 'phone', value: phone }));
      return setMode(LOGIN_FORM_MODE.OTP);
    }

    // set profile organizations in organizations store
    const { payload: organizations } = await dispatch(getProfileOrganizations());

    notification.success({
      message: translate(messages.welcome),
      duration: NOTIFICATION_DURATION.ONE_SECOND,
      className: 'notification-login-form-success',
    });

    // SOBO users should be redirected to the vendors/customers pages
    if (role === Roles.BO || role === Roles.SO) {
      const path = role === Roles.BO ? ROUTES.VENDOR_RECENT : ROUTES.CUSTOMER_RECENT;
      return appNavigate(path);
    }

    // In case a redirect is required
    if (originalPath || redirect) {
      return appNavigate(originalPath || redirect);
    }

    // TODO: if used only for redirect, remove this after the migration to the new login flow
    if (location.search.includes('ret')) {
      return appNavigate(`/${location.search.split('ret=')[1]}`, {
        organization: DEFAULT_ORGANIZATION_INDEX,
      });
    }

    // handle use case: assignment to new organization - update cookie to the relevant organization
    // if (organizationId) setCookie(ORG_ID_AUTH_KEY, organizationId); should be uncommented later on

    // default redirect
    const orgIndex = getOrganizationIndex({
      organizations,
      orgId: organizationId || orgIdFromLogin,
    });

    return appNavigate(ROUTES.VENDOR_RECENT, {
      organization: orgIndex,
    });
  };

  const onChange = (event, key) => {
    dispatch(setLoginFormData({ key, value: event.target.value }));
    handleChange(event);
  };

  const {
    errors,
    values: { email, password },
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: loginFullSchema(
      translate(messages.emailErrorFormat),
      translate(messages.requiredEmail),
      translate(messages.requiredPassword),
    ),

    onSubmit,
  });

  const onShowResetPasswordForm = () => setMode(LOGIN_FORM_MODE.FORGOT_PASSWORD);

  return (
    <div className="login-form">
      <CardWithLogo.Header title={translate(messages.welcome)} />
      <div className="google-sso-login-wrapper">
        <GoogleLogin
          ux_mode="popup"
          onSuccess={handleGoogleLogin}
          locale="en-us"
          width={'354'}
          onError={(error) => {
            notification.error('Unable to login with google.');
          }}
        />
      </div>
      <HrWithCildren>{translate(messages.loginOrHrText)}</HrWithCildren>
      <Form onSubmit={handleSubmit}>
        <Form.Item>
          <Input.Email
            block
            label={translate(messages.emailInputLabel)}
            onChange={(e) => onChange(e, 'email')}
            value={email}
            error={errors.email}
            data-test="login-form-email-input"
            data-test-error="login-form-email-error"
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            block
            name="password"
            label={translate(messages.labelPassword)}
            placeholder={translate(messages.inputPassword)}
            onChange={(e) => onChange(e, 'password')}
            value={password}
            error={errors.password}
            data-test="login-form-password-input"
            data-test-error="login-form-password-password-error"
            textHelperComponent={
              <Button
                className="login-form__forget-password-link"
                color="pink"
                name={translate(messages.forgotPasswordLinkTitle)}
                onClick={onShowResetPasswordForm}
                data-test="login-form-reset-password-link"
                link
              />
            }
          />
        </Form.Item>

        <ApiUrlSwitch />

        <Divider hidden />
        <Form.Item>
          <Button
            centered
            data-test="login-form-login-button"
            loading={loading}
            width={230}
            type="submit"
            name={translate(messages.loginButton)}
            color="pink"
          />
        </Form.Item>
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  setMode: PropTypes.func.isRequired,
};

export default LoginForm;
