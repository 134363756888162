import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { translate } from 'utils/index';
import Modal from 'ui/Modal';
import Icon from 'ui/Icon';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import { getFileMetaData, editFileMetaData } from 'api/assessment';
import MetadataItem from './metadataItem';
import { metadataControls } from './constants';
import { messages } from './messages';
import { API_STATUS } from 'constants/api';
import { notification } from 'antd';
import { truncateFilename } from 'pages/MyFiles/lib';
import moment from 'moment';
import { mixpanelTrackMetadataEdited } from 'utils/mixpanel';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import './index.scss';

const EvidenceMetadataEditor = ({ file, onHide, open, relation }) => {
  const [metaData, setMetaData] = useState({});
  const [showConfirmationDialog, setShowConfirmationDialog] = useState();
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const [fieldsEdited, setFieldsEdited] = useState([]);

  useEffect(() => {
    const getMetadata = async () => {
      const response = await dispatch(getFileMetaData({ id: file.id })).unwrap();
      if (
        response?.status === API_STATUS.FAILED ||
        !response?.metadata ||
        typeof response?.metadata !== 'object'
      ) {
        notification.error({ message: translate(messages.errorLoadingMetadata) });
        onHide();
      }
      setMetaData({
        ...response?.metadata,
        expiresOn: moment(response?.metadata?.expiresOn),
        issuedOn: moment(response?.metadata?.issuedOn),
      });
    };

    getMetadata();
  }, []);

  const onChange = (value, key, isDatePicker) => {
    setMetaData({
      ...metaData,
      // eslint-disable-next-line no-nested-ternary
      [key]: isDatePicker ? (value?.isValid() ? value : null) : value,
    });
    setFieldsEdited((prev) => {
      if (!prev.includes(key)) {
        return [...prev, key];
      }
      return prev;
    });
  };

  const onRemove = (key, ref) => {
    ref.current.value = null;
    setMetaData({ ...metaData, [key]: null });
  };

  const onSave = async (overriddenMetadata) => {
    setLoading(true);
    const metadataToSave = overriddenMetadata || {
      ...metaData,
      expiresOn: new Date(metaData['expiresOn']).toISOString() || null,
      issuedOn: new Date(metaData['issuedOn']).toISOString() || null,
    };
    dispatch(
      editFileMetaData({
        id: file.id,
        metadata: overriddenMetadata || JSON.stringify(metadataToSave),
      }),
    );
    mixpanelTrackMetadataEdited({
      relation: relation || 'none',
      streamDirection: isMyVendorsTabSelected ? 'downstream' : 'upstream',
      expiration: fieldsEdited?.includes('expiresOn') || false,
      fileName: file.title,
      fieldsEdited,
    });
    onHide();
    setLoading(false);
  };

  const onAccept = async () => {
    setLoading(true);
    await onSave(JSON.stringify(''));
    setShowConfirmationDialog(false);
    setLoading(false);
    onHide();
  };

  return (
    <Modal
      className="evidence-metadata-editor__modal"
      closeIcon={<Icon icon="close" />}
      confirmLoading={loading}
      open={open}
      onCancel={onHide}
      onOk={() => onSave()}
      okText={translate(messages.save)}
      cancelText={translate(messages.cancel)}
      width={685}
      title={translate(messages.modalTitle)}
      style={{ top: 20 }}
    >
      <div className="evidence-metadata-editor__header">
        <div className="evidence-metadata-editor__title">
          <span>{translate(messages.title)}</span>
          <strong>{truncateFilename(file)}</strong>
        </div>
      </div>
      <div className="evidence-metadata-editor__content">
        {metadataControls.map(({ title, items }) => (
          <div key={title} className="evidence-metadata-editor__category">
            <h4>{title}</h4>
            {items.map(({ key, date, ...rest }) => (
              <MetadataItem
                key={key}
                onRemove={onRemove}
                onChange={onChange}
                metadataValue={metaData[key]}
                itemKey={key}
                date={Boolean(date)}
                {...rest}
              />
            ))}
          </div>
        ))}
      </div>
      <RemoveConfirmationModal
        confirmLoading={loading}
        text={translate(messages.removeMetadataConfirmationDialogText)}
        onOk={onAccept}
        onCancel={() => setShowConfirmationDialog(false)}
        open={showConfirmationDialog}
      />
    </Modal>
  );
};

EvidenceMetadataEditor.propTypes = {
  file: PropTypes.any.isRequired,
  onHide: PropTypes.func,
  open: PropTypes.bool,
  relation: PropTypes.string,
};

export default EvidenceMetadataEditor;
