export const messages = {
  dashboardPageTitle: {
    key: 'dashboard.dashboardPageTitle',
    defaultMessage: 'Dashboard',
  },
};

export default {
  prefix: 'dashboard',
  messages,
};
