import { AssessmentMode } from 'constants/assessmentMode';
import { Status } from 'constants/status';
import { AssessmentStatus } from 'constants/assessmentStatus';

const { NotStarted, Completed } = Status;

export function getAssessmentMode(props) {
  if (props.showcaseName) {
    return AssessmentMode.ShowCase;
  }
  if (isStartAssessment(props) === AssessmentMode.StartAssessment) {
    return AssessmentMode.StartAssessment;
  }
  if (isResumeAssessment(props)) return AssessmentMode.ResumeAssessment;
  if (isReviewAndFinalizeLocked(props)) return AssessmentMode.ReviewAndFinalizeLocked;
  if (isReviewAndFinalizeWizard(props)) return AssessmentMode.ReviewAndFinalizeWizard;
  if (isReviewAndFinalizeWizardEditable(props)) {
    return AssessmentMode.ReviewAndFinalizeWizardEditable;
  }
  if (isReviewAndFinalizeDisabled(props)) return AssessmentMode.ReviewAndFinalizeDisabled;
  if (isMarkAsReviewed(props)) return AssessmentMode.MarkAsReviewed;
  if (isMarkAsReviewedDisabled(props)) return AssessmentMode.MarkAsReviewedDisabled;
  if (isReviewedAssessment(props)) return AssessmentMode.ReviewAssessment;
  return AssessmentMode.StartAssessment;
}

const isStartAssessment = ({ progress, finalized, status, upstream, assessor, assesse }) => {
  if (
    progress === NotStarted &&
    !finalized &&
    status === AssessmentStatus.notStarted &&
    !upstream
  ) {
    // TODO: simplify conditions
    if (assessor) {
      return true;
    }
    return !assessor && assesse;
  }
  return false;
};

const isResumeAssessment = ({ progress, finalized, status, upstream, assessor, assesse }) => {
  if (
    progress > NotStarted &&
    progress < Completed &&
    !finalized &&
    status === AssessmentStatus.inProgress &&
    !upstream
  ) {
    // TODO: simplify conditions
    if (assessor) {
      return true;
    }
    if (!assessor && assesse) {
      return true;
    }
  }
  return false;
};

const isReviewAndFinalizeLocked = ({
  progress,
  finalized,
  status,
  upstream,
  assessor,
  assesse,
}) => {
  if (progress === Completed && !finalized && status === AssessmentStatus.completed && !upstream) {
    // TODO: simplify conditions
    if (assessor) {
      return true;
    }
    return !assessor && assesse;
  }
  return false;
};

const isReviewAndFinalizeWizard = ({
  progress,
  finalized,
  status,
  upstream,
  assessor,
  assesse,
}) => {
  return (
    progress === Completed &&
    finalized &&
    status === AssessmentStatus.finalized &&
    !upstream &&
    !assessor &&
    assesse
  );
};

const isReviewAndFinalizeWizardEditable = ({
  progress,
  finalized,
  status,
  upstream,
  assessor,
  assesse,
}) => {
  return (
    progress === Completed &&
    finalized &&
    status === AssessmentStatus.finalized &&
    !upstream &&
    assessor &&
    (!assesse || assesse)
  );
};

const isReviewAndFinalizeDisabled = ({
  progress,
  finalized,
  status,
  upstream,
  assessor,
  assesse,
}) => {
  return (
    progress === Completed &&
    finalized &&
    status === AssessmentStatus.reviewed &&
    !upstream &&
    !assessor &&
    assesse
  );
};

const isMarkAsReviewed = ({ finalized, status, upstream, assessor, assesse }) => {
  return (
    finalized &&
    status === AssessmentStatus.reviewed &&
    !upstream &&
    assessor &&
    (!assesse || assesse)
  );
};

const isMarkAsReviewedDisabled = ({ finalized, status, upstream }) => {
  return finalized && status === AssessmentStatus.disabled && !upstream;
};

const isReviewedAssessment = ({ finalized, status, upstream }) => {
  return finalized && status === AssessmentStatus.finalized && upstream;
};
