import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {},
};

const reducers = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
});

// export const { } = reducers.actions;

export default reducers;
