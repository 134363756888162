import React from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import Popup from 'ui/Popup';
import Input from 'ui/Input';
import Select from 'ui/Select';
import { translate } from 'utils/index';
import { Roles } from 'constants/roles';
import { newUserSchema } from 'pages/Organization/schema';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { getCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY } from 'utils/auth';

import { addOrganizationProfile } from 'api/organizations';
import { mixpanelTrackTeammateAdded } from 'utils/mixpanel';

import { messages } from 'components/BaseLayout/messages';
import './inviteTeamMember.scss';

const InviteTeamMember = ({ open, setOpen, addNewUser }) => {
  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const initValues = { email: '', role: undefined };

  const setPopupOnClick = () => {
    setOpen(!open);
    setValues(initValues, false);
    setErrors({});
  };

  const options = [{ label: translate(messages.BO), value: Roles.BO, key: 'BORoleSelect' }];

  const {
    errors,
    values: { email, role },
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    setErrors,
  } = useFormik({
    validateOnChange: false,

    initialValues: {
      email: '',
      role: Roles.BO,
    },

    validationSchema: newUserSchema(
      translate(messages.inviteTeamMemberEmailErrorFormat),
      translate(messages.inviteTeamMemberEmailRequired),
      translate(messages.inviteTeamMemberRoleRequired),
    ),

    onSubmit: async () => {
      const currentOrgId = getCookie(ORG_ID_AUTH_KEY);
      const payload = { email, role, orgId: currentOrgId };
      const {
        payload: { profiles },
      } = await dispatch(addOrganizationProfile(payload));
      await addNewUser(email);

      setOpen(!open);
      const source = isMyVendorsTabSelected ? 'upstream vendors' : 'downstream vendors';
      mixpanelTrackTeammateAdded({ ...payload, options, source });

      if (profiles) {
        return notification.success({ message: translate(messages.inviteTeamMemberSuccess) });
      }

      return notification.success({ message: translate(messages.inviteTeamMemberError) });
    },
  });

  return (
    <Popup.WithForm
      className="recent-page-vendors-invite-team-member__popup"
      open={open}
      onClickCancel={setPopupOnClick}
      withIconOnTitle="plus"
      title={translate(messages.inviteTeamMemberUserTitle)}
      width={400}
      onClickOk={handleSubmit}
    >
      <Input
        block
        name="email"
        type="text"
        value={email}
        placeholder={translate(messages.inviteTeamMemberEmailPlaceholder)}
        onChange={handleChange}
        error={errors.email}
        data-test="recent-page-vendors-invite-team-member-button-email"
      />
      <Select
        placeholder={translate(messages.inviteTeamMemberRolePlaceholder)}
        options={options}
        size="large"
        color="gray"
        name="role"
        value={role}
        onSelect={(value) => setFieldValue('role', value, false)}
        error={errors.role}
        className="recent-page-vendors-invite-team-member__button-select"
        showSearch={false}
        setItemDataTest={(option) => `new-user-${option.value}`}
      />
    </Popup.WithForm>
  );
};

InviteTeamMember.propTypes = {
  addNewUser: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default InviteTeamMember;
