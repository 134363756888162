import { messages } from './messages';

export const riskValues = [
  {
    id: 0,
    label: messages.riskLow,
  },
  {
    id: 1,
    label: messages.riskMedium,
  },
  {
    id: 2,
    label: messages.riskhigh,
  },
  {
    id: 3,
    label: messages.riskCritical,
  },
  {
    id: 4,
    label: messages.riskShowstopper,
  },
];

export const findingStatus = [
  {
    id: 0,
    label: messages.statusOpen,
  },
  {
    id: 1,
    label: messages.statusClosed,
  },
  {
    id: 2,
    label: messages.statusPending,
  },
];
