const ROUTES = {
  // Vendors
  VENDOR_RECENT: '/:organization/vendor',
  VENDOR_OVERVIEW: '/:organization/vendor/overview',
  VENDOR_DASHBOARD: '/:organization/vendor/dashboard',
  VENDOR_VENDORS: '/:organization/vendor/vendors',
  VENDOR_NEW_VENDOR: '/:organization/vendor/vendors/new-vendor',
  VENDOR_EDIT: '/:organization/vendor/vendors/edit/:vendorId',
  VENDOR_UPLOAD_VENDORS: '/:organization/vendor/vendors/upload-vendors',
  VENDOR_VENDOR: '/:organization/vendor/vendors/:vendorId',
  VENDOR_ASSESSMENTS: '/:organization/vendor/assessments',
  VENDOR_ASSIGN_ASSESSMENT: '/:organization/vendor/assessments/assign-assessment',
  VENDOR_ASSESSMENT_TEMPLATES: '/:organization/vendor/assessments/templates',
  VENDOR_ASSESSMENT: '/:organization/vendor/assessments/:assessmentId',
  VENDOR_ASSESSMENT_QUESTIONNAIRE: '/:organization/vendor/assessments/:assessmentId/questionnaire',
  VENDOR_ASSESSMENT_FINDINGS: '/:organization/vendor/assessments/:assessmentId/findings',
  VENDOR_ASSESSMENT_SECURED_EVIDENCE:
    '/:organization/vendor/assessments/:assessmentId/secured-evidence',
  VENDOR_ASSESSMENT_REPORT: '/:organization/vendor/assessments/:assessmentId/report',
  VENDOR_ASSESSMENT_TEMPLATE: '/:organization/vendor/assessments/templates/:templateId',
  VENDOR_ASSESSMENT_TEMPLATE_PREVIEW:
    '/:organization/vendor/assessments/templates/preview/:assessmentId',
  VENDOR_FINDINGS: '/:organization/vendor/findings',
  VENDOR_SHOWCASE_PREVIEW: '/:organization/vendor/showcases/preview/:showcaseId',
  VENDOR_CONTINUOUS_MONITORING: '/:organization/vendor/continuous-monitoring/:assessmentId/:appId',
  VENDOR_MY_FILES: '/:organization/vendor/myFiles',
  // Customers
  CUSTOMER_RECENT: '/:organization/customer',
  CUSTOMER_OVERVIEW: '/:organization/customer/overview',
  CUSTOMER_CUSTOMERS: '/:organization/customer/customers',
  CUSTOMER_NEW_CUSTOMER: '/:organization/customer/customers/new-customer',
  CUSTOMER_EDIT_CUSTOMER: '/:organization/customer/customers/edit/:customerId',
  CUSTOMER_UPLOAD_CUSTOMERS: '/:organization/customer/customers/upload-customer',
  CUSTOMER_CUSTOMER: '/:organization/customer/customers/:customerId',
  CUSTOMER_ASSESSMENTS: '/:organization/customer/assessments',
  CUSTOMER_ASSIGN_ASSESSMENT: '/:organization/customer/assessments/assign-assessment',
  CUSTOMER_ASSESSMENT_TEMPLATES: '/:organization/customer/assessments/templates',
  CUSTOMER_ASSESSMENT: '/:organization/customer/assessments/:assessmentId',
  CUSTOMER_ASSESSMENT_QUESTIONNAIRE:
    '/:organization/customer/assessments/:assessmentId/questionnaire',
  CUSTOMER_ASSESSMENT_FINDINGS: '/:organization/customer/assessments/:assessmentId/findings',
  CUSTOMER_ASSESSMENT_SECURED_EVIDENCE:
    '/:organization/customer/assessments/:assessmentId/secured-evidence',
  CUSTOMER_ASSESSMENT_REPORT: '/:organization/customer/assessments/:assessmentId/report',
  CUSTOMER_ASSESSMENT_TEMPLATE: '/:organization/customer/assessments/templates/:templateId',
  CUSTOMER_ASSESSMENT_TEMPLATE_PREVIEW:
    '/:organization/customer/assessments/templates/preview/:assessmentId',
  CUSTOMER_SHOWCASES: '/:organization/customer/showcases',
  CUSTOMER_SHOWCASES_ASSIGN_SHOWCASE: '/:organization/customer/showcases/assign-showcase',
  CUSTOMER_SHOWCASE_PREVIEW: '/:organization/customer/showcases/preview/:showcaseId',
  CUSTOMER_FINDINGS: '/:organization/customer/findings',
  CUSTOMER_CONTINUOUS_MONITORING:
    '/:organization/customer/continuous-monitoring/:assessmentId/:appId',
  CUSTOMER_MY_FILES: '/:organization/customer/myFiles',
  // Other
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  BUSINESS_OWNER_PAGE: '/:organizationId/vendor/assessment/request',
  SALES_OWNER_PAGE: '/:organizationId/customer/assessment/request',
  PROFILE_SETTINGS: '/:organization/:type/profile-settings',
  ORGANIZATIONS_SETTINGS: '/:organization/:type/organizations-settings',
  ORGANIZATION_EDIT: '/:organization/:type/organization-edit/:baseOrganization/:organizationId',
  ORGANIZATION_EDIT_TAB:
    '/:baseOrganization/:type/organization-edit/:organization/:organizationId/:tab',
  ORGANIZATION_EDIT_ASSESSMENT: '/:organization/assessments/:organizationId/:assessmentId',
  RESET_PASSWORD: '/reset-password/:token',
  ACTIVATION: '/activation/:token',
  ONBOARD: '/activation/onboard/:token',
  ACTIVATION_ASSESSMENT: '/activation/:token/:assessmentId',
  ONBOARD_ASSESSMENT: '/activation/onboard/:token/:assessmentId',
  HASH_REDIRECT_PREFIX: '/site/',
};

export const ROUTES_TAB = {
  VENDOR: 'vendor',
  CUSTOMER: 'customer',
};

export const OLD_ROUTES = {
  RESET_PASSWORD: '/site/:token/activation',
  ACTIVATION_ASSESSMENT: '/site/:token/activation/:assessmentId',
  ASSIGN_TO_ORGANIZATION: '/site/:organizationId',
};

export default ROUTES;
