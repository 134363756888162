import React from 'react';

// Local Imports
import ROUTES from 'constants/routes';
import { VendorManageContextProvider } from 'context/vendorManageContext';
import BaseLayout from 'components/BaseLayout';

// Pages
import AssignAssessment from 'pages/AssignAssessment';
import AssignShowcase from 'pages/Showcases/assignShowcase';
import Assessment from 'pages/Assessment';
import AssessmentReport from 'pages/Assessment/report';
import Assessments from 'pages/Assessments';
import CustomersList from 'pages/Customers/CustomerList';
import CustomerManage from 'pages/Customers/CustomerManage';
import Editor from 'pages/Editor';
import NewVendorBoPage from 'pages/SoBoPages/NewVendor';
import NewCustomerSoPage from 'pages/SoBoPages/NewCustomer';
import OrganizationsSettings from 'pages/Organization/settings';
import OrganizationEdit from 'pages/Organization/edit';
import Overview from 'pages/Overview';
import ProfileSettings from 'pages/Profile/settings';
import Recent from 'pages/Recent';
import Showcases from 'pages/Showcases';
import VendorList from 'pages/Vendors/VendorsList';
import VendorManage from 'pages/Vendors/VendorManage';
import ContinuousMonitoring from 'pages/ContinuousMonitoring';

import { CustomerManageContextProvider } from 'context/customerManageContext';
import ShowcasePreview from 'pages/Showcases/showcasePreview';
import Findings from 'pages/Findings';
import PendingUnassignedAssessments from 'components/PendingUnassignedAssessments';
import PagePopups from 'pages/Recent/pagePopups';
import UploadList from 'pages/UploadList';
import MyFiles from 'pages/MyFiles';
import Dashboard from 'pages/Dashboard';

export const PRIVATE_ROUTES = {
  // Vendors
  VENDOR_RECENT: {
    path: ROUTES.VENDOR_RECENT,
    element: (
      <BaseLayout>
        <Recent />
        <PagePopups />
      </BaseLayout>
    ),
  },
  VENDOR_OVERVIEW: {
    path: ROUTES.VENDOR_OVERVIEW,
    element: (
      <BaseLayout>
        <Overview />
      </BaseLayout>
    ),
  },
  VENDOR_DASHBOARD: {
    path: ROUTES.VENDOR_DASHBOARD,
    element: (
      <BaseLayout>
        <Dashboard />
      </BaseLayout>
    ),
  },
  VENDOR_VENDORS: {
    path: ROUTES.VENDOR_VENDORS,
    element: (
      <BaseLayout>
        <VendorList />
      </BaseLayout>
    ),
  },
  VENDOR_EDIT: {
    path: ROUTES.VENDOR_EDIT,
    element: (
      <BaseLayout>
        <VendorManageContextProvider>
          <VendorManage />
        </VendorManageContextProvider>
      </BaseLayout>
    ),
  },
  VENDOR_NEW_VENDOR: {
    path: ROUTES.VENDOR_NEW_VENDOR,
    element: (
      <BaseLayout>
        <VendorManageContextProvider>
          <VendorManage />
        </VendorManageContextProvider>
      </BaseLayout>
    ),
  },
  VENDOR_UPLOAD_VENDORS: {
    path: ROUTES.VENDOR_UPLOAD_VENDORS,
    element: (
      <BaseLayout>
        <UploadList />
      </BaseLayout>
    ),
  },
  VENDOR_VENDOR: {
    path: ROUTES.VENDOR_VENDOR,
    element: <BaseLayout withPlaceholder>MY VENDORS: VENDOR</BaseLayout>,
  },
  VENDOR_ASSESSMENTS: {
    path: ROUTES.VENDOR_ASSESSMENTS,
    element: (
      <BaseLayout>
        <Assessments />
      </BaseLayout>
    ),
  },
  VENDOR_ASSIGN_ASSESSMENT: {
    path: ROUTES.VENDOR_ASSIGN_ASSESSMENT,
    element: (
      <BaseLayout>
        <AssignAssessment />
      </BaseLayout>
    ),
  },
  VENDOR_ASSESSMENTS_TEMPLATES: {
    path: ROUTES.VENDOR_ASSESSMENT_TEMPLATES,
    element: (
      <BaseLayout>
        <Assessments />
      </BaseLayout>
    ),
  },
  VENDOR_ASSESSMENT_TEMPLATE_PREVIEW: {
    path: ROUTES.VENDOR_ASSESSMENT_TEMPLATE_PREVIEW,
    element: (
      <BaseLayout>
        <Assessment />
      </BaseLayout>
    ),
  },
  VENDOR_ASSESSMENT: {
    path: ROUTES.VENDOR_ASSESSMENT,
    element: (
      <BaseLayout>
        <Assessment />
      </BaseLayout>
    ),
  },
  VENDOR_ASSESSMENT_QUESTIONNAIRE: {
    path: ROUTES.VENDOR_ASSESSMENT_QUESTIONNAIRE,
    element: (
      <BaseLayout>
        <Assessment />
      </BaseLayout>
    ),
  },
  VENDOR_ASSESSMENT_FINDINGS: {
    path: ROUTES.VENDOR_ASSESSMENT_FINDINGS,
    element: (
      <BaseLayout>
        <Assessment />
      </BaseLayout>
    ),
  },
  VENDOR_CONTINUOUS_MONITORING: {
    path: ROUTES.VENDOR_CONTINUOUS_MONITORING,
    element: (
      <BaseLayout>
        <ContinuousMonitoring />
      </BaseLayout>
    ),
  },
  VENDOR_ASSESSMENT_SECURED_EVIDENCE: {
    path: ROUTES.VENDOR_ASSESSMENT_SECURED_EVIDENCE,
    element: (
      <BaseLayout>
        <Assessment />
      </BaseLayout>
    ),
  },
  VENDOR_ASSESSMENT_REPORT: {
    path: ROUTES.VENDOR_ASSESSMENT_REPORT,
    element: <AssessmentReport />,
  },
  VENDOR_ASSESSMENT_TEMPLATE: {
    path: ROUTES.VENDOR_ASSESSMENT_TEMPLATE,
    element: (
      <BaseLayout>
        <Editor />
      </BaseLayout>
    ),
  },
  VENDOR_FINDINGS: {
    path: ROUTES.VENDOR_FINDINGS,
    element: (
      <BaseLayout>
        <Findings />
      </BaseLayout>
    ),
  },
  VENDOR_SHOWCASE_PREVIEW: {
    path: ROUTES.VENDOR_SHOWCASE_PREVIEW,
    element: (
      <BaseLayout>
        <ShowcasePreview />
      </BaseLayout>
    ),
  },
  VENDOR_MY_FILES: {
    path: ROUTES.VENDOR_MY_FILES,
    element: (
      <BaseLayout>
        <MyFiles />
      </BaseLayout>
    ),
  },
  // Customers
  CUSTOMER_RECENT: {
    path: ROUTES.CUSTOMER_RECENT,
    element: (
      <BaseLayout>
        <Recent />
        <PendingUnassignedAssessments />
      </BaseLayout>
    ),
  },
  CUSTOMER_OVERVIEW: {
    path: ROUTES.CUSTOMER_OVERVIEW,
    element: (
      <BaseLayout>
        <Overview />
      </BaseLayout>
    ),
  },
  CUSTOMER_CUSTOMERS: {
    path: ROUTES.CUSTOMER_CUSTOMERS,
    element: (
      <BaseLayout>
        <CustomersList />
      </BaseLayout>
    ),
  },
  CUSTOMER_NEW_CUSTOMER: {
    path: ROUTES.CUSTOMER_NEW_CUSTOMER,
    element: (
      <BaseLayout>
        <CustomerManageContextProvider>
          <CustomerManage />
        </CustomerManageContextProvider>
      </BaseLayout>
    ),
  },
  CUSTOMER_EDIT_CUSTOMER: {
    path: ROUTES.CUSTOMER_EDIT_CUSTOMER,
    element: (
      <BaseLayout>
        <CustomerManageContextProvider>
          <CustomerManage />
        </CustomerManageContextProvider>
      </BaseLayout>
    ),
  },
  CUSTOMER_UPLOAD_CUSTOMERS: {
    path: ROUTES.CUSTOMER_UPLOAD_CUSTOMERS,
    element: (
      <BaseLayout>
        <UploadList />
      </BaseLayout>
    ),
  },
  CUSTOMER_CUSTOMER: {
    path: ROUTES.CUSTOMER_CUSTOMER,
    element: <BaseLayout withPlaceholder>MY CUSTOMERS: ASSESSMENTS</BaseLayout>,
  },
  CUSTOMER_ASSESSMENTS: {
    path: ROUTES.CUSTOMER_ASSESSMENTS,
    element: (
      <BaseLayout>
        <Assessments />
      </BaseLayout>
    ),
  },
  CUSTOMER_ASSIGN_ASSESSMENT: {
    path: ROUTES.CUSTOMER_ASSIGN_ASSESSMENT,
    element: (
      <BaseLayout>
        <AssignAssessment />
      </BaseLayout>
    ),
  },
  CUSTOMER_ASSESSMENT_TEMPLATES: {
    path: ROUTES.CUSTOMER_ASSESSMENT_TEMPLATES,
    element: (
      <BaseLayout>
        <Assessments />
      </BaseLayout>
    ),
  },
  CUSTOMER_ASSESSMENT: {
    path: ROUTES.CUSTOMER_ASSESSMENT,
    element: (
      <BaseLayout>
        <Assessment />
      </BaseLayout>
    ),
  },
  CUSTOMER_ASSESSMENT_QUESTIONNAIRE: {
    path: ROUTES.CUSTOMER_ASSESSMENT_QUESTIONNAIRE,
    element: (
      <BaseLayout>
        <Assessment />
      </BaseLayout>
    ),
  },
  CUSTOMER_ASSESSMENT_FINDINGS: {
    path: ROUTES.CUSTOMER_ASSESSMENT_FINDINGS,
    element: (
      <BaseLayout>
        <Assessment />
      </BaseLayout>
    ),
  },
  CUSTOMER_ASSESSMENT_SECURED_EVIDENCE: {
    path: ROUTES.CUSTOMER_ASSESSMENT_SECURED_EVIDENCE,
    element: (
      <BaseLayout>
        <Assessment />
      </BaseLayout>
    ),
  },
  CUSTOMER_ASSESSMENT_REPORT: {
    path: ROUTES.CUSTOMER_ASSESSMENT_REPORT,
    element: <AssessmentReport />,
  },
  CUSTOMER_ASSESSMENT_TEMPLATE: {
    path: ROUTES.CUSTOMER_ASSESSMENT_TEMPLATE,
    element: (
      <BaseLayout>
        <Editor />
      </BaseLayout>
    ),
  },
  CUSTOMER_ASSESSMENT_TEMPLATE_PREVIEW: {
    path: ROUTES.CUSTOMER_ASSESSMENT_TEMPLATE_PREVIEW,
    element: (
      <BaseLayout>
        <Assessment />
      </BaseLayout>
    ),
  },
  CUSTOMER_SHOWCASES: {
    path: ROUTES.CUSTOMER_SHOWCASES,
    element: (
      <BaseLayout>
        <Showcases />
      </BaseLayout>
    ),
  },
  CUSTOMER_SHOWCASES_ASSIGN_SHOWCASE: {
    path: ROUTES.CUSTOMER_SHOWCASES_ASSIGN_SHOWCASE,
    element: (
      <BaseLayout>
        <AssignShowcase />
      </BaseLayout>
    ),
  },
  CUSTOMER_SHOWCASE_PREVIEW: {
    path: ROUTES.CUSTOMER_SHOWCASE_PREVIEW,
    element: (
      <BaseLayout>
        <ShowcasePreview />
      </BaseLayout>
    ),
  },
  CUSTOMER_FINDINGS: {
    path: ROUTES.CUSTOMER_FINDINGS,
    element: (
      <BaseLayout>
        <Findings />
      </BaseLayout>
    ),
  },
  CUSTOMER_CONTINUOUS_MONITORING: {
    path: ROUTES.CUSTOMER_CONTINUOUS_MONITORING,
    element: (
      <BaseLayout>
        <ContinuousMonitoring />
      </BaseLayout>
    ),
  },
  CUSTOMER_MY_FILES: {
    path: ROUTES.CUSTOMER_MY_FILES,
    element: (
      <BaseLayout>
        <MyFiles />
      </BaseLayout>
    ),
  },
  // PROFILE
  PROFILE_SETTINGS: {
    path: ROUTES.PROFILE_SETTINGS,
    element: (
      <BaseLayout>
        <ProfileSettings />
      </BaseLayout>
    ),
  },
  // ORGANIZATION
  ORGANIZATIONS_SETTINGS: {
    path: ROUTES.ORGANIZATIONS_SETTINGS,
    element: (
      <BaseLayout>
        <OrganizationsSettings />
      </BaseLayout>
    ),
  },
  ORGANIZATION_EDIT: {
    path: ROUTES.ORGANIZATION_EDIT,
    element: (
      <BaseLayout>
        <OrganizationEdit />
      </BaseLayout>
    ),
  },
  ORGANIZATION_EDIT_TAB: {
    path: ROUTES.ORGANIZATION_EDIT_TAB,
    element: (
      <BaseLayout>
        <OrganizationEdit />
      </BaseLayout>
    ),
  },
  ORGANIZATION_EDIT_ASSESSMENT: {
    path: ROUTES.ORGANIZATION_EDIT_ASSESSMENT,
    element: <BaseLayout withPlaceholder>Assessment</BaseLayout>,
  },
  BUSINESS_OWNER_PAGE: {
    path: ROUTES.BUSINESS_OWNER_PAGE,
    element: <NewVendorBoPage />,
  },
  SALES_OWNER_PAGE: {
    path: ROUTES.SALES_OWNER_PAGE,
    element: <NewCustomerSoPage />,
  },
};
