import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { validPayload } from 'pages/Vendors/VendorManage/lib';
import { messages } from 'pages/Vendors/VendorManage/messages';
import { selectProfile } from 'pages/Profile/selectors';

import { addProfileVendor, setProfileVendor } from 'api/vendor';
import { useAppNavigate } from 'hooks/navigation';

import { API_STATUS } from 'constants/api';
import { NOTIFICATION_DURATION } from 'constants/general';
import ROUTES from 'constants/routes';
import { MixpanelAssignAssessmentSource } from 'constants/mixpanel';

import { translate } from 'utils/index';
import { triggerCategoriesControls } from 'utils/triggers';
import {
  mixpanelTrackAssignedAssessmentFromVendorOrCustomer,
  mixpanelTrackVendorOrCustomer,
} from 'utils/mixpanel';
import { useIsVendorDirty } from 'hooks/useIsVendorDirty';
import { saveControl } from 'api/assessment';

const CONTACT_INFORMATION_TAB_KEY = 'Contact Information';
const CONTINUOUS_MONITORING_TAB_KEY = 'Continuous monitoring';

export const VendorManageContext = createContext({});

const initFormContact = (currentVendor) => ({
  corporateName: currentVendor?.profileCorporateName || '',
  companyDomain: currentVendor?.companyDomain || '',
  companyContact: currentVendor?.profileFullname || '',
  email: currentVendor?.profileEmail || '',
  phone: currentVendor?.profilePhone === 'undefined' ? '' : currentVendor?.profilePhone,
  setVendorGroups: currentVendor?.profileRelationGroups,
  vendorStatus: currentVendor?.vendorStatus || undefined,
});

export const VendorManageContextProvider = ({ children }) => {
  const { vendorId } = useParams();
  const appNavigate = useAppNavigate();
  const dispatch = useDispatch();
  const {
    user: { _assessmentJson: assessmentJson },
  } = useSelector(selectProfile);
  const { currentVendor, isFromRecent } = useSelector((state) => state.vendors);

  const [currentTab, setCurrentTab] = useState(
    currentVendor ? CONTINUOUS_MONITORING_TAB_KEY : CONTACT_INFORMATION_TAB_KEY,
  );
  const [contactFormErrors, setContactFormErrors] = useState(undefined);
  const [isAddingAnalysis, setIsAddingAnalysis] = useState(false);
  const [isRemovingAnalysis, setIsRemovingAnalysis] = useState(false);
  const [vendorContactData, setVendorContactData] = useState(() => initFormContact(currentVendor));
  const vendorAssetsDataDefault = {
    assessments: '',
    'assessments-period': null,
    apps: '',
  };
  const [vendorAssetsData, setVendorAssetsData] = useState(vendorAssetsDataDefault);

  const vendorAssessmentsDataDefault =
    currentVendor?._assessmentJson?.length || 0 ? currentVendor?._assessmentJson : [assessmentJson]; // eslint-disable-line no-underscore-dangle
  const [vendorAssessmentsData, setVendorAssessmentsData] = useState(vendorAssessmentsDataDefault);
  const isContactFormDirty = useRef(vendorContactData?.corporateName);

  const handleTabChange = (activeKey) => {
    setCurrentTab(activeKey);
  };

  useEffect(() => {
    setVendorContactData(initFormContact(currentVendor));
    setVendorAssessmentsData(vendorAssessmentsDataDefault);
    setCurrentTab(currentVendor ? CONTINUOUS_MONITORING_TAB_KEY : CONTACT_INFORMATION_TAB_KEY);
    isContactFormDirty.current = false;
  }, [currentVendor, assessmentJson]);

  const [isVendorDirty] = useIsVendorDirty(
    currentVendor,
    vendorContactData,
    vendorAssessmentsData,
    vendorAssetsData,
    vendorAssessmentsDataDefault,
    vendorAssetsDataDefault,
    initFormContact,
  );

  const updateVendor = async (isAddingTab) => {
    let response;
    const currentError = await validPayload(vendorContactData);

    if (Object.keys(currentError)?.length > 0) {
      setContactFormErrors(currentError);
      handleTabChange(CONTACT_INFORMATION_TAB_KEY);
      return null;
    }

    const payload = {
      ...vendorContactData,
      ...vendorAssetsData,
      assessmentJson: vendorId ? vendorAssessmentsData : vendorAssessmentsData[0],
    };

    if (isAddingTab) {
      setIsAddingAnalysis(true);
      const {
        payload: {
          vendor: { _assessmentJson: assessmentJsonFromBE },
        },
      } = await dispatch(
        setProfileVendor({
          ...payload,
          assessmentJson: [...payload.assessmentJson, assessmentJson],
          vendorId,
        }),
      );
      setIsAddingAnalysis(false);
      // There is a backend issue which causing a blank page, probably where the user's assessmentJSON is an empty array
      return assessmentJsonFromBE?.length === 0 ? assessmentJson.id : assessmentJsonFromBE[0]?.id;
    }

    if (!vendorId) {
      response = await dispatch(addProfileVendor(payload));
      mixpanelTrackVendorOrCustomer(
        {
          ...payload,
          impactAnalysisInfoFilled:
            JSON.stringify(assessmentJson) === JSON.stringify(payload.assessmentJson),
          relation: response?.payload?.profile_customer,
        },
        true,
      );
    } else {
      response = await dispatch(
        setProfileVendor({
          ...payload,
          vendorId,
        }),
      );
      mixpanelTrackAssignedAssessmentFromVendorOrCustomer(
        MixpanelAssignAssessmentSource.NewVendor,
        {
          ...payload,
          relation: response?.payload?.profile_customer,
        },
        'downstream',
      );
    }

    const { status } = response.payload;
    if (status === API_STATUS.FAILED || !status) return null;

    notification.success({
      message: translate(vendorId ? messages.vendorHasBeenUpdated : messages.vendorHasBeenAdded),
      duration: NOTIFICATION_DURATION.TWO_SECONDS,
    });
    if (isFromRecent) {
      return appNavigate(ROUTES.VENDOR_RECENT);
    }
    return appNavigate(ROUTES.VENDOR_VENDORS);
  };

  const onChangeContactFrom = (value, name) => {
    isContactFormDirty.current = true;
    setVendorContactData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onChangeAssessment = (values, assessmentId, newControlId) => {
    if (!vendorAssessmentsData?.length || !newControlId) {
      return null;
    }
    const currentAssessment = vendorAssessmentsData.find(
      (assessment) => assessment.id === assessmentId,
    );
    const categoriesWithControls = triggerCategoriesControls(
      values.find((control) => control.id === newControlId),
      currentAssessment?.categories,
    );
    const allTriggeredControls = categoriesWithControls.flatMap(({ controls }) => controls);

    setVendorAssessmentsData((prev) => {
      return prev.map((assessment) => {
        if (assessment.id === assessmentId) {
          return {
            ...assessment,
            categories: assessment.categories.map((assessmentCategory) => {
              return {
                ...assessmentCategory,
                controls: assessmentCategory.controls.map((control) => {
                  const currentUpdatedControl =
                    allTriggeredControls.find(
                      (currentControl) => currentControl.id === control.id,
                    ) || {};
                  return {
                    ...control,
                    ...currentUpdatedControl,
                  };
                }, []),
              };
            }),
          };
        }
        return assessment;
      });
    });

    if (vendorId) {
      dispatch(
        saveControl({
          categoryId: values[0].categoryId,
          assessmentId,
          control: { answer: values[0].answer, id: newControlId },
        }),
      );
    }

    return null;
  };

  const onChangeAssets = ({ checkedAssessmentRequired, checkedAssessmentPeriod }) => {
    setVendorAssetsData({
      assessments: checkedAssessmentRequired.join(','),
      'assessments-period':
        typeof checkedAssessmentPeriod === 'number' ? checkedAssessmentPeriod : null,
      apps: '',
    });
  };

  const onChangeTitle = async (value, id) => {
    const currentAssessmentsJson = vendorId ? vendorAssessmentsData : vendorAssessmentsData[0];
    const updatedAssessmentJson = currentAssessmentsJson.map((assessment) => {
      if (assessment.id === id) {
        return {
          ...assessment,
          title: value,
        };
      }
      return assessment;
    });

    await dispatch(
      setProfileVendor({
        assessmentJson: updatedAssessmentJson,
        vendorId,
        ...vendorContactData,
        ...vendorAssetsData,
      }),
    );
  };

  const onDeleteCategory = async (id) => {
    setIsRemovingAnalysis(true);
    const newAssessmentJson = vendorId ? vendorAssessmentsData : vendorAssessmentsData[0];
    await dispatch(
      setProfileVendor({
        assessmentJson: newAssessmentJson.filter((assessment) => assessment.id !== id),
        vendorId,
        ...vendorContactData,
        ...vendorAssetsData,
      }),
    );
    setIsRemovingAnalysis(false);
  };

  const value = useMemo(
    () => ({
      currentVendor,

      vendorContactData,
      contactFormErrors,
      isContactFormDirty,
      onChangeContactFrom,

      vendorAssessmentsData,
      vendorAssetsData,
      updateVendor,
      onChangeAssessment,
      onChangeAssets,
      handleTabChange,
      onChangeTitle,
      onDeleteCategory,
      currentTab,
      isAddingAnalysis,
      isRemovingAnalysis,
      isVendorDirty,
    }),
    [
      currentVendor,
      currentTab,
      vendorContactData,
      vendorAssessmentsData,
      vendorAssetsData,
      contactFormErrors,
      assessmentJson,
      onChangeAssessment,
      onChangeContactFrom,
      onChangeAssets,
      updateVendor,
      onChangeTitle,
      onDeleteCategory,
      isAddingAnalysis,
      isRemovingAnalysis,
      isVendorDirty,
    ],
  );

  return <VendorManageContext.Provider value={value}>{children}</VendorManageContext.Provider>;
};

VendorManageContextProvider.propTypes = {
  children: PropTypes.node,
};
