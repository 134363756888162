import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'ui/Checkbox';
import './index.scss';

const CheckboxGroup = ({
  children,
  disabled,
  error,
  onChange,
  options,
  name,
  value,
  mode = 'checkbox',
  isDataTestStandalone,
  ...restProps
}) => {
  const renderOptions = (options || []).map((option, index) => {
    const checkboxOnChange = () => {
      const newValue = value ? [...value] : [];
      if (newValue.includes(option.value)) {
        // eslint-disable-next-line no-shadow
        onChange(newValue.filter((value) => value !== option.value));
      } else {
        onChange([...newValue, option.value]);
      }
    };

    const dataTest = isDataTestStandalone
      ? restProps['data-test']
      : `${restProps['data-test'] || 'checkbox-group'}-item-${option.key || index}`;

    return (
      <Checkbox
        key={option.key}
        disabled={disabled}
        onChange={checkboxOnChange}
        value={option.value}
        name={`${name}-${option.value}`}
        checked={(value || []).indexOf(option.value) > -1}
        mode={mode}
        data-test={dataTest}
      >
        {option.label}
      </Checkbox>
    );
  });

  return (
    <div className="ui-checkbox-group" data-test={restProps['data-test']}>
      <div className="ui-checkbox-group__children">
        {options?.length > 0 ? renderOptions : children}
      </div>
      <div className="ui-checkbox-group__error">{error}</div>
    </div>
  );
};

CheckboxGroup.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  name: PropTypes.string,
  value: PropTypes.array,
  'data-test': PropTypes.string,
  isDataTestStandalone: PropTypes.bool,
  mode: PropTypes.oneOf(['checkbox', 'label']),
};

export default CheckboxGroup;
