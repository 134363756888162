import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useHotkeys } from 'react-hotkeys-hook';

import { saveTemplate } from 'api/editor';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import withScrolling from 'components/ReactDndScrolling';
import { translate } from 'utils/index';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { setActiveQuestion } from 'pages/Editor/reducers';
import { selectActiveQuestion, selectSubject } from 'pages/Editor/selectors';
import Question from 'pages/Editor/questions/question';
import { getOffset } from 'utils/html';
import { Apps } from 'constants/apps';
import { messages } from 'pages/Editor/messages';
import { KeyCodes } from 'constants/keyCodes';
import DraggableQuestion from './draggableQuestion';
import ActiveQuestion from './activeQuestion';
import AddQuestion from './addQuestion';
import EditAnswers from './editAnswers';
import Findings from './findings';
import AppQuestions from './appQuestions';
import { useRole } from 'hooks/useRole';
import './index.scss';

const stages = {
  activeQuestion: 'activeQuestion',
  editAnswers: 'editAnswers',
  findings: 'findings',
  triggers: 'triggers',
};

const ScrollingComponent = withScrolling('div');

const Questions = () => {
  const subject = useSelector(selectSubject);
  const activeQuestion = useSelector(selectActiveQuestion);
  const [questions, setQuestions] = useState([]);
  const [stage, setStage] = useState(stages.activeQuestion);
  const [modalOpened, setModalOpened] = useState(false);
  const [loading, setLoading] = useState();
  const [openVisibleSwitcher, setOpenVisibleSwitcher] = useState(false);
  const questionsRef = useRef([]);
  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const apps = [];
  const { isRestrictedBoSoViewerRole } = useRole();

  const handleSetVisibleSwitcherOpen = (value) => {
    setOpenVisibleSwitcher((state) => {
      if (value === undefined) return !state;
      return value;
    });
  };

  useEffect(() => {
    setQuestions(subject.controls.filter(filterApps));
  }, [subject]);

  useHotkeys(KeyCodes.Esc, () => {
    setStage(stages.activeQuestion);
    dispatch(setActiveQuestion(null));
  });

  const onRemove = async () => {
    setLoading(true);
    const payload = {
      categories: [
        {
          id: subject.id,
          controls: [{ id: activeQuestion }],
        },
      ],
    };
    dispatch(saveTemplate(payload)).unwrap();
    setQuestions((state) => state.filter((question) => question.id !== activeQuestion));
    setLoading(false);
    setModalOpened(false);
  };

  const getAppControls = (apps) => {
    return (subject.controls || []).filter((control) =>
      (control.apps || []).some(
        (app) => apps.includes(app) || (apps.includes(Apps.aws) && app === Apps.azure),
      ),
    );
  };

  const saveReorderedQuestions = async () => {
    const newQuestions = [...questions];
    const index = questions.findIndex(
      (question) =>
        question.apps && (question.apps.includes(Apps.aws) || question.apps.includes(Apps.azure)),
    );

    if (index !== -1) {
      const appControls = getAppControls(questions[index].apps);
      newQuestions.splice(index, 1, ...appControls);
    }

    const updatedPositions = newQuestions.map((question, index) => {
      return {
        ...question,
        position: index,
      };
    });

    const payload = {
      categories: [
        {
          id: subject.id,
          controls: updatedPositions,
        },
      ],
    };

    await dispatch(saveTemplate(payload)).unwrap();
  };

  const reorderQuestions = useCallback((dragIndex, hoverIndex) => {
    setQuestions((prevQuestions) =>
      update(prevQuestions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevQuestions[dragIndex]],
        ],
      }),
    );
  }, []);

  const onClickControl = (control) => {
    if (activeQuestion !== control.id && !isRestrictedBoSoViewerRole) {
      setStage(stages.activeQuestion);
      dispatch(setActiveQuestion(control.id));
    }
  };

  const setFindings = (control, event) => {
    setStage(stages.findings);
    dispatch(setActiveQuestion(control.id));
    event.stopPropagation();
  };

  const setTriggers = (control, event) => {
    setStage(stages.triggers);
    dispatch(setActiveQuestion(control.id));
    event.stopPropagation();

    setTimeout(() => {
      const triggersElement = document.querySelector('.editor-triggers');
      const triggersElementOffset = getOffset(triggersElement);

      window.scroll({
        top: triggersElementOffset.top - 90,
        left: 0,
        behavior: 'smooth',
      });
    });
  };

  const filterApps = (control) => {
    if (!control?.apps || control?.apps?.length === 0) {
      return true;
    }
    if (control?.apps?.some((app) => apps?.includes(app))) {
      return false;
    } else if (control?.apps?.includes(Apps.aws) || control?.apps?.includes(Apps.azure)) {
      apps.push(...control.apps, Apps.aws, Apps.azure);
    } else {
      apps.push(...control.apps);
    }

    return true;
  };

  const openModal = () => {
    setModalOpened(true);
  };

  const renderActiveQuestion = (control, index) => {
    switch (stage) {
      case stages.activeQuestion:
        return (
          <ActiveQuestion
            categoryId={subject.id}
            control={control}
            index={index}
            setFindings={() => setStage(stages.findings)}
            setEditAnswers={() => setStage(stages.editAnswers)}
            onRemoveQuestion={openModal}
            openVisibleSwitcher={openVisibleSwitcher}
            handleSetVisibleSwitcherOpen={handleSetVisibleSwitcherOpen}
          />
        );
      case stages.triggers:
        return (
          <ActiveQuestion
            categoryId={subject.id}
            control={control}
            index={index}
            setFindings={() => setStage(stages.findings)}
            setEditAnswers={() => setStage(stages.editAnswers)}
            onRemoveQuestion={openModal}
            defaultShowTriggers
            openVisibleSwitcher={openVisibleSwitcher}
            handleSetVisibleSwitcherOpen={handleSetVisibleSwitcherOpen}
          />
        );
      case stages.editAnswers:
        return (
          <EditAnswers
            control={control}
            index={index}
            onCancel={() => setStage(stages.activeQuestion)}
            setActiveQuestion={setActiveQuestion}
          />
        );
      case stages.findings:
        return (
          <DndProvider backend={HTML5Backend}>
            <Findings
              control={control}
              index={index}
              onCancel={() => setStage(stages.activeQuestion)}
            />
          </DndProvider>
        );
      default:
        return null;
    }
  };

  return (
    <div className="editor-questions">
      <div>
        <DndProvider backend={HTML5Backend}>
          <ScrollingComponent className="editor-questions__react-dnd-scrolling-wrapper">
            {(questions || []).map((control, index) => {
              const isActive = activeQuestion === control?.id;

              if (control?.apps && control?.apps?.length) {
                return (
                  <DraggableQuestion
                    key={control.id}
                    control={control}
                    index={index}
                    onDrop={saveReorderedQuestions}
                    onClickControl={() => onClickControl(control)}
                    reorderQuestions={reorderQuestions}
                    questionsRef={questionsRef}
                    dragable={!isRestrictedBoSoViewerRole && !subject?.app}
                  >
                    <AppQuestions control={control} />
                  </DraggableQuestion>
                );
              }

              return (
                <DraggableQuestion
                  key={control.id}
                  control={control}
                  index={index}
                  onDrop={saveReorderedQuestions}
                  onClickControl={() => onClickControl(control)}
                  reorderQuestions={reorderQuestions}
                  questionsRef={questionsRef}
                  dragable={!isRestrictedBoSoViewerRole && !subject?.app}
                >
                  {isActive ? (
                    renderActiveQuestion(control, index)
                  ) : (
                    <Question
                      categoryId={subject.id}
                      control={control}
                      index={index}
                      setFindings={(event) => setFindings(control, event)}
                      setTriggers={(event) => setTriggers(control, event)}
                      setOpenVisibleSwitcher={setOpenVisibleSwitcher}
                      disabled={isRestrictedBoSoViewerRole}
                    />
                  )}
                </DraggableQuestion>
              );
            })}
          </ScrollingComponent>
        </DndProvider>
      </div>

      <AddQuestion
        defaultStage={questions.length === 0 ? 'addQuestion' : undefined}
        setActiveQuestion={(controlId) => dispatch(setActiveQuestion(controlId))}
        isMyVendorsTabSelected={isMyVendorsTabSelected}
      />
      <RemoveConfirmationModal
        headerText={translate(messages.removeQuestionHeader)}
        confirmLoading={loading}
        onOk={onRemove}
        onCancel={() => setModalOpened(false)}
        text={translate(messages.removeQuestion)}
        open={!!modalOpened}
      />
    </div>
  );
};

export default Questions;
