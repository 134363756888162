import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { Button, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

// COMPONENTS:
import Icon from 'ui/Icon';
import NotificationsList from 'components/Messenger/notificationsList';

// REDUX:
import { selectUnreadMessages } from 'components/Messenger/selectors';
import { getUnreadChatThreadsNotifications } from 'api/messenger';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

// STYLE:
import './index.scss';

// LAZY IMPORT
const importMessagesPopup = () =>
  lazy(() => {
    return import('components/Messenger/popup');
  });

const Messenger = () => {
  const dispatch = useDispatch();
  const { totalThreads } = useSelector(selectUnreadMessages);
  const [activeDropDown, setActiveDropDown] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const popupComponent = useMemo(() => {
    if (popupOpen) {
      const MessagesPopup = importMessagesPopup();
      return (
        <Suspense fallback={<div>...</div>}>
          <MessagesPopup popupOpen={popupOpen} setPopupOpen={setPopupOpen} />
        </Suspense>
      );
    }
    return null;
  }, [popupOpen]);

  useEffect(() => {
    dispatch(getUnreadChatThreadsNotifications({})).unwrap();
  }, []);

  const unreadCount = useMemo(() => (totalThreads < 100 ? totalThreads : '99+'), [totalThreads]);

  function toggleMessagesDropdown() {
    setIsPopoverOpen((prev) => !prev);
  }

  return (
    <div className="messenger">
      <Popover
        className="messenger__drop-down"
        placement="bottomLeft"
        open={isPopoverOpen}
        onOpenChange={() => toggleMessagesDropdown()}
        showArrow={false}
        content={
          <NotificationsList
            setPopupVisible={setPopupOpen}
            toggleMessagesDropdown={toggleMessagesDropdown}
          />
        }
        trigger="click"
      >
        <Button
          type="link"
          className={classNames('messenger__button', {
            'messenger__button--purple': isMyVendorsTabSelected,
            'messenger__button--active': activeDropDown,
          })}
          onClick={() => setActiveDropDown((state) => !state)}
          onBlur={() => setActiveDropDown(false)}
        >
          <Icon className="messenger__button-ico" icon="messages" />
          {unreadCount ? <span className="messenger__button-counter">{unreadCount}</span> : null}
        </Button>
      </Popover>
      {popupComponent}
    </div>
  );
};

export default Messenger;
