import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getProfile } from 'api/profile';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import AppPreloader from 'ui/Loader/appPreloader';
import { setMixpanelIdentity } from 'utils/mixpanel';
import { selectProfile } from 'pages/Profile/selectors';
import { isProduction, isProductionDomain } from 'utils/index';
import { API_STATUS } from 'constants/api';
import { Roles } from 'constants/roles';

const LoggedUser = ({ children }) => {
  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { organization } = useParams();
  const {
    user: { email },
  } = useSelector(selectProfile);
  const [loading, setLoading] = useState(true);

  const getProfileData = useCallback(async () => {
    setLoading(true);
    const resp = await dispatch(getProfile({ upstream: !isMyVendorsTabSelected })).unwrap();

    if (resp?.status === API_STATUS.SUCCESS) {
      const profile = resp?.profile;

      if (profile && isProductionDomain()) setMixpanelIdentity(profile);

      if (window?.StonlyWidget && profile && isProduction()) {
        window.StonlyWidget('identify', profile.email);
      }
      if (window?.SessionStack && profile && isProductionDomain()) {
        window.SessionStack.identify({
          userId: profile.email,
          email: profile.email,
          displayName: profile.fullname ?? profile.email,
          orgId_str: profile.current_organization?.id || '',
          orgName_str: profile.current_organization?.label || '',
          userRole_str: Roles.getRoleName(profile.current_organization?.role),
          domain_str: window.location.hostname,
        });
      }
    }

    setLoading(false);
  }, [isMyVendorsTabSelected, organization, email]);

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes('organizations-settings') || path.includes('organization-edit')) {
      if (!email) {
        getProfileData().then();
      }
    } else {
      getProfileData().then();
    }
  }, [isMyVendorsTabSelected, organization, email]);

  return loading ? <AppPreloader /> : children;
};

LoggedUser.propTypes = {
  children: PropTypes.any,
};

export default LoggedUser;
