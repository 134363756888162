import { notification } from 'antd';
import { validate } from 'uuid';

const ORGANIZATIONS_STORAGE_KEY = 'Findings:Organizations';
export const DEFAULT_ORGANIZATION_INDEX = 0;

export const setOrganizations = (organizations) => {
  localStorage.setItem(ORGANIZATIONS_STORAGE_KEY, JSON.stringify(organizations));
};

export const getOrganizations = () => {
  try {
    const organizationsFromStorage = localStorage.getItem(ORGANIZATIONS_STORAGE_KEY);

    if (organizationsFromStorage && organizationsFromStorage !== 'undefined') {
      return JSON.parse(organizationsFromStorage);
    }

    return null;
  } catch (error) {
    return notification.error({
      message: 'Organizations',
      description: 'Organizations cant be loaded',
    });
  }
};

export const getOrganizationsById = (id) => {
  const organizations = getOrganizations();
  return organizations?.find((organization) => organization.id === id);
};

export const getOrganizationIndex = ({ organizations, orgId }) => {
  return organizations?.findIndex((organization) => organization.id === orgId);
};

export const getOrganizationIdFromEmailUrl = () => {
  const { 1: organizationIdOrIndex, 4: assessmentId } = window.location.pathname.split('/');
  const isOrganizationEditUrl = window.location.pathname.match('organization-edit');
  const isReportAssessmentUrl = window.location.pathname.includes(
    `assessments/${assessmentId}/report`,
  );
  const isBOUrl = window.location.pathname.includes('/vendor/assessment/request');
  const isSOUrl = window.location.pathname.includes('/customer/assessment/request');

  /** Edit Organization BO, SO and report have an org_id in url which causes conflicts with notification url */
  if (
    !organizationIdOrIndex ||
    !validate(organizationIdOrIndex) ||
    isOrganizationEditUrl ||
    isReportAssessmentUrl ||
    isBOUrl ||
    isSOUrl
  )
    return null;

  return organizationIdOrIndex;
};
