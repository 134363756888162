import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

import { ALLOW_FILES } from 'constants/general';
import { DATE_FORMAT } from 'constants/date';
import { translate } from 'utils/index';
import { getOffset, stripHtml } from 'utils/html';
import { removeEvidence, saveNote, saveRecommendation, uploadEvidences } from 'api/recommendation';
import { selectAssessment } from 'pages/Assessment/selectors';
import Chat from 'components/Chat';
import ChatButton from 'pages/Assessment/chatButton';
import TextArea from 'ui/TextArea';
import DatePicker from 'ui/DatePicker';
import Checkbox from 'ui/Checkbox';
import Approve2 from 'ui/Approve2';
import Icon from 'ui/Icon';
import FileList from 'ui/FileList';
import FileUpload from 'ui/FileUpload';
import SecondaryButton from 'ui/SecondaryButton';
import Note from 'pages/Assessment/note';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import RecommendationStatus from 'constants/recommendationStatus';
import { selectFinding } from 'pages/Assessment/finalized/findings/selectors';
import { setIsEditingAnswer } from 'pages/Assessment/reducers';

import RemoveRecommendation from './removeRecommendation';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import { API_STATUS } from 'constants/api';
import { chatSources } from 'components/Chat/lib';
import { selectCurrentOrganization } from 'pages/Profile/selectors';
import './recommendation.scss';

const Recommendation = ({ recommendation, index, sharedCM }) => {
  const [values, setValues] = useState(recommendation);
  const [searchParams] = useSearchParams();
  const [showNote, setShowNote] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const dispatch = useDispatch();
  const { assessor, assesse, id: assessmentId, customerId } = useSelector(selectAssessment);
  const finding = useSelector(selectFinding);
  const loading = useSelector(
    ({ assessmentRecommendations }) => assessmentRecommendations.recommendationLoading,
  );
  const { id: organizationId } = useSelector(selectCurrentOrganization);

  const [evidenceLoading, setEvidenceLoading] = useState(false);
  const { isRestrictedBoSoViewerRole } = useRole();
  const { control, id: findingId } = finding;
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const {
    comment,
    commentTimestampEdited,
    description,
    generate_description: generatedDescription,
    due,
    force_evidence: forceEvidence,
    id,
  } = values;
  const recommendationIdParam = searchParams.get('recommendationId');

  useEffect(() => {
    setValues(recommendation);
  }, [recommendation]);

  useEffect(() => {
    if (recommendationIdParam === id) {
      setShowChat(true);
    }
  }, [recommendationIdParam]);

  const onSaveTextDebounced = useCallback(
    debounce((field, newValue) => {
      const payload = {
        control,
        findingId,
        recommendation: {
          id,
          [field]: newValue,
        },
      };
      dispatch(saveRecommendation(payload));
    }, 1000),
    [findingId, control, id],
  );

  const onChange = async (field, value) => {
    setValues((state) => ({ ...state, [field]: value }));

    const payload = {
      control,
      findingId,
      recommendation: {
        id,
        [field]: value,
      },
    };

    await dispatch(saveRecommendation(payload)).unwrap();
  };

  const onChangeStatus = async (value) => {
    await onChange('status', value);
    updateAnswer();
  };

  const updateAnswer = () => {
    dispatch(setIsEditingAnswer(true));

    const answerElement = document.getElementById('assessment-finalized-finding-answer');
    const answerElementOffset = getOffset(answerElement);

    window.scroll({
      top: answerElementOffset.top - 142,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onTextChange = (field, { target: { value } }) => {
    setValues((state) => {
      return { ...state, [field]: value };
    });
    onSaveTextDebounced(field, value);
  };

  const onNoteSave = async (comment) => {
    const payload = {
      note: {
        id,
        comment,
      },
    };
    await dispatch(saveNote(payload));
  };

  const onEvidenceUpload = async (files) => {
    const payload = {
      control,
      findingId,
      recommendationId: id,
      files,
    };
    setEvidenceLoading(true);
    const linkFilesResp = await dispatch(uploadEvidences(payload)).unwrap();
    if (linkFilesResp?.status === API_STATUS.SUCCESS) {
      setValues((state) => ({
        ...state,
        evidences: [...state.evidences, ...files],
      }));
    }

    setEvidenceLoading(false);
  };

  const onEvidenceRemove = async (fileId) => {
    setEvidenceLoading(true);
    await dispatch(removeEvidence({ fileId, objectId: id })).unwrap();
    setEvidenceLoading(false);
  };

  return (
    <div key={id} className="assessment-finalized-recommendation">
      <div className="assessment-finalized-recommendation__header">
        <h4 className="assessment-finalized-recommendation__header-text">
          {translate(messages.recommendation)} ({index + 1})
        </h4>
        {assessor && <RemoveRecommendation disabled={isRestrictedBoSoViewerRole} id={id} />}
      </div>
      {assessor ? (
        <TextArea
          className="assessment-finalized-recommendation__description"
          disabled={isRestrictedBoSoViewerRole}
          value={stripHtml(generatedDescription || description)}
          onChange={(event) =>
            onTextChange(generatedDescription ? 'generate_description' : 'description', event)
          }
          placeholder={translate(messages.enterDescription)}
          loading={loading && values.description !== description}
        />
      ) : generatedDescription || description ? (
        <p>{stripHtml(generatedDescription || description)}</p>
      ) : (
        <p className="assessment-finalized-recommendation__description-empty">
          {translate(messages.emptyRecommendation)}
        </p>
      )}

      {isMyVendorsTabSelected && (showNote || comment) && (
        <Note
          description={comment}
          date={commentTimestampEdited}
          editMode={showNote}
          setEditMode={setShowNote}
          onSave={onNoteSave}
        />
      )}
      {assessor ? (
        <>
          <DatePicker
            className="assessment-finalized-recommendation__due-date"
            disabled={isRestrictedBoSoViewerRole}
            value={values?.due}
            onChange={(date) => onChange('due', date)}
            allowClear={false}
          />
          <Checkbox
            checked={values.force_evidence}
            className="assessment-finalized-recommendation__force-evidence"
            disabled={isRestrictedBoSoViewerRole}
            onChange={({ target: { checked } }) => onChange('force_evidence', checked)}
            name="force_evidence"
            mode="checkbox"
          >
            {translate(messages.vendorMustUploadEvidence)}
          </Checkbox>
        </>
      ) : (
        <div className="assessment-finalized-recommendation__due-date-wrapper">
          {due ? (
            <>
              <label className="assessment-finalized-recommendation__due-date-label">
                <Icon icon="date" />
                {translate(messages.dueDate)}:
              </label>
              <div className="assessment-finalized-recommendation__due-date-text">
                {moment(due).format(DATE_FORMAT)}
              </div>
            </>
          ) : null}

          {forceEvidence && (
            <div className="assessment-finalized-recommendation__evidence">
              - {translate(messages.vendorMustUploadEvidence)} -
            </div>
          )}
        </div>
      )}

      <FileList
        files={values.evidences}
        handleRemove={onEvidenceRemove}
        loading={evidenceLoading}
      />
      <div className="assessment-finalized-recommendation__action-bar">
        <FileUpload
          accept={ALLOW_FILES}
          className="assessment-finalized-recommendation__upload-button"
          disabled={isRestrictedBoSoViewerRole}
          handleUpload={onEvidenceUpload}
          button={
            <SecondaryButton
              className="assessment-finalized-recommendation__upload-button"
              icon="upload"
              tooltip={translate(messages.upload)}
              link
            />
          }
        />
        {sharedCM ? (
          <ChatButton
            data-test="chat-button-recommendation-shared"
            toggleChat={() => setShowChat((state) => !state)}
            threadId={id}
          />
        ) : null}
        {isMyVendorsTabSelected && (
          <SecondaryButton
            className="assessment-finalized-recommendation__note-button"
            onClick={() => setShowNote((state) => !state)}
            icon="note"
            tooltip={translate(messages.note)}
            link
          />
        )}
        {assesse && (
          <Tooltip title={translate(messages.vendorCompletion)}>
            <Checkbox.CompletedCheckbox
              defaultLabel={translate(messages.markComplete)}
              checkedLabel={translate(messages.completed)}
              checked={
                values.status === RecommendationStatus.approved ||
                values.status === RecommendationStatus.closed
              }
              className="assessment-finalized-recommendation__mark-complete-button"
              disabled={isRestrictedBoSoViewerRole}
              onChange={({ target: { checked } }) =>
                onChange(
                  'status',
                  checked ? RecommendationStatus.approved : RecommendationStatus.open,
                )
              }
              name={`recommendationComplete-${id}`}
            />
          </Tooltip>
        )}
        {assessor && (
          <div className="assessment-finalized-recommendation__approve-button-wrapper">
            {values.status === RecommendationStatus.approved && (
              <span className="assessment-finalized-recommendation__pending-status">
                {translate(messages.pendingApproval)}
              </span>
            )}
            <Approve2
              className="assessment-finalized-recommendation__approve-button"
              onApprove={() => onChangeStatus(RecommendationStatus.closed)}
              onReject={() => onChangeStatus(RecommendationStatus.reject)}
              onClear={() => onChangeStatus(RecommendationStatus.approved)}
              approveState={RecommendationStatus.closed}
              rejectState={RecommendationStatus.reject}
              disabled={values.status === RecommendationStatus.open || isRestrictedBoSoViewerRole}
              value={values.status === 2 ? 0 : values.status}
            />
          </div>
        )}
      </div>
      {showChat && (
        <Chat
          disabled={isRestrictedBoSoViewerRole}
          threadId={id}
          threadTitle={stripHtml(values.description)}
          close={() => setShowChat(false)}
          findingId={findingId}
          recommendationId={id}
          assessmentId={assessmentId}
          source={chatSources.cmRecommendation}
          relation={customerId || organizationId}
        />
      )}
    </div>
  );
};

Recommendation.propTypes = {
  index: PropTypes.number,
  recommendation: PropTypes.object,
  sharedCM: PropTypes.bool,
};

export default Recommendation;
