import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Icon from 'ui/Icon';
import Loader from 'ui/Loader';
import Button from 'ui/Button';
import SecondaryButton from 'ui/SecondaryButton';
import LinkedQuestions from 'pages/Editor/questions/linkedQuestions';

import { translate } from 'utils/index';
import { messages } from 'pages/Editor/messages';
import { generalMessages } from 'constants/messages';
import { calculateControlKey } from 'pages/Assessment/lib';
import { getAnswerTypes, saveAnswerType, saveTemplate } from 'api/editor';
import {
  getDefaultAnswer,
  isEditableAnswerType,
  checkForMissingOptionsInAnswerType,
} from 'utils/controls';
import { stripHtml } from 'utils/html';

import AnswerTypeSelector from 'pages/Editor/questions/answerCreator/answerTypeSelector';
import Answer from 'pages/Editor/questions/answerCreator/answer';
import AnswerBank from 'pages/Editor/questions/answerBank';
import {
  selectAllAnswerTypes,
  selectAnswerTypes,
  selectSubject,
  selectSubjectKey,
} from 'pages/Editor/selectors';
import './index.scss';
import useLinkedQuestions from '../useLinkedQuestions';

const EditAnswers = ({ control: defaultControl, index, onCancel, setActiveQuestion }) => {
  const subjectKey = useSelector(selectSubjectKey);
  const subject = useSelector(selectSubject);
  const answerTypes = useSelector(selectAllAnswerTypes);
  const templateAnswerTypes = useSelector(selectAnswerTypes);
  const [selectedType, selectType] = useState(
    templateAnswerTypes[defaultControl?.answer_type_idx]?.type,
  );
  const [control, setControl] = useState(defaultControl);
  const [answerType, setAnswerType] = useState(
    checkForMissingOptionsInAnswerType(templateAnswerTypes[defaultControl?.answer_type_idx]),
  );
  const [answerTypeIsDirty, setAnswerTypeIsDirty] = useState();
  const [showAnswerBank, setShowAnswerBank] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const linkedQuestions = useLinkedQuestions(answerType.id);

  const isNewAnswerType = !answerTypes.find(({ id }) => id === answerType.id);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const currentAnswerType = answerTypes.find(({ id }) => id === answerType.id);
    const answerTypeHasChanged =
      currentAnswerType &&
      JSON.stringify(answerType.options) !== JSON.stringify(currentAnswerType.options);

    if (isEditableAnswerType(answerType.type) && !isNewAnswerType && answerTypeHasChanged) {
      setAnswerTypeIsDirty(true);
    } else {
      setAnswerTypeIsDirty(false);
    }
  }, [answerType]);

  const onSelectType = (type) => {
    selectType(type);

    setControl((state) => ({ ...state, answer: getDefaultAnswer(type) }));

    if (isEditableAnswerType(type)) {
      setAnswerType((state) => ({
        ...state,
        id: uuidv4(),
        type,
        options: [{ key: 1, weight: 0, value: '' }],
      }));
    } else {
      // eslint-disable-next-line no-shadow
      const answerType = answerTypes.find(({ type: answerTypeType }) => answerTypeType === type);
      setAnswerType(answerType);
      setControl((state) => ({ ...state, answer_type: answerType.id }));
    }
  };

  const selectAnswerType = async (value) => {
    setControl((state) => ({
      ...state,
      answer_type: value,
      answer: getDefaultAnswer(value),
    }));

    // eslint-disable-next-line no-shadow
    const answerType = answerTypes.find(({ id }) => id === value);
    selectType(answerType.type);
    setAnswerType(answerType);
  };

  const onSaveAnswerType = async (saveOnlyForThisQuestion) => {
    if (saveOnlyForThisQuestion) answerType.id = uuidv4();
    const result = await dispatch(saveAnswerType(answerType)).unwrap();
    return result.answer_type.id;
  };

  const onSaveQuestion = async (args = {}) => {
    const payload = {
      categories: [
        {
          id: subject.id,
          controls: [
            {
              ...control,
              ...args,
            },
          ],
        },
      ],
    };
    await dispatch(saveTemplate(payload)).unwrap();
  };

  const onSave = async (overrideAnswerType) => {
    setLoading(true);
    if (isNewAnswerType || answerTypeIsDirty) {
      const id = await onSaveAnswerType(overrideAnswerType);
      await dispatch(getAnswerTypes()).unwrap();
      await onSaveQuestion({ answer_type: id });
    } else {
      await onSaveQuestion();
    }
    setLoading(false);
    onCancel();
  };

  return (
    <div className="editor-edit-answers">
      <div className="editor-edit-answers__header">
        <Icon icon="edit" />
        {translate(messages.editAnswersText)}
      </div>
      <div className="editor-edit-answers__main">
        <div className="editor-edit-answers__key">{calculateControlKey(subjectKey, index)}</div>
        <div className="editor-edit-answers__wrapper">
          <div className="editor-edit-answers__title">{stripHtml(control.title)}</div>
          <div className="editor-edit-answers__answer-type">
            <AnswerTypeSelector onChange={onSelectType} value={selectedType} />
            <div>{translate(messages.or)}</div>
            <SecondaryButton
              data-test={`editor-edit-answers-button-answer-bank-${index}`}
              onClick={() => setShowAnswerBank(true)}
              link
            >
              {translate(messages.openAnswerBank)}
            </SecondaryButton>
          </div>
          <Answer
            answerType={answerType}
            control={control}
            onValueChange={(value) => setControl((state) => ({ ...state, answer: value }))}
            setAnswerType={setAnswerType}
            type={selectedType}
            value={control.answer}
          />
        </div>
      </div>
      <div className="editor-edit-answers__action-bar">
        <div className="editor-edit-answers__action-bar-left-area">
          <Button
            className="editor-edit-answers__footer-cancel-button"
            size="sm"
            color="gray"
            onClick={onCancel}
            link
            uppercase
            width={100}
            data-test="editor-edit-answers-button-cancel"
          >
            {translate(generalMessages.cancel)}
          </Button>
          <LinkedQuestions
            activeControlAnswerType={answerType}
            setActiveQuestion={setActiveQuestion}
            defaultControl={defaultControl}
          />
        </div>
        {answerTypeIsDirty ||
        defaultControl.answer !== control.answer ||
        defaultControl.answer_type_idx !== answerType.id ? (
          <>
            {linkedQuestions.length > 1 ? (
              <SecondaryButton
                className="editor-edit-answers__footer-ok2-button"
                onClick={() => onSave(true)}
                size="medium"
                type="submit"
                outline
                uppercase
              >
                {translate(messages.saveOnlyForThisQuestion)}
              </SecondaryButton>
            ) : null}

            <SecondaryButton
              className="editor-edit-answers__footer-ok-button"
              data-test={`editor-edit-answers-button-save-${index}`}
              onClick={() => onSave(false)}
              size="medium"
              type="submit"
              uppercase
            >
              {translate(linkedQuestions.length > 1 ? messages.saveForAll : messages.saveChanges)}
            </SecondaryButton>
          </>
        ) : (
          <SecondaryButton
            className="editor-edit-answers__footer-ok-button"
            data-test={`editor-edit-answers-button-save-${index}`}
            onClick={onSave}
            size="medium"
            type="submit"
            outline
            uppercase
            disabled
          >
            {translate(messages.saveChanges)}
          </SecondaryButton>
        )}
      </div>
      <AnswerBank
        open={showAnswerBank}
        toggleModal={setShowAnswerBank}
        selectAnswerType={selectAnswerType}
      />
      {loading && <Loader />}
    </div>
  );
};

EditAnswers.propTypes = {
  control: PropTypes.object,
  index: PropTypes.number,
  onCancel: PropTypes.func,
  setActiveQuestion: PropTypes.func,
};

export default EditAnswers;
